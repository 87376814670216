import React from 'react'
import PageBase from '../pagebase';
import { Link } from 'react-router-dom';
import { PUBLISH_STATE, PREFECTURES, SCHOOL, BASE_TITLE } from '../../../config';
import { getCourseDetail } from '../../../api/course';
import DetailPageHeader from './detailPageHeader';
import DetailPageFooter from './detailPageFooter';
import { formatDateTimeRange, getIsOnlineImage } from '../../../utils';

export default class Class extends PageBase {

	static get path() {
		return '/courses/:course_id/course_target_ages/:course_target_age_id/course_classes/:class_id'
	}

	static get title() {
		if(this.instance.state.school == "regular") {
			return "レギュラースクール"
		}else if(this.instance.state.school == "short_term") {
			return "短期スクール"
		}
		return ""
	}

	static get title_en() {
		if(this.instance.state.school == "regular") {
			return "regularschool"
		}else if(this.instance.state.school == "short_term") {
			return "shortschool"
		}
		return ""
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					{this.title_en != "" &&
						<img src={`/assets/images/common/txt-${this.title_en}.svg`} alt={this.title} />
					}
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		if(this.instance.state.school == "short_term") {
			return false
		} else {
			return true
		}
	}

	constructor(props) {
		super(props)
		this.course_id = parseInt(this.props.match.params.course_id);
		this.course_target_age_id =parseInt(this.props.match.params.course_target_age_id);
		this.class_id = parseInt(this.props.match.params.class_id);

		this.state = {
			...this.state,
			isDataLoaded: false,
			school: '',
			isGetData: false
		}

		Class.instance = this

	}

	componentDidMount() {
		super.componentDidMount(false)
		const detailData = getCourseDetail(this.course_id, this.course_target_age_id)
		detailData.then((data)=>{
			console.log(data);
			this.setState({
				isDataLoaded: true,
				detailData: data,
				school: data.course.school,
				isGetData: true
			})
		})
	}

	componentDidUpdate () {
		super.componentDidUpdate()
		if(this.state.isGetData) {
			document.title = `${this.state.detailData.course.name} | ${this.state.detailData.course_target_age.target_age_label} | ${BASE_TITLE}`
			this.sendGa()
			this.setState({isGetData: false})
		}
	}

	renderChild () {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const detailData = this.state.detailData
			const {course_target_age, course_classes, navigators, course} = detailData
			// クラスIDから詳細情報を取得
			const class_data = (()=> {
				let target = {};
				course_classes.some((data, i)=>{
					if (data.id === this.class_id) {
						target = data
						return true
					}
				})
				return target
			})()
			const canApply = class_data.entry_state === 'accepting' || class_data.entry_state === 'cancel_waiting'
			return (
				<>
					<section className="school-detail">
						<div className="school-detail__wrap">

							{/* ページヘッダー */}
							<DetailPageHeader kind="class" school={this.school} detailData={detailData} />

							<div className="school-detail__description">

								{/* クラス名 */}
								{class_data.name &&
									<div className="school-detail__description__subtitle">
										{/* 改行コードごとにbrいれてあげる */}
										{class_data.name.split("\n").length > 0 && class_data.name.split("\n").map((txt, key) => {
											return (
												<React.Fragment key={key}>
													{txt}
													<br/>
												</React.Fragment>
											)
										})}

										<div className="school-detail__description__subtitle--statetag">
											{class_data.entry_state === 'in_preparation' &&
												<span className="school-detail__description__subtitle__icon standby">準備中</span>
											}
											{class_data.entry_state === 'accepting' &&
												<span className="school-detail__description__subtitle__icon entry-remain">受付中&ensp;<span className="small">残席あり</span></span>
											}
											{class_data.entry_state === 'cancel_waiting' &&
												<span className="school-detail__description__subtitle__icon entry-cancel">受付中&ensp;<span className="small">空席待ち</span></span>
											}
											{class_data.entry_state === 'closed' &&
												<span className="school-detail__description__subtitle__icon end">募集終了</span>
											}

											<span className="school-detail__description__subtitle__icon online">
												{getIsOnlineImage(course.is_online)}
											</span>
										</div>

									</div>
								}


								<div className="school-detail__description__list">

									{class_data.description &&
										<dl>
											<dt>日程概要</dt>
											<dd>
												{class_data.description.split('\n').map((str, index) => {
													return(
														<React.Fragment key={index}>{str}<br /></React.Fragment>
													)
												})}
											</dd>
										</dl>
									}

									<dl>
										<dt>開催地域</dt>
										{course.is_online &&
											<dd>このコースはオンラインです</dd>
										}
										{!course.is_online && class_data.prefecture_id === null &&
											<dd>開催地域未定</dd>
										}
										{!course.is_online && class_data.prefecture_id !== null &&
											<dd>{PREFECTURES[class_data.prefecture_id]}</dd>
										}
									</dl>
								</div>

								{/* 日程詳細 */}
								{class_data.schedules.length > 0 &&
									<div className="school-detail__description__list">
										<div className="school-detail__description__list__title"><span>日程詳細</span></div>
										{class_data.schedules.map((data, i)=>{
											const date = formatDateTimeRange(data.started_at, data.finished_at)
											const place = data.place
											return (
												<dl className="dtnarrow" key={i}>
													<dt>{i + 1}</dt>
													<dd>
														{course.is_online &&
															<span>{date}</span>
														}
														{!course.is_online &&
															<span>
																<span className="school-detail__description__list__text">
																	{date}<br/>
																	{/* NOTE: 会場不完全 */}
																	会場：{place.name}
																</span>
																{/* [未実装]地図 */}
																{/* 緯度と経度が入ってきたので Google Maps API */}

																<span className="school-detail__description__list__mapbtn">
																	{/* NOTE: これでリンク先にとんでも緯度と経度の訳わからん名前の情報が出てくる Googlemaps API必要？ */}
																	<a href={`https://www.google.com/maps?q=${place.lat},${place.lng}`} target="_blank">MAP</a>
																</span>

															</span>
														}
													</dd>
												</dl>
											)
										})}
									</div>
								}

							</div>

							<DetailPageFooter school={this.state.school} canApply={canApply} detailData={detailData} classId={this.class_id} />
						</div>
					</section>

					<div className="school__topbtn">
						<Link to={`/courses/${this.course_id}/course_target_ages/${this.course_target_age_id}/`}>
							<span>コーストップ</span>
						</Link>
					</div>
				</>
			)
		}
	}
}