import ApplicationBase from '../applicationBase'

export default class ShortSchoolApplication extends ApplicationBase {
	// ---------- 基本設定 ----------
	static get path() {
		return '/me/shortschool/application.html'
	}

	static get title() {
		return 'クラス選択画面　(短期スクール)'
	}

	static get title_en() {
		return 'shortschool_application'
	}

	// ---------- 基本設定ここまで ----------
	get path() {
		return 'shortschool'
	}
}