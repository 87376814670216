import React from 'react'
import { Link } from 'react-router-dom';
import { SCHOOL } from '../../../config';
import { getIsOnlineImage } from '../../../utils';
import Wave from '../../component/common/wave';
import Login from '../login';

export default class DetailPageHeader extends React.Component {


	/**
	 * render
	 */
	render() {
		const school = this.props.school
		const course_target_age = this.props.detailData.course_target_age
		const course = this.props.detailData.course

		return (
			<header>
				{/* メイン画像 */}
				{course_target_age.main_image_url &&
					<div className="school-detail__pct">
						<img src={course_target_age.main_image_url} alt=""/>
						<Wave />
					</div>
				}

				{/* コース名画像 */}
				{course_target_age.name_image_url &&
					<div className="school-detail__title socialcreativecourse">
						<picture>
							<source srcSet={course_target_age.name_image_url} media="(max-width: 768px)" />
							<img src={course_target_age.name_image_url} alt=""/>
						</picture>
					</div>
				}

				{school === SCHOOL.short && course.short_term_school_name &&
					<div className="school-detail__schooltitle">{course.short_term_school_name}</div>
				}

				{school === SCHOOL.short && course.name &&
					<div className="school-detail__schoolsubtitle">
						{/* 改行コードごとにbrいれてあげる */}
						{course.name.split("\n").length > 0 && course.name.split("\n").map((txt, key) => {
							return (
								<React.Fragment key={key}>
									{txt}
									<br/>
								</React.Fragment>
							)
						})}
					</div>
				}

				<div className="school-detail__status">
					<span className="school-detail__status__target">対象年齢：{course_target_age.target_age_label}</span>

					<div className="school-detail__status--statetag">
						{/* NOTE: 年齢詳細では出すが、クラス詳細ではクラスに紐付けて出したいのでHeaderでは出さない 2021/08の改修 */}
						{this.props.kind == "detail" &&
							<>
								{/* 受付ステータス */}
								{course_target_age.entry_state === 'in_preparation' &&
									<span className="school-detail__status__icon standby">準備中</span>
								}
								{course_target_age.entry_state === 'accepting' &&
									<span className="school-detail__status__icon entry-remain">受付中&ensp;<span className="small">残席あり</span></span>
								}
								{course_target_age.entry_state === 'cancel_waiting' &&
									<span className="school-detail__status__icon entry-cancel">受付中&ensp;<span className="small">空席待ち</span></span>
								}
								{course_target_age.entry_state === 'closed' &&
									<span className="school-detail__status__icon end">募集終了</span>
								}

								<span className="school-detail__status__icon online">
									{getIsOnlineImage(course.is_online)}
								</span>
							</>
						}
					</div>
				</div>

				{/* アーカイブ時 */}
				{this.props.publishState == 'past' && course_target_age.report_url &&
					<div className="school-detail__btn">
						<a href={course_target_age.report_url} target="_blank"><span>活動レポートはこちら</span></a>
					</div>
				}
			</header>
		)
	}
}