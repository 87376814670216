import React from 'react'
import { Link } from 'react-router-dom';
import AuthorizedPageBase from '../authorizedpagebase';
import { getCookie, deleteCookie } from '../../../utils'

export default class RegisterComplete extends AuthorizedPageBase {
	static get path() {
		return '/register/complete.html'
	}

	static get title() {
		return 'スコップ・スクールアカウントの登録完了'
	}

	static get title_en() {
		return 'parentsinfo'
	}

	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			courseUrl: null
		}
	}

	componentDidMount() {
		super.componentDidMount()
		const url = getCookie('page_after_login')
		if (url) {
			this.setState({
				courseUrl: url
			})
		}
	}

	/**
	 * render
	 */
	renderChild() {
		const url = this.state.courseUrl
		return (
			<section className="form-information">
				<div className="form-information-wrap">
					<div className="form-information__title">アカウントの登録が<br className="sp-view"/>完了しました</div>
					<div className="form-information__text topnarrow">スコップ・スクールのアカウントにご登録をいただきまして、ありがとうございます！<br/>ご登録いただいたLINEIDによってマイページへのログインができるようになります。</div>
						{url ? 
							<div className="form-information__btn">
								<Link to={url} onClick={()=>deleteCookie('page_after_login')}><span>クラス選択画面へ</span></Link>
							</div>
						:
							<div className="form-information__btn">
								<Link to="/me/"><span>マイページトップ</span></Link>
							</div>
						}
				</div>
			</section>
		)
	}
}