import { API } from '../config'
import { getApiKey } from './utils'
import Auth, { AuthError } from "./auth"

export async function getReportList() {

	const apiKey = await getApiKey()

	const res = await fetch(API.REPORTS, {
		headers: new Headers({
			'x-api-key': apiKey,
			'Authorization': 'Token token=' + Auth.accessToken,
		})
	})
	const json = await res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}

export async function getReport(report_id) {

	const apiKey = await getApiKey()

	const URL = API.REPORT + "/" + report_id + ".json"

	const res = await fetch(URL, {
		headers: new Headers({
			'x-api-key': apiKey,
			'Authorization': 'Token token=' + Auth.accessToken,
		}),
		method: "GET"
	})
	const json = await res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}