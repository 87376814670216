import React from 'react'
import { SCHOOL } from '../../../../config'
import { getDeviceInfo } from '../../../../utils'
import ListBase from '../listBase'

export default class Archive extends ListBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/archive/'
	}

	static get title() {
		return '過去のプログラム一覧'
	}

	static get title_en() {
		return 'archive-short'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-archive.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props, SCHOOL.short , 'past')
	}

	/**
	 * リード文表示用
	 */
	getLeadContents(isAllNonAccepting) {
		if(getDeviceInfo().isPC) {
			return (
				<div className="top-course__lead">
					<p>過去に実施したプログラム。<br/>今後も様々なテーマや領域のプログラムを開発してゆく予定です。</p>
				</div>
			)
		}else {
			return (
				<div className="top-course__lead">
					<p>過去に実施したプログラム。<br/>今後も様々なテーマや領域の<br/>プログラムを開発してゆく予定です。</p>
				</div>
			)
		}
	}
}