import React from 'react'
import PageBase from './pagebase'

export default class Privacy extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/test.html'
	}

	static get title() {
		return 'テストテスト'
	}

	static get title_en() {
		return 'testtest'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="test">
					{/* <img src="/assets/images/common/txt-privacy.svg" alt={this.title} /> */}
					テスト
				</span>
			</div>
		)
	}

	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<div>
				{/* <section className="policy">
					<div className="policy__wrap">
						<div className="policy__detail">
							<p className="policy__detail__text">株式会社スコップでは、（以下「当社」という。）は、 ホームページに関するプライバシーポリシーを以下のように定めます。このプライバシーポリシーに従い、ホームページにおいて提供するサービス（ホームページによる情報提供、お問い合わせ・お申込）によって収集した個人情報は、利用目的の範囲内で適切に取り扱います。当該情報は、プライバシー保護の観点から厳格に管理し適切に取り扱います。</p>
							<h2 className="policy__detail__title">１．関係法令・ガイドライン等の遵守</h2>
							<p className="policy__detail__text">当社は、個人情報保護法その他の法令及び「個人情報の保護に関する法律についてのガイドライン」その他のガイドラインおよび当社が定める「<a href="privacypolicy.html">個人情報の取り扱いについて</a>」を遵守して、個人情報の適正な取扱いを行います。</p>
							<h2 className="policy__detail__title">２．利用目的</h2>
							<p className="policy__detail__text">取得させていただく個人情報は、お問い合わせの回答や確認のご連絡、その他個別に定める目的のために利用し、通知・公表されている目的以外での利用はしません。</p>
							<h2 className="policy__detail__title">３．取得方法</h2>
							<p className="policy__detail__text">個人情報は、Webページのフォームにお名前、電話番号、LINEID、メールアドレス等ご入力いただくこと、その他の適法かつ公正な手段により取得します。</p>
							<h2 className="policy__detail__title">４．管理方法</h2>
							<p className="policy__detail__text">当サイトは、収集した個人情報の漏洩、滅失、き損の防止など個人情報の適切な管理を行います。また、収集した個人情報がサービスに必要なくなった場合、速やかに消去または破棄します。</p>
							<h2 className="policy__detail__title">５．安全対策</h2>
							<p className="policy__detail__text">
								当社は、不正アクセス・紛失・破壊・改ざん、及び漏洩等を防止するために適切な安全管理措置を講じます。<br/>
								<br/>
								（基本方針の策定）<br/>
								<span className="indent">・個人情報の適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針を策定</span>
								<br/>
								（個人情報の取扱いに係る規律の整備）<br/>
								<span className="indent">・取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報管理規則を策定</span>
								<br/>
								（組織的安全管理措置）<br/>
								<span className="indent">・個人情報の取扱いに関する責任者を設置するとともに、個人情報を取り扱う従業者及び当該従業者が取り扱う個人情報の範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備</span>
								<span className="indent">・個人情報の取扱状況について、定期的な自己点検を実施</span>
								<br/>
								（人的安全管理措置）<br/>
								<span className="indent">・個人情報の取扱いに関する留意事項について、従業者に定期的に周知</span>
								<span className="indent">・個人情報の秘密保持に関する事項を就業規則に記載</span>
								<br/>
								（物理的安全管理措置）<br/>
								<span className="indent">・個人情報を取り扱う権限を有しない者による個人情報の閲覧を防止する措置を実施</span>
								<span className="indent">・個人情報を取り扱う機器、電子媒体等を持ち運ぶ場合、容易に個人情報が判明しないよう措置を実施</span>
								<br/>
								（技術的安全管理措置）<br/>
								<span className="indent">・アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定</span>
								<span className="indent">・個人情報を取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入</span>
								<br/>
								（外的環境）<br/>
								<span className="indent">・個人情報を国外で取り扱う場合には、当該国における個人情報の保護に関する制度を把握した上で安全管理措置を実施</span>
							</p>
							<h2 className="policy__detail__title">６．開示、訂正、削除</h2>
							<p className="policy__detail__text">当社が収集した個人情報に関して、開示の請求、内容の訂正・追加・削除の請求、利用の停止・消去の請求、第三者提供の停止の請求を希望される場合には、info@schopschool.comまでご連絡ください。当社が適切であると判断した場合には合理的な範囲で対応いたします。</p>
							<h2 className="policy__detail__title">７．個人情報の第三者提供</h2>
							<p className="policy__detail__text">当社では、「<a href="privacypolicy.html">個人情報の取り扱いについて</a>」で定める場合、子どもの能力に関する研究開発の目的で共同研究機関（大学・企業等）に対して提供する場合および本人から個別に同意を得た場合を除いては、個人情報を第三者に開示することはありません。</p>
							<h2 className="policy__detail__title">８．個人情報の委託</h2>
							<p className="policy__detail__text">個人情報について、当社の個人情報保護管理体制が一定の水準に達していると認めた情報処理業者などに預託（委託）する場合があります。その場合には当社の責任で適切な委託先を選定し、個人情報の取扱いに関する契約を締結した上で委託いたします。</p>
							<h2 className="policy__detail__title">９．Cookieについて</h2>
							<p className="policy__detail__text">当社は、管理するウェブサイトおよびサービス上において、以下の情報収集モジュールおよびCookieを利用しております。本サイトにおける情報収集モジュールの内容およびCookieの取扱いについては、<a href="cookie.html">Cookieポリシー</a>のご確認をお願いいたします。</p>
							<h2 className="policy__detail__title">１０．適用範囲</h2>
							<p className="policy__detail__text">本プライバシーポリシーは、当サイトにおいてのみ適用いたします。</p>
							<h2 className="policy__detail__title">１１．本プライバシーポリシーの変更</h2>
							<p className="policy__detail__text">当社では、以上のプライバシーポリシーを改訂することがあります。改訂する場合は、このホームページでお知らせします。</p>
							<p className="policy__detail__text">個人情報に関するお問い合わせ先<br/>株式会社スコップ<br/>代表取締役　社長執行役員CEO　金子薫平<br/>東京都港区東新橋1-8-1<br/>info@schopschool.com</p>
						</div>
					</div>
				</section> */}
			</div>
		)
	}
}