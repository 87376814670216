import React from 'react'
import PageBase from './pagebase'

export default class Ie extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/ie.html'
	}

	static get title() {
		return ''
	}

	static get title_en() {
		return 'ie'
	}

	static get header_title() {
		return null
	}
	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<div>
				<section className="header">
					<div className="header__wrap"></div>
				</section>
				<section className="browser">
					<div className="browser__wrap">
						<div className="browser__logo">
							<img src="/assets/images/common/pct-browser.svg" alt="スコップ・スクール" />
						</div>
						<div className="browser__text">大変恐れ入りますが、お使いのブラウザは<br className="sp-view"/>スコップ・スクールではサポートされておりません。<br/>下記ブラウザの最新バージョンでご利用いただきますよう<br className="sp-view"/>よろしくお願いいたします。</div>
						<div className="browser__link">
							<ul>
								<li><a href="https://www.google.co.jp/chrome/" target="_blank">GoogleChrome</a></li>
								<li><a href="https://www.microsoft.com/ja-jp/edge" target="_blank">Microsoft Edge</a></li>
							</ul>
						</div>
					</div>
				</section>
			</div>
		)
	}
}