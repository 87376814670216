import React from 'react'
import FaqModule from '../../modules/faq';
import ScrollLink from '../component/common/scrolllink';
import PageBase from './pagebase';

export default class Faq extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/faq.html'
	}

	static get title() {
		return 'よくあるご質問'
	}

	static get title_en() {
		return 'law'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-faq.svg" alt={this.title} />
				</span>
			</div>
		)
	}


	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	// コンポーネントが表示されるとき
	componentDidMount() {
		super.componentDidMount()
		this.faqModule = new FaqModule()
	}

	// コンポーネントが非表示になるとき
	componentWillUnmount() {
		super.componentWillUnmount()
	}

	renderChild () {
		return (
				<div>
				<section className="faq-pagelink">
					<div className="faq-pagelink__list">
						<ul>
							<li><ScrollLink to="#p01"><span>説明会・入会関連</span></ScrollLink></li>
							<li><ScrollLink to="#p02"><span>授業関連</span></ScrollLink></li>
							<li><ScrollLink to="#p03"><span>運営関連</span></ScrollLink></li>
							<li><ScrollLink to="#p04"><span>受講料関連</span></ScrollLink></li>
							<li ><ScrollLink to="#p05"><span>受講スタイル</span></ScrollLink></li>
						</ul>
					</div>
				</section>
				<section className="faq-list"><span id="p01"></span>
					<div className="faq-list__wrap">
						<div className="faq-list__title">説明会・体験会・入会関連</div>
						<div className="faq-list__detail">
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">どのように入会をすればよいでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-panel">まずは説明会にご参加頂き、その後、体験会に参加ください。体験を受けた後に、入会のお申し込みを頂き、面談を行ったうえで入会となります。リアルスクールコースについては、お近くのスクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">説明会は行っているのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">はい、行っております。説明会のページから、ご希望の日程をご選択下さい。リアルスクールコースについては、お近くのスクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">体験会は行っているのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">説明会の後に行っております。必ず説明会にご参加の後、ご希望の日程をご選択下さい。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">連絡を取りたい場合はどうすればよいでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">問い合わせフォームまたは電話番号へご連絡をお願いします。お電話の場合、タイミング的に連絡が取れない場合がありますので、その際はこちらから折り返しご連絡をさせて頂きます。リアルスクールコースについては、お近くのスクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">「満席」の場合はどうすればよいでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">ご希望のクラスが「満席」の場合、「空席」が出ましたら、お申し込み順にご案内をさせて頂きますので、まずは説明会・体験会にご参加を頂き、入会のお申し込みをお願い致します。</div>
								</dd>
							</dl>
						</div>
					</div><span id="p02"></span>
					<div className="faq-list__wrap">
						<div className="faq-list__title">授業関連</div>
						<div className="faq-list__detail">
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">対象学年は何年生でしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-panel">オンラインプレミアムコースについては、「小1・2生+保護者」と「小3-6生」のカテゴリーで行っています。リアルスクールコースは「小1・2生」「小3-6生」のカテゴリーで行っています。短期スクールでは学年区分が変わることがあります。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">使用する教材などはあるのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-panel">それぞれのコースごとに必要な教材を、オリジナルのSK（スコップ・スクール・キット）という形でお渡しします。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">親が参加できない場合は、どうすればよいでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-panel">オンラインプレミアムコースについては、小1-2生は、親子での参加をお願いしております。もし親子参加が難しい場合、お子様だけでの参加でも可能ですが、内容によっては、プログラムへの参加ができない場合もあります。</div>
								</dd>
							</dl>
						</div>
					</div><span id="p03"></span>
					<div className="faq-list__wrap">
						<div className="faq-list__title">運営関連</div>
						<div className="faq-list__detail">
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">休んだ時の対応はどうなりますか？（振替はできますか？）</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">オンラインプレミアムコースについては、月に1回振替が可能です。リアルスクールコースについては、各スクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">遅刻や欠席の場合の連絡はどうすればよいですか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">オンラインプレミアムコースについては、ID登録時に、お友達になったLINEよりご連絡をお願いします。リアルスクールコースについては、各スクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">質問などがあった場合どうすればよいですか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">問い合わせフォームまたは電話番号へご連絡をお願いします。お電話の場合、タイミング的に連絡が取れない場合がありますので、その際はこちらから折り返しご連絡をさせて頂きます。リアルスクールコースについては、各スクールにお問い合わせください。</div>
								</dd>
							</dl>
						</div>
					</div><span id="p04"></span>
					<div className="faq-list__wrap">
						<div className="faq-list__title">受講料関連</div>
						<div className="faq-list__detail">
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">月謝の支払方法はどうなっていますか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">オンラインプレミアムコースについては、毎月14日に、ご希望の口座から引落をさせて頂きます。リアルスクールコースについては、各スクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">金融機関の指定は可能でしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">オンラインプレミアムコースについては、ご希望の口座を選択することが可能です。　※一部使えない金融機関もある場合もございます。リアルスクールコースについては、各スクールにお問い合わせください。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">教材費はどのようになるのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">毎月のターム開始タイミングごとにSK（スコップ・スクール・キット）をお渡ししますので、その際に教材費を頂くことになります。</div>
								</dd>
							</dl>
						</div>
					</div><span id="p05"></span>
					<div className="faq-list__wrap">
						<div className="faq-list__title">受講スタイル</div>
						<div className="faq-list__detail">
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">どのような受講スタイルなのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">受講スタイルには、”オンラインプレミアムコース”と”リアルスクールコース”の2つがあります。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">オンラインプレミアムコースとリアルスクールコースはどちらの方が良いのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">どちらのコースも答えを自分でつくりだす力を育成するプログラム内容を用意しておりますので、自宅での受講を希望される方はオンラインプレミアムコースを、教室での受講を希望される方はリアルスクールコースを推奨します。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">オンラインプレミアムコースとリアルスクールコースのプログラム内容は違うのでしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">どちらのコースも答えを自分でつくりだす力を育成するプログラム内容を用意しておりますが、オンライン、リアルそれぞれの特性を活かした形式の為、プログラムの内容は一部変わります。</div>
								</dd>
							</dl>
							<dl className="js-collapse">
								<dt className="js-collapse-handle">
									<div className="faq-list__detail__q">リアルスクールコースの振り替えをオンラインで受講することは可能でしょうか？</div>
								</dt>
								<dd>
									<div className="faq-list__detail__a js-collapse-target">リアルスクールコースの振り替えをオンラインで受講することはできません。各スクールにお問い合わせください。</div>
								</dd>
							</dl>
						</div>
					</div>
				</section>
			</div>
		)
	}
}