import React from 'react'
import { Link } from 'react-router-dom'
import CreativityModule from '../../modules/creativity';
import PageBase from './pagebase';

export default class About extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/about.html'
	}

	static get title() {
		return 'スコップ・スクールについて'
	}

	static get title_en() {
		return 'about'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/about/txt-about.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			waitImageLoading: true,
		}
	}

	componentDidLoad() {
		super.componentDidLoad()
		this.creativityModule = new CreativityModule()
	}

	renderChild () {
		return (
			<div>
				<section className="about-lead">
					<div className="about__wrap">
						<div className="about-lead__text">
							<p>社会で活躍する<br className="sp-view" />プロフェッショナルの<br />知見や考え方と、<br />人を動かす表現力や課題解決力を持つ<br />クリエーターの知恵を掛け合わせ、<br />子どもの楽しく能動的な<br className="sp-view" />学びの体験を生み出すことで<br />社会で活きる創造力＝答えのない世界で、<br />答えを自分でつくりだす<br className="sp-view" />感性と力を培います。</p>
						</div>
					</div>
				</section>
				<section className="about-detail">
					<div className="about__wrap">
						<div className="about-detail__title"><span><img src="/assets/images/about/txt-about_2.svg" alt="実践的創造力について" /></span></div>
						<div className="about-detail__pct">
							<div className="about-detail__pct__bg">
								<picture>
									<source srcSet="/assets/images/about/pct-figure--sp.png" media="(max-width: 768px)" /><img src="/assets/images/about/pct-figure.png" alt="認知能力／非認知能力" />
								</picture>
							</div>
							<div className="about-detail__pct__title"><span className="js_creativity"><img src="/assets/images/about/txt-creativity.svg" alt="実践的創造力" /></span></div>
						</div>
						<div className="about-detail__text">
							<p>Society5.0と言われる次の新しい社会を生き抜くのに必要とされるのは「非認知能力」と言われています。それは、IQや学力テスト、偏差値などのように測ることができる認知能力に対し、子どもの将来や人生を豊かにする能力のことです。スコップ・スクールが育む「実践的創造力」は、従来の教育で重視されている認知能力に加え、この「非認知能力」領域を重視しています。</p>
						</div>
						<div className="about-detail__skills js_creativity_detail">
							<div className="about-detail__skills__title"><img src="/assets/images/about/txt-creativity.svg" alt="実践的創造力" /></div>
							<div className="about-detail__skills__list js_skills">
								<ul>
									<li>
										<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill01.png" alt="クリエーションスキル うみだす力" /></div>
										<div className="about-detail__skills__list__text">・課題を発見する<br />・視点を広げる<br />・アイデアで解決する<br />・ひとりで思考する<br />・チームで実現する</div>
									</li>
									<li>
										<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill02.png" alt="コミュニケーションスキル つなげる力" /></div>
										<div className="about-detail__skills__list__text">・多様な「他者」を認める<br />・言語化する<br />・発想を分かち合う<br />・発想を掛け合わせる</div>
									</li>
									<li>
										<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill03.png" alt="コンプリートスキル やりぬく力" /></div>
										<div className="about-detail__skills__list__text">・計画を立てる<br />・意思を持続させる<br />・柔軟に応じる</div>
									</li>
								</ul>
							</div>
						</div>
						<div className="about-detail__text">
							<p>スコップ・スクールは、クリエーションスキル、コミュニケーションスキル、コンプリートスキルの３つからなる「実践的創造力」を開発しました。この３つのスキルを磨く過程で、子どもたちは小さな困難と成功体験を繰り返し、自由なひらめきや想像にとどまらず、あらゆる状況をポジティブに突破する力となり、自らのポテンシャルを社会の価値に変えていける力となります。</p>
						</div>
					</div>
				</section>
				{/* <section className="about-bottom">
					<div className="top-course__briefing">
						<div className="top-course__briefing__title"><span>スコップ・スクールの申し込みフローは<br/>下記からご確認ください</span></div>
						<div className="top-course__briefing__banner">
							<Link to="/briefing/">
								<picture>
									<source srcSet="/assets/images/top/pct-banner-briefing--sp.png" media="(max-width: 768px)" /><img src="/assets/images/top/pct-banner-briefing.png" />
								</picture>
							</Link>
						</div>
					</div>
				</section> */}
			</div>
		)
	}
}