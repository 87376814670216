import { API } from '../config'
import { parse } from 'querystring'
import { getApiKey, getSymbol } from './utils'
import { EventEmitter } from 'events'
import { deleteCookie, getCookie, setCookie } from '../utils'

/**
 *
 * Cookieについて
 *
 * access_token: 認証済みのアクセストークン
 * perishable_token: 認証に使う一時トークン
 * trying_to_authorize: 認証しようとしているフラグ（LINEのコールバック先がサーバなのでローカル開発するときにこれがないとperishable_tokenがサーバで消えてしまう
 * trying_to_register: 新規登録ボタンをクリックして認証しようとしたフラグ
 * page_after_login: ログインおよび新規登録後に遷移すべきページを保存している
 *
 */


/**
 *
 */
class _Auth extends EventEmitter {

	/**
	 * constructor
	 */
	constructor() {
		super()
		this.hasChecked = false
		this.isAuthorized = false
		this.perishableToken = null
		this.accessToken = null
		this.user = null
		this.confirmAndRedirect = "CONFIRM_AND_REDIRECT"
		this._check()
	}

	/**
	 * ログイン状態をチェックします
	 */
	check(callback) {
		if (this.hasChecked) {
			callback(this.isAuthorized)
			return
		}
		this.once('checked', ()=>{
			callback(this.isAuthorized)
		})
	}

	async _check() {
		const search = parse(document.location.search.slice(1))
		if (search && search['perishable_token'] && getCookie('trying_to_authorize')) {
			// 一時トークンがある場合
			this.accessToken = await this.getAccessToken(search['perishable_token'])

			if (this.accessToken) {
				deleteCookie('perishable_token')
				// ログイン成功
				setCookie('access_token', this.accessToken)

				//このコースを受講可能か判定して無理ならクラス詳細に戻す
				const pageBeforeLogin = getCookie('page_before_login')
				if(pageBeforeLogin != null && pageBeforeLogin != '') {
					window.location.href = pageBeforeLogin
					deleteCookie('page_before_login')
					return
				}
				// 遷移すべきページがCookieに保存されている場合
				const applicationUrl = getCookie('page_after_login')
				if (applicationUrl) {

					deleteCookie('page_after_login')
					window.location.href = applicationUrl
					return
				}
				window.location.href = '/me/'
				return
			} else { // 新規登録の場合
				//ログイン押したけどトークンが発行されなかった場合アラート出してから登録画面遷移
				if (getCookie('trying_to_register') != 1) {
					// alert("ご利用のLINE IDでの登録がありませんでした。\nアカウント登録画面に移動します。")
					// NOTE: 確認イベント発行
					this.emit(this.confirmAndRedirect)
					return
				}

				this.redirectRegistorForm()
				return
			}
		} else if (getCookie('access_token')) {
			this.accessToken = getCookie('access_token')
			deleteCookie('perishable_token')
		} else if (getCookie('perishable_token')) {
			// この状況は登録フォームの時に発生する
			this.perishableToken = getCookie('perishable_token')
		}

		deleteCookie('trying_to_authorize')

		// ユーザー情報取得
		if (this.accessToken) {
			try {
				this.user = await this.getUser(this.accessToken)
				this.isAuthorized = true
				deleteCookie('perishable_token')
			} catch (e) {
				this.destroy()
			}
		}
		this.hasChecked = true
		this.emit('checked', this.isAuthorized)
	}

	/**
	 * 新規登録フォームにリダイレクト
	 */
	redirectRegistorForm() {
		const search = parse(document.location.search.slice(1))
		setCookie('perishable_token', search['perishable_token'])
		deleteCookie('trying_to_register')
		// 登録フォームにリダイレクト

		window.location.href = '/register/form.html'
	}

	/**
	 * 認証情報を破棄します
	 */
	destroy() {
		deleteCookie('perishable_token')
		deleteCookie('access_token')
		deleteCookie('trying_to_authorize')
		deleteCookie('trying_to_register')
		deleteCookie('page_after_login')
		this.user = null
		this.isAuthorized = false
	}

	/**
	 * ユーザ情報が更新されたときにはこのメソッドを実行
	 */
	updateUser = async ()=> {
		if (this.accessToken && this.isAuthorized) {
			this.user = await this.getUser(this.accessToken)
			return this.user
		}
		return {}
	}

	/**
	 * LINE IDでログインします
	 */
	login(register = false, pageBeforeLogin='', pageAfterLogin='') {
		setCookie('trying_to_authorize', 1)
		setCookie('trying_to_register', register ? 1 : 0)
		setCookie('page_after_login', pageAfterLogin)
		setCookie('page_before_login', pageBeforeLogin)
		window.location.href = API.CONNECT_LINE
	}

	/**
	 * ログアウトします
	 */
	logout() {
		this.destroy()
		if (window.location.href.match(/^\/me\//)) window.location.href = '/'
		else document.location.reload()
	}

	/**
	 * 一時トークンを使ってアクセストークンを取得します
	 * @param {string} perishableToken
	 */
	async getAccessToken(perishableToken) {
		const apiKey = await getApiKey()
		let path = API.ACCESS_TOKEN + "?perishable_token=" + encodeURIComponent(perishableToken)
		const res = await fetch(path , {
			headers: new Headers({'x-api-key' : apiKey}),
			method: 'POST'
		})
		const data = await res.json()
		if(!res.ok) {
			if (res.status == 401) {
				throw new AuthError()
			}else if(res.status == 404) {  //NOTE: 脆弱性診断で明らかになったバグ 過去にLINEログインしたことがあるが新規登録はまだの状態
				this.emit(this.confirmAndRedirect)
			}
			throw new Error(data.message)
			return
		}
		if (!data.access_token) {

			// alert('不明なエラーが発生しました')
			// throw new Error('ログイン時に不明なエラーが発生しました')
		}
		return data.access_token
	}


	async addUser(parent, children) {
		if (!Auth.perishableToken) {
			throw new AuthError('一時認証トークンがありません')
		}

		const postData = {
			perishable_token: Auth.perishableToken,
			...parent,
			children: children,
		}
		const apiKey = await getApiKey()
		const res = await fetch(API.USERS, {
			headers: new Headers({
				'x-api-key' : apiKey,
				'Accept' : 'application/json',
				'Content-Type' : 'application/json',
			}),
			method: 'POST',
			body: JSON.stringify(postData)
		})

		const data = await res.json()

		if (res.ok) {
			setCookie('access_token', data.access_token)
		} else {

			// throw new Error(data.messages)  エラー送信いったんやめ（松本）メールアドレスのバグでエラーがいちいち発生しているとアカウント登録できないため
		}
		return data
	}

	/**
	 * ユーザー情報を取得します
	 */
	async getUser(accessToken) {
		const apiKey = await getApiKey()
		let path = API.ME
		const res = await fetch(path , {
			headers: new Headers({
				'x-api-key' : apiKey,
				'Authorization': 'Token token='+accessToken,
			}),
			method: 'GET'
		})
		const data = await res.json()

		if(!res.ok) {
			if (res.status == 401) {
				throw new AuthError()
			}
			throw new Error(data.message)
			return
		}
		return data.user
	}


	getChildById(child_id) {
		if (!this.user) return null
		if (!this.user.children) return null
		for (let i in this.user.children) {
			if (this.user.children[i].id == child_id) return this.user.children[i]
		}
		return null
	}

	/**
	 * Authに含まれるユーザ情報の最初の子供情報を取得
	 */
	getFirstChildId() {
		if (Auth.user.children && Auth.user.children.length > 0) return Auth.user.children[0].id
		return null
	}


	/**
	 * Authに含まれるユーザ情報の最初の*生徒*情報を取得
	 */
	getFirstStudentId() {
		if(Auth.user.children && Auth.user.children.length > 0) {
			for(const i in Auth.user.children) {
				if(Auth.user.children[i].child_profile){
					return Auth.user.children[i].id
				}
			}
			return null
		}
	}
}

const Auth = new _Auth()
export default Auth

export class AuthError extends Error {

}