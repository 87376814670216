import React from 'react'
import { Link } from 'react-router-dom';
import { SCHOOL } from '../../../../config';
import ListBase from '../listBase';

export default class ShortSchool extends ListBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/shortschool/'
	}

	static get title() {
		return '短期スクール'
	}

	static get title_en() {
		return 'shortschool'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-shortschool.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return false
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props, SCHOOL.short)
	}

	getLeadContents(isAllNonAccepting) {
		return (
			<>
				<div className="top-course__lead">
					<p>1～２日間などの短期間で集中的に学べるプログラム。<br/>テーマや領域は今後続々と拡充してゆく予定です。</p>
				</div>
				{isAllNonAccepting &&
				<div className="top-course__lead">
					<p>現在募集中の短期スクールはありません</p>
				</div>
				}
			</>
		)
	}
}