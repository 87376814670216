import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../authorizedpagebase'
import queryString from 'query-string'
import { getReport, getReportList } from '../../../../api/schopReport'
import { NOTE_URL } from '../../../../config'
import ReportClass from './class'
import ReportSelect from './select'
import PageNation from './pagenation'
import ReportModal from './pctModal'
import ShareModal from './shareModal'
import Loading from '../../../component/common/loading'
import { scrollToTop } from '../../../../utils'
import { AuthError } from '../../../../api/auth'

export default class SchopReport extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/report/'
	}

	static get title() {
		return 'スコップ・スクールレポート'
	}

	static get title_en() {
		return 'schopreport'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="schopreport">
					<img src="/assets/images/common/txt-schopreport.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			reports: null,
			report: null,
			report_id: null,
			isModalShow: false,  //モーダル表示か否か
			modalImage: null,  //モーダル開いた時の初期イメージ
			isDataLoaded: false
		}

	}

	componentDidMount() {
		super.componentDidMount()
	}

	authorizeDidComplete = async () => {
		const qs = queryString.parse(this.props.location.search);
		const child_id = parseInt(qs.child_id)
		const course_id = parseInt(qs.course_id)
		const course_target_age_id= parseInt(qs.course_target_age_id)
		const term_id = parseInt(qs.term_id)

		let reportList
		let report_id
		try{
			reportList = await getReportList()
			// マッチするレポートを探す
			for(const i in reportList.reports) {
				const data = reportList.reports[i]
				const is_child_id = data.child.id == child_id
				const is_course_id = data.course_id == course_id
				const is_course_target_age_id = data.course_target_age_id == course_target_age_id
				const is_term_id = data.term_id == term_id

				if(is_child_id && is_course_id && is_course_target_age_id && is_term_id) {
					report_id = data.id
					break  // 見つかったらループ抜ける
				}
			}

			// マッチするレポートがない場合（子供だけの指定の場合など）は最初にマッチするレポートを返す
			if(!report_id){
				for(const i in reportList.reports) {
					const data = reportList.reports[i]
					const is_child_id = data.child.id == child_id
					if(is_child_id) {
						report_id = data.id
						break
					}
				}
			}
		}catch(error) {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		}

		try{
			const report = await getReport(report_id)
			this.setState({
				reports: reportList,
				report: report.report_detail,
				report_id: report_id,
				isDataLoaded: true
			})
		} catch(error) {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		}

	}

	handleChangeReport = (e) => {
		e.preventDefault()
		Loading.instance.show()
		scrollToTop()
		const id = e.target.value ? e.target.value : e.target.id  //pagenationのaタグにはvalue属性がうまく効かなかったので
		getReport(id).then(res => {
			this.setState({
				report: res.report_detail,
				report_id: id,
			})
			Loading.instance.hide()
		}).catch(error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
	}

	/**
	 * 画像を取得してモーダルを表示
	 * TODO: アニメーションつけたい
	 * @param {DOMElement} e
	 */
	handleOpenModal = (e) => {
		if(e && e.cancelable) e.preventDefault()
		this.setState({
			isModalShow: true,
			modalImage: e.target.src
		})
	}

	handleCloseModal = (e) => {
		if(e && e.cancelable) e.preventDefault()
		this.setState({ isModalShow: false })
	}

	handleOpenShareModal = (e) => {
		if(e && e.cancelable) e.preventDefault()
		this.shareModal.show(this.state.report)
	}

	getReportDetail = () => {
		const report = this.state.report
		const isStudent = true
		const loc = location.href
		const isNoteAccess = loc.indexOf(NOTE_URL) >= 0  //マイページからのiframeアクセス以外は表示しない

		return (
			<>
				<div className="report__title">
					<div className="report__title-wrap">
						<div className="report__title-left">
							<div className="report__title__sub">{report.course_name}</div>
							<div className="report__title__main">{report.term_name}</div>
						</div>

						{/* シェアボタン ノートは子供が触るものなのでノートでは表示しない */}
						{isStudent && !isNoteAccess &&
						<div className="report__title-right">
							<div className="report__title__btn"><a href="#" onClick={this.handleOpenShareModal}><img src="/assets/images/report/btn-share.png" alt="シェア"/></a></div>
						</div>
						}
					</div>

					<div className="report__title__pct"> <img src={report.report_image_url}/></div>
				</div>

				{/* レポートのタイムライン トーク画面みたいなところ */}
				<div className="report__timeline">
					<div className="report__timeline-wrap">

						{report.report_schedules.length > 0 &&
							<ReportClass
								report={report}
								onModalOpen={this.handleOpenModal}
							/>
						}

						<div className="report__timeline__message">
						{/* ナビゲーターからのコメント */}
						{report.report_comments.length > 0 &&
							report.report_comments.map((comment, key) => {
								return (
										<div className="report__timeline__message-wrap" key={key}>
											{comment.navigator &&
												// NOTE: 画像なければダミーおく？
												<div className="report__timeline__message__thumb">
													<img src={comment.navigator.icon_url}/>
												</div>
											}
											{!comment.navigator &&
												<div className="report__timeline__message__thumb">
													<img src="/assets/images/icon_schope.png"/>
												</div>
											}
											<div className="report__timeline__message__text">
												{comment.navigator &&
												<div className="report__timeline__message__text__title">{comment.navigator.last_name}ナビゲーターからのメッセージ</div>
												}
												{!comment.navigator &&
												<div className="report__timeline__message__text__title">スコッペからのメッセージ</div>
												}
												<div className="report__timeline__message__text__fukidashi"><span>{comment.content}</span></div>
											</div>
										</div>
								)
							})
						}
						</div>
					</div>
				</div>
			</>
		)
	}

	renderChild() {
		const reports = this.state.reports.reports
		const report = this.state.report
		const isStudent = true  //ユーザーかどうか falseならシェアページログインの
		if(!reports || !report) return (
			<div className="report__empty">
				<div className="report__empty-wrap">
					<div className="report__empty__icon"><img src="/assets/images/ico-note.svg"/></div>
					<div className="report__empty__text">まだレポートが<span data-ruby="さくせい">作成</span>されてないよ。</div>
				</div>
			</div>
			)
		return (
			<>
				<div className="report-wrapper">
					<section className="report">

						{/* レポートを選択するセレクトボックス */}
						{isStudent &&
							<ReportSelect
								reports={reports}
								report={report}
								id={this.state.report_id}
								onChangeReport={this.handleChangeReport}
							/>
						}

						{/* メインの内容 */}
						{report && this.getReportDetail()}

						{/* マイページには専用のフッターがあるからこれをそのまま移植するのむずい */}
						{/* {reports && report &&
							<PageNation
							reports={this.state.reports}
							report={this.state.report}
							onPageMove={this.handleChangeReport}
							/>
						} */}

						{/* 写真のモーダル */}

					</section>
				</div>
				<ReportModal
					report={this.state.report}
					onClose={this.handleCloseModal}
					isShown={this.state.isModalShow}
					imgData={this.state.modalImage}
					ref={node => this.modal = node}
				/>
				<ShareModal
					ref={node => this.shareModal = node}
					report={this.state.report}
				/>
		</>
		)
	}
}