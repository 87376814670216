
import React from 'react'
import Footer from '../component/common/footer'
import PageBase from './pagebase';

export default class NotFound extends PageBase {

	static get path(){
		return ''
	}

	static get title() {
		return ''
	}

	static get title_en() {
		return 'error'
	}

	renderChild () {
		return (
			<>
			<div className="lowerPage" id="wrapper">
				<section className="pagenotfound">
					<div className="pagenotfound__wrap">
						<div className="pagenotfound__logo"><img src="/assets/images/common/pct-404.svg" alt="404 Not Found"/></div>
						<div className="pagenotfound__text">お探しのページは見つかりませんでした。</div>
					</div>
				</section>
			</div>
		</>
		)
	}
}