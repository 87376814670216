
import React from 'react'
import Footer from '../../component/common/footer'
import PageBase from '../pagebase';
import ReactPixel from "react-facebook-pixel"

export default class WinterSchoolDone extends PageBase {

	static get path(){
		return '/briefing_done/winterschool.html'
	}

	static get title() {
		return '冬の1day体験スクール申し込み完了'
	}

	static get title_en() {
		return 'winterschool_done'
	}

	componentDidMount() {
		super.componentDidMount()

		const shouldDebug = process.env.REACT_APP_IS_PRODUCTION === "false"
		ReactPixel.init("1159087367831831", {}, {debug: shouldDebug})  // 初期化
		ReactPixel.pageView()  // ページビューの送信
		ReactPixel.track("CompleteRegistration", {value: 3000.00, currency: "JPY"})  // カスタムイベントの送信
	}

	renderChild () {
		return (
			<>
			<section className="briefing">
				<div className="briefing__thanks">
					<div className="briefing__thanks-wrap">
						<div className="briefing__thanks__pct">
								<picture>
										<source srcSet="/assets/images/briefing/pct-thanks--sp.png" media="(max-width: 768px)"/>
										<img src="/assets/images/briefing/pct-thanks.png"/>
								</picture>
						</div>
						<div className="briefing__thanks__lead">
							<p>
								スコップ・スクールの体験会に<br className="sp-view"/>
								お申し込みいただきまして、ありがとうございます。<br/>
								ご登録いただきましたメールアドレスに、<br className="sp-view"/>
								体験会のZoomのURLをご連絡しますので、<br/>
								ご確認お願い致します。
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
		)
	}
}