import React from 'react'
import { Link } from 'react-router-dom'

import Footer from '../../component/common/footer';
import TopModule from '../../../modules/top/top';
import {BASE_TITLE, SCHOOL} from '../../../config'
import PageBase from '../pagebase';
import CourseList from './courseList';
import BannerList from './bannerList';
import NewsList from './newsList';
import ReportList from './reportList';
import { scrollToTop } from "../../../utils";
import CreativityModule from '../../../modules/creativity';
import ScrollLink from '../../component/common/scrolllink';
import Wave from '../../component/common/wave'

export default class Top extends PageBase {
	// ---------- 基本設定 ----------
	static get path() {
		return '/'
	}

	static get title() {
		return 'スコップ・スクール'
	}

	static get title_en() {
		return 'top'
	}

	static get header_title() {
		return (<></>)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			// waitImageLoading: false,
			waitImageLoading: true,
			isTop: true,
		}
	}

	// コンポーネントが表示されるとき
	componentDidMount() {
		super.componentDidMount()
		document.title = BASE_TITLE;
		if (!this.topModule) this.topModule = new TopModule()
		this.topModule.onEnter()
	}

	// コンポーネントが非表示になるとき
	componentWillUnmount() {
		super.componentWillUnmount()
		this.topModule.onExit()
	}

	componentDidLoad() {
		super.componentDidLoad()
		this.creativityModule = new CreativityModule()
	}
	// onClick = (e) => {
	// 	scrollToTop()
	// }
	renderChild () {
		return (
				<div className="topPage" id="wrapper">
				<section className="intro">
					<canvas className="js_intro"></canvas><img className="intro__lead js_intro_lead01" src="/assets/images/intro/txt-lead01.svg" alt="こんな学校"/><img className="intro__lead js_intro_lead02" src="/assets/images/intro/txt-lead02.svg" alt="子どものころに"/><img className="intro__lead js_intro_lead03" src="/assets/images/intro/txt-lead03.svg" alt="欲しかった" />
				</section>
				<section className="mv" style={{visibility: "hidden"}}>
					<div className="mv__wrap">
						<div className="mv__movie bg loading">
							<video className="js_movie" src="" muted autoPlay playsInline></video>
						</div>
						<div className="mv__title">
							<div className="mv__title-01"><img src="/assets/images/top/txt-mv01.svg" alt="幸せをつかむ創造力を、子どもたちに。" /></div>
							<div className="mv__title-02"><img src="/assets/images/top/txt-mv02.svg" alt="SCHOP" /></div>
							<div className="mv__title-03"><img src="/assets/images/top/txt-mv03.svg" alt="SCHOOL" /></div>
							<div className="mv__title-04"><img src="/assets/images/top/txt-mv04.svg" alt="SCHOP 幸せをつかむ創造力" /></div>
						</div>
						<BannerList />
					</div>
				</section>
				<section className="top-lead" id="topConcept">
					<div className="top-lead__wrap">
						<div className="top-lead__title">
							<canvas className="js_morph" width="300" height="300"></canvas><img src="/assets/images/top/txt-lead.svg" alt="社会で活きる創造力を。"/>
						</div>
						<div className="top-lead__text">
							<p>想像を超えて、<br />社会はさらに劇的に変わり、<br />予測が難しくなります。</p>
							<p>学歴は、価値の１つですが、<br />それだけで通じる看板になるでしょうか。<br />（そもそも、試験や入試の形が変わります）</p>
							<p>人生100年の時代に、<br />不確定な社会を生き抜くために必要な力。<br />生き抜くだけではなく、<br />自分らしく幸せに生き続ける力。</p>
							<p>どんな生き方にも、どんな職業にも、<br />役に立つ力。</p>
							<p>現代社会と世界の潮流から、<br />私たちスコップ・スクールが導き出したのは<br />「実践的創造力」です。</p>
						</div>
						{/* <div className="top-lead__btn"><Link to="/about.html"><span>もっと詳しく</span></Link></div> */}
						<div className="top-lead__btn"><ScrollLink to="#topAbout"><span>もっと詳しく</span></ScrollLink></div>
						
						{/* <div className="top-lead__btn"><Link to="/test.html"><span>テストボタン</span></Link></div> */}
					</div>
				</section>
				<div className="schop-animation schop01">
					<div className="inner">
						<canvas width="110" height="56"></canvas>
						<picture>
							<source srcSet="/assets/images/top/sp_hole_1.png"  media="(max-width: 768px)"/>
							<img src="/assets/images/top/pc_hole_1.png" alt="" />
						</picture>
					</div>
				</div>

				<section className="top-movie">
					<div className="top-movie__wrap">
						<div className="top-movie__title"><span><img src="/assets/images/top/txt-movie.svg" alt="紹介動画"/></span></div>
						<div className="top-movie__content">
							<video className="js-top-video" src="/assets/images/top/movie.mp4" muted autoPlay loop controls playsInline></video>
						</div>
					</div>
				</section>
				<section className="top-feature">
					<div className="schop-animation schop02">
						<div className="inner">
							<canvas width="110" height="56"></canvas>
							<picture>
								<source srcSet="/assets/images/top/sp_hole_2.png" media="(max-width: 768px)"/>
								<img src="/assets/images/top/pc_hole_2.png" alt="" />
							</picture>
						</div>
					</div>
					<div className="top-feature__wrap">
						<div className="top-feature__title" id="topClass"><span><img src="/assets/images/top/txt-feature_new.svg" alt="具体的授業例"/></span></div>
						<div className="top-feature__lead">実社会で活躍するいろいろなプロの<br className="pc-view" />ナビゲーションによるプログラムで、<br className="pc-view" /> 子どもたちの多様な領域への興味を喚起しながら、<br className="pc-view" /> 一貫して「実践的創造力」を育みます。</div>
						<div className="top-feature__detail-new-01">
							<div className="top-feature__detail-new-01__pct">
								<picture>
									<source srcSet="/assets/images/top//pct-feature_new01--sp.svg" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature_new01.svg"  alt="" />
								</picture>
							</div>
							<div className="top-feature__detail-new-01__btns">
								<Link to="/archive/">
									<span>過去の短期プログラム一覧へ</span>
								</Link>
							</div>
						</div>

						
						<div className="top-feature__title__02" id="topProgram">{/* <span><img src="/assets/images/top/txt-feature_new_02.svg" alt="1回のプログラム進行例"/></span> */}</div>
						<div className="top-feature__detail-05">
							<div className="top-feature__detail-01__pct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature05-1--sp.jpg" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature05-1.jpg"  alt="" />
								</picture>
							</div>
							{/* <div className="top-feature__detail-05__title01"><img src="/assets/images/top/txt-feature01-1.svg" alt="好奇心と探求力を"/></div>
							<div className="top-feature__detail-05__title02"><img src="/assets/images/top/txt-feature01-2.svg" alt="掘り起こす仕組み"/></div> */}
							{/* <div className="top-feature__detail-05__lead">プログラムは１カ月ごとに更新していきます。<br className="pc-view" />ひとつのプログラムは４回で完結するものが<br className="pc-view" />基本となります。<br className="pc-view" />４回を通じて、徐々にテーマへの理解を深め、<br className="pc-view" />子どものアウトプットを引き出すための構成を<br className="pc-view" />組んでいるのがスコップ流のメソッドです。</div> */}
							 <div className="top-feature__detail-05__title01"><img src="/assets/images/top/txt-feature_new_02.svg" alt="1回のプログラム進行例"/></div>
							<div className="top-feature__detail-05__lead">プログラムは1か月ごとに更新していきます。<br className="pc-view" />ひとつのプログラムは4回で<br className="pc-view" />完結するものが基本となります。<br className="pc-view" />独自のメソッドに則り、4回を通じて、<br className="pc-view" />毎回バランス良くインプットと、<br className="pc-view" />アウトプットを配置することで、<br className="pc-view" />徐々にテーマへの理解を深めつつ、<br className="pc-view" />ユニークなアウトプットにつなげます。</div>
							{/* <div className="top-feature__detail-05__subpct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature01-2--sp.png" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature01-2.png" alt="" />
								</picture>
							</div> */}
						</div>
						<div className="top-feature__detail-01" id="topFeature">
							<div className="top-feature__detail-01__pct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature01-1--sp.jpg" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature01-1.jpg"  alt="" />
								</picture>
							</div>
							<div className="top-feature__detail-01__title01"><img src="/assets/images/top/txt-feature01-1.svg" alt="好奇心と探求力を"/></div>
							<div className="top-feature__detail-01__title02"><img src="/assets/images/top/txt-feature01-2.svg" alt="掘り起こす仕組み"/></div>
							<div className="top-feature__detail-01__lead">ただ学ぶだけではない。ただつくるだけではない。<br className="pc-view" />課題の本質や仕組みを理解した上で、<br className="pc-view" />自由に発想を飛躍させる楽しさが生まれる設計です。</div>
							<div className="top-feature__detail-01__subpct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature01-2--sp.png" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature01-2.png" alt="" />
								</picture>
							</div>
						</div>
						<div className="top-feature__detail-02">
							<div className="top-feature__detail-02__pct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature02-1--sp.jpg" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature02-1.jpg" alt="" />
								</picture>
							</div>
							<div className="top-feature__detail-02__title01"><img src="/assets/images/top/txt-feature02-1.svg" alt="先生は、"/></div>
							<div className="top-feature__detail-02__title02"><img src="/assets/images/top/txt-feature02-2.svg" alt="各分野のプロフェッショナル"/></div>
							<div className="top-feature__detail-02__lead">映像に登場する第一線で活躍するプロによる、<br className="pc-view" />各分野ならではのナビゲーションで本物の価値を届け、<br className="pc-view" />現場の先生によるファシリテーションで、<br className="pc-view" />学びを深めます。</div>
							<div className="top-feature__detail-02__subpct">
								<picture>
									<img src="/assets/images/top/pct-feature02-2.png" alt="" />
								</picture>
							</div>
						</div>
						{/* <div className="top-feature__detail-03">
							<div className="top-feature__detail-03__pct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature03-1--sp.jpg" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature03-1.jpg" alt="" />
								</picture>
							</div>
							<div className="top-feature__detail-03__title"><img src="/assets/images/top/txt-feature03.svg" alt="実社会との関わり"/></div>
							<div className="top-feature__detail-03__lead">進学の先に、必ず社会があります。<br className="pc-view" />不確定な社会で自分らしく生き抜く本質的な力は、<br className="pc-view" />すぐには育たないと考えています。<br className="pc-view" />今から、常に社会に関わる学びを提供します。</div>
							<div className="top-feature__detail-03__subpct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature03-2--sp.png" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature03-2.png" alt="" />
								</picture>
							</div>
						</div> */}
						<div className="top-feature__detail-04">
							<div className="top-feature__detail-04__pct">
								<picture>
									<source srcSet="/assets/images/top/pct-feature04--sp.png" media="(max-width: 768px)"/>
									<img src="/assets/images/top/pct-feature04.png" alt="" />
								</picture>
							</div>
							<div className="top-feature__detail-04__title01"><img src="/assets/images/top/txt-feature04-1.svg" alt="社会と世界に通じる"/></div>
							<div className="top-feature__detail-04__title02"><img src="/assets/images/top/txt-feature04-2.svg" alt="学びのメソッド"/></div>
							<div className="top-feature__detail-04__lead">社会で活躍するクリエーターの実績と知見と、<br className="pc-view" />世界の様々な教育理論を基に独自に設計した<br className="pc-view" />メソッドによって、<br className="pc-view" />実践的創造力の成長循環を導きます。</div>
						</div>
					</div>
				</section>
				<section className='top-about' id='topAbout'>
					<section className="about-detail">
						<div className="about__wrap">
							<div className="about-detail__title"><span><img src="/assets/images/about/txt-about_2.svg" alt="実践的創造力について" /></span></div>
							<div className="about-detail__pct">
								<div className="about-detail__pct__bg">
									<picture>
										<source srcSet="/assets/images/about/pct-figure--sp.png" media="(max-width: 768px)" /><img src="/assets/images/about/pct-figure.png" alt="認知能力／非認知能力" />
									</picture>
								</div>
								<div className="about-detail__pct__title"><span className="js_creativity"><img src="/assets/images/about/txt-creativity.svg" alt="実践的創造力" /></span></div>
							</div>
							<div className="about-detail__text">
								<p>Society5.0と言われる次の新しい社会を生き抜くのに必要とされるのは「非認知能力」と言われています。それは、IQや学力テスト、偏差値などのように測ることができる認知能力に対し、子どもの将来や人生を豊かにする能力のことです。スコップ・スクールが育む「実践的創造力」は、従来の教育で重視されている認知能力に加え、この「非認知能力」領域を重視しています。</p>
							</div>
							<div className="about-detail__skills js_creativity_detail">
								<div className="about-detail__skills__title"><img src="/assets/images/about/txt-creativity.svg" alt="実践的創造力" /></div>
								<div className="about-detail__skills__list js_skills">
									<ul>
										<li>
											<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill01.png" alt="クリエーションスキル うみだす力" /></div>
											<div className="about-detail__skills__list__text">・課題を発見する<br />・視点を広げる<br />・アイデアで解決する<br />・ひとりで思考する<br />・チームで実現する</div>
										</li>
										<li>
											<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill02.png" alt="コミュニケーションスキル つなげる力" /></div>
											<div className="about-detail__skills__list__text">・多様な「他者」を認める<br />・言語化する<br />・発想を分かち合う<br />・発想を掛け合わせる</div>
										</li>
										<li>
											<div className="about-detail__skills__list__pct"><img src="/assets/images/about/pct-skill03.png" alt="コンプリートスキル やりぬく力" /></div>
											<div className="about-detail__skills__list__text">・計画を立てる<br />・意思を持続させる<br />・柔軟に応じる</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="about-detail__text">
								<p>スコップ・スクールは、クリエーションスキル、コミュニケーションスキル、コンプリートスキルの３つからなる「実践的創造力」を開発しました。この３つのスキルを磨く過程で、子どもたちは小さな困難と成功体験を繰り返し、自由なひらめきや想像にとどまらず、あらゆる状況をポジティブに突破する力となり、自らのポテンシャルを社会の価値に変えていける力となります。</p>
							</div>
						</div>
					</section>
				</section>
				<section className='top-school'>
					<div className="top-school__wrap">
						<div className="top-school__title"><span><img src="/assets/images/top/txt-class-trial.svg" alt="体験参加受付中!" /></span></div>
						<div className="top-school__textarea">
							<p className="top-school__textarea--icon">
								<img src="/assets/images/common/chara_img.svg"></img>
							</p>
							<p className="top-school__textarea--msg">
								まずはお近くの教室で体験参加を受付ている<br />かご確認のうえ、お申込みください。

							</p>
							<div className="top-school__textarea--btn">
								<Link to="/trial/">
									<span>体験参加申込はこちらから</span>
								</Link>
								{/* <a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込はこちらから</span></a> */}
							</div>
						</div>
					</div>
				</section>
				
				{/* <section className="top-course">
					<div className="schop-animation schop03">
						<div className="inner">
							<canvas width="110" height="56"></canvas>
							<picture>
								<source srcSet="/assets/images/top/sp_hole_2.png" media="(max-width: 768px)"/>
								<img src="/assets/images/top/pc_hole_2.png" alt="" />
							</picture>
						</div>
					</div>
					<div className="top-course__wrap">
						<div className="top-course__title"><span className="schop01"><img src="/assets/images/top/txt-regularschool.svg" alt="レギュラースクール"/></span></div>
						<div className="top-course__lead">
							<p>
								年間を通して継続的に学んでいきます。<br/>
								主に「自分に向き合う」プログラムと、<br className="sp-view"/>
								主に「社会に向き合う」プログラムを非連続に<br className="sp-view"/>
								配置しながら、<br className="pc-view"/>一貫して手を動かす、頭を動かす、<br className="sp-view"/>
								発信する、吸収する、協働するという<br className="sp-view"/>
								クリエーティブワークを積み重ねることで、<br/>
								自分を活かす力と社会で活きる力、<br className="sp-view"/>
								すなわち「答えを自分でつくりだす力」を<br className="sp-view"/>
								育成していきます。
							</p>
						</div>
						<CourseList school={SCHOOL.regular} />
						<div className="top-course__briefing">
							<div className="schop-animation schop04">
								<div className="inner">
									<canvas width="110" height="56"></canvas>
									<picture>
										<source srcSet="/assets/images/top/sp_hole_3.png" media="(max-width: 768px)"/>
										<img src="/assets/images/top/pc_hole_3.png" alt="" />
									</picture>
								</div>
							</div>
						</div>
						<div className="top-course__title"><span className="schop02"><img src="/assets/images/top/txt-shortschool.svg" alt="短期スクール"/></span></div>

						<CourseList school={SCHOOL.short} />
						<div className="top-course__briefing">
							<div className="top-course__briefing__banner">
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSddHoel01nknmjGB9yV8x3r4uu3jHZ6162iEMYAgJAerGWnMg/viewform" target="_blank">
									<picture>
										<img src="/assets/images/common/pct-banner-contact.png" alt="" />
									</picture>
								</a>
							</div>
							<div className="schop-animation schop05">
								<div className="inner">
									<canvas width="110" height="56"></canvas>
									<picture>
										<source srcSet="/assets/images/top/sp_hole_2.png" media="(max-width: 768px)"/>
										<img src="/assets/images/top/pc_hole_2.png" alt="" />
									</picture>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				<section className="top-news">

					<div className="top-news__wrap">
						<div className="top-news__title"><img src="/assets/images/top/txt-news.svg" alt="ニュース"/></div>
						<NewsList />
					</div>
					<div className="top-news__btn">
						<Link to="/news/"><span>ニュース一覧</span></Link>
					</div>

				</section>
				{/* <section className="top-report">
					<div className="top-report__wrap">
						<div className="top-report__title"><img src="/assets/images/common/txt-report.svg" alt="活動レポート"/></div>
						<ReportList />
					</div>
					<div className="top-report__btn"><Link to="/report/"><span>活動レポート一覧</span></Link></div>
				</section> */}
				<Footer />
			</div>
		)
	}
}