import React from 'react'
import PropTypes from 'prop-types'
import Validate from '../_utils/validate'
import DateInput from '../../component/form/dateinput'
import Select from '../../component/form/select'
import FileInput from '../../component/form/fileInput'
import TextInput from '../../component/form/textinput'
import TrimImage from '../../component/form/trimImage'
import { CHILD_FORM_INFO, CHILD_ITEM_LIST } from '../../../config'

export default class RegisterChildForm extends React.Component {
	static propTypes = {
		index: PropTypes.number,
		isConfirming: PropTypes.bool,
		isFirst: PropTypes.bool,
		isLast: PropTypes.bool,
		onChange: PropTypes.func,
		onChangeChildType: PropTypes.func,
		onAdd: PropTypes.func,
		child: PropTypes.shape({
			is_student: PropTypes.bool,
			last_name: PropTypes.string,
			first_name: PropTypes.string,
			birth_date: PropTypes.string,
			gender_id: PropTypes.string,
			child_profile: PropTypes.shape({
				last_name_kana: PropTypes.string,
				first_name_kana: PropTypes.string,
				elementary_school: PropTypes.string,
				grade_id: PropTypes.string,
				interest: PropTypes.string,
				after_school_activity: PropTypes.string,
			}),
			errors: PropTypes.object,
		}),
		validateScheme: PropTypes.object,
		childElm: PropTypes.object,
	}

	static defaultProps = {
		index:0,
		isConfirming: false,
		isFirst: true,
		isLast: true,
		onChange: () => {},
		onChangeChildType : () => {},
		onBlur: () => {},
		onAdd: () => {},
		child: {
			is_student: true,
			...CHILD_ITEM_LIST
		},
		validateScheme: {},
		childElm: {}
	}

	handleChange = (e) => {
		this.props.onChange(this.props.index, {
			...this.props.child,
			[e.target.name]: e.target.value
		})
		if (e.preventDefault) e.preventDefault()
	}

	handleBlur = (e)=> {
		const name = e.target.name
		const value = e.target.value
		const name_ja = e.target.dataset.name_ja
		const priority = e.target.dataset.priority

		this.props.onBlur(this.props.index, {
			...this.props.child,
			[name]: value,
			errors: {
				...this.props.child.errors,
				[name]: Validate.isValid(name_ja, value, priority, this.props.validateScheme[name])
			}
		})
	}

	handleProfileChange = (e) => {
		this.props.onChange(this.props.index, {
			...this.props.child,
			child_profile: {
				...this.props.child.child_profile,
				[e.target.name]: e.target.value
			}
		})
		if (e.preventDefault) e.preventDefault()
	}

	handleChangeIcon = (file) => {
		TrimImage.instance.show(file, this.handleTrimIcon)
	}

	handleTrimIcon = (dataURL) => {
		this.props.onChange(this.props.index, {
			...this.props.child,
			child_profile: {
				...this.props.child.child_profile,
				icon: dataURL,
			},
			errors: {
				...this.props.child.errors,
				// NOTE: プロフィール写真 e.target渡ってこないので決め打ち？
				// TODO: ちゃんとpriority受け取る
				icon: Validate.isValid("プロフィール写真", dataURL, 0, this.props.validateScheme.icon)
			}
		})
	}

	render() {
		const checked = (this.props.child.is_student) ? true : false
		return (
			<>
			{(!this.props.isConfirming) ?
				<section className="form-edit">
					{this.props.index == 0 &&
						<div className="form-edit__lead">
							お子様情報のご入力お願いします。<br className="sp-view"/>
							未就学児のご兄弟がいらっしゃる場合も<br className="sp-view"/>
							ご入力ください。
							<div className="form-edit__lead-caution">
								<span>※</span>は必ず記入してください。
							</div>
						</div>
					}
					<div className="form-edit-wrap">
						{this.props.index !== 0 &&
							<div className="form-edit__closebtn"><a href="#" onClick={(e)=>{this.props.onDelete(e, this.props.index)}}><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
						}
						<div className="form-edit__subtitle-bgyellow">お子様{this.props.index+1}人目</div>
						<div className="form-edit__input">
							{this.props.index !== 0 &&
								<>
									<div className="form-information__checkbox">
										<label><span className="form-information__checkbox__label">スコップ・スクール生徒登録する</span>
											<input
												type="checkbox"
												defaultChecked={checked}
												key={this.props.index}
												index={this.props.index}
												onChange={this.props.onChangeChildType}
											/>
											<div className="form-information__checkbox__toggle"></div>
										</label>
									</div>
									<div className="form-information__text pccenter pcgray">※プログラムに参加応募するお子様は​生徒登録をお願いします。</div>
								</>
							}
							{this.forms()}
							{this.props.isLast && (<div className="form-edit__input-btn arrowbottom"><a href="#" onClick={this.props.onAdd}><span>お子様を追加</span></a></div>)}
						</div>
					</div>
				</section>
				:
				<section className="form-informatino">
					<div className="form-information-wrap">
						<div className="form-information__subtitle">お子様{this.props.index+1}人目</div>
						<div className="form-information__list">
							{this.forms()}
						</div>
					</div>
				</section>
		}
		</>
		)
	}

	/**
	 * 確認と編集時のクラスだ仕分け
	 */
	setEditClass() {
		return (this.props.isConfirming) ? '' : 'form-edit__pcwrap-input2col'
	}

	forms() {
		const display = (this.props.child.is_student) ? 'block' : 'none'
		return (
			<>
				<FileInput
					index={this.props.index}
					name='icon'
					name_ja={CHILD_FORM_INFO(this.props.index).icon.text}
					priority={CHILD_FORM_INFO(this.props.index).icon.priority}
					imgSrc={this.props.child.child_profile.icon}
					defaultImgSrc={RegisterChildForm.defaultProps.child.child_profile.icon}
					isConfirming={this.props.isConfirming}
					isStudent={this.props.child.is_student}
					onChange={this.handleChangeIcon}
					error={this.props.child.errors.icon}
					targetRef={(elm => {this.props.childElm.icon = elm})}
					targetRef2={(elm => {this.props.childElm.iconWrap = elm})}
				/>
				<div className={this.setEditClass()}>
					<TextInput
						title='お名前'
						required
						isConfirming={this.props.isConfirming}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						inputs={[
							{
								name:'last_name',
								name_ja: CHILD_FORM_INFO(this.props.index).last_name.text,
								priority: CHILD_FORM_INFO(this.props.index).last_name.priority,
								placeholder:'姓',
								value:this.props.child.last_name,
								error:this.props.child.errors.last_name,
								targetRef: (elm => {this.props.childElm.last_name = elm}),
							},
							{
								name:'first_name',
								name_ja: CHILD_FORM_INFO(this.props.index).first_name.text,
								priority: CHILD_FORM_INFO(this.props.index).first_name.priority,
								placeholder:'名',
								value:this.props.child.first_name,
								error:this.props.child.errors.first_name,
								targetRef: (elm => {this.props.childElm.first_name = elm}),
							},
						]}
					/>
					<TextInput
						title='ふりがな'
						required
						isDisplay={this.props.child.is_student}
						isConfirming={this.props.isConfirming}
						onChange={this.handleProfileChange}
						onBlur={this.handleBlur}
						inputs={[
							{
								name:'last_name_kana',
								name_ja: CHILD_FORM_INFO(this.props.index).last_name_kana.text,
								priority: CHILD_FORM_INFO(this.props.index).last_name_kana.priority,
								placeholder:'せい',
								value:this.props.child.child_profile.last_name_kana,
								error:this.props.child.errors.last_name_kana,
								targetRef: (elm => {this.props.childElm.last_name_kana = elm}),
							},
							{
								name:'first_name_kana',
								name_ja: CHILD_FORM_INFO(this.props.index).first_name_kana.text,
								priority: CHILD_FORM_INFO(this.props.index).first_name_kana.priority,
								placeholder:'めい',
								value:this.props.child.child_profile.first_name_kana,
								error:this.props.child.errors.first_name_kana,
								targetRef: (elm => {this.props.childElm.first_name_kana = elm}),
							},
						]}
					/>
					<DateInput
						title='生年月日'
						required
						name='birth_date'
						value={this.props.child.birth_date}
						isConfirming={this.props.isConfirming}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						pastYears={20}
						futureYears={0}
						selects={{
							birth_date_year: {
								name_ja: CHILD_FORM_INFO(this.props.index).birth_date_year.text,
								priority: CHILD_FORM_INFO(this.props.index).birth_date_year.priority,
								error: this.props.child.errors.birth_date_year,
								targetRef: (elm => {this.props.childElm.birth_date_year = elm})
							},
							birth_date_month: {
								name_ja: CHILD_FORM_INFO(this.props.index).birth_date_month.text,
								priority: CHILD_FORM_INFO(this.props.index).birth_date_month.priority,
								error: this.props.child.errors.birth_date_month,
								targetRef: (elm => {this.props.childElm.birth_date_month = elm})
							},
							birth_date_day: {
								name_ja: CHILD_FORM_INFO(this.props.index).birth_date_day.text,
								priority: CHILD_FORM_INFO(this.props.index).birth_date_day.priority,
								error: this.props.child.errors.birth_date_day,
								targetRef: (elm => {this.props.childElm.birth_date_day = elm})
							}
						}}
					/>
					<Select
						title='性別'
						required
						name='gender_id'
						name_ja={CHILD_FORM_INFO(this.props.index).gender_id.text}
						priority={CHILD_FORM_INFO(this.props.index).gender_id.priority}
						isConfirming={this.props.isConfirming}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						value={this.props.child.gender_id} options={[
							{ value:'1', title: '男性' },
							{ value:'2', title: '女性' },
							{ value:'3', title: 'その他' },
						]}
						error={this.props.child.errors.gender_id}
						targetRef={(elm => {this.props.childElm.gender_id = elm})}
					/>
				</div>
				<div style={{display : `${display}` }}>
					<div className={this.setEditClass()}>
						<TextInput
							title='通っている小学校'
							required
							isConfirming={this.props.isConfirming}
							onChange={this.handleProfileChange}
							onBlur={this.handleBlur}
							inputs={[
								{
									name:'elementary_school',
									name_ja: CHILD_FORM_INFO(this.props.index).elementary_school.text,
									priority: CHILD_FORM_INFO(this.props.index).elementary_school.priority,
									placeholder:'○○小学校',
									value:this.props.child.child_profile.elementary_school,
									error:this.props.child.errors.elementary_school,
									targetRef:(elm => {this.props.childElm.elementary_school = elm})
								},
							]}
						/>
						<Select
							title='学年'
							name='grade_id'
							name_ja={CHILD_FORM_INFO(this.props.index).grade_id.text}
							priority={CHILD_FORM_INFO(this.props.index).grade_id.priority}
							required
							value={this.props.child.child_profile.grade_id}
							isConfirming={this.props.isConfirming}
							onChange={this.handleProfileChange}
							onBlur={this.handleBlur}
							options={[
								{value:'1', title:'小学1年生'},
								{value:'2', title:'小学2年生'},
								{value:'3', title:'小学3年生'},
								{value:'4', title:'小学4年生'},
								{value:'5', title:'小学5年生'},
								{value:'6', title:'小学6年生'},
							]}
							error={this.props.child.errors.grade_id}
							targetRef={(elm => {this.props.childElm.grade_id = elm})}
						/>
						<TextInput
							title='興味があること'
							required
							isConfirming={this.props.isConfirming}
							onChange={this.handleProfileChange}
							onBlur={this.handleBlur}
							inputs={[
								{
									name:'interest',
									name_ja: CHILD_FORM_INFO(this.props.index).interest.text,
									priority: CHILD_FORM_INFO(this.props.index).interest.priority,
									placeholder:'昆虫',
									value:this.props.child.child_profile.interest,
									error:this.props.child.errors.interest,
									targetRef:(elm => {this.props.childElm.interest = elm})
								},
							]}
						/>
						<TextInput
							title='習い事'
							required
							isConfirming={this.props.isConfirming}
							onChange={this.handleProfileChange}
							onBlur={this.handleBlur}
							inputs={[
								{
									name:'after_school_activity',
									name_ja: CHILD_FORM_INFO(this.props.index).after_school_activity.text,
									priority: CHILD_FORM_INFO(this.props.index).after_school_activity.priority,
									placeholder:'水泳',
									value:this.props.child.child_profile.after_school_activity,
									error:this.props.child.errors.after_school_activity,
									targetRef:(elm => {this.props.childElm.after_school_activity = elm})
								},
							]}
						/>
					</div>
				</div>
			</>
		)
	}

}