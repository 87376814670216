import React from 'react'
import PageBase from '../pagebase'
import moment from 'moment'
import { getReportDetail } from '../../../api/report'
import { Link } from 'react-router-dom'
import ReportModule from '../../../modules/report'
import { BASE_TITLE } from '../../../config'

export default class ReportDetail extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/report/:id/'
	}

	static get title() {
		return ''
	}

	static get title_en() {
		return ''
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-report.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		this.id = parseInt(this.props.match.params.id);
		this.state = {
			...this.state,
			isDataLoaded: false,
			isGetData: false,
		}
	}

	componentDidMount() {
		super.componentDidMount(false)
		this.getDataAndSetState()
	}

	componentDidUpdate() {
		super.componentDidUpdate()
		const id = parseInt(this.props.match.params.id);
		if (this.id !== id) {
			this.id = id
			this.getDataAndSetState()
		}

		// データ取得時に一度だけ実施
		if (this.state.isGetData) {
			this.setState({isGetData: false})
			document.title = `${this.state.reportDetail.title} | ${BASE_TITLE}`;
			this.sendGa()
			new ReportModule()
		}
	}

	/**
	* APIから情報を取得してstateに反映
	* @param {*} e
	*/
	getDataAndSetState() {
		const reportDetail = getReportDetail(this.id)
		this.setState({isGetData: false})
		reportDetail.then((data)=>{
			this.setState({
				...this.state,
				isDataLoaded: true,
				reportDetail: data,
				isGetData: true,
			})
		})
	}

	renderChild() {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const id = this.id
			const reportDetail = this.state.reportDetail
			let ts = moment.unix(reportDetail.date)
			let date = ts.format("yyyy.MM.DD")
			return (
				<>
					<section className="news-detail">
						<div className="news__wrap">
							<div className="news-detail__head">
								<div className="news-detail__head__date">{date}</div>
								<div className="news-detail__head__title">{reportDetail.title}</div>
							</div>
							<div className="news-detail__body">
								<div dangerouslySetInnerHTML={{ __html: reportDetail.contents }}></div>
							</div>
						</div>
					</section>

					<section className="pagenation">
						<div className="pagenation__wrap">
							<div className="pagenation__prev">
								{reportDetail.next_post_link &&
									<Link to={reportDetail.next_post_link} onClick={this.resetLoadingStatus}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="前へ" />
										</picture>
									</Link>
								}
							</div>
							<div className="pagenation__btn pc-view">
								<Link to="/report/"><span>活動レポート一覧</span></Link>
							</div>
							<div className="pagenation__next">
								{reportDetail.prev_post_link &&
									<Link to={reportDetail.prev_post_link} onClick={this.resetLoadingStatus}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="次へ" />
										</picture>
									</Link>
								}
							</div>
						</div>
						<div className="pagenation__btn sp-view">
						<Link to="/report/"><span>活動レポート一覧</span></Link>
						</div>
					</section>
				</>
			)
		}
	}
}