import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import MypageTab from '../../../component/common/mypagetab'
import MyClassBase from './myClassBase'
import Wave from '../../../component/common/wave'
import { getCourseClasses, getNextSchedule } from '../../../../api/myClass'
import MypageSelect from '../../../component/common/mypageSelect'
import Term from './term'
import { formatDate, formatDateTimeRange, formatTimeRange, getIsOnlineImage } from '../../../../utils'
import NoteModal from '../common/noteModal'
import ZoomModal from '../common/zoomModal'
import { getReportList } from '../../../../api/schopReport'
import { AuthError } from '../../../../api/auth'

export default class MyClass extends MyClassBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/myclass/'
	}

	static get title() {
		return '受講中・予約中スクール'
	}

	static get title_en() {
		return ''
	}

	static get key() {
		return "index"
	}

	// ---------- 基本設定ここまで ----------
	componentDidMount() {
		super.componentDidMount()
		getReportList().then((data) => {
			this.setState({ reports: data.reports })
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
	}

	loadCourseData() {
		return getCourseClasses()
	}

	next(terms) {
		const nextSchedule = getNextSchedule(terms)
		if (!nextSchedule) return '未定'
		const start = nextSchedule.schedule.started_at
		const end = nextSchedule.schedule.finished_at
		const date = formatDate(start)
		const time = formatTimeRange(start, end)
		return (
			<>
				{nextSchedule.term.term_name} <br /> {nextSchedule.schedule.period_content}<br />
				{date} {time}
			</>
		)
	}

	getCurrentTerm(terms) {
		const nextSchedule = getNextSchedule(terms)
		if (!nextSchedule) return null
		return nextSchedule
	}

	handleOpenNote = (e, data) => {
		if (!this.noteModal) return
		this.noteModal.show(data)
		e.preventDefault()
	}

	handleOpenZoom = (e, data) => {
		if (!this.zoomModal) return
		this.zoomModal.show(data)
		e.preventDefault()
	}

	/**
	 * 単に表示用のjsxをreturnする 記述が長いので切り離した
	 * @param {object} entryList
	 */
	getCourseList() {
		const courseListAll = this.state.courseList
		//選択中の子供でフィルターかける
		let courseList = []
		if(courseListAll && courseListAll.my_course_classes.length > 0) {
			courseList = courseListAll.my_course_classes.filter( item =>
				// 受講済みは消す
				item.child.id == this.state.child_id && item.member_state != "finished"
			)
		}
		const reportsAll = this.state.reports
		let reportList = []
		if(reportsAll && reportsAll.length > 0){
			reportList = reportsAll.filter(item => item.child.id == this.state.child_id)
		}

		if(courseList.length == 0) {
			return super.nothingData()
		}
		return (
			<ul>
				{courseList.map((courseClass, key) => {
					const currentTerm = this.getCurrentTerm(courseClass.my_terms)
					let reports = []
					if(reportList && reportList.length > 0) {
						reports = reportList.filter(item => {
							return (
								item.course_id == courseClass.course_id
								&&
								item.course_target_age_id == courseClass.course_target_age_id
							)

						})
					}
					//NOTE: 受講状況のタームは全部表示するのかどうかわからなかったのでいったんmy_terms[0]だけ表示するようにしている
					if(courseClass.child.id == this.state.child_id) {
						return (
							<li key={key}>
								<div className="mypage-list-block pcdetail">
									<div className="mypage-list__pct">
										<img src={courseClass.course_target_age_main_image_url} alt=""/>
										<Wave />
									</div>
									<div className="mypage-list__schooltitle-detail">{courseClass.course_name}<small>対象年齢：{courseClass.target_age_label}</small></div>
									<div className="mypage-list__lead">{courseClass.course_target_sales_copy}</div>
									<div className="mypage-list__description">
										<div className="mypage-list__description__title">{courseClass.name}</div>
										<div className="mypage-list__description__list">
											<dl>
												<dt>日程概要</dt>
												<dd>
												{courseClass.description.split('\n').map((desc, key) => {
													return (<React.Fragment key = {key}>{desc}<br/></React.Fragment>)
												})}
												</dd>
											</dl>
										</div>
									</div>
									<div className="mypage-list__pcwrap-detail">
										<div className="mypage-list__user-detail">
											<div className="mypage-list__user__thumb">
												<img src={courseClass.child.child_profile.icon_url} alt=""/>
											</div>
											<div className="mypage-list__user__name">{courseClass.child.first_name}さん</div>
											<div className="mypage-list__user__status">
											{getIsOnlineImage(courseClass.course_is_online)}
											</div>
										</div>
										<div className="mypage-list__btn">
											<Link to={`/courses/${courseClass.course_id}/course_target_ages/${courseClass.course_target_age_id}/`}><span>コース詳細</span></Link>
										</div>
									</div>
									<div className="mypage-list__pcwrap-detail2">
										<div className="mypage-list__date-detail">
											<span>次回授業</span>
											<p>{this.next(courseClass.my_terms)}</p>
										</div>
										<div className="mypage-list__btns">
											<a href="#" onClick={(e) => { this.handleOpenNote(e, courseClass) }}>
												<span className="mypage-list__btns__icon"><img src="/assets/images/me/btn-note.svg" alt="スコップ・スクールノート"/></span>
												<span className="mypage-list__btns__text">スコップ・スクールノート</span>
											</a>
											<a href="#" onClick={(e) => {this.handleOpenZoom(e, courseClass) }}>
												<span className="mypage-list__btns__icon"><img src="/assets/images/me/btn-zoom.svg" alt="Zoom URL"/></span>
												<span className="mypage-list__btns__text">Zoom URL</span>
											</a>
										</div>
									</div>
									<div className="mypage-list__detail">
										<div className="mypage-list__detail__title">
											<span>受講状況</span>
										</div>
											{ currentTerm &&
											<Term data={currentTerm.term}/>
											}
									</div>
									{reports.length > 0 &&
										<div className="mypage-list__detail">
											<div className="mypage-list__detail__title"><span>スコップ・スクールレポート</span></div>
											<div className="mypage-list__detail__report">
												<ul>
													{reports.length > 0 && reports.map((report,i) => {
														const path = `/me/report/?child_id=${report.child.id}&course_id=${courseClass.course_id}&course_target_age_id=${courseClass.course_target_age_id}&term_id=${report.term_id}`
														return (
															<li key={i}>
																<Link to={path} >ターム{i+1} {report.term_name}</Link>
															</li>
															)
														})}
												</ul>
											</div>
										</div>
									}
								</div>
							</li>
						)
					}
				})}

			</ul>
		)
	}
}