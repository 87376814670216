import React from 'react'
import { Link } from 'react-router-dom'
import { getCourseList } from '../../../api/course'
import { REAL_SCHOOL_DATA, SCHOOL } from '../../../config'
import SlideFlick from '../../../modules/top/slideFlick'
import BarPager from '../../../modules/top/barPager'
import { getIsOnlineImage } from '../../../utils'
import Wave from '../../component/common/wave'

export default class CourseList extends React.Component {
	constructor(props) {
		super(props)
		this.school = this.props.school
		this.isRegular = (this.school === SCHOOL.regular ) ? true : false
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}

	componentDidMount() {
		const courseList = getCourseList(this.school, 'published')
		courseList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				courseList: data,
			})
		})
	}

	componentDidUpdate() {
		if (!this.slide && this.state.courseList) {
			this.slide = new SlideFlick($(this.container), false)
			this.pager = new BarPager($(this.pager), this.slide.numContents, 0 )
			this.slide.on('change', this.pager.setIndex.bind(this.pager))
		}
	}

	/**
	 *
	 * @param {Object} courseData
	 * @param {string} key
	 * @param {boolean} isRealSchool
	 */
	_getCourseCard(courseData, key, isRealSchool = false) {
		const isRegular = this.isRegular
		return (
			<li key={key}>
				<div className="top-course__list__pct">
					<img src={courseData.main_image_url} alt={courseData.name} />
					<div className="top-course__list__pct__icon">
						{getIsOnlineImage(courseData.is_online)}
					</div>
					<Wave />
				</div>
				{/* レギュラーのみ */}
				{isRegular && courseData.name_image_url &&
					<div className="top-course__list__title socialcreativecourse">
						<img src={courseData.name_image_url} alt={courseData.name} />
					</div>
				}

				{/* 短期のみ */}
				{!isRegular && courseData.short_term_school_name &&
					<div className="top-course__list__title">{courseData.short_term_school_name}</div>
				}

				{/* 短期のみ */}
				{!isRegular && courseData.name &&
					<div className="top-course__list__subtitle">{courseData.name}</div>
				}

				{courseData.introduction &&
					<div className="top-course__list__text">{courseData.introduction}</div>
				}

					{courseData.course_target_ages.length != 0 &&
						<div className="top-course__list__btn">
							{courseData.course_target_ages.map((target, key2)=>{
								let suffix = (courseData.course_target_ages.length == 1) ? "single" : "double"
								return (
									<div className={`top-course__list__btn-${suffix}`} key={key2}>
										{target.target_age_label &&
											<p>{target.target_age_label}</p>
										}
										<Link to={`/courses/${courseData.id}/course_target_ages/${target.id}`}>
											<span>詳細</span>
										</Link>
									</div>
								)
							})}
						</div>
					}
			</li>
		)
	}

	render() {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const courseList = this.state.courseList
			const isRegular = this.isRegular

			// レギュラーコースは古い順
			if(isRegular) {
				courseList.courses.sort((course1, course2) => {
					return course1.id - course2.id
				})
			}else {
				courseList.courses.sort((course1, course2) => {
					return course2.id - course1.id
				})
			}
			return (
				<React.Fragment>
					{!isRegular &&
					<div className="top-course__lead">
						<p>1～２日間などの短期間で集中的に学べるプログラム。<br />テーマや領域は今後続々と拡充してゆく予定です。</p>
					</div>
					}
					{courseList.courses.length == 0 &&
					<>
						<div className="top-course__lead">
							<p>現在募集中の短期スクールはありません</p>
						</div>
						<div className="mypage-list-empty__btns">
							<Link to="/archive/">
								<span>過去のスクール一覧</span>
							</Link>
						</div>
					</>
					}


					<div className="top-course__list" ref={node => this.container = node}>
						<ul>
							{courseList.courses.map(course => {
								return this._getCourseCard(course, course.id)
							})}
							{/* {this._getCourseCard(REAL_SCHOOL_DATA, REAL_SCHOOL_DATA.id, true)} */}
						</ul>
						{courseList.courses.length > 1 &&
						<div className="top-course__list__scrollbar" ref={node => this.pager = node}>
							<div className="top-course__list__scrollbar-cursor js_bar"></div>
						</div>
						}
					</div>
				</React.Fragment>
			)
		}
	}
}