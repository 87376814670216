import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FormBase from './formbase'


export default class Select extends FormBase {

	static propTypes = {
		title: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		noWrap: PropTypes.bool,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		name: PropTypes.string,
		name_ja: PropTypes.string,
		priority: PropTypes.number,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		options: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.shape({
				title: PropTypes.string,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			})),
			PropTypes.object,
			PropTypes.array,
		]),
		error: PropTypes.object,
		targetRef: PropTypes.func,
	}


	static defaultProps = {
		title: '',
		value: '',
		noWrap: false,
		required: false,
		isConfirming: false,
		isDisplay: true,
		needEmpty: true,
		name: '',
		name_ja: '',
		priority: 0,
		onChange: () => {},
		onBlur: () => {},
		options: [
			{
				title: '',
				value: '',
			}
		],
		error: {},
		targetRef: () => {},
	}

	constructor(props) {
		super(props)
		this.options = this.props.options
		if (this.options instanceof Array) {
			if (this.options.length > 0 && !(this.options[0] instanceof Object)) {
				let options = []
				this.options.map((item, i) => {
					options.push({
						title: item,
						value: item,
					})
				})
				this.options = options
			}
		} else if (this.options instanceof Object) {
			let options = []
			Object.keys(this.options).map((key) => {
				options.push({
					title: this.options[key],
					value: key
				})
			})
			this.options = options
		}
	}

	get confirm() {
		let value = null
		this.options.map( item => {
			if (item.value == this.props.value) {
				value = item.title
			}
		})
		return value
	}

	get form() {
		const className = (!this.props.noWrap) ? "form-edit__input-form-1col" : ""
		const error = (this.props.error && this.props.error.isError) ? ' error': ''
		return (
			<div className={className} style={{position : "relative"}}>
				<select
					className={error}
					name={this.props.name}
					data-name_ja={this.props.name_ja}
					data-priority={this.props.priority}
					value={this.props.value}
					onChange={this.props.onChange}
					onBlur={this.props.onBlur}
					ref={this.props.targetRef}
				>
					{this.props.needEmpty && (<option value=""></option>) }
					{this.options.map( (item, i) => {
						return (
							<option value={item.value} key={i}>{item.title}</option>
						)
					})}
				</select>
				{(this.props.error && this.props.error.isError) &&
					<div className="form-edit__input-error">
						{this.props.error.message[0]}
					</div>
				}
			</div>
		)
	}
}