import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Validate, {MaxLength, MinLength, EqualLength} from '../_utils/validate'
import Auth from '../../../api/auth'
import Select from '../../component/form/select'
import TelInput from '../../component/form/telinput'
import TextInput from '../../component/form/textinput'
import ZipInput from '../../component/form/zipinput'
import TrimImage from '../../component/form/trimImage'
import PageBase from '../pagebase'
import RegisterChildForm from './childform'
import AddressAutofill from '../../../modules/addressAutofill'
import { scrollToTop, scrollToElement } from '../../../utils'
import Loading from '../../component/common/loading'
import { FORM_INFO, PREFECTURES, REASON } from '../../../config'
import RegisterComplete from './complete'

export default class RegisterForm extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/register/form.html'
	}

	static get title() {
		return 'スコップ・スクールアカウントの新規登録'
	}

	static get title_en() {
		return 'parentsinfo'
	}

	static get isConf() {
		return this._isConf
	}
	static set isConf(flg) {
		this._isConf = flg
	}

	static get header_title_edit() {
		return (
			<span>
				<img src="/assets/images/form/txt-parentsinfo.svg" alt={this.title} />
			</span>
		)
	}

	static get header_title_conf() {
		return (
			<span>
				<img src="/assets/images/form/txt-confirm.svg" alt="登録内容確認" />
			</span>
		)
	}

	static get header_title() {
		if (this.isConf === undefined) this.isConf = false
		return (
			<div className="header__title">
				{this.isConf ? this.header_title_conf : this.header_title_edit}
			</div>
		)
	}

	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)

		if (Auth.perishableToken == null) {
			this.props.history.push('/login.html')
		}

		this.state = {
			...this.state,
			isConfirming: false,
			isAllRead: false,
			isConfirmChecked: false,
			agree: {
				0: false,
				1: false,
				2: false,
			},
			parent: {
				last_name: '',
				first_name: '',
				last_name_kana: '',
				first_name_kana: '',
				post_code1: '',
				post_code2: '',
				prefecture_id: '',
				city: '',
				address1: '',
				address2: '',
				tel1: '',
				tel2: '',
				tel3: '',
				email: '',
				emergency_name1: '',
				emergency_tel1_1: '',
				emergency_tel1_2: '',
				emergency_tel1_3: '',
				emergency_name2: '',
				emergency_tel2_1: '',
				emergency_tel2_2: '',
				emergency_tel2_3: '',
				work_place: '',
				reason_id: '',
				errors: {},
			},
			children: [
				{
					...RegisterChildForm.defaultProps.child
				}
			]
		}

		// 親フォーム用バリデーションスキーム
		this.parentValidateScheme = {
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			last_name_kana: [Validate.Required, Validate.Furigana],
			first_name_kana: [Validate.Required, Validate.Furigana],
			post_code1: [Validate.Required, Validate.Number, new EqualLength(3)],
			post_code2: [Validate.Required, Validate.Number, new EqualLength(4)],
			prefecture_id: [Validate.Required],
			city: [Validate.Required],
			address1: [Validate.Required],
			tel1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			tel2: [Validate.Required, Validate.Number, new MaxLength(4)],
			tel3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			email: [Validate.Required, Validate.Mail],
			emergency_name1: [Validate.Required],
			emergency_tel1_1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			emergency_tel1_2: [Validate.Required, Validate.Number, new MaxLength(4)],
			emergency_tel1_3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			emergency_name2: [Validate.Required],
			emergency_tel2_1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			emergency_tel2_2: [Validate.Required, Validate.Number, new MaxLength(4)],
			emergency_tel2_3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			work_place: [Validate.Required],
			reason_id: [Validate.Required],
		}

		// 子供登録用バリデーションスキーム
		this.childValidateScheme = {
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			gender_id: [Validate.Required],
			birth_date_year: [Validate.Required],
			birth_date_month: [Validate.Required],
			birth_date_day: [Validate.Required],
		}

		// 生徒登録用バリデーションスキーム
		this.studentValidateScheme = {
			icon: [Validate.IconChecked],
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			last_name_kana: [Validate.Required, Validate.Furigana],
			first_name_kana: [Validate.Required, Validate.Furigana],
			gender_id: [Validate.Required],
			elementary_school: [Validate.Required],
			grade_id: [Validate.Required],
			interest: [Validate.Required],
			after_school_activity: [Validate.Required],
			birth_date_year: [Validate.Required],
			birth_date_month: [Validate.Required],
			birth_date_day: [Validate.Required],
		}

		// バリデーション要素格納
		this.parentElm = {}
		this.childrenElm = []

		//三つの必読項目
		this.toReads = []
	}

	/**
	 * このコンポーネントがはじめにマウントされた時に実行されます
	 */
	componentDidMount() {
		super.componentDidMount()
		this.addressAutofill = new AddressAutofill(
			"post_code1",
			"post_code2",
			"prefecture_id",
			".js-address-autofill"
		)
		this.addressAutofill.on(AddressAutofill.UpdateZip, this.updateZip)
		this.addressAutofill.on(AddressAutofill.UpdateZipError, this.updateZipError)

		for(let i in this.toReads) this.toReads[i] = false
	}

	/**
	 * このコンポーネントが削除される前に実行されます
	 */
	componentWillUnmount() {
		super.componentWillUnmount()
		this.addressAutofill.onExit()
	}

	/**
	 * 保護者情報に変更が発生した時に発火します
	 * @param {event} e
	 */
	handleParentChange = (e) => {
		this.setState({
			parent: {
				...this.state.parent,
				[e.target.name]: e.target.value
			}
		})
		e.preventDefault()
	}

	/**
	 * 保護者情報のフォームのフォーカスが外れた時に発火します
	 * @param {event} e
	 */
	handleParentBlur = (e) =>{
		const name = e.target.name
		const value = e.target.value
		const name_ja = e.target.dataset.name_ja
		const priority = e.target.dataset.priority

		this.setState({
			parent: {
				...this.state.parent,
				[name]: value,
				errors: {
					...this.state.parent.errors,
					[name]: Validate.isValid(name_ja, value, priority, this.parentValidateScheme[name])
				},
			}
		})
	}

	/**
	 * 子どものフォームの内容が変更された時に発火します
	 * @param {number} index 子どものインデックス
	 * @param {object} data 子どものフォームのデータ
	 */
	handleChildChange = (index, data) => {
		const children = this.state.children.concat()
		children[index] = data
		this.setState({children})
	}

	/**
	 * 子どものフォームのフォーカスが外れた時に発火します
	 * @param {number} index
	 * @param {object} data
	 */
	handleChildBlur = (index, data) => {
		this.handleChildChange(index, data)
	}

	/**
	 * 子どもの追加ボタンがクリックされ時に発火します
	 */
	handleChildAdd = (e) => {
		const children = this.state.children.concat()

		children.push({
			...RegisterChildForm.defaultProps.child,
			errors: {},
		})
		this.setState({children})
		e.preventDefault()
	}

	/**
	 * 子どもの削除ボタンがクリックされた時に発火します
	 * @param {event} e
	 * @param {number} index 子どものインデックス
	 */
	handleChildDelete = (e, index) => {
		const children = this.state.children.concat()
		if(children.length > 1) {
			this.confirmShow(
				"確認",
				'このお子様の情報を削除します',
				(()=> { //OK時
					children.splice(index, 1)
					this.setState({children})
				}),
				null
			)
		} else {
			this.errorModal("エラー", "最低一人のお子様を登録してください。")
		}
		e.preventDefault()
	}

	/**
	 * 子供登録のチェック変更時対応
	 * @param {*} e
	 */
	handleChangeChildType = (e) => {
		const index = parseInt(e.target.getAttribute("index"))
		const checked = e.target.checked
		const children = this.state.children.concat()
		children[index].is_student = checked
		this.setState({children})
	}

	/**
	 * フォームの入力内容をチェックして確認画面に遷移します
	 * @param {event} e
	 */
	confirm = async (e) => {
		e.preventDefault()

		let _state = {...this.state}

		// 親 : バリデーション確認
		for (let key in this.parentValidateScheme) {
			if (this.parentElm[key]) {
				const value = this.parentElm[key].value
				const name_ja = this.parentElm[key].dataset.name_ja
				const priority = this.parentElm[key].dataset.priority
				_state.parent.errors[key] = Validate.isValid(name_ja, value, priority, this.parentValidateScheme[key])
			} else { //予期せぬエラー
				_state.parent.errors[key] = Validate.unKnownError()
			}
		}
		const isParentError = Validate.isAllOk(_state.parent.errors, this.parentValidateScheme)

		// 子のバリデーション
		let isChildrenError = false
		for (let index in _state.children) {
			const child = _state.children[index]
			const validateScheme = child.is_student ? this.studentValidateScheme : this.childValidateScheme
			for (let key in validateScheme) {
				if (this.childrenElm[index][key]) {
					const value = this.childrenElm[index][key].value
					const name_ja = this.childrenElm[index][key].dataset.name_ja
					const priority = this.childrenElm[index][key].dataset.priority
					_state.children[index].errors[key] = Validate.isValid(name_ja, value, priority, validateScheme[key])

				} else { //予期せぬエラー
					_state.children[index].errors[key] = Validate.unKnownError()
				}
			}
			const isChildError = Validate.isAllOk(_state.children[index].errors, validateScheme)
			if (isChildError) isChildrenError = true
		}

		if (isParentError || isChildrenError) {
			this.setState(_state)

			// 親/子のerror要素をまとめる
			let errors = []
			for(const key in _state.parent.errors) {
				if(_state.parent.errors[key].isError) errors.push(_state.parent.errors[key])
			}

			for (let index in _state.children) {
				const child = _state.children[index]
				// 子のerror要素
				for(const key in child.errors) {
					if(child.errors[key].isError) errors.push(child.errors[key])
				}
			}

			// priorityでソート
			errors.sort((el1, el2) => el1.priority - el2.priority)
			// エラーメッセージ配列
			const errorMsgs = errors.map((el, _) => Validate.formatErrorMessage(el))

			this.setErrorFocus()
			this.errorListModal("エラー","入力内容に誤りがあります。ご確認ください。", errorMsgs)
			e.preventDefault()

			return
		}


		// 確認画面へ
		this.setState({
			..._state,
			isConfirming: true
		})
		RegisterForm.isConf = true

		scrollToTop()
		return false
	}

	/**
	 * 編集ボタンをクリックした時に発火し、確認画面から編集画面に戻ります
	 * @param {event} e
	 */
	edit = (e) => {
		this.setState({
			isConfirming: false,
			isConfirmChecked:false
		})
		RegisterForm.isConf = false
		scrollToTop()
		e.preventDefault()
	}



	/**
	 * 登録ボタンをクリックした時に発火し、ユーザー登録を実行します
	 * @param {event} e
	 */
	submit = (e) => {

		Loading.show()

		this._submit()
		scrollToTop()
		e.preventDefault()
	}

	async _submit() {
		const _state = {...this.state}

		// NOTE: 子供の場合はchild_profileをnullに
		for (let index in _state.children) {
			const child = _state.children[index]
			if (!child.is_student) {
				_state.children[index].child_profile = null
			}
		}

		let isNormal = true
		const result = await Auth.addUser(_state.parent, _state.children)
		Loading.hide()

		if(!result.messages) {
			// this.infoModal("アカウントの登録が完了しました。")  //完了画面が存在するからいらない？
		} else {

			this.errorModal(result.messages[0])
			isNormal = false
		}

		if(isNormal) {
			window.location.href = RegisterComplete.path
		}
	}

	/**
	 * エラーフォーカス
	 */
	setErrorFocus() {
		// 上から数えて最初にエラーが起きているフォームにフォーカス
		// 親
		for(const key in this.state.parent.errors) {
			const err = this.state.parent.errors[key]
			if(err.isError) {


				this.parentElm[key].focus()
				return
			}
		}
		//子
		for(const i in this.state.children) {
			for(const key in this.state.children[i].errors) {
				const err = this.state.children[i].errors[key]
				if(err.isError) {
					if(key !== 'icon') {

						this.childrenElm[i][key].focus()
						return
					} else { // icon設定対応
						scrollToElement(this.childrenElm[i].iconWrap, -40)
					}
				}
			}
		}

	}

	/**
	 * 住所更新時
	 */
	updateZip = (prefecture_id, city)=> {
		this.setState({
			parent: {
				...this.state.parent,
				prefecture_id,
				city,
			}
		})
	}

	/**
	 * 住所更新エラー
	 * @param {*} titls -
	 * @param {*} msg -
	 */
	updateZipError = (titls, msg)=>{
		this.errorModal(titls, msg)
	}

	/**
	 * 一つ目の確認ボタンのクリックイベント
	 * @param {e}} e
	 */
	checkIsConfirm = (e) => {
		if(!this.state.isAllRead) {
			e.preventDefault()
			e.target.checked = false
			this.errorModal("文書をご確認ください","３つの文書をお読みいただいた上で登録ボタンをクリックしてください。")
			return
		}
		this.setState({ isConfirmChecked: e.target.checked })
	}

	/**
	 * 三つの必読項目を確認 this.toReads[]
	 * @param {num} num
	 */
	checkIsRead = (num) => {
		this.setState({
			agree: {
				...this.state.agree,
				[num]: true
			}
		}, () => {
			let isAllRead = true
			for(let key in this.state.agree) {
				if(this.state.agree[key] == false) isAllRead = false
			}

			if(isAllRead) {
				this.setState({
					isAllRead: true
				})
			}
		})
	}

	checkDisabled() {
		if(this.state.isAllRead) return ""
		else return ' disable'
	}

	getIsReadClass(isRead) {
		return isRead ? "bggray" : ""
	}

	/**
	 * 確認と編集時のクラスだ仕分け
	 */
	setEditClass() {
		return (this.state.isConfirming) ? '' : 'form-edit__pcwrap-input2col'
	}

	/**
	 * render
	 */
	renderChild() {
		return (
			<form>

				{/* ----- 保護者 ----- */}
				{(!this.state.isConfirming) ? (
					<section className="form-edit">
						<div className="form-edit__lead">
							LINEログインありがとうございます。<br/>
							続いて、スコップ・スクールのアカウント登録に<br className="sp-view"/>
							必要な個人情報のご入力をお願いします。
							<div className="form-edit__lead-caution"> <span>※</span>は必ず記入してください。</div>
						</div>
						<div className="form-edit-wrap">
							<div className="form-edit__subtitle-bgyellow">保護者情報</div>
							<div className="form-edit__input">
								{this.forms()}
							</div>
						</div>
					</section>
				) : (
					<section className="form-information">
						<div className="form-information__lead">内容をご確認いただき「登録する」ボタンをクリックしてください</div>
						<div className="form-information-wrap">
							<div className="form-information__subtitle">保護者情報</div>
							<div className="form-information__list">
								{this.forms()}
							</div>
						</div>
					</section>
				)}

				{/* ----- 子ども ----- */}
				<section className="header">
					<div className="header__wrap">
						<div className="header__title"><span className="childinfo"><img src="/assets/images/form/txt-childinfo.svg" alt="お子様情報入力"/></span></div>
					</div>
				</section>

				{
					this.state.children.map((item, i) => {
						const isFirst = i == 0
						const isLast = i == this.state.children.length - 1
						this.childrenElm[i] = {}
						const validateScheme = item.is_student ? this.studentValidateScheme : this.childValidateScheme
						return (
							<RegisterChildForm
								isConfirming={this.state.isConfirming}
								child={item}
								onChange={this.handleChildChange}
								onChangeChildType={this.handleChangeChildType}
								onBlur={this.handleChildBlur}
								onAdd={this.handleChildAdd}
								onDelete={(e, i) => {this.handleChildDelete(e, i)}}
								index={i}
								isFirst={isFirst}
								isLast={isLast}
								validateScheme={validateScheme}
								key={i}
								childElm={this.childrenElm[i]}
							/>
						)
					})
				}

				{/* ----- ボタン ----- */}
				{this.state.isConfirming ? (

					<>

						<section className="form-editbtn"><a href="#" onClick={this.edit}><span>編集</span></a></section>
						<div className="form-information-wrap">
							<div className="form-information__subtitle">承諾書承認</div>
							<div className="form-information__text nopadding">スコップ・スクールの会員/準会員利用規約・個人情報の取り扱い・肖像権・利用者著作物の承諾書を確認いただき、同意の上、登録へ進んでください。承諾書を確認すると、「確認しました」へのチェックが可能になります。</div>
							<div className={`form-information__btn ${this.getIsReadClass(this.state.agree[0])}`}>
								<Link to="/terms.html" target="_blank" onClick={() => this.checkIsRead(0)}>
									<span>会員/準会員利用規約</span>
								</Link>
							</div>
							<div className={`form-information__btn ${this.getIsReadClass(this.state.agree[1])}`}>
								<Link to="/privacypolicy.html" target="_blank" onClick={() => this.checkIsRead(1)}>
									<span>個人情報の取り扱い</span>
								</Link>
							</div>
							<div className={`form-information__btn ${this.getIsReadClass(this.state.agree[2])}`}>
								<Link to="/portraitrights.html" target="_blank" onClick={() => this.checkIsRead(2)}>
									<span>肖像権、利用者著作物承諾書</span>
								</Link>
							</div>
							<div className="form-information__text small pccenter pcgray">※上記３つの文書をお読みいただいた上で、チェックをお入れください。</div>
							<div className={`form-information__checkbox${this.checkDisabled()}`}>
								<label style={{pointerEvents:'auto'}}>
									<span className="form-information__checkbox__label">確認しました</span>
									<input type="checkbox" onClick={(e) => this.checkIsConfirm(e)}/>
									<div className="form-information__checkbox__toggle"></div>
								</label>
							</div>
						</div>
						<section className="form-followbtn">
							{this.state.isConfirmChecked ?
							<div className="mypage-information__btn">
								<a href="#" onClick={this.submit}><span>登録する</span></a>
							</div>
							:
							<div className="mypage-information__btn disable">
								<a href="#" onClick={this.submit}><span>登録する</span></a>
							</div>
							}
						</section>
					</>
				) : (
					<>
					<section className="form-followbtn">
						<div className="mypage-information__btn">
							<a href="#" onClick={this.confirm}><span>登録内容の確認</span></a>
						</div>
					</section>
					</>
				)}
				<TrimImage />
		</form>
		)
	}

	/**
	 * 保護者のフォーム
	 */
	forms() {
		return (
			<>
				<div className={this.setEditClass()}>
					<TextInput title='お名前'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'last_name',
								name_ja: FORM_INFO.last_name.text,
								priority: FORM_INFO.last_name.priority,
								placeholder:'姓',
								value:this.state.parent.last_name,
								error: this.state.parent.errors.last_name,
								targetRef: (elm => {this.parentElm.last_name = elm}),
							},
							{
								name:'first_name',
								name_ja: FORM_INFO.first_name.text,
								priority: FORM_INFO.first_name.priority,
								placeholder:'名',
								value:this.state.parent.first_name,
								error: this.state.parent.errors.first_name,
								targetRef: (elm => {this.parentElm.first_name = elm}),
							},
						]}
					/>
					<TextInput
						title='ふりがな'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'last_name_kana',
								name_ja: FORM_INFO.last_name_kana.text,
								priority: FORM_INFO.last_name_kana.priority,
								placeholder:'せい',
								value:this.state.parent.last_name_kana,
								error: this.state.parent.errors.last_name_kana,
								targetRef: (elm => {this.parentElm.last_name_kana = elm}),
							},
							{
								name:'first_name_kana',
								name_ja: FORM_INFO.first_name_kana.text,
								priority: FORM_INFO.first_name_kana.priority,
								placeholder:'めい',
								value:this.state.parent.first_name_kana,
								error: this.state.parent.errors.first_name_kana,
								targetRef: (elm => {this.parentElm.first_name_kana = elm}),
							},
						]}
						/>
				</div>
				<ZipInput
					required
					onChange={this.handleParentChange}
					onBlur={this.handleParentBlur}
					isConfirming={this.state.isConfirming}
					inputs={[
						{
							name:'post_code1',
							name_ja: FORM_INFO.post_code1.text,
							priority: FORM_INFO.post_code1.priority,
							value: this.state.parent.post_code1,
							error: this.state.parent.errors.post_code1,
							targetRef: (elm => {this.parentElm.post_code1 = elm}),
						},
						{
							name:'post_code2',
							name_ja: FORM_INFO.post_code2.text,
							priority: FORM_INFO.post_code2.priority,
							value: this.state.parent.post_code2,
							error: this.state.parent.errors.post_code2,
							targetRef: (elm => {this.parentElm.post_code2 = elm}),
						},
					]}
					/>
				<div className={this.setEditClass()}>
					<Select name='prefecture_id'
						title='都道府県'
						name_ja={FORM_INFO.prefecture_id.text}
						priority={FORM_INFO.prefecture_id.priority}
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						value={this.state.parent.prefecture_id}
						error={this.state.parent.errors.prefecture_id}
						targetRef={elm => {this.parentElm.prefecture_id = elm}}
						options={PREFECTURES}
					/>
					<TextInput
						title='市町村'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'city',
								name_ja: FORM_INFO.city.text,
								priority: FORM_INFO.city.priority,
								placeholder:'〇〇区〇〇町',
								value:this.state.parent.city,
								error: this.state.parent.errors.city,
								targetRef: (elm => {this.parentElm.city = elm}),
							},
						]}
						/>
					<TextInput
						title='番地'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'address1',
								name_ja: FORM_INFO.address1.text,
								priority: FORM_INFO.address1.priority,
								placeholder:'〇丁目〇番地',
								value:this.state.parent.address1,
								error: this.state.parent.errors.address1,
								targetRef: (elm => {this.parentElm.address1 = elm}),
							},
						]}
						/>
					<TextInput
						title='建物名/号室'
						onChange={this.handleParentChange}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'address2',
								name_ja: FORM_INFO.address2.text,
								priority: FORM_INFO.address2.priority,
								placeholder:'〇〇マンション 〇〇号室',
								value:this.state.parent.address2
							},
						]}
						/>
					<TelInput
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name: 'tel1',
								name_ja: FORM_INFO.tel1.text,
								priority: FORM_INFO.tel1.priority,
								placeholder:'00',
								value:this.state.parent.tel1,
								error: this.state.parent.errors.tel1,
								targetRef: (elm => {this.parentElm.tel1 = elm}),
							},
							{
								name: 'tel2',
								name_ja: FORM_INFO.tel2.text,
								priority: FORM_INFO.tel2.priority,
								placeholder:'0000',
								value:this.state.parent.tel2,
								error: this.state.parent.errors.tel2,
								targetRef: (elm => {this.parentElm.tel2 = elm}),
							},
							{
								name: 'tel3',
								name_ja: FORM_INFO.tel3.text,
								priority: FORM_INFO.tel3.priority,
								placeholder:'0000',
								value:this.state.parent.tel3,
								error: this.state.parent.errors.tel3,
								targetRef: (elm => {this.parentElm.tel3 = elm}),
							},
						]}
						/>
					<TextInput
						title='メールアドレス'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'email',
								name_ja: FORM_INFO.email.text,
								priority: FORM_INFO.email.priority,
								placeholder:'schop@schopshchool.com',
								value:this.state.parent.email,
								error: this.state.parent.errors.email,
								targetRef: (elm => {this.parentElm.email = elm}),
							},
						]}
						/>
				</div>

				<div className="form-edit__subtitle">緊急連絡先1</div>
				<div className={this.setEditClass()}>
					<TextInput
						title='生徒との続柄'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'emergency_name1',
								name_ja: FORM_INFO.emergency_name1.text,
								priority: FORM_INFO.emergency_name1.priority,
								placeholder:'父',
								value:this.state.parent.emergency_name1,
								error: this.state.parent.errors.emergency_name1,
								targetRef: (elm => {this.parentElm.emergency_name1 = elm}),
							},
						]}
						/>
					<TelInput
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name: 'emergency_tel1_1',
								name_ja: FORM_INFO.emergency_tel1_1.text,
								priority: FORM_INFO.emergency_tel1_1.priority,
								placeholder:'00',
								value: this.state.parent.emergency_tel1_1,
								error: this.state.parent.errors.emergency_tel1_1,
								targetRef: (elm => {this.parentElm.emergency_tel1_1 = elm}),
							},
							{
								name: 'emergency_tel1_2',
								name_ja: FORM_INFO.emergency_tel1_2.text,
								priority: FORM_INFO.emergency_tel1_2.priority,
								placeholder:'0000',
								value: this.state.parent.emergency_tel1_2,
								error: this.state.parent.errors.emergency_tel1_2,
								targetRef: (elm => {this.parentElm.emergency_tel1_2 = elm}),
							},
							{
								name: 'emergency_tel1_3',
								name_ja: FORM_INFO.emergency_tel1_3.text,
								priority: FORM_INFO.emergency_tel1_3.priority,
								placeholder:'0000',
								value: this.state.parent.emergency_tel1_3,
								error: this.state.parent.errors.emergency_tel1_3,
								targetRef: (elm => {this.parentElm.emergency_tel1_3 = elm}),
							},
						]}
						/>
				</div>

				<div className="form-edit__subtitle">緊急連絡先2</div>
				<div className={this.setEditClass()}>
					<TextInput
						title='生徒との続柄'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'emergency_name2',
								name_ja: FORM_INFO.emergency_name2.text,
								priority: FORM_INFO.emergency_name2.priority,
								placeholder:'祖母',
								value:this.state.parent.emergency_name2,
								error: this.state.parent.errors.emergency_name2,
								targetRef: (elm => {this.parentElm.emergency_name2 = elm}),
							},
						]}
						/>
					<TelInput
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name: 'emergency_tel2_1',
								name_ja: FORM_INFO.emergency_tel2_1.text,
								priority: FORM_INFO.emergency_tel2_1.priority,
								placeholder:'00',
								value: this.state.parent.emergency_tel2_1,
								error: this.state.parent.errors.emergency_tel2_1,
								targetRef: (elm => {this.parentElm.emergency_tel2_1 = elm}),
							},
							{
								name: 'emergency_tel2_2',
								name_ja: FORM_INFO.emergency_tel2_2.text,
								priority: FORM_INFO.emergency_tel2_2.priority,
								placeholder:'0000',
								value: this.state.parent.emergency_tel2_2,
								error: this.state.parent.errors.emergency_tel2_2,
								targetRef: (elm => {this.parentElm.emergency_tel2_2 = elm}),
							},
							{
								name: 'emergency_tel2_3',
								name_ja: FORM_INFO.emergency_tel2_3.text,
								priority: FORM_INFO.emergency_tel2_3.priority,
								placeholder:'0000',
								value: this.state.parent.emergency_tel2_3,
								error: this.state.parent.errors.emergency_tel2_3,
								targetRef: (elm => {this.parentElm.emergency_tel2_3 = elm}),
							},
						]}
						/>
					<TextInput
						title='勤務先'
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						inputs={[
							{
								name:'work_place',
								name_ja: FORM_INFO.work_place.text,
								priority: FORM_INFO.work_place.priority,
								placeholder:'株式会社〇〇',
								value: this.state.parent.work_place,
								error: this.state.parent.errors.work_place,
								targetRef: (elm => {this.parentElm.work_place = elm}),
							},
						]}
						/>
					<Select
						title='登録のきっかけ'
						name='reason_id'
						name_ja={FORM_INFO.reason_id.text}
						priority={FORM_INFO.reason_id.priority}
						required
						onChange={this.handleParentChange}
						onBlur={this.handleParentBlur}
						isConfirming={this.state.isConfirming}
						value={this.state.parent.reason_id}
						error={this.state.parent.errors.reason_id}
						options={REASON}
						targetRef={elm => {this.parentElm.reason_id = elm}}
						/>
				</div>
			</>
		)
	}
}