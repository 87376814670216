import { SALT } from '../config'
import moment from 'moment'

/**
 * APIキー取得
 */
export async function getApiKey(){
	const text = `${SALT}_${moment().format('yyyyMMDD')}`
	const uint8  = new TextEncoder().encode(text)
	const digest = await crypto.subtle.digest('SHA-256', uint8)
	return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2,'0')).join('')
}


export function getSymbol(path) {
	let symbol = "&"
	if (path.indexOf('?') < 0) symbol = "?"
	return symbol
}
