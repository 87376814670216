import {API} from '../config'
import {getApiKey, getSymbol} from './utils'
import Auth, { AuthError } from './auth'

/*
 * 予約中クラス一覧
*/
export async function getEntries() {
	/* access tokenとは別に、APIKeyというのを取得する必要がある
	 * これは多分フロント内だけで完結してる
	 */
	const apiKey = await getApiKey()
	let path = API.ENTRIES

	//fetchはAPIに情報を取りに行くメソッド
	const res = await fetch(path, {
		headers: new Headers({
			'x-api-key': apiKey,
			'Authorization': 'Token token=' + Auth.accessToken,
		})
	})

	const json = res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}

/*
 * 受講中クラス一覧
*/
export async function getCourseClasses() {
	/* access tokenとは別に、APIKeyが必要
	 */
	const apiKey = await getApiKey()
	let path = API.COURSE_CLASSES
	// path = path+'?member_state=continuing' // 受講中
	// path = path+'?member_state=finished' // 受講済み
	// path = path+'?child='+encodeURIComponent(child_id) // 子どもでフィルタリング

	//fetchはAPIに情報を取りに行くメソッド
	const res = await fetch(path, {
		headers: new Headers({
			'x-api-key': apiKey,
			'Authorization': 'Token token=' + Auth.accessToken,
		})
	})
	const json = res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}

/**
 * タームの配列から次回授業のデータを探して my_schedule オブジェクトを返す
 * @param {array} terms
 */
export function getNextSchedule(terms) {
	const now = Math.floor(Date.now() / 1000)
	for (const t in terms) {
		const term = terms[t]
		for (const s in term.my_schedules) {
			const schedule = term.my_schedules[s]
			// 最初に見つかった、出席ステータスが空で開始日時が未来でタームが公開状態のものを返す
			if (schedule.attendance_state == null && schedule.started_at > now && term.published) {
				return {
					term: term,
					schedule: schedule,
				}
			}
		}
	}
	return null // 見つからなかったらnullを返す
}