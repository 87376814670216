import React from "react"
import { Link } from 'react-router-dom'

export default class MypageTab extends React.Component {

	getClassName(name) {
		return (name == this.props.current) ? 'mypage-tab-current' : ''
	}
	
	render() {
		return (
			<section className="mypage-tab">
        <ul>
					<li className={this.getClassName("index")}><Link to={`/me/myclass/?child_id=${this.props.child_id}`}>受講中・予約中</Link></li>
					<li className={this.getClassName("application")}><Link to={`/me/myclass/application/?child_id=${this.props.child_id}`}>申し込み中</Link></li>
					<li className={this.getClassName("complete")}><Link to={`/me/myclass/complete/?child_id=${this.props.child_id}`}>受講済</Link></li>
        </ul>
      </section>
	)
}
}