import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TextInput from './textinput'


export default class FileInput extends TextInput {

	static propTypes = {
		index: PropTypes.number,
		name: PropTypes.string,
		name_ja: PropTypes.string,
		priority: PropTypes.number,
		imgSrc: PropTypes.string,
		imgUrl: PropTypes.string,
		defaultImgSrc: PropTypes.string,
		isConfirming: PropTypes.bool,
		required: PropTypes.bool,
		isStudent: PropTypes.bool,
		onChange: PropTypes.func,
		error: PropTypes.object,
		targetRef: PropTypes.func,
		targetRef2: PropTypes.func,
	}


	static defaultProps = {
		index: 0,
		name: '',
		name_ja: '',
		priority: 0,
		imgSrc: '',
		imgUrl: '',
		defaultImgSrc: '',
		required: true,
		isConfirming: false,
		isStudent: false,
		onChange: () => {},
		error: {},
		targetRef: {},
		targetRef2: {},
	}

	handleChange = (e) => {
		if (!e.target.files || e.target.files.length < 1) return
		if (!this.props.onChange) return
		const file = e.target.files[0]
		if (!file.type.match(/^image\/(png|jpeg|gif)$/)) {
			alert('JPEG / PNG / GIF のいずれかのファイルを選択してください')
			return
		}
		e.target.value = '' // 次に同じファイル選択されてもchangeイベントが発火するように、input type=file をリセット
		this.props.onChange(file)
	}

	render() {
		const display = (this.props.isStudent) ? 'block' : 'none'
		return (
			<>
				<div style={{display : `${display}` }}>
					<div className="form-edit__thumb" ref={this.props.targetRef2}>
						<div className="form-edit__thumb__pct"><img src={`${this.props.imgSrc || this.props.imgUrl+"?"+new Date().getTime()}`} alt=""/></div>
						{!this.props.isConfirming && this.form()}
					</div>
				</div>
				{this.props.isConfirming && this.confirm()}
			</>
		)
	}

	/**
	 * 入力時
	 */
	form() {
		return (
			<div className="form-edit__thumb__link">
				<label htmlFor={`file${this.props.index}`}>
					<span>プロフィール写真を選択する</span>
					<input
						type="file"
						id={`file${this.props.index}`}
						onChange={this.handleChange}
						style={{display: 'none'}}
					/>
				</label>
				<span className="form-edit__thumb__link-require">※</span>
				<input
					name={this.props.name}
					data-name_ja={this.props.name_ja}
					data-priority={this.props.priority}
					type="hidden"
					value={this.props.imgSrc}
					ref={this.props.targetRef}
				/>
				{(this.props.error && this.props.error.isError) &&
					<div className="form-edit__input-error" style={{position : 'static', marginTop : '15px'}}>
						{this.props.error.message[0]}
					</div>
				}
			</div>
		)
	}

	/**
	 * 確認時
	 */
	confirm() {
		const isReg = (this.props.isStudent) ? 'する' : 'しない'
		return (
			<div>
				<dl>
					<dt>スコップ・スクール生徒登録</dt>
					<dd>{isReg}</dd>
				</dl>
			</div>
		)
	}
}