import { Application, Container, Graphics, Sprite, Texture } from 'pixi.js'
import gsap from 'gsap'

export default class ReportModule {
	constructor() {
		this.$iframes = $('.news-detail__body').find('iframe[src^="https://www.youtube.com"]')
		this.$iframes.each((i) => {
			$(this.$iframes[i]).wrap('<div class="youtube">')
		})
	}
}
