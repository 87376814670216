import React from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../../../api/auth'
import MyCourseClass from './myCourseClass'

export default class MyCourseClassList extends React.Component {

	render () {
		const courseList = this.props.courseList
		const firstChildId = Auth.getFirstStudentId()
		return (
			<>
				{courseList?.my_course_classes.length > 0 &&
					<>
						<div className="mypage-list__title"><span>受講中・予約中スクール</span>
							<div className="mypage-list__title__btn">
								<Link to={`/me/myclass/?child_id=${firstChildId}`}><span>一覧</span></Link>
							</div>
						</div>
						<ul>
							{courseList.my_course_classes.map((value,key)=>{
								return <MyCourseClass data={value} allCourses={this.props.allCourses} key={key} onOpenNote={this.props.onOpenNote} onOpenZoom={this.props.onOpenZoom} />
							})}
						</ul>
					</>
				}
			</>
		)
	}
}