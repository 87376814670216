import React from 'react'
import gsap from "gsap/gsap-core"
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

// SVGのソースをObjectURLにして埋め込み
const frames = [
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,20.83H56.43A1.43,1.43,0,0,0,55,22.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,20.83Z"/><path d="M50,29.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,1,0-4.28,0v20A2.14,2.14,0,0,0,50,29.41Z"/></svg>'], {type:'image/svg+xml'})),
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path d="M65.71,54.92a.81.81,0,1,1,.81-.81A.8.8,0,0,1,65.71,54.92Z"/><path d="M65.71,51.26a2.86,2.86,0,1,0,2.86,2.85,2.86,2.86,0,0,0-2.86-2.85Z"/><path d="M73.43,48.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,73.43,48.86Z"/><path d="M73.43,46.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M34.29,54.92a.81.81,0,1,1,.8-.81A.81.81,0,0,1,34.29,54.92Z"/><path d="M34.29,51.26a2.86,2.86,0,1,0,2.85,2.85,2.86,2.86,0,0,0-2.85-2.85Z"/><path d="M26.57,48.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,26.57,48.86Z"/><path d="M26.57,46.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,27.83H56.43A1.43,1.43,0,0,0,55,29.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,27.83Z"/><path d="M50,36.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,0,0-4.28,0v20A2.14,2.14,0,0,0,50,36.41Z"/></svg>'], {type:'image/svg+xml'})),
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path d="M67.71,52.92a.81.81,0,1,1,.81-.81A.8.8,0,0,1,67.71,52.92Z"/><path d="M67.71,49.26a2.86,2.86,0,1,0,2.86,2.85,2.86,2.86,0,0,0-2.86-2.85Z"/><path d="M80.57,49.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,80.57,49.86Z"/><path d="M80.57,47.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M73.43,44.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,73.43,44.86Z"/><path d="M73.43,42.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M32.29,52.92a.81.81,0,1,1,.8-.81A.81.81,0,0,1,32.29,52.92Z"/><path d="M32.29,49.26a2.86,2.86,0,1,0,2.85,2.85,2.86,2.86,0,0,0-2.85-2.85Z"/><path d="M19.43,49.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,19.43,49.86Z"/><path d="M19.43,47.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M26.57,44.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,26.57,44.86Z"/><path d="M26.57,42.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,30.83H56.43A1.43,1.43,0,0,0,55,32.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,30.83Z"/><path d="M50,39.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,1,0-4.28,0v20A2.14,2.14,0,0,0,50,39.41Z"/></svg>'], {type:'image/svg+xml'})),
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path d="M80.57,51.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,80.57,51.86Z"/><path d="M80.57,49.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M73.43,46.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,73.43,46.86Z"/><path d="M73.43,44.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M19.43,51.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,19.43,51.86Z"/><path d="M19.43,49.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M26.57,46.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,26.57,46.86Z"/><path d="M26.57,44.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,27.83H56.43A1.43,1.43,0,0,0,55,29.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,27.83Z"/><path d="M50,36.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,0,0-4.28,0v20A2.14,2.14,0,0,0,50,36.41Z"/></svg>'], {type:'image/svg+xml'})),
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path d="M80.57,55.86a.61.61,0,1,1,.61-.6A.6.6,0,0,1,80.57,55.86Z"/><path d="M80.57,53.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path d="M19.43,55.86a.61.61,0,1,1,.6-.6A.6.6,0,0,1,19.43,55.86Z"/><path d="M19.43,53.11a2.15,2.15,0,1,0,2.14,2.15,2.14,2.14,0,0,0-2.14-2.15Z"/><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,23.83H56.43A1.43,1.43,0,0,0,55,25.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,23.83Z"/><path d="M50,32.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,1,0-4.28,0v20A2.14,2.14,0,0,0,50,32.41Z"/></svg>'], {type:'image/svg+xml'})),
	URL.createObjectURL(new Blob(['<svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;}</style></defs><path class="cls-1" d="M96,63.4H66.25a5,5,0,0,0-4.73,3.41,11.77,11.77,0,0,1-22.32,0,5,5,0,0,0-4.74-3.41H4"/><path d="M60.71,20.83H56.43A1.43,1.43,0,0,0,55,22.26v4.79a5.14,5.14,0,0,1-4.85,5.21,5,5,0,0,1-5.15-5v-5a1.43,1.43,0,0,0-1.43-1.43H39.29a1.43,1.43,0,0,0-1.43,1.43v6a29.37,29.37,0,0,0,9.93,22l.32.28a2.84,2.84,0,0,0,3.78,0l.32-.28a29.37,29.37,0,0,0,9.93-22v-6A1.43,1.43,0,0,0,60.71,20.83Z"/><path d="M50,29.41a2.14,2.14,0,0,0,2.14-2.15v-20a2.14,2.14,0,1,0-4.28,0v20A2.14,2.14,0,0,0,50,29.41Z"/></svg>'], {type:'image/svg+xml'})),
]

export default class Loading extends React.Component {
	static instance = null
	/**
	 * ローディングを表示します
	 */
	static show() {
		if (!this.instance) return
		return this.instance.show()
	}

	/**
	 * ローディングを非表示にします
	 */
	static hide() {
		if (!this.instance) return
		return this.instance.hide()
	}

	/**
	 * constructor
	 * @param {Object} props
	 */
	constructor(props) {
		super(props)
		this.tween = null
		this.isShown = false
		this.shouldShow = false
		this.shownOn = new Date().getTime()
		this.state = {
			...this.state,
			frame: 0
		}
		Loading.instance = this
	}

	update = () => {
		if (!this.isShown) return
		window.requestAnimationFrame(this.update)
		const frame = Math.floor(new Date().getTime()/100) % frames.length
		if (this.state.frame != frame) this.setState({frame})
	}

	/**
	 * コンポーネントがマウントされた際に実行されます
	 */
	componentDidMount() {
		this.isShown = true
		if (this.shouldShow) this.show()
		else this.hide()
	}

	componentDidUpdate() {
	}

	/**
	 * ローディングを表示します
	 */
	show() {
		if (!this.node) {
			this.shouldShow = true
			return
		}
		this.isShown = true
		if (this.tween) this.tween.kill()
		this.shownOn = new Date().getTime()
		this.tween = gsap.to(this.node, {opacity: 1, display: 'flex', y: 0, duration: 0, ease: 'Expo.easeOut'})
		this.update()
	}

	/**
	 * ローディングを非表示にします
	 */
	hide() {
		if (!this.node) {
			this.shouldShow = false
			return
		}
		if (this.tween) this.tween.kill()
		let delay = 0.25
		if (this.shownOn) delay = Math.max(new Date().getTime() - this.shownOn, 300) / 1000 // 最低0.3秒表示する
		this.tween = gsap.to(this.node, {opacity: 0, display: 'none', y: 20, duration: 0.3, delay: delay, ease: 'Sine.easeOut', onComplete: ()=>{
			this.isShown = false
		}})
	}

	/**
	 *
	 */
	onNodeUpdate = (node) => {
		this.node = node
		if (this.shouldShow && !this.isShown) this.show()
		else this.hide()
	}

	/**
	 *
	 */
	pct(index) {
		return (
			<img src={frames[index]} />
		)
	}

	/**
	 * render
	 */
	render () {
		return (
			<section ref={this.onNodeUpdate} className='loading' id='mainLoading'>
				<div className="loading__pct">{this.pct(this.state.frame)}</div>
			</section>
		)
	}
}