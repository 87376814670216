import React from 'react'
import { Link } from 'react-router-dom';
import { SCHOOL } from '../../../../config';
import ListBase from '../listBase';

export default class RegularSchool extends ListBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/regularschool/'
	}

	static get title() {
		return 'レギュラースクール'
	}

	static get title_en() {
		return 'regularschool'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-regularschool.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props, SCHOOL.regular)
	}

	/**
	 * リード文表示用
	 */
	getLeadContents(isAllNonAccepting) {
		return (
			<div className="top-course__lead">
				<p>
					年間を通して継続的に学んでいきます。<br/>
					主に「自分に向き合う」プログラムと、<br className="sp-view"/>
					主に「社会に向き合う」プログラムを非連続に<br className="sp-view"/>
					配置しながら、<br className="pc-view"/>一貫して手を動かす、頭を動かす、<br className="sp-view"/>
					発信する、吸収する、協働するという<br className="sp-view"/>
					クリエーティブワークを積み重ねることで、<br/>
					自分を活かす力と社会で活きる力、<br className="sp-view"/>
					すなわち「答えを自分でつくりだす力」を<br className="sp-view"/>
					育成していきます。
				</p>
			</div>
		)
	}
}