import moment from 'moment'
import gsap from "gsap/gsap-core"
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import Auth from './api/auth'
import { getCourseClasses, getEntries } from './api/myClass'
import { func } from 'prop-types'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

moment.locale('ja', {
	weekdays: ['日','月','火','水','木','金','土']
})

/**
 * 日付を文字列にフォーマット
 */
export function formatYMD (date) {
	return moment.unix(date.getTime()/1000).format('yyyy-MM-DD')
}

export function formatDate(unixDate) {
	return moment.unix(unixDate).format('yyyy/M/D（dddd）')
}

export function formatTime(unixDate) {
	return moment.unix(unixDate).format('HH:mm')
}

export function formatDateTime(unixDate) {
	return moment.unix(unixDate).format('yyyy/M/D（dddd） HH:mm')
}

export function formatDateTime2(unixDate) {
	return moment.unix(unixDate).format('yyyy/M/D HH:mm')
}

export function formatTimeRange(start, end) {
	const s = moment.unix(start)
	const e = moment.unix(end)
	return s.format("HH:mm") + "〜" + e.format("HH:mm")
}

export function formatDateTimeRange(start, end) {
	const s = moment.unix(start)
	const e = moment.unix(end)
	return s.format("yyyy/M/D（dddd）HH:mm") + "〜" + e.format("HH:mm")
}

export function getYear(unix) {
	return moment.unix(unix).format("YYYY")
}

/**
 * 渡された二つの日時が年を跨いでいるかどうか
 * @param {*} unix1
 * @param {*} unix2
 */
 export function isAcrossYear(unix1, unix2) {
	const last = moment.unix(unix1)
	const current = moment.unix(unix2)
	if(last.add(1, "Y").format("YYYY") == current.format("YYYY")) return true
	return false
}

/**
 * ex) 10月29日
 * @param {number} unix
 */
 export function getDateJa(unix) {
	return moment.unix(unix).format("MM月DD日")
}

/**
 * ex) （火）
 * @param {number} unix
 */
 export function getDayOfTheWeekJa(unix) {
	return moment.unix(unix).format("（dddd）")
}

/**
 * @param {YYYY-MM-DD} string
 */
export function formatDateToJa(date) {
	return moment(date).format('YYYY年MM月DD日')
}

/**
 * 全角→半角変換
 * @param {string} str
 */
export function zenkaku2Hankaku(str) {
	return str.replace(/[Ａ-Ｚａ-ｚ０-９＠．]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
	});
}

/**
 * 生徒かどうかの真偽値を返す 生徒ならtrue
 * @param {object} child
 */
export function getIsStudent(child) {
	return child.child_profile !== null
}

export function copyUrl(url) {
	window.addEventListener("copy", (e) => {
		e.clipboardData.setData('text/plain', url)
		e.preventDefault()
	}, {once: true})

	if(window.clipboardData) {
		//IEの場合
		window.clipboardData.setData('Text', url)
	} else {
		//IE以外の場合
		//コピーイベントを発生させる
		const result = document.execCommand("copy")
	}
}


/**
 * Cookieに保存された値を取得します
 * @param {string} key
 */
export function getCookie(key) {
	return $.cookie(key)
}

/**
 * Cookieに値を保存します
 * @param {string} key
 * @param {*} value
 */
export function setCookie(key, value) {
	$.cookie(key, value, {path:'/'})
}

/**
 * Cookieに保存された値を削除します
 * @param {string} key
 */
export function deleteCookie(key) {
	$.cookie(key, '', {path:'/', expires: -1})
}

/**
 * ページのトップまでスクロールします
 * @param {number} duration
 */
export function scrollToTop(duration=0.5) {
	gsap.to(window, { scrollTo:{y:0, autoKill:false}, duration:duration })
}

/**
 * 特定の要素までスクロールします
 * @param {DOMElement} element
 * @param {number} offset
 * @param {number} speedPerSec
 */
export function scrollToElement(element, offset=-20, speedPerSec=4000, minDuration=0.5, maxDuration=2) {
	if ($(element).length == 0) return
	const top = $(element).offset().top
	const scrollTop = $(window).scrollTop()
	const distance = Math.abs(top - scrollTop)
	let duration = distance / speedPerSec // 1秒で4000pxスクロール
	if (duration < minDuration) duration = minDuration
	if (duration > maxDuration) duration = maxDuration
	gsap.to(window, { scrollTo:{y:top + offset, autoKill:false}, duration:duration })
}

/**
 * 適用したいDOMのCSS初期値にdisplay:noneとopacity:0を指定する
 * @param {DOMElement, Array} element
 * @param {num} duration
 */
export function showElement(element, duration = 0.5, delay=0, moveY=false) {
	if($(element).length == 0) return
	element.style.display = 'block'
	gsap.fromTo(element, {opacity:0, y:(moveY?20:0)}, {opacity:1, y:0, duration: duration, ease:'expo.out', delay:delay})
}

export function hideElement(element, duration = 0.5, delay=0, moveY=false) {
	if($(element).length == 0) return
	gsap.to(element, {opacity:0, y:(moveY?20:0), duration: duration, ease:'expo.out', delay:delay, onComplete:()=>{element.style.display = 'none'}})
}

/**
 * オンラインかリアルスクールかのアイコンをreturnする
 * @param {bool} is_online
 */
export function getIsOnlineImage(is_online) {
	return (is_online) ?
	<img src="/assets/images/common/ico-online.svg" alt=" オンライン"/>
	:
	<img src="/assets/images/common/ico-realschool.svg" alt="リアルスクール"/>
}

//PCかSPか、AndroidかiPhoneか、MacかWindowsかくらいまで判別したい
export function getDeviceInfo() {
	if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
		return {
			isPC: false,
		}
	}else{
		return {
			isPC: true
		}
	}
}

/**
 * 画像をロードしてdataURLの文字列（Base64）として返します
 * @param {string} url
 */
export async function loadImageAsDataURL(url) {

	const image = new Image()
	await new Promise((resolve, reject) => {
		image.onerror = reject
		image.onload = resolve
		image.src = url
	})
	if (image.naturalWidth > 0) {
		const canvas = document.createElement('canvas')
		canvas.width  = image.naturalWidth
		canvas.height = image.naturalHeight
		const ctx = canvas.getContext('2d')
		ctx.drawImage(image, 0, 0)
		return canvas.toDataURL()
	}
	return null
}

/**
 * 引数に渡した(コースidと年齢id)コースに申し込み可能な自分の子供の配列を返す
 * @param {number} course_id  //コース判別ID
 * @param {number} course_target_age_id  //対象年齢判別ID
 */
export async function getApplableStudent(course_id, course_target_age_id) {
	const asyncs = [getCourseClasses(), getEntries()]
	let children
	await Promise.all(asyncs).then(res => {
		//フィルタリングのためのコールバック
		const filterCallback = item => {
			const is_course_id = item.course_id == course_id
			const is_course_target_age_id = item.course_target_age_id == course_target_age_id
			return is_course_id && is_course_target_age_id
		}
		const courses = res[0].my_course_classes.filter(filterCallback)  // 自分の受講中コースリストを現在表示中のコースでフィルタリング
		const entries = res[1].entries.filter(filterCallback)  // 自分の申し込み中コースリストを現在表示中のコースでフィルタリング
		const coursesBoth = courses.concat(entries)  //受講中と受付中を結合 いずれかのステータスで自分が絡んでるコース情報のりすと
		//子供を除く
		children = Auth.user.children.filter (child => {
			if(child.child_profile == null) return false  //単に子供なら配列から除く
			for(let i in coursesBoth) {
				//この授業をとっているもしくはそもそも生徒登録していないなら
				if(coursesBoth[i].child.id == child.id) {
					return false  //配列から除く
				}
			}
			return true
		})
	})
	return children  //children.length > 0 で一人以上の受講可能な子供がいることになる
}


/**
 * 二つのオブジェクトが一致しているかどうか
 * @param {obj} obj1
 * @param {obj} obj2
 */
export function getIsEqaulObj(obj1, obj2) {
	const a = objSort(obj1)
	const b = objSort(obj2)


	const aJSON = JSON.stringify(a)
	const bJSON = JSON.stringify(b)
	return aJSON === bJSON
}

function objSort(obj) {
	const sorted = Object.entries(obj).sort()
	//内部にオブジェクトを持っていればさらにソートする（再起処理）
	for(let i in sorted) {
		const val = sorted[i][1]
		if(typeof val === "object") {
			sorted[i][1] == objSort(val)
		}
	}
	return sorted
}
