import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../authorizedpagebase'
import DateInput from '../../../component/form/dateinput'
import Select from '../../../component/form/select'
import TextInput from '../../../component/form/textinput'

import Validate, {MaxLength, MinLength, EqualLength} from '../../_utils/validate'
import { addChild } from '../../../../api/child';
import Auth, { AuthError } from '../../../../api/auth'
import Loading from '../../../component/common/loading'
import ChildFormBase from './childFormBase'

const STUDENT_FILE = 'txt-studentadd'
const CHILD_FILE = 'txt-childadd'

export default class ChildAdd extends ChildFormBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/children/add.html'
	}

	static get title() {
		return '生徒追加'
	}

	static get title_en() {
		return 'student'
	}

	static get header_title() {
		if (this._file_name === undefined) this._file_name = CHILD_FILE
		return (
			<div className="header__title">
				<span>
					<img src={`/assets/images/me/${this.file_name}.svg`} alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props, STUDENT_FILE, CHILD_FILE)
	}

	get submitLabel() {
		return '追加する'
	}

	authorizeDidComplete() {
		super.authorizeDidComplete()
		this.constructor.file_name = (this.state.is_student) ? this.studentFile : this.childFile
		this.setState({
			...this.state,
			isDataLoaded: true,
			errors: {},
			form: {
				...this.state.form,
				last_name: Auth.user.last_name,
				last_name_kana: Auth.user.last_name_kana,
			}
		})
	}

	submit = async (e) => {
		e.preventDefault()
		let _state = {...this.state}

		//バリデーション確認
		for (let key in this.validateScheme) {
			if (this.childElm[key]) {
				const value = this.childElm[key].value
				const name_ja = this.childElm[key].dataset.name_ja
				const priority = this.childElm[key].dataset.priority

				_state.form.errors[key] = Validate.isValid(name_ja, value, priority, this.validateScheme[key])
			} else { //予期せぬエラー
				_state.form.errors[key] = Validate.unKnownError()
			}
		}

		const isErrorAtLeastOnce = Validate.isAllOk(_state.form.errors, this.validateScheme)

		if (isErrorAtLeastOnce) {
			let errors = []
			for(const key in _state.form.errors) {
				const error = _state.form.errors[key]
				if(error.isError) errors.push(error)
			}
			// priorityでソート
			errors.sort((el1, el2) => el1.priority - el2.priority)
			const errorMsgs = errors.map((el, _) => Validate.formatErrorMessage(el))
			this.setState(_state)
			this.errorListModal("エラー", "入力内容に誤りがあります。ご確認ください。", errorMsgs)
			this.setErrorForcus()
			e.preventDefault()
			return
		}

		// 子供の場合はchild_profile: nullに
		let sendData = {...this.state.form}
		if (!this.state.is_student) {
			sendData = {
				...this.state.form,
				child_profile: null
			}
		}

		Loading.show()
		try {
			const data = await addChild(sendData, this.state.form.child_id)
		} catch(error) {
			if (error instanceof AuthError) {
				this.onUnauthorizedError()
			} else {
				this.errorModal('編集失敗', error.message)
				Loading.hide()
			}
			e.preventDefault()
			return
		}

		// 正常対応
		this.infoModal("追加完了", ((this.state.is_student) ? '生徒' : 'お子様') + 'を追加しました。')
		Auth.updateUser().then(() => {
			this.props.history.push('/me/children/')
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
		e.preventDefault()
	}
}