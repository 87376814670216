import React from 'react'
import Auth from '../../../api/auth'
import { LINE_URL } from '../../../config';
import AuthorizedPageBase from '../../pages/authorizedpagebase'

export default class LineBlock extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isOpen: false
		}
		Auth.once('checked', this.onAuthChecked)
	}


	onAuthChecked = ()=>{
		if (Auth.isAuthorized && !Auth.user.is_line_friend) {
			this.setState({ isOpen: true })
		}
	}

	
	onCloseClick = (e) =>{
		e.preventDefault()
		this.setState({ isOpen: false })
	}

	render () {
		// ログイン前またはログイン済みで正常に友達登録されている場合
		if (Auth.isAuthorized && Auth.user.is_line_friend) {
			return null
		}
		
		// lineブロック時
		return (
			<section className={this.state.isOpen ? 'alert open' : 'cookie'} ref={node => this.node = node}>
				<div className="alert__text">
					<p>
						スコップ・スクールのLINEアカウントがブロックされているようです。<br className="pc-view"/>
						重要なお知らせなどのご連絡ができなくなってしまうため、お友だち追加お願いします。
					</p>
				</div>
				<div className="alert__btn">
					<a href="#" onClick={() => window.open(LINE_URL.MESSAGE)}>
						<span>お友達追加はこちらから</span>
					</a>
				</div>
				<div className="alert__closebtn">
					<a className="js_close_btn" href="#" onClick={this.onCloseClick}>
						<img src="/assets/images/common/btn-close.svg" alt="閉じる"/>
					</a>
				</div>
			</section>
		)
	}
}