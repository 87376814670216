import {API} from '../config'
import Auth, { AuthError } from './auth'
import {getApiKey, getSymbol} from './utils'

/**
 * Course一覧取得
 */
export async function getCourseList(school = "", publish_state = "") {
	const apiKey = await getApiKey()
	let path = API.COURSES
	// Auth
	//
	//一時的に
	// if (school != "") {
	// 	path += `${getSymbol(path)}school=${school}`
	// }
	// if (publish_state != "") {
	// 	path += `${getSymbol(path)}publish_state=${publish_state}`
	// }

	// const res = await fetch(path, {
	// 	headers: new Headers({'x-api-key' : apiKey})
	// });
	const res = await fetch(path);
	console.log(res);
	const json = await res.json();
	if (!res.ok) throw new Error(json.message);
	return json
}

/**
 * コース詳細取得
 */
export async function getCourseDetail(course_id, course_target_age_id) {
	const apiKey = await getApiKey()
	let path = API.COURSE_DETAIL

	// 引数無効チェック
	if (typeof (course_id) === "undefined" || typeof (course_target_age_id) === "undefined") {
		const messeage = `引数が無効です。course_id = ${course_id}, course_target_age_id = ${course_target_age_id}`
		await new Promise((resolve, reject)=>reject(messeage))
		throw new Error(messeage)
		return {messeage}
	}

	path = path.replace("{course_id}", course_id)
	path = path.replace("{course_target_age_id}", course_target_age_id)

	const res = await fetch(path, {
		headers: new Headers({'x-api-key' : apiKey})
	});
	const json = await res.json();
	if (!res.ok) throw new Error(json.message);
	return json
}

/**
 * コース予約
 * @param {Number} child_id
 * @param {Number} course_class_id
 */
export async function postCourseEntries(child_id, course_class_id) {
	const apiKey = await getApiKey()
	const res = await fetch(API.ME_ENTRIES, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Accept' : 'application/json',
			'Content-Type' : 'application/json',
		}),
		method: 'POST',
		body: JSON.stringify({
			child_id,
			course_class_id
		})
	})

	const json = await res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}