import CompleteBase from '../completeBase'

export default class ShortSchoolApplicationComplete extends CompleteBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/shortschool/complete.html'
	}

	static get title() {
		return '申し込み完了画面　(レギュラースクール)'
	}

	static get title_en() {
		return 'shortschool_application_complete'
	}
}