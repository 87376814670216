import { API } from '../config'
import { getApiKey, getSymbol } from './utils'
import Auth, { AuthError } from './auth'

/**
 * 子供削除
 */
export async function deleteChild(id) {
	const apiKey = await getApiKey()
	const url = API.CHILDREN + '/' + id + '.json'
	const res = await fetch(url, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Accept' : 'application/json',
			'Content-Type' : 'application/json',
		}),
		method: 'DELETE'
	})
	const json = await res.json()

	if (!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return {
		data: json
	}
}

export async function editChild(form, id) {
	const apiKey = await getApiKey()
	const url = API.CHILDREN + '/' + id + '.json'
	const putData = form
	const res = await fetch(url, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Content-Type' : 'application/json',
		}),
		method: 'PUT',
		body: JSON.stringify(putData)
	})
	const json = await res.json()

	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return {
		data: json
	}
}

export async function recreateChildPasscode(child_id) {
	const apiKey = await getApiKey()
	const url = API.RECREATE_PASSCODE.replace("{child_id}", child_id)
	const res = await fetch(url, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Content-Type' : 'application/json',
		}),
		method: 'PUT',
	})
	const json = await res.json()
	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.messages)
	}
	return true
}

export async function addChild(form, id) {
	const apiKey = await getApiKey()
	const url = API.CHILDREN+ '.json'
	const putData = form
	const res = await fetch(url, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Content-Type' : 'application/json',
		}),
		method: 'POST',
		body: JSON.stringify(putData)
	})
	const json = await res.json()

	if(!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return {
		data: json
	}
}