import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getReportList } from '../../../api/report'

export default class ReportList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}

	componentDidMount() {
		const reportList = getReportList(3, 0)
		reportList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				reportList: data,
			})
		})
	}

	render() {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const reportList = this.state.reportList
			return (
				<div className="top-report__list">
					<ul>
						{
							reportList.reports.map((value, key)=>{
								let ts = moment.unix(value.date)
								let date = ts.format("yyyy.MM.DD")
								let imagePath = (value.thumbnail == null) ? "/assets/images/common/pct-noimage.png" : value.thumbnail
								return (
									<li key={key}>
										<Link to={value.link}>
											<span className="top-report__list__thumb">
												<img src={imagePath} alt="サムネイル"/>
											</span>
											<span className="top-report__list__text">
												<span className="top-report__list__date">{date}</span>
												<span className="top-report__list__title">{value.title}</span>
											</span>
										</Link>
									</li>
								)
							})
						}
					</ul>
				</div>
			)
		}
	}
}