import React from 'react'
import PageBase from '../pagebase'
import { PUBLISH_STATE, SCHOOL, TAX_RATE, BASE_TITLE } from '../../../config'
import { getCourseDetail } from '../../../api/course'
import { Link } from 'react-router-dom'
import DetailPageHeader from './detailPageHeader'
import DetailPageFooter from './detailPageFooter'
import NavigatorModal from './navigatorModal'

export default class Detail extends PageBase {

	static get path() {
		return '/courses/:course_id/course_target_ages/:course_target_age_id'
	}

	static get title() {
		if(this.instance.state.school == "regular") {
			return "レギュラースクール"
		}else if(this.instance.state.publish_state == "past") {
			return "過去のスクール"
		}else if(this.instance.state.school == "short_term") {
			return "短期スクール"
		}
		return ""
	}

	static get title_en() {
		if(this.instance.state.school == "regular") {
			return "common/txt-regularschool"
		}else if(this.instance.state.publish_state == "past") {
			return "archive/txt-archive-short"
		}else if(this.instance.state.school == "short_term") {
			return "common/txt-shortschool"
		}
		return ""  // NOTE: デフォルトでレギュラースクールにしてしまうと短期のときに一瞬見え隠れするのでデフォルトはnullにする（0525の修正)

	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					{this.title_en != "" &&
						<img src={`/assets/images/${this.title_en}.svg`} alt={this.title} />
					}
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		if(this.instance.state.school == "short_term") {
			return false
		} else {
			return true
		}
	}

	constructor(props, school, publish_state=PUBLISH_STATE.published) {
		super(props)
		this.school = school
		this.navigatorDisplayCount = 6
		this.course_id = parseInt(this.props.match.params.course_id);
		this.course_target_age_id = parseInt(this.props.match.params.course_target_age_id);

		this.state = {
			...this.state,
			isDataLoaded: false,
			moreNavigators: false, // ナビゲーターを全員見せるかフラグ
			school: '',
			publish_state,
			isGetData: false
		}

		Detail.instance = this
	}

	async componentDidMount() {
		super.componentDidMount(false)
		const detailData = getCourseDetail(this.course_id, this.course_target_age_id)
		if (this.sync) {
			await detailData.then(this.onDataLoaded)
		} else {
			detailData.then(this.onDataLoaded)
		}
	}

	onDataLoaded = (data) => {
		this.setState({
			isDataLoaded: true,
			detailData: data,
			school: data.course.school,
			publish_state: data.course.publish_state,
			isGetData: true
		})
	}

	componentDidUpdate = () => {
		super.componentDidUpdate()
		if(this.state.isGetData){
			document.title = `${this.state.detailData.course.name} | ${this.state.detailData.course_target_age.target_age_label} | ${BASE_TITLE}`
			this.sendGa()
			this.setState({ isGetData: false })
		}
	}

	/**
	 * ナビゲーターのもっと見るボタンがクリックされた時に発火されます
	 * @param {event} e
	 */
	onClickMoreNavigators = (e) => {
		e.preventDefault()
		this.setState({
			moreNavigators: true
		})
	}

	/**
	 * ナビゲーターのクリック時に発火されます
	 * @param {event} e
	 * @param {Object} data
	 */
	onClickNavigator(e, index) {
		const {navigators} = this.state.detailData
		e.preventDefault()
		if (!this.navigatorModal) return
		this.navigatorModal.show(index, navigators)
	}

	//短期なら月いらない
	getPrice(course_target_age) {
		const detailData = this.state.detailData
		if(detailData.course.school == "regular") {
			return `${(detailData.course_target_age.price * (TAX_RATE+1)).toLocaleString()}円(税込) /月`
		}else{
			return `${(detailData.course_target_age.price * (TAX_RATE+1)).toLocaleString()}円(税込)`
		}
	}

	renderChild () {
		let canApply = false
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const detailData = this.state.detailData
			const {course_target_age, course_classes, navigators} = detailData
			//名前順でソート（苗字同じなら下の名前で比較）
			navigators.sort((navigator1, navigator2) => {
				//苗字
				if (navigator1.last_name_kana < navigator2.last_name_kana) return -1
				else if(navigator1.last_name_kana > navigator2.last_name_kana) return 1
				else if(navigator1.first_name_kana < navigator2.first_name_kana) return -1
				return 1
			})

			let materialPrice = null
			if(detailData.course_classes[0]) {
				for(const i in detailData.course_classes[0].schedules) {
					const schedule = detailData.course_classes[0].schedules[i]
					if(schedule.period_kit_price) materialPrice += schedule.period_kit_price
				}
			}
			return (
				<div>
					<section className="school-detail">
						<div className="school-detail__wrap">

							{/* ページヘッダー */}
							<DetailPageHeader kind="detail" school={this.state.school} publishState={this.state.publish_state} detailData={detailData} />

							<div className="school-detail__text">
								<strong>{course_target_age.sales_copy}</strong>
								{/* 紹介 */}
								{course_target_age.introduction &&
									<p>
										{course_target_age.introduction.split('\n').map((str, index) => (
											<React.Fragment key={index}>{str}<br /></React.Fragment>
										))}
									</p>
								}
							</div>

							{/* 画像 */}
							{course_target_age.course_target_age_images.length != 0 &&
								<div className="school-detail__fig">
									<ul>
										{course_target_age.course_target_age_images.map((data, i)=>{
											return (
												<li key={i}>
													<figure>
														<img src={data.content_url} alt=""/>
														{data.caption &&
															<figcaption>{data.caption}</figcaption>
														}
													</figure>
												</li>
											)
										})}
									</ul>
								</div>
							}

							<div className="school-detail__description">
								<div className="school-detail__description__title"><span>概要</span></div>

								{course_target_age.description &&
									<p>
										{course_target_age.description.split('\n').map((str, index) => (
											<React.Fragment key={index}>{str}<br /></React.Fragment>
										))}
									</p>
								}

								<div className="school-detail__description__list">
									{course_target_age.content &&
										<dl>
											<dt>コンテンツ</dt>
											<dd>{course_target_age.content}</dd>
										</dl>
									}

									{course_target_age.price &&
										<dl>
											<dt>受講料</dt>
											<dd>{this.getPrice(course_target_age)}　<small>※教材費など別途</small></dd>
										</dl>
									}

									{this.state.school == "short_term" && materialPrice &&
										<dl>
											<dt>教材費</dt>
											<dd>{Math.floor(materialPrice * (TAX_RATE+1)).toLocaleString()}円（税込）　<small>※送料込み</small><br/><small>※教材はご自宅に郵送します。</small></dd>
										</dl>
									}

								</div>

								{/* 担当ナビゲーター */}
								{navigators.length > 0 &&
									<div className="school-detail__description__list">
										<div className="school-detail__description__list__title"><span>担当ナビゲーター</span></div>
										<div className="school-detail__member">
											<ul>
												{navigators.map((data, i)=>{
													// もっと見るボタンが押されてない場合はナビゲーターは this.navigatorDisplayCount で設定した人数だけ
													if (!this.state.moreNavigators && i >= this.navigatorDisplayCount) return null
													return (
														<li key={i}>
															<a href="#" onClick={(e) => {return this.onClickNavigator(e, i)}}>
																<span className="school-detail__member__thumb">
																	<img src={data.icon_url} alt={`${data.last_name} ${data.first_name}`} />
																</span>
																<span className="school-detail__member__name">{data.last_name} {data.first_name}</span>
																<span className="school-detail__member__title">{data.business_title}</span>
															</a>
														</li>
													)
												})}
											</ul>
											{!this.state.moreNavigators && navigators.length > this.navigatorDisplayCount &&
												<div className="school-detail__member__btn"><a href="#" onClick={this.onClickMoreNavigators}><span>もっと見る</span></a></div>
											}
										</div>
									</div>
								}

								{/* クラス一覧 */}
								{course_classes  &&
									<div className="school-detail__description__list">
										<div className="school-detail__description__list__title">
											<span>クラス一覧</span>
										</div>
										{course_classes.map((data, i)=>{
											if (data.entry_state == 'accepting' || data.entry_state == 'cancel_waiting') canApply = true
												return (
												<dl className="dtwide" key={i}>
													<dt>
														<span>{data.name}</span>

														{this.state.publish_state != "past" && data.schedules && data.schedules.length > 0 &&
															<div className="school-detail__description__list__btn">
																<Link to={`/courses/${this.course_id}/course_target_ages/${this.course_target_age_id}/course_classes/${data.id}`}>
																	<span>詳細</span>
																</Link>
															</div>
														}
													</dt>
													<dd>
														<div className="content_left">
															{data.description.split('\n').map((sentence, key) => {
																return (
																	<React.Fragment key={key}>
																		{sentence}
																		<br/>
																	</React.Fragment>
																)
															})}
															定員：{data.capacity}名
															<br/>

															{/* NOTE: 新しくできるAPIで表示非表示切り替え */}
															{/* TODO: 文字サイズを少し小さくする, ちゃんとクラス作ったほうが良い */}
															{data.entry_state === 'cancel_waiting' &&
																<span style={{color: "red"}}>
																	※空席待ちでも申し込みは可能です。<br />空きが出次第、先着順にご案内させていただきます。
																</span>
															}

														</div>
														<div className="content_right">
															{data.entry_state === 'in_preparation' &&
																<span className="icon standby">準備中</span>
															}
															{data.entry_state === 'accepting' &&
																<span className="icon entry-remain">受付中&ensp;<span className="small">残席あり</span></span>
															}
															{data.entry_state === 'cancel_waiting' &&
																<span className="icon entry-cancel">受付中&ensp;<span className="small">空席待ち</span></span>
															}
															{data.entry_state === 'closed' &&
																<span className="icon end">募集終了</span>
															}
														</div>
													</dd>
												</dl>
											)
										})}
									</div>
								}
							</div>
							<DetailPageFooter school={this.state.school} canApply={canApply} detailData={detailData} />
						</div>
					</section>

					<NavigatorModal ref={node => this.navigatorModal = node} />
				</div>
			)
		}
	}
}