import React from 'react'
import TweenManager from './tween'
import gsap from 'gsap/gsap-core'

export default class ReportModal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			imageIndex: null,
			isShown: false,
		}
		this.images = []  //この授業に対する全ての画像を格納する
	}

	get x () {
		if(!this._x) return 0
		return this._x
	}

	set x (value) {
		this._x = value
		gsap.set(this.pane, {x: this._x})
	}

	componentDidMount() {
		window.addEventListener("resize", this.onResize)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize)
	}

	componentDidUpdate = () => {
		if(this.props.isShown && !this.state.isShown) {
			this.show()
			this.setState({ isShown: true })
		}
	}

	onTouchStart = (e) => {
		if (e.target.classList.contains('ignoreDrag')) return true   //タッチを無視したいボタンなどの時は無視する
		if (e.cancelable) e.preventDefault()
		window.addEventListener('touchmove', this.onTouchMove, {passive: false})
		window.addEventListener('touchend', this.onTouchEnd, {passive: false})
		this.touchStartPoint = {x: e.touches[0].clientX, y: e.touches[0].clientY}
		this.lastTouchPoint = this.touchStartPoint
		this.lastMoveX = 0
		this.touchStartX = this.x
	}

	onTouchMove = (e) => {
		if(e.cancelable) e.preventDefault()
		const currentTouchPoint = {x: e.touches[0].clientX, y: e.touches.clientY}
		this.lastMoveX = this.lastTouchPoint.x - currentTouchPoint.x
		this.lastTouchPoint = currentTouchPoint
		let x = this.touchStartX + ( currentTouchPoint.x - this.touchStartPoint.x)
		this.xTween?.kill()
		this.xTween = gsap.to(this, {x: x, duration: 0.2, ease: "expo.out"})
	}

	onTouchEnd = (e) => {
		if(e.cancelable) e.preventDefault()
		window.removeEventListener('touchmove', this.onTouchMove)
		window.removeEventListener('touchend', this.onTouchEnd)
		let index = Math.round(-this.x / document.body.clientWidth)
		if(Math.abs(this.lastMoveX) > 3) {
			if(this.lastMoveX > 0) index = Math.ceil(-this.x / document.body.clientWidth)
			else index = Math.floor(-this.x / document.body.clientWidth)
		}
		const x = index
		if(index < 0) index = 0
		if(index >= this.images.length) index = this.images.length - 1
		this.setState({imageIndex: index})
	}

	// 一枚目の画像に飛ばす実装
	onResize = () => {
		this.x = 0
		this.setState({imageIndex: 0})
	}

	// TODO:　モーダルの動き実装 アニメーションつけるならisShownいらんかも？ gsap + cssで管理？
	show = () => {
		// tweenで入ってくる
		TweenManager.showElement(this.modal, 0.5)
		TweenManager.showElement(this.modalContent, 0.5, 0.2, false)
		if(this.pane) this.pane.addEventListener('touchstart', this.onTouchStart, {passive: false})
	}

	hide = async　() => {
		//tweenできえる

		TweenManager.hideElement(this.modal, 0.5)
		TweenManager.hideElement(this.modal, 0.5, 0.2, false, () => {
			this.props.onClose()
			setTimeout(this.setState({ imageIndex: null, isShown: false }), 0)  // NOTE: 必ずonCloseが終わってから実行
		})
		this.pane.removeEventListener('touchstart', this.onTouchStart)
		window.removeEventListener('touchmove', this.onTouchMove)
		window.removeEventListener('touchend', this.onTouchEnd)
	}

	handleClose = async (e) => {
		e.preventDefault()
		this.hide()
	}

	/**
	 * モーダルが表示されるたび最初に一回だけ実行
	 * 深いネストを潜っていってレポート写真を格納した配列を作る
	 */
	setStartImage = () => {
		let counter = 0
		let index = null
		this.images = []
		let findId = false
		const schedules = this.props.report.report_schedules
		for(const i in schedules) {
			const subjects = this.props.report.report_schedules[i].report_camera_subjects
			for(const j in subjects) {
				const photos = subjects[j].photo_urls
				for(const k in photos) {
					if(photos[k] == this.props.imgData){
						index = counter
						findId = true
					}
					this.images.push(photos[k])
					counter++
				}
			}
		}
		return index
	}

	// renderごとに実行される
	onPaneReady = (index, node) => {
		const x = index * - document.body.clientWidth
		const ease = Math.abs(x - this.x) < 0.5 ? 'cubic.inOut' : 'cubic.out'
		if(!node) return
		this.pane = node
		gsap.to(this, {x: x, duration: 0.25, ease: ease})  // 初期セットはduration :0
	}

	next(index) {
		const i = index+1
		if(i < this.images.length) {
			this.setState({
				imageIndex: i
			})
		}
	}

	prev(index) {
		const i = index - 1
		if(i >= 0) {
			this.setState({
				imageIndex: i
			})
		}
	}

	render () {
		let index = -1  //現在表示中画像のインデックス

		// imageIndexが0の時にfalseの評価をされてしまう
		if(this.state.imageIndex || this.state.imageIndex == 0) {
			index = this.state.imageIndex
		}else {  //初期マウント時
			index = this.setStartImage()
		}

		const className = this.props.isShown ? "" : "hidden"

		return (
			<div className={`report__modal ${className}`} ref={node => this.modal = node} style={{opacity: 0, display:"none"}}>
				<span className="report__modal__bg"></span>
				<div className="report__modal-wrap" ref={node => this.modalContent = node}>
					<div className="report__modal__pct">
						{this.props.imgData &&
							<div className="report__modal__scroll-pane" ref={node =>this.onPaneReady(index, node)}>
								{this.images.map((image, i) => {
									return (
										<div className="report__modal__pct__image-wrapper" key={i}>
											<img src={image} />
										</div>
									)
								})}
							</div>
						}
						{this.images[Number(index)-1] &&
							<div className="report__modal__pct__prev ignoreDrag" onClick={()=>this.prev(index)}><a href="#" className=""><img src="/assets/images/report/btn-pager-wh.svg" alt="前へ" style={{pointerEvents: "none"}}/></a></div>
						}
						{this.images[Number(index)+1] &&
							<div className="report__modal__pct__next ignoreDrag" onClick={()=>this.next(index)}><a href="#" className=""><img src="/assets/images/report/btn-pager-wh.svg" alt="次へ" style={{pointerEvents: "none"}}/></a></div>
						}
						<div className="report__modal__pct__closebtn ignoreDrag" onClick={this.handleClose}><a href="#" className=""><img src="/assets/images/report/btn-close-wh.svg" alt="閉じる"/></a></div>
					</div>
				</div>
			</div>
		)
	}

}

/**
 * 実装方法
 * 対象の全ての画像を持った配列をここで作って、img_urlで比較、自分の現在位置を取得する？
 * img_urlでの比較なのでちょっとダサい気がする
 */