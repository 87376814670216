import React from 'react'
import PageBase from './pagebase'

export default class PrivacyPolicy extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/privacypolicy.html'
	}

	static get title() {
		return '個人情報の取り扱いについて'
	}

	static get title_en() {
		return 'privacypolicy'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="privacypolicy">
					<img src="/assets/images/common/txt-privacypolicy.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<div>
				<section className="policy">
					<div className="policy__wrap">
						<div className="policy__detail">
							<h2 className="policy__detail__title">１．お客さまの個人情報の利用目的について</h2>
							<p className="policy__detail__text">当社において、事業を行うにあたり、各種の申し込みの受付等により、お客さまの住所・氏名・郵便番号・電話番号・メールアドレスなどの個人情報を取得いたします。当社が取得した個人情報は、次の目的で利用させていただきます</p>
							<p className="policy__detail__text">【お預かりした個人情報の利用目的】<br />当社は、「個人情報の保護に関する法律」を遵守し、当社の事業の実施【※】および下記の目的のためにお客さまの個人情報を利用させていただきます。</p>
							<p className="policy__detail__text-caution">【※】子どもの能力の向上・育成を目的とした教育プログラムの開発・提供（オンライン授業およびスクーリングによるリアル授業）や、教材の製造・販売、EdTechサービスの開発・提供、能力開発のための研究・開発、その他事業活動</p>
							<p className="policy__detail__text">具体的には、当社の事業活動や契約の実現とともに、下記のような目的などで利用させていただきます。</p>
							<p className="policy__detail__text">・お客さまからのご意見・ご感想をいただくため<br />・お客さまからのお問合せや資料請求などに対応するため<br />・市場調査や新しいサービスの開発のため<br />・各種イベント、セミナー、キャンペーン、会員制サービスなどの案内のため<br />・刊行物などの発送のため<br />・当社または提携先で取り扱っている商品やサービスに関する情報の提供・広告配信のため<br />・お客様の登録内容等に合わせたウェブサイトのカスタマイズのため</p>
							<h2 className="policy__detail__title">２．個人情報の共同利用について</h2>
							<p className="policy__detail__text">当社は、お客さまの個人情報を次のとおり共同利用させていただきます。</p>
							<p className="policy__detail__text">● 個人情報の項目<br />お客さまの住所・氏名・性別・生年月日・家族構成・郵便番号・電話番号・メールアドレス・受講履歴・購買履歴・アンケート情報など</p>
							<p className="policy__detail__text">● 共同利用者の範囲<br />株式会社エデュソル</p>
							<p className="policy__detail__text">● 利用目的<br />上記１の「お客さまの個人情報の利用目的について」と同じ</p>
							<p className="policy__detail__text">● 個人情報管理責任者の氏名・連絡先<br />【個人情報管理責任者】<br />　個人情報保護相談窓口　株式会社スコップ 代表取締役<br className="sp-view" />　岡本弘毅<br />【連絡先】<br />　〒107-0052　　東京都港区赤坂4-12-5 FAD20-2F<br />　株式会社スコップ　個人情報保護相談窓口<br />　<br />　メールアドレス<br className="sp-view" />　info@schopschool.com</p>
							<h2 className="policy__detail__title">３．個人情報の第三者提供について</h2>
							<p className="policy__detail__text">当社は、以下のいずれかに該当する場合を除きお預かりした個人情報を第三者に提供いたしません。</p>
							<p className="policy__detail__text">・お客さまから事前にご同意をいただいた場合<br />・利用目的の達成に必要な範囲内において外部委託した場合<br />・法令に基づき提供を求められた場合<br />・人の生命、身体または財産の保護のために必要な場合であって、<br />　お客さまの同意を得ることが困難である場合<br />・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、<br />　お客さまの同意を得ることが困難である場合<br />・国または地方公共団体などが法令の定める事務を実施するうえで、協力する必要がある場合であって、<br />　お客さまの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合<br />・上記２の共同利用者の範囲に掲げる者に対して提供する場合 </p>
							<p className="policy__detail__text">以　上</p>
						</div>
					</div>
				</section>
			</div>
		)
	}
}