import React from 'react'
import Auth, { AuthError } from '../../../../api/auth';
import { getApplableStudent, scrollToTop } from '../../../../utils';
import Wave from '../../../component/common/wave';

export default class Select extends React.Component {

	constructor(props) {
		super(props)
		this.state= {
			...this.state,
			applableChildren: []
		}
	}

	componentDidMount() {
		const course_id = this.props.state.courseData.course.id
		const course_target_age_id = this.props.state.courseData.course_target_age.id
		//申し込み可能な生徒を取得
		getApplableStudent(course_id, course_target_age_id).then(data => {
			this.setState({ applableChildren: data })
		}).catch( error => {
			this.props.onError(error)
		})
	}

	handleSubmit = (e)=>{
		const childElm = document.getElementsByName('child')
		const classElm = document.getElementsByName('class')

		const child_id = (()=>{
			for (let i = 0; i < childElm.length; i++) {
				if (childElm[i].checked) {
					return parseInt(childElm[i].value)
				}
			}
		})()

		const course_id = (()=>{
			for (let i = 0; i < classElm.length; i++) {
				if (classElm[i].checked) {
					return parseInt(classElm[i].value)
				}
			}
		})()

		this.props.onInputSubmit(child_id, course_id)
		scrollToTop()
		e.preventDefault()
	}

	render () {
		const course = this.props.state.courseData.course
		const course_target_age = this.props.state.courseData.course_target_age
		const course_classes = this.props.state.courseData.course_classes

		return (
			<section className="form-selectclass">
				<div className="form-selectclass-wrap">
					{course_target_age.main_image_url &&
						<div className="form-selectclass__pct">
							<img src={course_target_age.main_image_url} alt=""/>
							<Wave />
						</div>
					}

					<div className="form-selectclass__list">
						{course.name &&
							<dl>
								<dt>コース名</dt>
								<dd>
									{course.short_term_school_name}
									{course.short_term_school_name && <br />}
									{course.name}
								</dd>
							</dl>
						}

						{course_target_age.target_age_label &&
							<dl>
								<dt>対象年齢</dt>
								<dd>{course_target_age.target_age_label}</dd>
							</dl>
						}

						<div className="form-selectclass__list__title">クラス</div>
						<div className="form-selectclass__list__radiobtn">
							{course_classes.map((data,i)=>{
								if (data.entry_state != 'accepting' && data.entry_state != 'cancel_waiting') return null

								let checked = false

								if (this.props.state.select && this.props.state.select.class_id === data.id) {
									checked = true
								} else {
									checked = (i !== 0) ? false : true
								}

								return (
									<label key={i}>
										<span className="form-selectclass__list__radiobtn__label">
											{data.name}<br />
											{data.description.split('\n').map((desc, key) => {
												return (<React.Fragment key = {key}>{desc}<br/></React.Fragment>)
											})}
										</span>
										<input
											type="radio"
											name="class"
											value={data.id}
											defaultChecked={checked}
										/>
										<div className="form-selectclass__list__radiobtn__toggle"></div>
									</label>
								)
							})}
						</div>
						<div className="form-selectclass__list__title">参加するお子様</div>
						<div className="form-selectclass__list__radiobtn">
							{this.state.applableChildren.length != 0 &&
							this.state.applableChildren.map((data, i)=>{
								let checked = false
								if (this.props.state.select && this.props.state.select.child_id === data.id) {
									checked = true
								} else {
									checked = (i !== 0) ? false : true
								}

								return (
									<label key={i}>
										<span className="form-selectclass__list__radiobtn__label">
											{data.last_name} {data.first_name} 様
										</span>
										<input
											type="radio"
											name="child"
											value={data.id}
											defaultChecked={checked}
										/>
										<div className="form-selectclass__list__radiobtn__toggle"></div>
									</label>
								)
							})}
						</div>
					</div>

					{course_target_age.notices &&
						<div className="form-selectclass__detail">
							<div className="form-selectclass__detail__title"><span>特記事項</span></div>
							<div className="form-selectclass__detail__text">
								<p>
									{course_target_age.notices.split('\n').map((str, index) => (
										<React.Fragment key={index}>{str}<br /></React.Fragment>
									))}
								</p>
							</div>
						</div>
					}
					{(course_target_age.entry_state === 'accepting' || course_target_age.entry_state === 'cancel_waiting') &&
						<div className="form-selectclass__btn">
							<a href="#" onClick={this.handleSubmit}><span>お申し込み内容の確認</span></a>
						</div>
					}
				</div>
			</section>
		)
	}
}