import React from 'react'
import { Link } from 'react-router-dom';
import PageBase from '../../pagebase';

export default class AccountDeleteComplete extends PageBase {
	static get path() {
		return '/me/account/delete-complete.html'
	}

	static get title() {
		return 'スコップ・スクールアカウントの削除完了'
	}

	static get title_en() {
		return 'parentsinfo'
	}

	// ---------- 基本設定ここまで ----------

	/**
	 * render
	 */
	renderChild() {
		return (
			<section className="mypage-information">
				<div className="mypage-information-wrap">
					<div className="mypage-information__text pccenter">アカウントの削除が完了しました。</div>
					<div className="mypage-information__btn back">
						<Link to="/"><span>サイトトップ</span></Link>
					</div>
				</div>
			</section>
		)
	}
}