import React from 'react'

export default class Wave extends React.Component {
	// ----------------------------------------------------------------------
	// static
	// ----------------------------------------------------------------------
	static _initialized = false
	static init() {
		if (this._initialized) return
		this._initialized = true
		this.instances = []
		this.phaseSpeed = 0.0
		this.ampPhaseSpeed = 0.8
		this.patternCanvas = document.createElement('canvas')
		this.patternCanvas.width = 80
		this.patternCanvas.height = 30
		this.paternCtx = this.patternCanvas.getContext('2d')
		this.patternCanvas.style.width = (this.patternCanvas.width / 2)+'px'
		this.patternCanvas.style.height = (this.patternCanvas.height / 2)+'px'
		this.update()
		window.addEventListener("resize", function(){this._resize()}.bind(Wave))
	}

	static _resize () {
		if(this.timer > 0) clearTimeout(this.timer)
		this.timer = setTimeout(function(){this.resize()}.bind(Wave), 100)
	}

	static resize () {
		for(const i in this.instances) {
			this.instances[i].resize()
		}
	}

	static update = () => {
		window.requestAnimationFrame(this.update)
		let x = 0
		let time = new Date().getTime()/1000
		let phase = time * this.phaseSpeed * Math.PI * 2
		let ampPhase = time * this.ampPhaseSpeed * Math.PI * 2
		const baseY = this.patternCanvas.height / 2
		const amp = this.patternCanvas.height * 0.25 * Math.abs(Math.sin(ampPhase))
		this.paternCtx.clearRect(0, 0, this.patternCanvas.width, this.patternCanvas.height)
		this.paternCtx.fillStyle = "#fff"
		this.paternCtx.beginPath()
		this.paternCtx.moveTo(0,this.patternCanvas.height)
		for (x=0; x<=this.patternCanvas.width; x+=1) {
			let y = baseY + Math.sin(phase + (x/this.patternCanvas.width)*Math.PI*2) * amp
			this.paternCtx.lineTo(x, y)
		}
		this.paternCtx.lineTo(this.patternCanvas.width,this.patternCanvas.height)
		this.paternCtx.closePath()
		this.paternCtx.fill()
		this.instances.map( instance => instance.update(this.patternCanvas) )
	}

	static addInstance(instance) {
		this.instances.push(instance)
	}

	static removeInstance(instance) {
		this.instances = this.instances.filter(n => n != instance)
	}

	// ----------------------------------------------------------------------
	constructor(props) {
		super(props)
		this.constructor.init()
		this.className = 'wave'
		if (this.props.className) this.className += ' ' + this.props.className
	}

	componentDidMount() {
		this.resize()
		this.constructor.addInstance(this)
	}


	componentWillUnmount() {
		this.constructor.removeInstance(this)
	}

	resize() {
		this.canvas.width = $(this.canvas).width() * 2
		this.canvas.height = $(this.canvas).height() * 2
		this.ctx = this.canvas.getContext('2d')
	}

	update = (pattern) => {
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
		this.ctx.fillStyle = this.ctx.createPattern(pattern, 'repeat')
		this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
	}

	render () {
		return (
			<canvas className={this.className} ref={node => this.canvas = node}></canvas>
		)
	}
}