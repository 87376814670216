import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

export default class Pager {

	constructor($target, index=0, activeClass='active') {
		this.$target = $target
		this.index = index
		this.activeClass = activeClass
	}

	setIndex(index) {
		this.index = index
		this.$target.removeClass(this.activeClass)
		this.$target.eq(this.index).addClass(this.activeClass)
	}
}

