import React from 'react'
import { ATTENDANCE } from '../../../../config'
import { formatDateTimeRange } from '../../../../utils'

export default class Term extends React.Component {

	render() {
		return(
			<>
				<div className="mypage-list__detail__lead">{this.props.data.term_name}</div>
				<div className="mypage-list__detail__list">
					{this.props.data.my_schedules.map((schedule, key) => {
						const date = formatDateTimeRange(schedule.started_at, schedule.finished_at)
						const state = schedule.attendance_state
						return(
							<dl className="dtnarrow" key={key}>
								<dt>
									{schedule.num_of_times}
									{state && (
										<div className={"mypage-list__detail__list__status "+state}>
											{ATTENDANCE[state]}
										</div>
									)}
								</dt>
								<dd>{schedule.period_content}<br/>{date}</dd>
							</dl>
						)
					})}
				</div>
			</>
		)
	}
}