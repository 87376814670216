import { Application, Container, Graphics, Sprite, Texture } from 'pixi.js'
import gsap from 'gsap'
import paper from 'paper'
gsap.registerPlugin(gsap.plugins.css)

let resetTransform = (object)=> {
	if (object instanceof Array) {
		for (const i in object) {
			object[i].transformContent = false
		}
	} else {
		object.transformContent = false
	}
}

export default class MorphablePath extends paper.Path {

	// param {Object} paths: 変形情報
	// param {Number} morph: 変形状態
	constructor(paths, morph=0) {
		// パス起点設定
		resetTransform(paths)
		// アンカーをコピー
		const segments = []
		for (const i in paths[0].segments) {
			segments.push(paths[0].segments[i].clone())
		}
		// 作成
		super(segments)
		resetTransform(this)
		this.paths = paths
		this.morph = morph
		// アンカーポイントの数をチェック
		this.checkVertices()
		// パスのクローズ状態を確認
		if (this.paths[0].closed) {
			this.closed = true
		}
		// this.position.x = this.position.y = 0
		this.update()
	}


	//
	// 全てのパスのアンカーポイントの数が同じでなければエラーを返す
	//
	checkVertices() {
		for (const i in this.paths) {
			const path = this.paths[i]
			if (this.segments.length != path.segments.length) {
				throw("アンカーポイントの数が違います")
			}
		}
	}

	//
	// シェイプトゥイーン
	// this.param {Number} morph: 変形状態
	update() {
		if (this.paths.length <= 1) return
		let fromIndex = Math.floor(this.morph)
		if (fromIndex < 0) fromIndex = 0
		else if (fromIndex > this.paths.length - 2) fromIndex = this.paths.length - 2
		window.requestAnimationFrame(this.update.bind(this))
		let toIndex = fromIndex + 1
		let _from = this.paths[fromIndex]
		let _to = this.paths[toIndex]
		let p = this.morph - fromIndex
		const phaseX = (new Date().getTime() / 1000) * 0.5
		const phaseY = (new Date().getTime() / 1000) * 0.5 + 1.0
		const roughSize = 2
		for (const i in this.segments) {
			const segment = this.segments[i]
			const roughX = Math.sin((phaseX + i*0.25) * Math.PI * 2) * roughSize
			const roughY = Math.sin((phaseY + i*0.25) * Math.PI * 2) * roughSize
			segment.point.x = _from.segments[i].point.x + (_to.segments[i].point.x - _from.segments[i].point.x) * p + roughX
			segment.point.y = _from.segments[i].point.y + (_to.segments[i].point.y - _from.segments[i].point.y) * p + roughY
			segment.handleIn.x = _from.segments[i].handleIn.x + (_to.segments[i].handleIn.x - _from.segments[i].handleIn.x) * p
			segment.handleIn.y = _from.segments[i].handleIn.y + (_to.segments[i].handleIn.y - _from.segments[i].handleIn.y) * p
			segment.handleOut.x = _from.segments[i].handleOut.x + (_to.segments[i].handleOut.x - _from.segments[i].handleOut.x) * p
			segment.handleOut.y = _from.segments[i].handleOut.y + (_to.segments[i].handleOut.y - _from.segments[i].handleOut.y) * p
			if (p <= 0) {
				if (_from.segments[i].handleIn.x == 0 && _from.segments[i].handleIn.y == 0) {
					segment.handleIn.x = _from.segments[i].handleIn.x
					segment.handleIn.y = _from.segments[i].handleIn.y
				}
				if (_from.segments[i].handleOut.x == 0 && _from.segments[i].handleOut.y == 0) {
					segment.handleOut.x = _from.segments[i].handleOut.x
					segment.handleOut.y = _from.segments[i].handleOut.y
				}
			}
			if (p >= 1) {
				if (_to.segments[i].handleIn.x == 0 && _to.segments[i].handleIn.y == 0) {
					segment.handleIn.x = _to.segments[i].handleIn.x
					segment.handleIn.y = _to.segments[i].handleIn.y
				}
				if (_to.segments[i].handleOut.x == 0 && _to.segments[i].handleOut.y == 0) {
					segment.handleOut.x = _to.segments[i].handleOut.x
					segment.handleOut.y = _to.segments[i].handleOut.y
				}
			}

		}
	}

	// リセット処理
	reset() {
		this.morph = 0
		this.update()
	}
}