import React from 'react'
import { Link } from 'react-router-dom'
import { getBannerList } from '../../../api/banner'
import { getCourseList } from '../../../api/course'
import { SCHOOL } from '../../../config'
import Pager from '../../../modules/top/pager'
import SlideFlick from '../../../modules/top/slideFlick'
import Wave from '../../component/common/wave'

export default class BannerList extends React.Component {
	constructor(props) {
		super(props)
		this.slide = null
		this.state = {
			...this.state,
			isDataLoaded: false,
			bannerList: {}
		}
	}

	componentDidMount() {
		// const bannerList = getBannerList(-1)
		const bannerList = getBannerList(8)//8件がMAX
		bannerList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				bannerList: data,
			})
		})
	}


	componentDidUpdate() {
		if (!this.slide && this.state.bannerList) {
			this.slide = new SlideFlick($('.js_banner'), true)
			this.pager = new Pager($('.js_pager li'), 0, 'current')
			this.slide.on('change', this.pager.setIndex.bind(this.pager))
		}

	}

	render() {
		const bannerList = this.state.bannerList
		return (
			<div className="mv__banner js_banner">
				{/* <ul>
					{console.log(bannerList.banners)}
					{Object.keys(bannerList).length !== 0 &&
						bannerList.banners.map((value, key) => {
							if(value.link.match(/^https/)) {
								return(
									<li key={key}><a href={value.link}><img src={value.img_src} alt={value.title} /></a></li>
								)
							}else {
								return(
									<li key={key}><Link to={value.link}><img src={value.img_src} alt={value.title} /></Link></li>
								)
							}
						})
					}
				</ul> */}
				{/* {console.log(bannerList.total)} */}
					{
						
						<ul className={"bnr" + bannerList.total}>
							{Object.keys(bannerList).length !== 0 &&
							bannerList.banners.map((value, key) => {
								if(value.link.match(/^https/)) {
									return(
										<li key={key}><a href={value.link}><img src={value.img_src} alt={value.title} /></a></li>
									)
								}else {
									return(
										<li key={key}><Link to={value.link}><img src={value.img_src} alt={value.title} /></Link></li>
									)
								}
							})
						}
					
						</ul>
					}
				<div className="mv__banner__pager">
					<ul className="js_pager">
						{Object.keys(bannerList).length !== 0 &&
							bannerList.banners.map((value, key) => {
								const current = (key !== 0) ? "" : "current"
								return (
									<li key={key} className={current}><Link to={value.link}>{key+1}</Link></li>
								)
							})
						}
					</ul>
				</div>
			</div>
		)
	}
}