import React from 'react'
import { Link } from 'react-router-dom'
import Wave from './wave'
import { scrollToTop } from '../../../utils'

export default class Footer extends React.Component {
	onClick = (e) => {
		scrollToTop()
	}

	render () {
		return (
			<section className="footer">
				<Wave />
				<div className="footer__inquiry">
					<p className="footer__inquiry--icon">
						<img src="/assets/images/common/ico-schope.svg"></img>
					</p>
					<h3 className="footer__inquiry--title">
						お問い合わせ
					</h3>
					<p className="footer__inquiry--msg">
						ご質問がある方はこちらのフォームから<br/>お問い合わせください。
					</p>
					<div className="footer__inquiry--btn">
						<a href="https://docs.google.com/forms/d/e/1FAIpQLSddHoel01nknmjGB9yV8x3r4uu3jHZ6162iEMYAgJAerGWnMg/viewform" target="_blank">
							<span>お問い合わせ</span>
						</a>
					</div>
				</div>
				{this.props.title != 'ie' ?
					<div className="footer__title"><Link onClick={this.onClick} to="/"><img src="/assets/images/common/pct-logo-footer.png" alt="SCHOP 幸せをつかむ創造力"/></Link></div>
					:
					<div className="footer__title"><span><img src="/assets/images/common/pct-logo-footer.png" alt="SCHOP 幸せをつかむ創造力"/></span></div>
				}
				<div className="footer__copyright">&copy; 2020 SCHOP Inc. All rights reserved.</div>
			</section>
		)
	}
}