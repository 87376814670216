import { WP_API } from '../config'

/**
 * ニュース一覧取得
 */
export async function getNewsList(count=3, offset=1,pages) {//初期値は最新3件　テスト用に第3引数にページタイプを追加
	// console.log("count:" + count + ",off:" +offset)
	// const res = await fetch(`${WP_API.NEWS}?count=${encodeURIComponent(count)}&offset=${encodeURIComponent(offset)}&page=${pages}`);
	const res = await fetch(`${WP_API.NEWS}?count=${encodeURIComponent(count)}&offset=${encodeURIComponent(offset)}&pages=${pages}`);
	// const res = await fetch(`https://schopschool.jp/api/news/?count=${encodeURIComponent(count)}&offset=${encodeURIComponent(offset)}`);
	// const res = await fetch(`https://schopschool.jp/api/news/?count=3&offset=4`);
	const json = await res.json();
	if (!res.ok) throw new Error(json.message);
	return json;
}

/**
 * ニュース詳細取得
 */
export async function getNewsDetail(id) {
	const res = await fetch(WP_API.NEWS + "?id=" + encodeURIComponent(id));
	// const res = await fetch("https://wp.stg.schopschool.com/wp-json/api/v1/news/" + encodeURIComponent(id));
	console.log(res)
	const json = await res.json();
	console.log(json)
	if (!res.ok) throw new Error(json.message);
	return json;
}