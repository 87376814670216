import React from 'react'
import { Link } from 'react-router-dom'
import { getNavigator } from '../../../api/navigator'
import { hideElement, showElement } from '../../../utils'

export default class NavigatorModal extends React.Component {

	/**
	 *
	 * @param {object} props
	 */
	constructor(props) {
		super(props)
		this.state = {
			index: 0,
			data: null,
			next: null,
			prev: null,
		}
	}

	show = async(index, navigators=null)=>{
		if (!navigators) navigators = this.state.navigators
		if (!navigators) return
		const data = navigators[index]
		const next = index < navigators.length - 1 ? navigators[index+1] : null
		const prev = index > 0 ? navigators[index-1] : null
		const detailData = await getNavigator(data.id)

		this.setState({
			index: index,
			navigators: navigators,
			data: data,
			next: next,
			prev: prev,
			detailData: detailData,
		})
		showElement(this.modal, 0.5)
		showElement(this.modalContent, 0.5, 0.2, true)
	}

	hide() {
		hideElement(this.modalContent, 0.5, 0, true)
		hideElement(this.modal, 0.5, 0.2)
	}

	handleNext = (e) => {
		const index = this.state.index + 1
		if (index < this.state.navigators.length) this.show(index)
		e.preventDefault()
	}

	handlePrev = (e) => {
		const index = this.state.index - 1
		if (index >= 0) this.show(index)
		e.preventDefault()
	}

	handleClose = (e) => {
		this.hide()
		e.preventDefault()
	}

	handleBgClick = (e) => {
		if (e.target == this.bg) {
			this.handleClose(e)
		}
	}

	/**
	 *
	 */
	render() {
		const data = this.state.data
		const detailData = this.state.detailData
		return (
			// ナビゲーターモーダル実装
			<section className="school-modal" style={{display: 'none', opacity: 0}} ref={node => this.modal = node}>
				<div className="school-modal-wrap" ref={node => this.bg = node} onClick={this.handleBgClick}>
				{data && detailData && (
					<div className="school-modal__content" ref={node => this.modalContent = node}>
						<div className="school-modal__content-head">
							<div className="school-modal__content__thumb"><img src={data.icon_url} alt=""/></div>
							<div className="school-modal__content__title">
								<strong>{data.last_name} {data.first_name}</strong>
								{data.business_title}
							</div>
						</div>
						<div className="school-modal__content-body">
							<div className="school-modal__content__desc">{data.introduction}</div>
							{detailData.handle_target_ages.length !== 0 &&
								<div className="school-modal__content__list">
									<div className="school-modal__content__list__title">担当コース一覧</div>
									{detailData.handle_target_ages.map((data, i)=> {
										return(
											<a href={`/courses/${data.course_id}/course_target_ages/${data.course_target_age_id}`} key={i}>
												<dl>
													<dt>
														<div className="school-modal__content__list__thumb">
															<img src={data.course_main_image_url} alt=""/>
														</div>
													</dt>
													<dd>
														{data.course_name}
														{data.short_term_school_name &&
															<span><br/>{data.short_term_school_name}</span>
														}
														{data.target_age_label &&
															<span><br/>対象年齢：{data.target_age_label}</span>
														}
													</dd>
												</dl>
											</a>
										)
									})}
								</div>
							}
						</div>

					</div>
				)}
					<div className="school-modal__pagenation">
						<div className="school-modal__pagenation-prev">
						{
							this.state.prev && (
								<a href="#" onClick={this.handlePrev}>
									<span className="school-modal__pagenation__thumb">
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" /><img src={this.state.prev.icon_url}/>
										</picture>
									</span>
									<span className="school-modal__pagenation__name pc-view">{this.state.prev.last_name} {this.state.prev.first_name}</span>
								</a>
							)
						}
						</div>
						<div className="school-modal__pagenation-closebtn">
							<a href="#" onClick={this.handleClose}>
								<picture>
									<source srcSet="/assets/images/common/btn-menu-close--sp.svg" media="(max-width: 768px)" /><img src="/assets/images/common/btn-menu-close.svg" alt="閉じる"/>
								</picture>
							</a>
						</div>
						<div className="school-modal__pagenation-next">
						{
							this.state.next && (
								<a href="#" onClick={this.handleNext}>
									<span className="school-modal__pagenation__thumb">
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" /><img src={this.state.next.icon_url}/>
										</picture>
									</span>
									<span className="school-modal__pagenation__name pc-view">{this.state.next.last_name} {this.state.next.first_name}</span>
								</a>
							)
						}
						</div>
					</div>
				</div>
			</section>
		)
	}
}