import React from 'react'
import { Link } from 'react-router-dom'
import { GENDER } from '../../../../../config'


export default class Child extends React.Component {
	render() {
		const childData = this.props.childData
		let dates = childData.birth_date.split('-')
		let y = dates[0]
		let m = dates[1]
		let d = dates[2]

		return (
			<>
				<section className="mypage-information">
					<div className="mypage-information-wrap">
						<div className="mypage-information__pcwrap-studentdetail">
							<div className="mypage-information__pcwrap-studentdetail-sub">
								<div className="mypage-information__name">{childData.last_name}{childData.first_name}</div>
								<div className="pc-view">
									<div className="mypage-information__btn">
										<Link to={`/me/children/edit.html?child_id=${childData.id}`}><span>編集する</span></Link>
									</div>
									<div className="mypage-information__btn bggray">
										<Link to={`/me/children/delete.html?child_id=${childData.id}`}><span>削除する</span></Link>
									</div>
								</div>
							</div>
							<div className="mypage-information__pcwrap-studentdetail-main">
								<div className="mypage-information__list">
									<dl>
										<dt>生年月日</dt>
										<dd>{y}年{m}月{d}日</dd>
									</dl>
									<dl>
										<dt>性別</dt>
										<dd>{GENDER[childData.gender_id]}</dd>
									</dl>
								</div>
								<div className="sp-view">
									<div className="mypage-information__btn">
									<Link to={`/me/children/edit.html?child_id=${childData.id}`}><span>編集する</span></Link>
									</div>
								</div>
								<div className="mypage-information__btn studententry">
									<Link to={`/me/children/edit.html?child_id=${childData.id}&status=to_be_student`}><span>スコップ・スクール生徒登録</span></Link>
								</div>
								<div className="sp-view">
									<div className="mypage-information__btn bggray">
										<Link to={`/me/children/delete.html?child_id=${childData.id}`}><span>削除する</span></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="mypage-backbtn"><Link to="/me/children/"><span>お子様一覧へ</span></Link></section>
			</>
		)
	}
}