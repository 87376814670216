import React from "react"
import { Link } from 'react-router-dom'
import Auth from "../../../api/auth"
import Menu from "../../../modules/menu"
import { scrollToTop } from "../../../utils"

export default class MypageMenu extends React.Component {f
	constructor(props) {
		super(props)
		this.menu = null
		this.state = {
			...this.state,
			isAuthorized: Auth.isAuthorized
		}
	}

	componentDidMount() {
		this.initMenu()
		Auth.check((isAuthorized) => {
			this.setState({
				isAuthorized: isAuthorized
			})
		})
	}

	componentDidUpdate() {
		this.initMenu()
	}

	initMenu() {
		if (this.state.isAuthorized && this.menu == null) {
			this.menu = new Menu('.js_menu-me', '.js_menu-me_open_btn', '.js_menu-me_close_btn')
		}
	}

	logout = (e) => {
		e.preventDefault()
		Auth.logout()
	}

	onClick = (e) => {
		scrollToTop()
		if (this.menu) this.menu.close(e,200)
	}

	render () {
		if (window.location.href.indexOf("ie.html") >= 0) {
			return null
		}

		if (!this.state.isAuthorized) {
			return (
				<section className="menu-me">
					<div className="menu-me__btn">
						{/* <Link onClick={this.onClick} to="/login.html" className="menu-me__btn__login">
							ログイン
						</Link> */}
					</div>
				</section>
			)
		}

		const child_id = Auth.getFirstChildId()
		return (
			<section className="menu-me">
				<div className="menu-me__btn">
					<a className="js_menu-me_open_btn menu-me__btn__menu" href="#">
						<img src="/assets/images/me/btn-menu.svg" alt="メニュー" />
					</a>
				</div>
				<div className="menu-me__list js_menu-me">
					<div className="menu-me__list__wrap">
						<div className="menu-me__list__head">
							<div className="menu-me__list__head__logo">
								<a href="/">
									<img src="/assets/images/common/pct-logo.svg" alt="SCHOP 幸せをつかむ創造力"/>
								</a>
							</div>
							<div className="menu-me__list__head__closebtn">
								<a className="js_menu-me_close_btn" href="#">
									<img src="/assets/images/common/btn-menu-close.svg" alt="閉じる"/>
								</a>
							</div>
						</div>

						<div className="menu-me__list__top">
							<Link onClick={this.onClick} to="/me/"><span>マイページトップ</span></Link>
						</div>
						{
							child_id && (
								<div className="menu-me__list__status">
									<div className="menu-me__list__status__title">スクール受講状況</div>
									<div className="menu-me__list__status__btns">
										<Link onClick={this.onClick} to={`/me/myclass/?child_id=${child_id}`}>
											<span className="menu-me__list__status__btns__icon">
												<img src="/assets/images/me/btn-menu-ordered.svg" alt="受講中・予約中スクール"/>
											</span>
											<span className="menu-me__list__status__btns__text">受講中・予約中<br/>スクール</span>
										</Link>
										<Link onClick={this.onClick} to={`/me/myclass/application/?child_id=${child_id}`}>
											<span className="menu-me__list__status__btns__icon">
												<img src="/assets/images/me/btn-menu-entry.svg" alt="申し込み中スクール"/>
											</span>
											<span className="menu-me__list__status__btns__text">申し込み中<br/>スクール</span>
										</Link>
										<Link onClick={this.onClick} to={`/me/myclass/complete/?child_id=${child_id}`}>
											<span className="menu-me__list__status__btns__icon">
												<img src="/assets/images/me/btn-menu-finish.svg" alt="受講済スクール"/>
											</span>
											<span className="menu-me__list__status__btns__text">受講済<br/>スクール</span>
										</Link>
									</div>
								</div>
							)
						}
						<div className="menu-me__list__link">
							<div className="menu-me__list__link__title">アカウント管理</div>
							<div className="menu-me__list__link__btns">
								<Link onClick={this.onClick} to="/me/children/">お子様情報管理</Link>
								<Link onClick={this.onClick} to="/me/account/edit.html">基本情報編集</Link>
							</div>
						</div>
						<div className="menu-me__list__btn">
							<a href="#" onClick={this.logout}><span>ログアウト</span></a>
						</div>
						<div className="menu-me__list__textlink">
							<Link onClick={this.onClick} to="/me/quit.html">申し込みのキャンセル/休会/退会/アカウント削除について</Link>
							<Link onClick={this.onClick} to="/terms.html">会員/準会員利用規約</Link>
						</div>
					</div>
				</div>
			</section>
		)
	}
}