import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../authorizedpagebase'
import DateInput from '../../../component/form/dateinput'
import Select from '../../../component/form/select'
import TextInput from '../../../component/form/textinput'

import Validate, {MaxLength, MinLength, EqualLength} from '../../_utils/validate'
import { addChild, editChild } from '../../../../api/child';
import Auth from '../../../../api/auth'
import TrimImage from '../../../component/form/trimImage'
import FileInput from '../../../component/form/fileInput'
import { loadImageAsDataURL, scrollToElement } from '../../../../utils'
import Loading from '../../../component/common/loading'
import { CHILD_FORM_INFO, CHILD_ITEM_LIST } from '../../../../config'


export default class ChildFormBase extends AuthorizedPageBase {

	static get file_name() {
		return this._file_name
	}
	static set file_name(str) {
		this._file_name = str
	}

	constructor(props, studentFile, childFile) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
			isConfirming: false,
			isNeedCheckBox: false,
			is_student: true,
			form: CHILD_ITEM_LIST,
		}

		this.studentFile = studentFile
		this.childFile = childFile

		// バリデーション要素格納
		this.childElm = {}

		this.childValidateScheme = {
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			last_name_kana: [Validate.Required],
			first_name_kana: [Validate.Required],
			gender_id: [Validate.Required],
			birth_date_year: [Validate.Required],
			birth_date_month: [Validate.Required],
			birth_date_day: [Validate.Required]
		}

		this.studentValidateScheme = {
			icon: [Validate.IconChecked],
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			last_name_kana: [Validate.Required, Validate.Furigana],
			first_name_kana: [Validate.Required, Validate.Furigana],
			gender_id: [Validate.Required],
			elementary_school: [Validate.Required],
			grade_id: [Validate.Required],
			interest: [Validate.Required],
			after_school_activity: [Validate.Required],
			birth_date_year: [Validate.Required],
			birth_date_month: [Validate.Required],
			birth_date_day: [Validate.Required]
		}
	}

	get validateScheme() {

		return (this.state.is_student) ? this.studentValidateScheme : this.childValidateScheme
	}

	get submitLabel() {
		return ''
	}

	/**
	 * 登録形式の変更
	 * @param {bool} is_student - true 生徒 ／ false 子供
	 */
	changeRegisterType(is_student) {
		// ページタイトルの変更
		this.constructor.file_name = (is_student) ? this.studentFile : this.childFile

		/**
		 * 登録タイプのステート変更
		 */
		this.setState({
			...this.state,
			is_student,
			errors: {},
		})
	}

	/**
	 * 生徒登録チェック変更時
	 * @param {*} e
	 */
	onRegisterTypeChange = (e) => {
		this.changeRegisterType(e.target.checked)
	}

	/**
	 * 値変更時
	 * ステート更新
	 * @param {*} e
	 */
	handleChange = (e) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		})
		if (e.preventDefault) e.preventDefault()
	}

	/**
	 * フォーカス外れたとき
	 * バリデーションチェック
	 * @param {*} e
	 */
	handleBlur = (e) =>{
		const name = e.target.name
		const value = e.target.value
		const name_ja = e.target.dataset.name_ja
		const priority = e.target.dataset.priority

		this.setState({
			form: {
				...this.state.form,
				[name]: value,
				errors: {
					...this.state.form.errors,
					[name]: Validate.isValid(name_ja, value, priority, this.validateScheme[name])
				},
			}
		})
	}

	handleProfileChange = (e) => {
		this.setState({
			form: {
				...this.state.form,
				child_profile: {
					...this.state.form.child_profile,
					[e.target.name]: e.target.value
				}
			}
		})
		if (e.preventDefault) e.preventDefault()
	}

	/**
	 * 戻るボタンクリック時
	 * @param {*} e
	 */
	onBuckBtnClick = (e)=>{
		this.props.history.goBack()
		e.preventDefault()
	}

	/**
	 * ファイルアップロード時
	 * @param {*} file
	 */
	handleChangeIcon = (file) => {
		TrimImage.instance.show(file, this.handleTrimIcon)
	}

	/**
	 * トリム後の処理
	 * @param {*} dataURL
	 */
	handleTrimIcon = (dataURL) => {
		this.setState({
			...this.state,
			form : {
				...this.state.form,
				child_profile: {
					...this.state.form.child_profile,
					icon: dataURL,
				},
				errors: {
					...this.state.form.errors,
					// NOTE: titleきめうちでいいやつ
					// TODO: priorityひろう
					icon: Validate.isValid('プロフィール写真', dataURL, 0, this.validateScheme.icon)
				}
			},
		})
	}

	/**
	 * エラーフォーカス
	 */
	setErrorForcus() {
		//上から数えて最初にエラーが起きているフォームにフォーカス
		for(const key in this.state.form.errors) {
			const err = this.state.form.errors[key]
			if(err.isError) {
				if (key !== 'icon') {
					this.childElm[key].focus()
				} else { // icon設定対応
					scrollToElement(this.childElm.iconWrap, -40)
				}
				return
			}
		}
	}

	renderChild () {
		const display = (this.state.is_student) ? 'block' : 'none'

		return (
			<div>
				<div className="form-edit-wrap">
					<div className="form-edit__input">
					<div className="mypage-edit__title">基本データ</div>
						{!this.state.isNeedCheckBox &&
						<div className="form-information__checkbox">
							<label><span className="form-information__checkbox__label">スコップ・スクール生徒登録する</span>
								<input type="checkbox" onChange={this.onRegisterTypeChange} checked={this.state.is_student} />
								<div className="form-information__checkbox__toggle"></div>
							</label>
						</div>
						}
						<div className="form-information__text pccenter pcgray">※プログラムに参加応募するお子様は​生徒登録をお願いします。</div>


						<FileInput
							name='icon'
							name_ja={CHILD_FORM_INFO().icon.text}
							priority={CHILD_FORM_INFO().icon.priority}
							imgSrc={this.state.form.child_profile.icon}
							imgUrl={this.state.form.child_profile.icon_url}
							isStudent={this.state.is_student}
							onChange={this.handleChangeIcon}
							error={this.state.form.errors.icon}
							targetRef={(elm => {this.childElm.icon = elm})}
							targetRef2={(elm => {this.childElm.iconWrap = elm})}
						/>

						<div className="form-edit__pcwrap-input2col">
							<TextInput
								title='お名前'
								required
								isConfirming={this.props.isConfirming}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								inputs={[
									{
										name:'last_name',
										name_ja: CHILD_FORM_INFO().last_name.text,
										priority: CHILD_FORM_INFO().last_name.priority,
										placeholder:'姓',
										value:this.state.form.last_name,
										error:this.state.form.errors.last_name,
										targetRef: (elm => {this.childElm.last_name = elm}),
									},
									{
										name:'first_name',
										name_ja: CHILD_FORM_INFO().first_name.text,
										priority: CHILD_FORM_INFO().first_name.priority,
										placeholder:'名',
										value:this.state.form.first_name,
										error:this.state.form.errors.first_name,
										targetRef: (elm => {this.childElm.first_name = elm}),
									},
								]}
							/>

							<TextInput
								title='ふりがな'
								required
								isConfirming={this.props.isConfirming}
								isDisplay={this.state.is_student}
								onChange={this.handleProfileChange}
								onBlur={this.handleBlur}
								inputs={[
									{
										name:'last_name_kana',
										name_ja: CHILD_FORM_INFO().last_name_kana.text,
										priority: CHILD_FORM_INFO().last_name_kana.priority,
										placeholder:'せい',
										value:this.state.form.child_profile.last_name_kana,
										error:this.state.form.errors.last_name_kana,
										targetRef: (elm => {this.childElm.last_name_kana = elm}),
									},
									{
										name:'first_name_kana',
										name_ja: CHILD_FORM_INFO().first_name_kana.text,
										priority: CHILD_FORM_INFO().first_name_kana.priority,
										placeholder:'めい',
										value:this.state.form.child_profile.first_name_kana,
										error:this.state.form.errors.first_name_kana,
										targetRef: (elm => {this.childElm.first_name_kana = elm}),
									},
								]}
							/>

							<DateInput
								title='生年月日'
								required
								name='birth_date'
								value={this.state.form.birth_date}
								isConfirming={this.props.isConfirming}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								pastYears={20}
								futureYears={0}
								selects={{
									birth_date_year: {
										name_ja: CHILD_FORM_INFO().birth_date_year.text,
										priority: CHILD_FORM_INFO().birth_date_year.priority,
										error: this.state.form.errors.birth_date_year,
										targetRef: (elm => {this.childElm.birth_date_year = elm})
									},
									birth_date_month: {
										name_ja: CHILD_FORM_INFO().birth_date_month.text,
										priority: CHILD_FORM_INFO().birth_date_month.priority,
										error: this.state.form.errors.birth_date_month,
										targetRef: (elm => {this.childElm.birth_date_month = elm})
									},
									birth_date_day: {
										name_ja: CHILD_FORM_INFO().birth_date_day.text,
										priority: CHILD_FORM_INFO().birth_date_day.priority,
										error: this.state.form.errors.birth_date_day,
										targetRef: (elm => {this.childElm.birth_date_day = elm})
									}
								}}
							/>

							<Select
								title='性別'
								required
								name="gender_id"
								name_ja={CHILD_FORM_INFO().gender_id.text}
								priority={CHILD_FORM_INFO().gender_id.priority}
								isConfirming={this.props.isConfirming}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								value={this.state.form.gender_id} options={[
									{ value:'1', title: '男性' },
									{ value:'2', title: '女性' },
									{ value:'3', title: 'その他' },
								]}
								error={this.state.form.errors.gender_id}
								targetRef={(elm => {this.childElm.gender_id = elm})}
							/>
						</div>

						<div style={{display : `${display}` }}>
							<div className="form-edit__pcwrap-input2col">
								<TextInput
									title='通っている小学校'
									required
									isConfirming={this.props.isConfirming}
									onChange={this.handleProfileChange}
									onBlur={this.handleBlur}
									inputs={[
										{
											name:'elementary_school',
											name_ja: CHILD_FORM_INFO().elementary_school.text,
											priority: CHILD_FORM_INFO().elementary_school.priority,
											placeholder:'○○小学校',
											value:this.state.form.child_profile.elementary_school,
											error:this.state.form.errors.elementary_school,
											targetRef:(elm => {this.childElm.elementary_school = elm})
										},
									]}
								/>
								<Select
									title='学年'
									name='grade_id'
									name_ja={CHILD_FORM_INFO().grade_id.text}
									priority={CHILD_FORM_INFO().grade_id.priority}
									required
									value={this.state.form.child_profile.grade_id}
									isConfirming={this.props.isConfirming}
									onChange={this.handleProfileChange}
									onBlur={this.handleBlur}
									options={[
										{value:'1', title:'小学1年生'},
										{value:'2', title:'小学2年生'},
										{value:'3', title:'小学3年生'},
										{value:'4', title:'小学4年生'},
										{value:'5', title:'小学5年生'},
										{value:'6', title:'小学6年生'},
									]}
									error={this.state.form.errors.grade_id}
									targetRef={(elm => {this.childElm.grade_id = elm})}
								/>
								<TextInput
									title='興味があること'
									isConfirming={this.props.isConfirming}
									onChange={this.handleProfileChange}
									onBlur={this.handleBlur}
									required
									inputs={[
										{
											name:'interest',
											name_ja: CHILD_FORM_INFO().interest.text,
											priority: CHILD_FORM_INFO().interest.priority,
											placeholder:'昆虫',
											value:this.state.form.child_profile.interest,
											error:this.state.form.errors.interest,
											targetRef:(elm => {this.childElm.interest = elm})
										},
									]}
								/>

								<TextInput
									title='習い事'
									isConfirming={this.props.isConfirming}
									onChange={this.handleProfileChange}
									onBlur={this.handleBlur}
									required
									inputs={[
										{
											name:'after_school_activity',
											name_ja: CHILD_FORM_INFO().after_school_activity.text,
											priority: CHILD_FORM_INFO().after_school_activity.priority,
											placeholder:'水泳',
											value:this.state.form.child_profile.after_school_activity,
											error:this.state.form.errors.after_school_activity,
											targetRef:(elm => {this.childElm.after_school_activity = elm})
										},
									]}
								/>
							</div>
						</div>

						<div className="mypage-edit__input-btn">
							<input type="submit" value={this.submitLabel} onClick={this.submit}/>
						</div>
					</div>
				</div>

				<section className="mypage-backbtn"><a href="#" onClick={this.onBuckBtnClick}><span>戻る</span></a></section>
				<TrimImage />
			</div>
		)
	}
}