import React from 'react'
import { Link } from 'react-router-dom';
import Auth, { AuthError } from '../../../api/auth';
import { getCourseClasses, getEntries } from '../../../api/myClass';
import { SCHOOL } from '../../../config';
import { getApplableStudent } from '../../../utils'

export default class DetailPageFooter extends React.Component {

	/**
	 * 申し込みボタンをクリックされた時に発火されt、ログイン状態を確認して必要な遷移を行います
	 * @param {event} e
	 */
	onApplicationClick = (e) => {
		if (Auth.isAuthorized) return true
		e.preventDefault()
		Auth.login(false, this.pageAfterLoginUrl)
	}

	/**
	 * 申し込みフォームのリンクを返します
	 */
	get pageAfterLoginUrl() {
		const school = this.props.school
		const course_target_age = this.props.detailData.course_target_age
		const course = this.props.detailData.course
		const path = (school === SCHOOL.regular) ? '/regularschool' : '/shortschool'
		const classId = this.props.classId
		if(classId) return `/me${path}/application.html?course_id=${course.id}&course_target_age_id=${course_target_age.id}&course_class_id=${classId}`
		else return `/me${path}/application.html?course_id=${course.id}&course_target_age_id=${course_target_age.id}`
	}

	get url() {
		const school = this.props.school
		const course_target_age = this.props.detailData.course_target_age
		const course = this.props.detailData.course
		const path = (school === SCHOOL.regular) ? '/regularschool' : '/shortschool'
		const classId = this.props.classId
		if(classId) return `/courses/${course.id}/course_target_ages/${course_target_age.id}/course_classes/${classId}/`
		else return `/courses/${course.id}/course_target_ages/${course_target_age.id}/`
	}

	constructor(props) {

		super(props)
		this.state = {
			isApplicationChild: false  //一人でも現在表示中のクラスに申し込み可能な子供がいるか
		}
	}

	componentDidMount() {
		//非ログイン時に認証API叩けないので非ログイン時は以下の処理実行しない
		if(Auth.isAuthorized) {
			getApplableStudent(this.props.detailData.course.id, this.props.detailData.course_target_age.id)
			.then(child => {
				const tf = child.length == 0 ? false : true
				this.setState({ isApplicationChild : tf })
			}).catch( error => {
				Auth.destroy()
				document.location.reload()
				return
			})
		}

	}

	/**
	 * ユーザーの状態を判断してボタンをだし分ける
	 * 4つの状態があります 上から
	 * ・非ログイン時
	 * ・ログイン時、自分の子供が誰も申し込めない状態（生徒が全員申し込み済み）
	 * ・ログイン時、クラスがまだこのコースに登録されていない
	 * ・ログイン時、申し込み可能な時
	 */
	getApplicationLink() {
		if(!Auth.accessToken) {
			//非ログイン時
			return (
				<>
					<div className="school-detail__link__btn">
						{/* 短期スクールには説明会を促す文言必要ない */}
						{this.props.school != "short_term" ?
							// 短期スクール以外の文言
							<>
								<div className="school-detail__link__btn__caution">※説明会・体験会を受講の上、お申し込みください。<br/>※日程は変更になる場合があります。</div>
								<div className="school-detail__link__title">このコースに申し込む</div>
							</>
							:
							// 短期スクールの文言
							<>
								<div className="school-detail__link__title">このコースに申し込む</div>
								<div className="school-detail__link__title caution">※スコップ・スクールでは、皆様のお申し込みの省力化のため、LINE登録からの申し込みをお願いしています。<br/>LINE登録済の方はログインして申し込みしてください。</div>
							</>
						}
						<Link to="#" onClick={()=>{Auth.login(false, this.url, this.pageAfterLoginUrl)}}><span>ログインして申し込み</span></Link>
					</div>
					<div className="school-detail__link__btn-line"><Link to="#" onClick={()=>{Auth.login(true, this.url, this.pageAfterLoginUrl)}}><span>LINEで登録して申し込み</span></Link></div>
				</>
			)
		}
		// ログインしていれば以下のどれか

		if (!this.state.isApplicationChild) {
			//子供が全員申し込み済みの時
			return (
				<>
					<div className="school-detail__link__title">すでに申し込み済みです。</div>
					<div className="school-detail__link__caution">※生徒登録していないお子様はお申し込みできません｡<br/>下記リンクより生徒登録してください。</div>
					<div className="school-detail__link__btn"><Link to="/me/children/"><span>お子様管理画面はこちら</span></Link></div>
				</>
			)
		}else if(this.props.detailData.course_classes.length == 0) {
			//クラスが未作成の時
			return (
				<div className="school-detail__link__btn">
					<div className="school-detail__link__btn__caution">このコースの日程は調整中です。</div>
				</div>
				)
		}else {
			//一人でも申し込み可能な子供がいる場合
			return (
				<div className="school-detail__link__btn">
						{this.props.school != "short_term" &&
							// 短期スクール以外の文言
							<div className="school-detail__link__btn__caution">※説明会・体験会を受講の上、お申し込みください。<br/>※日程は変更になる場合があります。</div>
						}
					<Link to={this.pageAfterLoginUrl}><span>このコースに申し込む</span></Link>
				</div>
			)
		}


	}

	/**
	 * render
	 */
	render() {
		const course_target_age = this.props.detailData.course_target_age
		return (
			<footer>
				{course_target_age.notices &&
					<div className="school-detail__attention">
						<div className="school-detail__attention__title"><span>特記事項</span></div>
						<p>
							{course_target_age.notices.split('\n').map((str, index) => (
								<React.Fragment key={index}>{str}<br /></React.Fragment>
							))}
						</p>
					</div>
				}

				{this.props.canApply &&
					<div className="school-detail__link">
						{this.props.school != "short_term" &&
							<div className="school-detail__link__banner">
								<a href="https://docs.google.com/forms/d/e/1FAIpQLSddHoel01nknmjGB9yV8x3r4uu3jHZ6162iEMYAgJAerGWnMg/viewform" target="_blank">
									<picture>
										<img src="/assets/images/common/pct-banner-contact.png" alt="" />
									</picture>
								</a>
							</div>
						}
						{/* 短期スクールは説明会の文言いらない */}
						{this.props.school == "short_term" &&
							<>
								{(course_target_age.entry_state == 'accepting' || course_target_age.entry_state == 'cancel_waiting') &&
									this.getApplicationLink()
								}
							</>
						}
					</div>
				}
			</footer>
		)
	}

}