import React from 'react'

export default class Pagenation extends React.Component {

	render () {
		const reports = this.props.reports.reports
		const report = this.props.report
		let nextReport = null
		let prevReport = null

		for(const _i in reports) {
			if(reports[_i].id == report.report_id) {
				const i = Number(_i)
				if(reports[i+1]) nextReport = reports[i+1]
				if(reports[i-1]) prevReport = reports[i-1]
			}
		}
		console.log(reports, report)

		if(reports.length <= 1) return null
		return (
			<>
				<div className="report__pagenation">
					<span className="report__pagenation__wave"></span>
					<div className="report__pagenation-wrap">
						{nextReport && 
							<div className="report__pagenation__next"><a href="#" id={nextReport.id} onClick={(e) => this.props.onPageMove(e)}>{nextReport.term_name}</a></div>
						}
						{prevReport && 
							<div className="report__pagenation__prev"><a href="#" id={prevReport.id} onClick={(e) => this.props.onPageMove(e)}>{prevReport.term_name}</a></div>
						}
					</div>
				</div>
			</>
		)
 }
	
}