import React from 'react'
import { copyUrl, hideElement, showElement } from '../../../../utils'
import { LINE_URL } from '../../../../config'
import '../../../../assets/css/mine/transition.css'
import InfoModal from '../../../component/common/infoModal'

export default class ZoomModal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
		}
	}

	get meetingId() {
		if(!this.state.data) return ''
		return this.state.data.zoom_url.replace(/https:\/\/.*zoom\.us\/.*\/([0-9]{3})([0-9]{4})([0-9]{4}).*$/,'$1-$2-$3')
	}

	show(data) {
		this.setState({
			data: data
		})
		showElement(this.modal, 0.5, 0)
		showElement(this.modalContent, 0.5, 0.2, true)

		//NOTE: クリップボードコピー対応のブラウザか確認
		window.addEventListener("copy", this.testCopy)
		this.useCopy = document.execCommand("copy")

		// ボタンの半透明処理
		// if (!this.useCopy) {
		// 	this.copyBtn.classList.add("disable")
		// }
	}

	testCopy = (e)=>{
		// console.log("testCopy");
		e.clipboardData.setData('text/plain', "")
		e.preventDefault()
		window.removeEventListener("copy", this.testCopy)
	}

	hide() {
		hideElement(this.modalContent, 0.5, 0, true)
		hideElement(this.modal, 0.5, 0.2)
	}

	handleClose = (e) => {
		this.hide()
		e.preventDefault()
	}

	handleCopyUrl = (e) => {
		e.preventDefault()
		const url = this.state.data.zoom_url

		if (this.useCopy) {
			copyUrl(url)
			InfoModal.instance.show("クリップボードにコピーしました。")
		} else {
			if ('clipboard' in navigator) {
				navigator.clipboard.writeText(url)
				InfoModal.instance.show("クリップボードにコピーしました。")
			} else {
				InfoModal.instance.show("", "お使いのブラウザーでは自動コピーできません。\nお手数ですが手動でコピーお願いします。\n\n" + url)
			}
		}
	}

	handleShareLine = (e) => {
		e.preventDefault()
		if(this.state.data) {
			const data = this.state.data
			const shareText = `${data.course_name} ${data.name} \n ${data.child.first_name}さんのzoomURLはこちらです`
			const shareUrl = data.zoom_url
			const param = encodeURIComponent(`${shareText}\n\n${shareUrl}`)

			window.open(`${LINE_URL.SHARE}${param}`)
		}
	}

	render() {
		const data = this.state.data
		return(
			<section className="mypage-modal" style={{display: 'none', opacity:0}} ref={node => this.modal = node}><span className="mypage-modal-bg" onClick={this.handleClose}></span>
				<div className="mypage-modal-wrap" ref={node => this.modalContent = node}>
					<div className="mypage-modal-top">
						<div className="mypage-modal__closebtn"><a href="#" onClick={this.handleClose}><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
						<div className="mypage-modal__title">{data && data.course_name}</div>
						<div className="mypage-modal__btn"><a href={data && data.zoom_url} target="_blank" rel="noopener noreferrer"><span>Zoomミーティングを開く</span></a></div>
					</div>
					<div className="mypage-modal-bottom">
						<div className="mypage-modal__qrcode">
							<div className="mypage-modal__qrcode__title">ミーティングID：{this.meetingId}</div>
							<div className="mypage-modal__qrcode__btns">
								<a href="#" onClick={this.handleCopyUrl}><img src="/assets/images/me/ico-copy.svg" alt="URLをコピー"/><span>URLをコピー</span></a>
								<a href="#" onClick={this.handleShareLine}><img src="/assets/images/me/ico-line.svg" alt="LINEで共有"/><span>LINEで共有</span></a>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}