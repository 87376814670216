import ApplicationBase from '../applicationBase'

export default class RegularSchoolApplication extends ApplicationBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/regularschool/application.html'
	}

	static get title() {
		return 'クラス選択画面　(レギュラースクール)'
	}

	static get title_en() {
		return 'regularschool_application'
	}

	// ---------- 基本設定ここまで ----------
	get path() {
		return 'regularschool'
	}
}