import React from 'react'
import moment from 'moment'
import PageBase from '../pagebase'
import { Link } from 'react-router-dom';
import queryString from 'query-string'
import { getTrialList } from '../../../api/trial';

export default class Trial extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/trial/'
	}

	static get title() {
		return '体験参加申込'
	}

	static get title_en() {
		return 'Trial'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="trial">
					<img src="/assets/images/trial/txt-trial.svg" alt={this.title} />
					{/* 体験参加申込 */}
				</span>
				<p className="header__title__lead">まずはお近くの教室で体験参加を受付けているかご確認のうえ、お申込みください。</p>
			</div>
		)
	}
	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		// const qs = queryString.parse(this.props.location.search);
		// console.log(qs)
		// let count = 10//1ページの表示件数 後の計算で使用
		// let page = (qs.page) ? parseInt(qs.page) : 1
		// if (page <= 0) page = 1

		this.state = {
			...this.state,
			isDataLoaded: false,
			// count,
			// page,
		}
	}
	componentDidMount() {
		super.componentDidMount()
		this.getDataAndSetState(this.state.page)
	}
	/**
	 * APIから情報を取得してstateに反映
	 * @param {*} e
	 */
	getDataAndSetState() {
		const trialList = getTrialList()
		trialList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				trialList: data,
			})
		})
		this.resetLoadingStatus()
	}

	renderChild() {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			// const page = this.state.page
			const trialList = this.state.trialList
			console.log(trialList.prefecture)
			// const total_page = Math.ceil(newsList.total / this.state.count)
			return (
				<>
				<section className='trialindex'>
					<div className="trialindex__wrap">
						<div className="trialindex__list">
						{
							trialList.prefecture.map((value, key)=>{
								let prefectureName = value.prefecture
								return (
									<div className="trialindex__list__block" id={value.id}>
										<div className="blockttl">
											<span>{value.name}</span>
										</div>
										<table>
											<thead>
												<tr>
													<th>教室名</th>
													<th>エリア</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{
													value.data.map((valueData, keyData)=>{
														return (
															<tr>
																<td>{valueData.name}</td>
																<td>{valueData.area}</td>
																<td>
																	<div className="trail__btn">
																		<a href={valueData.link} target="_blank"><span>体験参加申込</span></a>
																	</div>
																</td>
															</tr>	
														)
													})
												}
												
											</tbody>
										</table>
									</div>
									// <li key={key}>
									// 	<Link to={value.link}>
									// 		<span className="top-news__list__date">{date}</span>
									// 		<span className="top-news__list__title">{value.title}</span>
									// 	</Link>
									// </li>
								)
							})
						}
						{/* <div className="trialindex__list">
							<div className="trialindex__list__block">
								<div className="blockttl">
									<span>〇〇県</span>
								</div>
								<table>
									<thead>
										<tr>
											<th>教室名</th>
											<th>エリア</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="trialindex__list__block">
								<div className="blockttl">
									<span>宮城県</span>
								</div>
								<table>
									<thead>
										<tr>
											<th>教室名</th>
											<th>エリア</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
										<tr>
											<td>河合塾NEXT上杉教室</td>
											<td>仙台市青葉区</td>
											<td>
												<div className="trail__btn">
													<a href="https://schopschool.jp/search/" target="_blank"><span>体験参加申込</span></a>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div> */}
						</div>
					</div>
				</section>
					{/* <section className="newsindex">
						<div className="newsindex__wrap">
						<div className="newsindex__list">
								<ul>
									{
										newsList.news.map((value, key)=>{
											let ts = moment.unix(value.date)
											let date = ts.format("yyyy.MM.DD")
											return (
												<li key={key}>
													<Link to={value.link}>
														<span className="newsindex__list__date">{date}</span>
														<span className="newsindex__list__title">{value.title}</span>
													</Link>
												</li>
											)
										})
									}
								</ul>
							</div>
						</div>
					</section>
					<section className="pagenation">
						<div className="pagenation__wrap">
							<div className="pagenation__prev">
								{page!==1 &&
									<Link to={`/news/?page=${page-1}`} onClick={this.onPrevBtnClick}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="前へ" />
										</picture>
									</Link>
								}

							</div>
							<div className="pagenation__num">
								<span>{page} / {total_page}</span>
							</div>
							<div className="pagenation__next">
								{total_page !== page &&
									<Link to={`/news/?page=${page+1}`} onClick={this.onNextBtnClick}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="前へ" />
										</picture>
									</Link>
								}
							</div>
						</div>
					</section> */}
				</>
			)
		}
	}
}