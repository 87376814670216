import React from 'react'
import { Link} from 'react-router-dom'
import MypageTab from '../../../component/common/mypagetab'
import MyClassBase from './myClassBase'
import Wave from '../../../component/common/wave'
import MypageSelect from '../../../component/common/mypageSelect'
import { getCourseClasses, getEntries } from '../../../../api/myClass'
import Auth from '../../../../api/auth'
import { getCourseList } from '../../../../api/course'
import { getReportList } from '../../../../api/schopReport'

export default class MyClassComplete extends MyClassBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/myclass/complete/'
	}

	static get title() {
		return '申し込み中'
	}

	static get title_en() {
		return ''
	}

	static get key() {
		return "complete"
	}

	// ---------- 基本設定ここまで ----------
	componentDidMount() {
		super.componentDidMount()
		this.getReportList()
	}

	loadCourseData() {
		return getCourseClasses()
	}

	getReportList() {
		getReportList().then((data) => {
			this.setState({ reports: data.reports })
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
	}

	getCourseList () {
		const courseListAll = this.state.courseList
		//選択中の子供でフィルターかける
		const courseList = courseListAll.my_course_classes.filter( item =>
			// 受講済みのみにする
			item.child.id == this.state.child_id && item.member_state == "finished"
		)
		if(courseList.length == 0) {
			return super.nothingData()
		}

		const reportsAll = this.state.reports
		// まずレポートを自分の子供のもののみに絞る
		const reportList = reportsAll && reportsAll.filter(item =>
			item.child.id == this.state.child_id
		)

		return (
			<ul>
				{courseList.map((courseClass, key) => {
					// レポートをこのクラスのものに絞る
					const reports = reportList.filter(item => {
						return (
							item.course_id == courseClass.course_id
							&&
							item.course_target_age_id == courseClass.course_target_age_id
							)
					})
					console.log(reports);
					return (
						<li key={key}>
							<div className="mypage-list-block pcdetail">
								<div className="mypage-list__pct">
									<img src={courseClass.course_target_age_main_image_url} alt=""/>
									<Wave />
								</div>
								<div className="mypage-list__schooltitle-detail">{courseClass.course_name}<small>対象年齢：{courseClass.target_age_label}</small></div>
								<div className="mypage-list__lead">{courseClass.course_target_sales_copy}</div>
								<div className="mypage-list__description">
									<div className="mypage-list__description__title">{courseClass.name}</div>
									<div className="mypage-list__description__list">
										<dl>
											<dt>日程概要</dt>
											<dd>
											{courseClass.description.split('\n').map((desc, key) => {
												return (<React.Fragment key = {key}>{desc}<br/></React.Fragment>)
											})}
											</dd>
										</dl>
									</div>
								</div>
								<div className="mypage-list__pcwrap-detail">
									<div className="mypage-list__user-detail">
										<div className="mypage-list__user__thumb">
											<img src={courseClass.child.child_profile.icon_url} alt=""/>
										</div>
										<div className="mypage-list__user__name">{courseClass.child.first_name}さん</div>
										<div className="mypage-list__user__status"></div>
									</div>
									<div className="mypage-list__btn">
										<Link to={`/courses/${courseClass.course_id}/course_target_ages/${courseClass.course_target_age_id}/`}><span>コース詳細</span></Link>
									</div>
								</div>
								{/* レポート */}
								{reports.length > 0 &&
									<div className="mypage-list__detail">
										<div className="mypage-list__detail__title"><span>スコップ・スクールレポート</span></div>
										<div className="mypage-list__detail__report">
											<ul>
												{reports.map((report,i) => {
													const path = `/me/report/?child_id=${report.child.id}&course_id=${courseClass.course_id}&course_target_age_id=${courseClass.course_target_age_id}&term_id=${report.term_id}`
													return (
														<li key={i}>
															<Link to={path} >ターム{i+1} {report.term_name}</Link>
														</li>
														)
													})}
											</ul>
										</div>
									</div>
								}
							</div>
						</li>
					)
				})}
			</ul>
		)
	}
}