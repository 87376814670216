
import React from 'react'
import Footer from '../component/common/footer'
import PageBase from './pagebase';

export default class NotFound extends PageBase {

	static get path(){
		return '/shortschool_done.html'
	}

	static get title() {
		return '2021年夏スクール申し込み完了'
	}

	static get title_en() {
		return 'shortschool_done'
	}

	renderChild () {
		return (
			<>
			<div className="lowerPage" id="wrapper">

				<section className="briefing">
					<div className="briefing__thanks">
						<div className="briefing__thanks-wrap">
							<div className="briefing__thanks__pct">
									<picture>
											<source srcSet="/assets/images/briefing/pct-thanks--sp.png" media="(max-width: 768px)"/>
											<img src="/assets/images/briefing/pct-thanks.png"/>
									</picture>
							</div>
							<div className="briefing__thanks__lead">
								<p>
									スコップ・スクールの「2021夏スクール」に<br className="sp-view"/>
									お申し込みいただきまして、ありがとうございます。<br/>
									ご登録いただきましたメールアドレスに、<br className="sp-view"/>
									この後の流れを記載したメールをお送りしますので、<br/>
									ご確認お願い致します。<br/>
									夏スクールでお会いすることを楽しみにしております！
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
		)
	}
}