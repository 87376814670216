import gsap from "gsap/gsap-core"


// 実行中のTweenを管理
export default class TweenManager {

	// それぞれのtweenのキー（値は何でも良い）
	static show = "show tween"
	static hide = "hide tween"

	static tweens = []  // 実行中のtween

	/**
	 * show tween
	 * @param {*} element
	 * @param {*} duration
	 * @param {*} delay
	 * @param {*} moveY
	 * @returns
	 */
	static showElement(element, duration = 0.5, delay=0, moveY=false) {
		if(!element || element.length == 0) return
		element.style.display = 'block'
		element.style.pointerEvents = "auto"
		const top = "50vh - 50%"
		this.killOtherTween(this.hide)
		const tween = gsap.fromTo(element, {opacity:0}, {opacity:1, duration: duration, ease:'expo.out', delay:delay, onComplete: () => {
			tween.kill()
		}})
		this.tweens.push({[this.show]: tween})  //実行中tweenの種類として記憶

	}

	/**
	 * hide tween
	 */
	static hideElement(element, duration = 0.5, delay=0, moveY=false, cb) {
		if(!element || element.length == 0) return
		element.style.pointerEvents = "none"  //tweenの完了を待たずに次のtweenを発生させたいため
		this.killOtherTween(this.show)
		const tween = gsap.to(element, {opacity:0, duration: duration, ease:'expo.out', delay:delay, onComplete:()=>{
			element.style.display = 'none'
			if(cb) cb()
			tween.kill()
		}})
		this.tweens.push({[this.hide]: tween})  //実行中tweenの種類として記憶

	}

	/**
	 * 自分が実行される時に消すべきtweenをkillして配列からも削除
	 * @param {str} key
	 */
	static killOtherTween (key) {
		for(const i in this.tweens) {
			if(key in this.tweens[i]) {
				this.tweens[i][key].kill()
				this.tweens.splice(i, 1)
			}
		}
	}

	/**
	 * ページのトップまでスクロールします
	 * @param {number} duration
	 */
	static scrollToTop(duration=0.5, delay=0) {
		const tween = gsap.to(window, { scrollTo:{y:0, autoKill:false}, duration:duration, delay: delay, onComplete: () => {
			tween.kill()
		}})
	}

	/**
	 * ページの下までスクロールします
	 * @param {number} duration
	 */
	static scrollToBottom(duration=0.5, delay=0) {
		const el = document.documentElement
		const bottom = el.scrollHeight - el.clientHeight
		const tween = gsap.to(window, { scrollTo:{y:bottom, autoKill:false}, duration:duration, delay: delay, onComplete: () => {
			tween.kill()
		}})
	}

}