import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../authorizedpagebase'
import DateInput from '../../../component/form/dateinput'
import Select from '../../../component/form/select'
import TextInput from '../../../component/form/textinput'

import Validate, {MaxLength, MinLength, EqualLength} from '../../_utils/validate'
import { addChild, editChild } from '../../../../api/child';
import Auth, { AuthError } from '../../../../api/auth'
import { loadImageAsDataURL } from '../../../../utils'
import Loading from '../../../component/common/loading'
import { getIsStudent } from '../../../../utils'
import { CHILD_ITEM_LIST } from '../../../../config'
import ChildFormBase from './childFormBase'
import queryString from 'query-string'

const STUDENT_FILE = 'txt-studentedit'
const CHILD_FILE = 'txt-childedit'

export default class ChildEdit extends ChildFormBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/children/edit.html'
	}

	static get title() {
		return '生徒編集'
	}

	static get title_en() {
		return 'student'
	}

	static get header_title() {
		if (this._file_name === undefined) this._file_name = CHILD_FILE
		return (
			<div className="header__title">
				<span>
					<img src={`/assets/images/me/${this.file_name}.svg`} alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props, STUDENT_FILE, CHILD_FILE)
		const qs = queryString.parse(this.props.location.search)
		this.child_id = parseInt(qs.child_id)

		this.state = {
			...this.state,
			to_be_student: false
		}
	}

	get submitLabel() {
		return '変更する'
	}

	/**
	 * Auth情報を参照して子供情報を取得
	 */
	authorizeDidComplete() {
		super.authorizeDidComplete()
		this.childData = Auth.getChildById(this.child_id)
		if (this.childData) this.setInitialData()

		/**
		 * 子供を生徒登録する時
		 */
		const qs = queryString.parse(this.props.location.search)
		if(qs.status == "to_be_student") {
			//NOTE: タイトルはどうするか（松本）
			this.setState({
				is_student: true,
				to_be_student: true
			})
			this.constructor.file_name = STUDENT_FILE
		}
	}

	async setInitialData() {
		const is_student = getIsStudent(this.childData)
		let child_profile

		//子供ならchild_profileはnullで初期登録
		if(is_student) {
			child_profile = {
				grade_id: this.childData.child_profile.grade_id,
				icon_url: this.childData.child_profile.icon_url,
				last_name_kana: this.childData.child_profile.last_name_kana,
				first_name_kana: this.childData.child_profile.first_name_kana,
				elementary_school: this.childData.child_profile.elementary_school,
				interest: this.childData.child_profile.interest,
				after_school_activity: this.childData.child_profile.after_school_activity,
			}
		}else {
			child_profile = CHILD_ITEM_LIST.child_profile
		}

		this.constructor.file_name = (is_student) ? this.studentFile : this.childFile

		this.setState({
			...this.state,
			isDataLoaded: true,
			isNeedCheckBox: is_student,  // 編集に飛んできた時点で生徒ならチェックボックスいらない
			is_student,
			form : {
				...this.state.form,
				child_id: this.childData.id,
				gender_id: this.childData.gender_id,
				birth_date: this.childData.birth_date,
				last_name: this.childData.last_name,
				first_name: this.childData.first_name,
				child_profile: child_profile
			}
		})
	}

	submit = async (e) => {
		e.preventDefault()
		let _state = {...this.state}

		//バリデーション確認
		for (let key in this.validateScheme) {
			if (this.childElm[key]) {
				const value = this.childElm[key].value
				const name_ja = this.childElm[key].dataset.name_ja
				const priority = this.childElm[key].dataset.priority

				_state.form.errors[key] = Validate.isValid(name_ja, value, priority, this.validateScheme[key])

				// NOTE: icon変更発生してない場合はNULLだが編集では以前設定した情報があるのでバリデーション不要のためリセット ここだね（松本）
				if (key === 'icon' && _state.form.errors[key].isError && !this.state.to_be_student) {
					_state.form.errors[key] = {
						isError: false,
						message: []
					}
				}

			} else { //予期せぬエラー
				_state.form.errors[key] = Validate.unKownError()
			}
		}

		const isError = Validate.isAllOk(_state.form.errors, this.validateScheme)

		if (isError) {
			let errors = []
			for(const key in _state.form.errors) {
				const error = _state.form.errors[key]
				if(error.isError) {
					// NOTE: エラー文言配列追加
					errors.push(error)
				}
			}
			// priorityでソート
			errors = errors.sort((el1, el2) => el1.priority - el2.priority)
			// 文字だけ抽出した配列
			const errorMsgs = errors.map((el, _) => Validate.formatErrorMessage(el))
			this.setState(_state)
			this.errorListModal("エラー", "入力内容に誤りがあります。ご確認ください。", errorMsgs)
			this.setErrorForcus()
			e.preventDefault()
			return
		}

		// 子供の場合は送信データのchildd_profileをnullに
		let sendData = {...this.state.form}
		if (!this.state.is_student) {
			sendData = {
				...this.state.form,
				child_profile: null
			}
		}

		Loading.show()

		try {
			const data = await editChild(sendData, this.state.form.child_id)
		} catch(error) {
			if (error instanceof AuthError) {
				this.onUnauthorizedError()
			} else {
				this.errorModal('編集失敗', error.message)
				Loading.hide()
			}
			e.preventDefault()
			return
		}

		// 正常対応
		Auth.updateUser().then(() => {
			Loading.hide()
			this.infoModal(((this.state.is_student) ? '生徒' : 'お子様') + '情報を編集しました。')
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			e.preventDefault()
			return
		})
		e.preventDefault()
	}
}