//
import {React, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { scrollToElement } from './utils'

// 各ページ
import Top from './react/pages/top/index'
import About from './react/pages/about'
import Company from './react/pages/company'
// import Law from './react/pages/law'
import Faq from './react/pages/faq'
import Privacy from './react/pages/privacy'
import PrivacyPolicy from './react/pages/privacypolicy'
import CookiePolicy from './react/pages/cookiepolicy'
import Terms from './react/pages/terms'
import Portraitrights from './react/pages/portraitrights'
import Summer2021 from './react/pages/summer2021'
import ShortSchoolDone from './react/pages/shortschool_done'


//news
import News from './react/pages/news/index'
import NewsDetail from './react/pages/news/detail'

//report
import Report from './react/pages/report/index'
import ReportDetail from './react/pages/report/detail'

//briefing
import Briefing from './react/pages/briefing/index'
import BriefingDone from './react/pages/briefing_done/index'
import WinterSchoolDone from './react/pages/briefing_done/winterschool_done'

// login
import Login from './react/pages/login'

// common
import SideMenu from './react/component/common/sidemenu'
import MypageMenu from './react/component/common/mypagemenu'
import Cookie from './react/component/common/cookie'

//regularschool
import RegularSchool from './react/pages/school/regular/index'

//shortschool
import ShortSchool from './react/pages/school/short/index'

//archiv
import Archive from './react/pages/school/archive/index'

import Detail from './react/pages/school/detail'
import Class from './react/pages/school/class'

import RegisterForm from './react/pages/register/form'
import RegisterComplete from './react/pages/register/complete'

//mypage
import Me from './react/pages/me/index'

import RegularSchoolApplication from './react/pages/me/school/regular/application'
import RegularSchoolApplicationComplete from './react/pages/me/school/regular/complete'

import ShortSchoolApplication from './react/pages/me/school/short/application'
import ShortSchoolApplicationComplete from './react/pages/me/school/short/complete'

import MyClass from './react/pages/me/myclass/index'
import MyClassApplication from './react/pages/me/myclass/application'
import MyClassComplete from './react/pages/me/myclass/complete'

import SchopReport from './react/pages/me/report/schopReport'

import ChildrenList from './react/pages/me/children'
import ChildAdd from './react/pages/me/children/add'
import ChildDetail from './react/pages/me/children/detail/index'
import ChildDelete from './react/pages/me/children/delete'
import Quit from './react/pages/me/quit'

import ChildEdit from './react/pages/me/children/edit'

import AccountEdit from './react/pages/me/account/edit'
import AccountDelete from './react/pages/me/account/delete'
import AccountDeleteComplete from './react/pages/me/account/deleteComplete'

// エラー関連
import NotFound from './react/pages/error'
import Ie from './react/pages/ie'

import './assets/css/app.styl'
import Loading from './react/component/common/loading'
import InfoModal from './react/component/common/infoModal'
import LineBlock from './react/component/common/lineBlock'
import { TAG_MANAGER_ARGS } from './config'

// 体験参加申込
import Trial from './react/pages/trial/index'

// テスト
import Test from './react/pages/test'

function Routes() {
	// この配列にページのクラスを追加する
	const lowerPages = [
		// 通常ページ
		Login,
		About,
		Company,
		Privacy,
		PrivacyPolicy,
		Test,
		Trial,
		CookiePolicy,
		// Law,
		Faq,
		Briefing,
		BriefingDone,
		RegisterForm,
		RegisterComplete,
		Terms,
		Portraitrights,

		Summer2021,
		ShortSchoolDone,
		WinterSchoolDone,

		News,
		NewsDetail, // ニュース詳細

		Report,
		ReportDetail, // レポート詳細

		RegularSchool,
		ShortSchool,
		Archive,
		Detail,
		Class,

		//マイページ
		Me,

		RegularSchoolApplication,
		RegularSchoolApplicationComplete,
		ShortSchoolApplication,
		ShortSchoolApplicationComplete,

		MyClass,
		MyClassApplication,
		MyClassComplete,
		ChildrenList,
		ChildEdit,
		ChildAdd,

		SchopReport, // スコップ・スクールレポート

		ChildDetail,
		ChildEdit,
		ChildDelete,

		AccountEdit,
		AccountDelete,
		AccountDeleteComplete,

		Quit,
		Ie,
		NotFound,
	]

	return (
		<Switch>
			<Route exact path="/" component={Top} />
			{lowerPages.map(LowerPage=>{
				return (
					<Route exact path={LowerPage.path} key={LowerPage.path} component={LowerPage} />
				)
			})}
		</Switch>
	)
}
function ScrollToTop() {
　　const { pathname } = useLocation();
　　const { hash } = useLocation();
	// if(hash) {
	// 	console.log("あり")
	// } else {
	// 	console.log("なし")
	// }
	// console.log(hash)
	useEffect(() => {
		if(hash) {
			// setTimeout(scrollToElement($(hash)),5000)
			scrollToElement($(hash))
		} else {
			window.scrollTo(0, 0);
		}
		// window.scrollTo(0, 0);
	}, [pathname]);
	return null;
}

export default ScrollToTop;
function Content() {
	return (
		<>
			<Router>
				<ScrollToTop />
				<Routes />
				<Cookie />
				<SideMenu />
				<LineBlock />
				<MypageMenu />
			</Router>
			<Loading />
			<InfoModal />
		</>
	)
}

// GTM設定
TagManager.initialize(TAG_MANAGER_ARGS)

ReactDOM.render(
	<Content/>,
	document.getElementById('root')
)
// scrollToElement($(this.props.to))