import React from 'react'
import PageBase from './pagebase'
import CompanyModule from '../../modules/company'
import ScrollLink from '../component/common/scrolllink'

export default class Company extends PageBase {
	// ---------- 基本設定 ----------
	static get path() {
		return '/company.html'
	}

	static get title() {
		return '会社概要'
	}

	static get title_en() {
		return 'company'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-company.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	// コンポーネントが表示されるとき
	componentDidMount() {
		super.componentDidMount()
	}

	componentDidLoad() {
		super.componentDidLoad()
		this.companyModule = new CompanyModule()
	}

	// コンポーネントが非表示になるとき
	componentWillUnmount() {
		super.componentWillUnmount()
	}

	renderChild () {
		return (
			<div>

				<section className="company-pagelink">
					<div className="company-pagelink__list">
						<ul>
							<li><ScrollLink to="#pVision"><span>ビジョン</span></ScrollLink></li>
							{/* <li><ScrollLink to="#pMessage"><span>メッセージ</span></ScrollLink></li>
							<li><ScrollLink to="#pMember"><span>メンバー</span></ScrollLink></li> */}
							<li><ScrollLink to="#pInfo"><span>基本情報</span></ScrollLink></li>
						</ul>
					</div>
				</section>
				<section className="company-vision"><span id="pVision"></span>
					<div className="company__wrap">
						<div className="company-vision__pct">
							<picture>
								<source srcSet="/assets/images/company/pct-top--sp.jpg" media="(max-width: 768px)" /><img src="/assets/images/company/pct-top.jpg"/>
							</picture>
						</div>
						<div className="company-vision__title"><img src="/assets/images/company/txt-vision.svg" alt="ビジョン"/></div>
						<div className="company-vision__lead">
							<picture>
								<source srcSet="/assets/images/company/txt-title--sp.svg" media="(max-width: 768px)" /><img src="/assets/images/company/txt-title.svg" alt="Creativity, for the unknown society. 幸せをつかむ創造力"/>
							</picture>
						</div>
						<div className="company-vision__text">
							<p>不確定で多様化する社会において、<br/>既存の「成功モデル」もまた、<br className="sp-view"/>不確定になり多様化する。<br/>ゆえに、予測不能に変化し続ける社会の中で、<br/>自分の個性や適性を自覚し、活かし、<br/>社会が喜ぶ価値を生み出していくことが、<br/>新しい時代の幸せの形になる。<br/>もう成功モデルは用意されない社会で、<br/>自らイメージする幸せを実現するために<br className="sp-view"/>必要な力。<br/>スコップ・スクールが一人ひとりの中から<br className="sp-view"/>掘り起こし、育てます。 </p>
						</div>
					</div>
				</section>
				{/* <section className="company-message"><span id="pMessage"></span>
					<div className="company__wrap">
						<div className="company-message__title"><img src="/assets/images/company/txt-message.svg" alt="設立者メッセージ"/></div>
						<div className="company-message__tab">
							<ul>
								<li className="current"><a className="js_message_btn" href="#MessageA">A</a></li>
								<li><a className="js_message_btn" href="#MessageB">B</a></li>
								<li><a className="js_message_btn" href="#MessageC">C</a></li>
							</ul>
						</div>
						<div className="company-message__detail">
							<ul>
								<li className="js_message current" id="MessageA">
									<div className="company-message__detail__head">
										<div className="company-message__detail__head__thumb"><img src="/assets/images/company/pct-leader1a.jpg" alt="平石 洋介"/></div>
										<div className="company-message__detail__head__text"><strong>平石 洋介</strong>
											<p>執行役員/Chief Creative Officer</p>
										</div>
									</div>
									<div className="company-message__detail__body">
										<p><strong>子どもに負けるな。</strong></p>
										<p>子どもは、子ども扱いされてないか。<br/>これからは社会が変わってルールも変わる。<br/>まだ何にも染まってない分、変化と進化の先頭に立てるのは、<br className="sp-view"/>むしろ子どもなのに。</p>
										<p>わからないこと、<br className="sp-view"/>できないことはテクノロジーを使えばいい。<br/>そして、人と人が助け合えばいい。</p>
										<p>優等生なんて言葉はもうなくなる。<br className="sp-view"/>むしろ、いろんな種類の優等生が出てくる。<br/>ハンデキャップもチャンスにできる。</p>
										<p>今は、できること、やりたいことを探して、<br/>自分を見つめ、人と刺激しあい、<br className="sp-view"/>未来に必要な力をつけていけばいい。</p>
										<p>だから子どもは、<br className="sp-view"/>子どもということに負けるな。<br/>　</p>
										<p>大人は、自信を失ってないか。<br/>確かに、激変する社会で<br className="sp-view"/>今までの経験や知識にしがみついてたら、<br/>まず子どもに置いていかれるだろう。</p>
										<p>でも、テクノロジーがほとんどの仕事を<br className="sp-view"/>やってしまう社会で<br className="sp-view"/>人間がやれること、<br className="pc-view"/>やるべきことは、<br className="sp-view"/>「人間らしい」ことしかない。</p>
										<p>子どもより長く人間をやってきて、<br className="sp-view"/>喜怒哀楽をフルに働かせてきたからこそ、<br/>AI社会において子どものリーダーになれる。</p>
										<p>必要なのは新しい力をつけること。<br/>大人が変化してこそ、子どもが輝く。</p>
										<p>だから大人は、子どもに負けるな。<br/>　</p>
										<p>子どもにとっても、<br/>大人にとっても、<br/>次の社会に必要な力がある。<br/>それを掘り起こし、未知の社会で<br className="sp-view"/>生き抜く力にするために。</p>
										<p>さあ、100年使えるスコップ・スクールを手にしよう。</p>
									</div>
								</li>
								<li className="js_message" id="MessageB">
									<div className="company-message__detail__head">
										<div className="company-message__detail__head__thumb"><img src="/assets/images/company/pct-leader1b.jpg" alt="平石 洋介"/></div>
										<div className="company-message__detail__head__text"><strong>平石 洋介</strong>
											<p>代表取締役/Chief Creative Officer</p>
										</div>
									</div>
									<div className="company-message__detail__body">
										<p><strong>きみはひとりしかいない。</strong><br/><strong>きみはひとりじゃない。</strong></p>
										<p>ゆでガエルなんて見たことないけど、<br/>激変は気がつかないうちにおこっている。<br/>どんな社会になるのか、もう誰にもわからない。</p>
										<p>どうせなら、<br className="sp-view"/>きみが自分で社会をつくるチャンスにしよう。<br/>といっても、<br className="sp-view"/>政治家や大富豪を目指すわけではありません。<br/>(もちろん、目指してもいい。)</p>
										<p>そもそも、<br/>子どものころ描いた夢を<br className="sp-view"/>そのまま実現する人はほとんどいないし、<br/>夢が無くたっていい。</p>
										<p>探し続ける、どんどん変えていく。<br/>好きなものに出会う。<br className="sp-view"/>好きじゃなかったものにも出会う。<br/>出会い続けること、掘り続けることで、<br/>世界でたった1人のきみという人間を、<br className="sp-view"/>まず自分自身が知ろう。</p>
										<p>その「きみらしさ」を社会で活かす力！<br/>そして様々な人たちの「らしさ」をお互いに活かす力！</p>
										<p>この2つの力を発揮し続ければ、<br/>次の社会をつくることができるんじゃないでしょうか。</p>
										<p>スコップ・スクールは、<br/>社会で活かせる「きみらしさ」を<br className="sp-view"/>掘り起こすための場であり、道具です。</p>
										<p>今の社会を生きる、<br className="sp-view"/>想像力と創造力のプロフェッショナルたちが、<br/>一緒に掘り起こすお手伝いをします。</p>
									</div>
								</li>
								<li className="js_message" id="MessageC">
									<div className="company-message__detail__head">
										<div className="company-message__detail__head__thumb"><img src="/assets/images/company/pct-leader1c.jpg" alt="平石 洋介"/></div>
										<div className="company-message__detail__head__text"><strong>平石 洋介</strong>
											<p>代表取締役/Chief Creative Officer</p>
										</div>
									</div>
									<div className="company-message__detail__body">
										<p><strong>私の母は、大喜利の名人。</strong></p>
										<p>私が小学生のころ、<br/>「誕生日プレゼント何がほしい？」と聞いたら、<br/>「あなたが普通になってほしい。」<br className="sp-view"/>と真顔で切り返され、<br/>うまい！座布団一枚！とヒザをたたきました。</p>
										<p>普通じゃない、いわゆる問題児だった私が、<br/>普通の社会で生きていけるのか。<br className="sp-view"/>心配のかけ通しでした。</p>
										<p>就職が決まったら決まったで、<br className="sp-view"/>今度は父から一言、<br/>「もっと普通の会社がいいんじゃないか？」</p>
										<p>異能の見本市のような電通という世界で、<br/>多士済々の方々に刺激され、常に助けられ、<br className="sp-view"/>アイデアを出し続け、<br/>数えきれないくらいの広告を<br className="sp-view"/>世の中に送り出してきましたが、<br/>「普通じゃない視点」は、<br className="sp-view"/>時に武器になってきました。</p>
										<p>普通の企画じゃ、お金にならない。<br className="sp-view"/>世の中に刺さらない。<br/>今風に言えば、普通に考えていては<br className="sp-view"/>イノベーションを生み出せない、<br/>ということ。笑</p>
										<p>そしてこれから、普通のレール、<br className="sp-view"/>普通の成功モデルが、無くなる。<br/>高い学歴や経歴、地位も、<br/>持ってるだけでは価値がない時代が来る<br className="sp-view"/>（と予想されてます）。</p>
										<p>これをチャンスととらえ、<br className="sp-view"/>普通を越えて、変えて、<br/>楽しく心豊かに生きていくために。<br/>でも単に変なヤツ、ではなく、<br className="sp-view"/>社会が喜ぶ価値を生み出すために。</p>
										<p>「やくにたつ創造力」を、一緒に掘り起こし、<br className="sp-view"/>育てていきましょう。<br/>いい大人の私だって、これからこそ頑張ります。</p>
										<p>子どもに、負けるな！<br/><a className="js_message_link" href="#MessageA">（Aに戻る）</a></p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</section> */}
				{/* <section className="company-message">
					<div className="company__wrap">
						<div className="company-message__title"><img src="/assets/images/company/txt-message_2.svg" alt="代表メッセージ"/></div>
						<div className="company-message__detail">
							<div className="company-message__detail__head">
								<div className="company-message__detail__head__thumb"><img src="/assets/images/company/pct-leader2.jpg" alt="金子 薫平"/></div>
								<div className="company-message__detail__head__text"><strong>金子 薫平</strong>
									<p>代表取締役/Chief Executive Officer</p>
								</div>
							</div>
							<div className="company-message__detail__body">
								<p>『実践的な創造力』を身に着けてもらう。<br/>それを促す『環境づくり』をする。<br/>これが私たちの提示する、<br className="sp-view"/>幸せな未来への選択肢です。</p>
								<p>10年近く仕事で携わらせて頂き、<br/>すっかり魅了されている『発酵』を引き合いに<br className="sp-view"/>２つの話をさせてください。</p>
								<p>１つ目。<br/>発酵は、菌と人間との協同ワーク。<br/>人間が、菌の持つ力を引き出して、<br className="sp-view"/>もとのものが変質し、<br/>人にとって有益な新たなものが<br className="sp-view"/>生まれるのが発酵です。<br/>例えば、「牛乳」が菌の力で<br className="sp-view"/>「カルピス」になる。<br/>あるいは、「日本各地の野菜」が<br className="sp-view"/>「その土地ならではの漬物」になる。</p>
								<p>面白いのは、たとえ“製品”として<br className="sp-view"/>大量に流通しているものでも<br/>絶対的な統一された味があるわけではなく、<br/>菌のコンディションや、季節に応じた<br className="sp-view"/>温度などにより、<br className="sp-view"/>微妙に振れ幅があるということ。<br/>菌の声を聞き、状態をしっかり見極めて、<br className="sp-view"/>いかに力を発揮してくれるか、<br/>そのために努めることが、<br className="sp-view"/>醸造家、発酵屋さんの『環境づくり』です。</p>
								<p>２つ目。<br/>「発酵に有用なのはこの菌。」<br className="sp-view"/>と抜き出したものだけを<br/>純粋培養して使っていくのではなく、<br/>発酵そのものの役に立っているか<br className="sp-view"/>わからない蔵付きの菌も呼び込むことで、<br/>相互に影響しあったり、<br className="sp-view"/>ときに人間が解明できない働きをして、<br/>それぞれの蔵独自の複雑な味が<br className="sp-view"/>生まれるといいます。</p>
								<p>つまり、そこにいるだけ、と思われるものでも<br className="sp-view"/>互いに存在を認めあいながら、<br/>個性を発揮すると、その時どきにしか<br className="sp-view"/>生まれない、ほかにはない唯一無二のものに<br/>なったりする。<br/>周囲との関係性も含めて、<br className="sp-view"/>そのときどきの環境の中で、<br className="sp-view"/>相応しい力を発揮することが<br/>私のイメージする『実践的な創造力』です。</p>
								<p>いかに仲間とともに、<br className="sp-view"/>本気で向かっていきたい！と思える<br className="sp-view"/>テーマを用意し、<br/>いかに気持ちよく創造力の発揮を誘発するか。<br/>そのための条件や環境を整えてお待ちします。</p>
								<p>子どもたちと菌を一緒にするなって。<br/>いえいえ、菌よりも、<br className="sp-view"/>もっと『未知なるもの』として接し、<br/>より素敵な相互作用を引き起こして<br className="sp-view"/>いきたいと思います。<br/>お楽しみに。</p>
							</div>
						</div>
					</div>
				</section> */}
				{/* <section className="company-member"><span id="pMember"></span>
					<div className="company__wrap">
						<div className="company-member__title"><img src="/assets/images/company/txt-member.svg" alt="メンバー"/></div>
						<div className="company-member__detail">
							<dl className="js_photo">
								<dt>
									<div className="company-member__detail__pct"><img src="/assets/images/company/pct-kaneko.jpg" alt="金子 薫平"/></div>
								</dt>
								<dd>
									<div className="company-member__detail__text"><span>代表取締役/Chief Executive Officer</span><strong>金子 薫平</strong>
										<p>(株)電通にて、民放BSデジタル放送を開局から10年間担当。次いで、営業として国民的乳酸菌飲料を10年強担当した後、スコップ・スクールに携わる。オリジナル子ども番組の制作や親子参加型イベントの運営など、子どもとの接点が多い社会人生活を過ごしてきた中で掲げたモットーは、「同じ視点でわくわくしよう！」。<br/>自身は2児の父。趣味は、植物の育成と発酵食品探求。</p>
									</div>
								</dd>
							</dl>
							<dl className="js_photo">
								<dt>
									<div className="company-member__detail__pct"><img src="/assets/images/company/pct-hiraishi.jpg" alt="平石 洋介"/></div>
								</dt>
								<dd>
									<div className="company-member__detail__text"><span>執行役員/Chief Creative Officer</span><strong>平石 洋介</strong>
										<p>(株)電通入社後、営業局を７年。 <br/>その後、クリエーティブ局に転局。<br className="sp-narrow-view"/><a href="https://www.tcc.gr.jp/relay_column/id/2817/ " target="_blank">（当時の様子）</a><br/>その後、NYの電通アメリカに出向。<br className="sp-view"/><a href="https://www.tcc.gr.jp/relay_column/id/2813/ " target="_blank">（当時の様子）</a><br/>その後、次世代教育開発事業スコップ・スクールを構想、起案。 電通社内外の優秀な方々の手と情熱によって実現されたのは、 これが世界が求めているものだからと信じている。</p>
									</div>
								</dd>
							</dl>
							<dl className="js_photo">
								<dt>
									<div className="company-member__detail__pct"><img src="/assets/images/company/pct-tanaka.png" alt="田中 孝英" /></div>
								</dt>
								<dd>
									<div className="company-member__detail__text"><span>執行役員/Producer</span><strong>田中 孝英</strong>
										<p>
											(株)電通入社後、中部支社と東京本社で新聞局に１５年間在籍。<br />
											新聞のメディアバイイング・プランニング・イベント等の企画立案や運営を数年経験した後に、デジタルメディアの企画立案・セールスや高校野球のライブ配信サービスなどの事業開発に携わる。<br />
											また、新たなアドテクノロジーの事業開発や海外のデジタルマーケティングプラットフォーム企業の日本における事業開発プロジェクトなどを推進した後に、2022年7月よりスコップ・スクールに参画。
										</p>
									</div>
								</dd>
							</dl>
							<dl className="js_photo">
								<dt>
									<div className="company-member__detail__pct"><img src="/assets/images/company/pct-kitamoto.jpg" alt="北本 英光"/></div>
								</dt>
								<dd>
									<div className="company-member__detail__text"><span>Chief Learning Director</span><strong>北本 英光</strong>
										<p>在学中より学習環境デザイン領域を学び、(株)電通で多くの知育・教育ブランドのクリエーティブや事業支援に従事。こどもたちの可能性をプレイフルに拡げるワークショップや展覧会、遊びと学びのコンテンツ開発を手掛ける。モンテッソーリやレッジョ・エミリア等オルタナティブ教育の研究者でもあり、教育系NPOや団体の理事を務める。著書に「レゴブックミュージアム」他。</p>
									</div>
								</dd>
							</dl>
							<dl className="js_photo">
								<dt>
									<div className="company-member__detail__pct"><img src="/assets/images/company/pct-okamoto.jpg" alt="岡本 弘毅"/></div>
								</dt>
								<dd>
									<div className="company-member__detail__text"><span>Chief Growth Director</span><strong>岡本 弘毅</strong>
										<p>教育の問題解決をする会社として（株）エデュソルを設立。世界に羽ばたく「倭僑」の育成の為、従来の教育だけではなく、STEAM教育やSDG’s教育（ESD）、グローバル教育を中心に、3歳から社会人までの幅広い年齢にあわせた、様々な教育プログラムを提供している。子どもの為の大学を設立し、累計10,000人を超える学びの場を提供するNPO法人の理事長も務める。</p>
									</div>
								</dd>
							</dl>
						</div>
					</div>
				</section> */}
				<section className="company-info"><span id="pInfo"></span>
					<div className="company__wrap">
						<div className="company-info__title"><img src="/assets/images/company/txt-info.svg" alt="基本情報"/></div>
						<div className="company-info__detail">
							<table>
								<tbody>
									<tr>
										<th>社名</th>
										<td>株式会社スコップ</td>
									</tr>
									<tr>
										<th>代表者</th>
										<td>代表 岡本弘毅</td>
									</tr>
									<tr>
										<th>所在地</th>
										<td>港区赤坂4-12-5 FAD20-2F</td>
									</tr>
									<tr>
										<th>資本金等</th>
										<td>3億5000万円</td>
									</tr>
									<tr>
										<th>決算期</th>
										<td>12月</td>
									</tr>
									<tr>
										<th>設立年月日</th>
										<td>2020年11月2日</td>
									</tr>
									
									<tr>
										<th>事業内容</th>
										<td>・「スコップ・スクール」の運営<br/>・学習環境支援ツールの開発<br/>・実践的創造力育成コンテンツの開発など</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</section>
			</div>
		)
	}
}