const gsap = require('gsap').gsap
import Intro from './intro'
import TopVideo from './topVideo'
import SchopAnimations from './schopAnimations'
import Morph from './morph'

export default class TopModule {

	constructor() {
		this.spMaxWidth = 768
		this.onResize = this.resize.bind(this)
	}

	onEnter() {
		this.isSp = window.innerWidth <= this.spMaxWidth
		$(window).on('resize', this.onResize)
		this.intro = new Intro()
		this.intro.on('complete', ()=>{
			this.topVideo = new TopVideo()
			this.morph = new Morph()
			this.schopAnimations = new SchopAnimations()
		})
		this.intro.setup()
	}

	onExit() {
		this.intro.destroy()
		delete this.intro
		this.schopAnimations.destroy()
		delete this.schopAnimations
		this.morph.destroy()
		delete this.morph
		$(window).off('resize', this.onResize)
	}

	resize() {
		const isSp = window.innerWidth <= this.spMaxWidth
		if (this.isSp != isSp) {
			document.location.reload()
		}
	}
}
