import React from 'react'
import Auth from '../../../../api/auth';
import Wave from '../../../component/common/wave';

export default class Confirm extends React.Component {

	render () {
		const course = this.props.state.courseData.course
		const course_target_age = this.props.state.courseData.course_target_age
		const course_classes = this.props.state.courseData.course_classes
		return (
			<section className="form-information">
				<div className="form-information-wrap">
					<div className="form-information__subtitle">クラス選択</div>
					<div className="form-information__list">
						{course.name &&
							<dl>
								<dt>コース名</dt>
								<dd>{course.name}</dd>
							</dl>
						}

						{course_target_age.target_age_label &&
							<dl>
								<dt>対象年齢</dt>
								<dd>{course_target_age.target_age_label}</dd>
							</dl>
						}

						<dl>
							<dt>クラス</dt>
							{course_classes.map((data,i)=>{
								if (data.id === this.props.state.select.class_id) {
									return (
										<dd key={i}>
											{data.name}<br />
											{data.description.split('\n').map((desc, key) => {
												return (<React.Fragment key = {key}>{desc}<br/></React.Fragment>)
											})}
										</dd>
									)
								}
							})}
						</dl>

						<dl>
							<dt>参加するお子様</dt>
							{Auth.user.children.map((data, i)=>{
								if (data.id === this.props.state.select.child_id) {
									return (
										<dd key={i}>{data.last_name} {data.first_name} 様</dd>
									)
								}
							})}
						</dl>
						<div className="form-editbtn">
							<a href="#" onClick={this.props.onEditSubmit}><span>編集</span></a>
						</div>
					</div>
				</div>
				<section className="form-followbtn">
					<a href="#" onClick={this.props.onConfirmSubmit}><span>仮申し込みをする</span></a>
				</section>
			</section>
		)
	}
}