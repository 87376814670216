import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'


export default class FormBase extends React.Component {

	static propTypes = {
		title: PropTypes.string,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		onChange: PropTypes.func,
		value: PropTypes.string,
		noWrap: PropTypes.bool,
	}


	static defaultProps = {
		title: '',
		required: false,
		isConfirming: false,
		isDisplay: false,
		onChange: () => {},
		value: '',
		noWrap: false,
	}

	get title() {
		if (this.props.isConfirming) return this.props.title
		return (
			<>
				<div className="form-edit__input-title">{this.props.title}</div>
				{this.props.required && !this.props.isConfirming && (
					<div className="form-edit__input-require">※</div>
				)}
			</>
		)
	}

	get form() {
		return (
			<div>
				<input type="text" value={this.props.value} />
			</div>
		)
	}

	get confirm() {
		return (
			<>{this.props.value}</>
		)
	}

	render () {
		if (!this.props.noWrap) {
			return (
				<dl>
					<dt>
						{this.title}
					</dt>
					<dd>
						{!this.props.isConfirming && this.form}
						{this.props.isConfirming && this.confirm}
					</dd>
				</dl>
			)
		} else {
			return (
				<>
					{!this.props.isConfirming && this.form}
					{this.props.isConfirming && this.confirm}
				</>
			)
		}
	}
}