import React from 'react'

export default class ReportSelect extends React.Component {

	render () {

		const reports = this.props.reports
		const report = this.props.report
		if(!reports || !report) return (<></>)
		
		return (
			<div className="report__top">
				<div className="report__top-wrap">
					<div className="report__top__select"> 
						<select name="test" onChange={this.props.onChangeReport} value={this.props.id ? this.props.id : 0}>
							{reports &&
								reports.map((rep,key) => {
									return(
										<option key={key} value={rep.id}>{rep.course_name} / {rep.term_name}</option>
									)
								})
							}
							{/* iosセレクト全文表示のために必要 */}
							<optgroup label=""></optgroup>
						</select>
					</div>
				</div>
			</div>
		)
	}
	
}