import { DEFAULT_ICON } from "../../../config";

export const String = "String";
export const Number = "String";

/**
 * 桁数チェック用データクラス(最小値)
 */
export class MinLength {
	static key = "MinLength"
	constructor(val) {
		this.key = MinLength.key
		this.value = val
	}
}

/**
 * 桁数チェック用データクラス(最大値)
 */
export class MaxLength {
	static key = "MaxLength"
	constructor(val) {
		this.key = MaxLength.key
		this.value = val
	}
}

/**
 * 桁数チェック用データクラス(同値)
 */
export class EqualLength {
	static key = "EqualLength"
	constructor(val) {
		this.key = EqualLength.key
		this.value = val
	}
}

/**
 * バリデーション
 */
class Validation {
	Required = "Required"
	String = "String"
	Number = "Number"
	Furigana = "Furigana"
	Tel = "Tel"
	Mail = "Mail"
	IconChecked = "IconChecked"

	ErrorMessages = {
		Required    : "この項目は必須です。",
		Furigana    : "ひらがなで入力してください。",
		Number      : "数値を入力してください。",
		Tel         : "0から開始してください。",
		Mail        : "メールアドレスとして正しくありません。",
		IconChecked : "アイコンを設定してください。",
		MinLength   : "${val}文字以上を入力してください。",
		MaxLength   : "${val}文字以下で入力してください。",
		EqualLength : "${val}文字入力してください。",
	}

	/**
	 * バリデーション
	 * @param {*} value - 値
	 * @param {*} scheme - チェック条件
	 */
	isValid = (name_ja, value, priority, scheme) => {
		let result = {
			title: name_ja,
			priority: priority,
			isError: false,
			message: []
		}

		scheme.map((valid)=>{
			if (typeof(valid) === "object") {
				if (valid.key === EqualLength.key) result = this.ValidEqualLength(value, result, valid.value)
				else if (valid.key === MaxLength.key) result = this.ValidMaxLength(value, result, valid.value)
				else if (valid.key === MinLength.key) result = this.ValidMinLength(value, result, valid.value)
			} else {
				if (valid === this.Required) result = this.ValidRequired(value, result)
				else if (valid === this.Furigana) result = this.ValidFurigana(value, result)
				else if (valid === this.Number) result = this.ValidNumber(value, result)
				else if (valid === this.Tel) result = this.ValidTel(value, result)
				else if (valid === this.Mail) result = this.ValidMail(value, result)
				else if (valid === this.IconChecked) result = this.ValidIconChecked(value, result)
			}
		})

		return result
	}

	/**
	 * 必須チェック
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 */
	ValidRequired = (value, result)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.Required)
		}
		return result
	}

	/**
	 * ふりがなチェック
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 */
	ValidFurigana = (value, result)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.Furigana)
		} else if (value.match(/^[ぁ-んー　]*$/) === null) {
			result.isError = true
			result.message.push(this.ErrorMessages.Furigana)
		}
		return result
	}

	/**
	 * 数値チェック
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 */
	ValidNumber = (value, result)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.Number)
		} else if (value.match(/^[0-9]*$/) === null) {
			result.isError = true
			result.message.push(this.ErrorMessages.Number)
		}
		return result
	}

	/**
	* メールチェック
	* @param {string} value - 検証値
	* @param {object} result - 結果データ
	*/
	ValidTel = (value, result)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.Tel)
		} else if (value.match(/^0/) === null) {
			result.isError = true
			result.message.push(this.ErrorMessages.Tel)
		}
		return result
	}

	/**
	 * メールチェック
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 */
	ValidMail = (value, result)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.Mail)
		} else if (value.match(/[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/) === null) {
			result.isError = true
			result.message.push(this.ErrorMessages.Mail)
		}
		return result
	}

	/**
	 * アイコンチェック
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 */
	ValidIconChecked = (value, result)=> {
		if (value === "" || value === DEFAULT_ICON) {
			result.isError = true
			result.message.push(this.ErrorMessages.IconChecked)
		}
		return result
	}

	/**
	 * 文字数チェック（同値）
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 * @param {any} comparison - 比較値
	 */
	ValidEqualLength = (value, result, comparison)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.EqualLength.replace("${val}", comparison))
		} else if (value.length !== comparison) {
			result.isError = true
			result.message.push(this.ErrorMessages.EqualLength.replace("${val}", comparison))
		}
		return result
	}

	/**
	 * 文字数チェック（最大値）
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 * @param {any} comparison - 比較値
	 */
	ValidMaxLength = (value, result, comparison)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.MaxLength.replace("${val}", comparison))
		} else if (value.length > comparison) {
			result.isError = true
			result.message.push(this.ErrorMessages.MaxLength.replace("${val}", comparison))
		}
		return result
	}

	/**
	 * 文字数チェック（最小値）
	 * @param {string} value - 検証値
	 * @param {object} result - 結果データ
	 * @param {any} comparison - 比較値
	 */
	ValidMinLength = (value, result, comparison)=> {
		if (value === "") {
			result.isError = true
			result.message.push(this.ErrorMessages.MinLength.replace("${val}", comparison))
		} else if (value.length < comparison) {
			result.isError = true
			result.message.push(this.ErrorMessages.MinLength.replace("${val}", comparison))
		}
		return result
	}

	/**
	 * 全てのバリデーションが正常でかつ一回以上フォーカスが当てられたかどうかを確認
	 */
	isAllOk = (errors, scheme)=> {
		let isError = false

		// スキームに対応した項目が全て存在するか確認
		// (存在しないということは一度もフォーカスされてない)
		for(let key in scheme) {
			if (Object.keys(errors).indexOf(key) < 0) {
				isError = true
				break
			}
		}

		// 全てのバリデーションがtrueか確認
		if (!isError) {
			for (const key in errors) {
				const data = errors[key];
				if (data.isError) {
					isError = true
					break
				}
			}
		}
		return isError
	}

	/**
	 * 不明なエラー
	 */
	unKnownError = ()=>{
		return {
			isError : true,
			message : ['不明なエラー']
		}
	}

	/**
	 * 全てのバリデーション正常かどうかのみを確認
	 * edit系の画面はフォームのvalueが初期値で存在するので一度もフォーカスが当たらないことがある
	 */
	isAllValidationOk = (errors)=> {
		let isError = false

		// 全てのバリデーションがtrueか確認
		for (const key in errors) {
			const data = errors[key];
			if (data.isError) {
				isError = true
				break
			}
		}

		return isError
	}


	/**
	 * スキームに対応した項目がセットされてない場合は強制的にエラーをセット
	 * @param {*} errors
	 * @param {*} scheme
	 */
	setBlankData = (errors, scheme)=> {
		for(let key in scheme) {
			if (Object.keys(errors).indexOf(key) < 0) {
				errors[key] = {
					isError: true,
					message: [this.ErrorMessages[scheme[key][0]]]
				}
			}
		}
		return errors
	}

	/**
	 * 単にエラーが起きてる項目のエラーをセット
	 * edit系の画面はフォームのvalueが初期値で存在するので一度もフォーカスが当たらないことがある
	 * @param {*} errors
	 * @param {*} scheme
	 */
	setErrorData = (errors, scheme)=> {
		for (const key in errors) {
			console.log(errors[key]);
			const data = errors[key];
			if (data.isError) {
				errors[key] = {
					title: data.title,
					isError: true,
					priority: data.priority,
					message: errors[key].message
				}
			}
		}
		return errors
	}

	/**
	 * エラー文を整形する
	 * @param {object}} error
	 * @returns
	 */
	formatErrorMessage(error) {
		// NOTE: message配列の最後の方の要素のほうがそのバリデーションに対してユニークなメッセージが得られる（最初の方は汎用メッセージがち
		return error.title + " : " + error.message[0]
	}


}

export default new Validation()