import React from 'react'
import { Link } from 'react-router-dom'

export default class Cookie extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isOpen: false
		}
	}

	componentDidMount() {
		if (!$.cookie('gdpr')) this.open()
	}

	componentWillUnmount() {
	}

	open() {
		this.closeButton.addEventListener('click', this.onCloseButtonClicked)
		this.setState({ isOpen: true })
	}

	onCloseButtonClicked = (e) => {
		e.preventDefault()
		this.close()
	}

	close() {
		this.accept()
		this.closeButton.removeEventListener('click', this.onCloseButtonClicked)
		this.setState({ isOpen: false })
	}

	accept() {
		$.cookie('gdpr', 1, { path: '/', expires: 365 })
	}

	render () {
		return (
			<section className={this.state.isOpen ? 'cookie open' : 'cookie'} ref={node => this.node = node}>
					<div className="cookie__text">
						<p>このウェブサイトではサイトの利便性の向上を目的にCookieを使用します。これ以降ページを遷移した場合、Cookieなどの設定や使用に同意したことになります。ブラウザの設定によりCookieの機能を変更することもできます。詳細は<Link to="/cookie.html">Cookieポリシーページ</Link>をご覧ください。</p>
					</div>
					<div className="cookie__closebtn"><a ref={node => this.closeButton = node} href="#"><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
			</section>
		)
	}
}