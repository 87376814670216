import React from 'react'
import PageBase from './pagebase'

export default class Portraitrights extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/portraitrights.html'
	}

	static get title() {
		return '肖像権・利用者著作物の利用に関する承諾'
	}

	static get title_en() {
		return 'portraitrights'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="portraitrights">
					<picture>
							<source srcset="/assets/images/common/txt-portraitrights--sp.svg" media="(max-width: 768px)"/><img src="/assets/images/common/txt-portraitrights.svg" alt={this.title} />
					</picture>
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<div>
				<section className="policy">
					<div className="policy__wrap">
						<div className="policy__detail">
							<p className="policy__detail__text">スコップ・スクールの各コース・プログラムでは、広報広告活動（以下あわせて「本件広報広告」といいます）としてプログラムの様子を映像・写真撮影させて頂き、WEBサイトやイベント・各種報道機関などで使用を予定しております。プログラムに参加されるお子様・ご家族の映像および写真、お子様・ご家族がプログラム内で創作された制作物並びに音声その他の素材（以下あわせて「本件素材」といいます）を使用することにご承諾をお願い致します。</p>
							<h2 className="policy__detail__title">本件素材の取り扱い</h2>
							<p className="policy__detail__text">1）本件素材は、期間、地域、媒体、態様等何らの制限なく、株式会社スコップが自由に広報広告目的として、広報活動および宣伝活動全般において無償で利用させていただく場合があります。<br/>2）本件素材を本件広報広告において利用するかどうかは、株式会社スコップの判断にお任せいただきます。また、本件素材の複製、編集、加工、頒布、翻案、翻訳などについても株式会社スコップの任意とさせていただきます。<br/>3）株式会社スコップおよび撮影者による本件素材の利用について、肖像権、プライバシー権、パブリシティ権、著作権、著作者隣接権その他いかなる権利の主張および請求等もされないことをあらかじめご了承お願いいたします。</p>
							<p className="policy__detail__text">以　上</p>
						</div>
					</div>
				</section>
			</div>
		)
	}
}