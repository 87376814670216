import React from 'react'
import AuthorizedPageBase from '../../authorizedpagebase'
import { getCourseDetail, postCourseEntries } from '../../../../api/course'
import Select from './select'
import Confirm from './confirm'
import queryString from 'query-string'
import { deleteCookie, getCookie, scrollToTop } from '../../../../utils'
import { AuthError } from '../../../../api/auth'

export default class ApplicationBase extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/form/txt-selectclass.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	static get StateInput () {
		return "Input"
	}

	static get StateConfirm () {
		return "Confirm"
	}

	constructor(props) {
		super(props)
		const qs = queryString.parse(this.props.location.search);
		this.course_id = parseInt(qs.course_id);
		this.course_target_age_id = parseInt(qs.course_target_age_id)
		const class_id = (qs.course_class_id) ? parseInt(qs.course_class_id) : undefined
		this.state = {
			...this.state,
			isDataLoaded: false,
			status: this.constructor.StateInput,
			courseData: {},
			select: {
				class_id: class_id
			}
		}
	}

	get path() {
		return ''
	}

	/**
	 * 入力画面 : 申込ボタンクリック時
	 * @param {Number} child_id
	 * @param {Number} class_id
	 */
	onInputSubmit = (child_id, class_id) =>{
		this.setState({
			status: this.constructor.StateConfirm,
			select: {
				child_id,
				class_id,
			}
		})
	}

	/**
	 * 確認画面 : 編集ボタンクリック時
	 */
	onEditSubmit = (e) =>{
		this.setState({
			status: this.constructor.StateInput,
		})
		scrollToTop()
		e.preventDefault()
	}

	/**
	 * 確認画面 : 仮申込確定
	 */
	onConfirmSubmit = (e) =>{
		postCourseEntries(this.state.select.child_id, this.state.select.class_id).then((data)=>{
			this.props.history.push(`/me/${this.path}/complete.html?course_id=${this.course_id}&course_target_age_id=${this.course_target_age_id}`)
		}).catch( error => {
			this.onError(error)
			e.preventDefault()
			return
		})
		scrollToTop()
		e.preventDefault()
	}

	componentDidMount() {
		super.componentDidMount()
		const courseData = getCourseDetail(this.course_id, this.course_target_age_id)
		courseData.then((data)=>{
			this.setState({
				isDataLoaded: true,
				courseData: data
			})
		})
	}

	onError = (error) => {
		if (error instanceof AuthError) this.onUnauthorizedError()
		else this.errorModal('エラー', error.message)
	}

	renderChild () {

		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else if (this.state.status === this.constructor.StateInput) { // 入力
			return (
				<Select
					state={this.state}
					onError={this.onError}
					onInputSubmit={this.onInputSubmit}
				/>
			)
		} else { // 確認
			return (
				<Confirm
					state={this.state}
					onEditSubmit={this.onEditSubmit}
					onConfirmSubmit={this.onConfirmSubmit}
				/>
			)
		}
	}
}