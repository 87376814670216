import React from 'react'
import AuthorizedPageBase from '../../authorizedpagebase'
import { Link } from 'react-router-dom'

export default class CompleteBase extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get header_title() {
		return (null)
	}
	// ---------- 基本設定ここまで ----------

	renderChild () {
		return (
			<div>
				<section className="form-information">
					<div className="form-information-wrap">
						<div className="form-information__title">
							スクールの仮申し込みが<br className="sp-view"/>完了しました
						</div>
						<div className="form-information__text topnarrow">
							仮申し込み、ありがとうございます！スコップ・スクールの運営事務局側で承認されますと、受講料などの初回費用のお振込について、ご登録いただいたLINE宛にご案内をさせていただきます。お振込の確認ができ次第、申込が正式に完了となります。
						</div>
						<div className="form-information__btn back">
							<Link to="/me/"><span>マイページトップ</span></Link>
						</div>
					</div>
				</section>
			</div>
		)
	}
}