import React from 'react'
import { hideElement, showElement, copyUrl } from '../../../../utils'
import { LINE_URL, NOTE_URL } from '../../../../config'
import InfoModal from '../../../component/common/infoModal'

export default class ShareModal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			report: null,
			child: null,
		}
	}

	get reportUrl (){
		const report = this.state.report
		const url = NOTE_URL + `share/report/?page=report` + encodeURIComponent(`&course_id=${report.course_id}&course_target_age_id=${report.course_target_age_id}&term_id=${report.term_id}&page_token=${report.page_token}`)
		return url
	}

	get copyUrl () {
		const report = this.state.report
		const url = NOTE_URL + `share/report/?page=report&course_id=${report.course_id}&course_target_age_id=${report.course_target_age_id}&term_id=${report.term_id}&page_token=${report.page_token}`
		return url
	}

	show(data) {
		this.setState({
			report: data,
		})

		showElement(this.modal, 0.5)
		showElement(this.modalContent, 0.5, 0.2, true)

		//NOTE: クリップボードコピー対応のブラウザか確認
		window.addEventListener("copy", this.testCopy)
		this.useCopy = document.execCommand("copy")
	}

	testCopy = (e)=>{
		// console.log("testCopy");
		e.clipboardData.setData('text/plain', "")
		e.preventDefault()
		window.removeEventListener("copy", this.testCopy)
	}

	hide() {
		hideElement(this.modalContent, 0.5, 0, true)
		hideElement(this.modal, 0.5, 0.2)
	}

	handleClose = (e) => {
		this.hide()
		e.preventDefault()
	}

	handleCopyUrl = (e) => {
		e.preventDefault()
		const url = this.copyUrl

		if (this.useCopy) {
			copyUrl(url)
			InfoModal.instance.show("クリップボードにコピーしました。")
		} else {
			InfoModal.instance.show("", "お使いのブラウザーでは自動コピーできません。\nお手数ですが手動でコピーお願いします。\n\n" + url)
		}
	}

	handleShareLine = (e) => {
		e.preventDefault()
		if(this.state.report) {
			const shareText = encodeURIComponent(`${this.state.report.child.first_name}さんのスコップ・スクールレポートのシェアページログインURLです。`)
			const shareUrl = this.reportUrl

			window.open(`${LINE_URL.SHARE}${shareText} ${shareUrl}`)
		}
	}

	bottom() {
		return (
			<div className="mypage-modal-bottom">
				<div className="mypage-modal__qrcode__btns">
					<a href="#" onClick={this.handleCopyUrl} ref={node=>this.copyBtn=node}><img src="/assets/images/report/ico-copy.svg" alt="URLをコピー" style={{PointerEvent: "none"}}/><span>URLをコピー</span></a>
					<a href="#" onClick={this.handleShareLine}><img src="/assets/images/report/ico-line.svg" alt="LINEで共有" style={{PointerEvent: "none"}}/><span>LINEで共有</span></a>
				</div>
			</div>
		)
	}

	render() {
		const report = this.props.report
		return (
			<section className="mypage-modal" style={{display: 'none', opacity: 0}} ref={node => this.modal = node}><span className="mypage-modal-bg" onClick={this.handleClose}></span>
				<div className="mypage-modal-wrap" ref={node => this.modalContent = node}>
					<div className="mypage-modal-top">
						<div className="mypage-modal__closebtn"><a href="#" onClick={this.handleClose}><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
						<div className="mypage-modal__title">レポートシェアページURL</div>
						<div className="mypage-modal__passcode">パスコード：{report && report.child.child_profile.passcode}</div>
					</div>
					{this.bottom()}
				</div>
			</section>
		)
	}
}