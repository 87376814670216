import { Application, Container, Graphics, Sprite, Texture, utils } from 'pixi.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

class SchopAnimation {
	constructor(id, schopKey) {
		this.spMaxWidth = 768
		this.isSp = window.innerWidth <= this.spMaxWidth
		this.id = id
		this.schopKey = schopKey
		this.schopFrame = 0
		this.numSchopFrames = 4
		const view = $('.schop-animation.schop0'+this.id+' canvas').get(0)
		const w = this.isSp ? 160 : 110
		const h = this.isSp ? 90 : 56
		this.app = new Application({
			width: w,
			height: h,
			transparent: true,
			view: view,
		})
	}

	destroy() {
		if (this.isUnSupported) return
		// for (let frame=0; frame<=this.numSchopFrames; frame++) {

		// 	if (utils.TextureCache['schop_'+this.schopKey+'_'+frame+'.png']) {
		// 		utils.TextureCache['schop_'+this.schopKey+'_'+frame+'.png'].destroy(true)
		// 		delete utils.TextureCache['schop_'+this.schopKey+'_'+frame+'.png']
		// 	}
		// }
		// this.schopSprite.destroy(true)
		this.app.destroy(true, {children: true, texture:false, baseTexture:false})
		delete this.app
	}

	setup() {
		this.schopSprite = new Sprite(Texture.from('schop_'+this.schopKey+'_1.png'))
		this.app.stage.addChild(this.schopSprite)
		this.$schop = $('.schop-animation.schop0'+this.id)
		gsap.to(this, {
			duration: 1.5,
			schopFrame: 20,
			ease: 'linear',
			scrollTrigger: {
				trigger: '.schop0'+this.id,
				start: 'top 75%',
				// markers: true,
			}, onStart:()=>{
				this.$schop.addClass('show')
			}, onUpdate: this.onUpdate.bind(this)
		})
	}

	onUpdate() {
		const frame = Math.floor(this.schopFrame) % this.numSchopFrames + 1
		this.schopSprite.texture = Texture.from('schop_'+this.schopKey+'_'+frame+'.png')
	}
}

export default class SchopAnimations {
	constructor() {
		if (this.isUnSupported) return

		this.animation1 = new SchopAnimation(1, 1)
		this.animation2 = new SchopAnimation(2, 2)
		this.animation3 = new SchopAnimation(3, 4)
		this.animation4 = new SchopAnimation(4, 3)
		this.animation5 = new SchopAnimation(5, 2)
		if (this.animation1.isSp) {
			if (!utils.TextureCache['/assets/images/top/sp_schop.json_image']) {
				this.animation1.app.loader.add('/assets/images/top/sp_schop.json')
				this.animation1.app.loader.load(this.onLoad.bind(this))
				// utils.TextureCache['/assets/images/top/sp_schop.json_image'].destroy(true)
				// delete utils.TextureCache['/assets/images/top/sp_schop.json_image']
			}
		} else {
			if (!utils.TextureCache['/assets/images/top/pc_schop.json_image']) {
				this.animation1.app.loader.add('/assets/images/top/pc_schop.json')
				this.animation1.app.loader.load(this.onLoad.bind(this))
				// utils.TextureCache['/assets/images/top/pc_schop.json_image'].destroy(true)
				// delete utils.TextureCache['/assets/images/top/pc_schop.json_image']
			}
		}
	}

	get isUnSupported() {
		return !utils.isWebGLSupported()
	}


	destroy() {
		if (this.isUnSupported) return


		this.animation1.app.loader.reset()
		this.animation1.app.loader.destroy()
		this.animation1.destroy()
		this.animation2.destroy()
		this.animation3.destroy()
		this.animation4.destroy()
		this.animation5.destroy()
	}

	onLoad() {
		if (this.isUnSupported) return

		this.animation1.setup()
		this.animation2.setup()
		this.animation3.setup()
		this.animation4.setup()
		this.animation5.setup()
	}

}
