import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'

export default class Banner extends React.Component {

	onBannerRef = (node) => {
		if(!node) return

		gsap.to(node, {
			scrollTrigger: {
				trigger: "#root",
				start: `top -=10`,
				// markers: true,
				onEnter: () => {
					node.classList.remove("hide")
				},
				onLeaveBack: () => {
					node.classList.add("hide")
				}
			}
		})
	}

	render () {
		return (
			<section className="banner hide" ref={this.onBannerRef}>
				{/* NOTE: 端っこが見切れたりしていい画像(bg-)ではないのでこうやってJSX側で２つ指定するしかないか？ */}
				<a href="https://schopschool.jp/search/" target="_blank">
					<picture>
						{/* PC */}
						<source srcSet="/assets/images/common/btn-banner.png" media="(min-width: 769px)"/>
						{/* SP */}
						<img srcSet="/assets/images/common/btn-banner--sp.png"/>
					</picture>
				</a>
			</section>
		)
	}
}