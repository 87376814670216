import React from 'react'
import PageBase from './pagebase'

export default class CookiePolicy extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/cookie.html'
	}

	static get title() {
		return '"Cookieポリシー'
	}

	static get title_en() {
		return 'cookie'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-cookie.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<div>
				<section className="policy">
					<div className="policy__wrap">
						<div className="policy__detail">
							<p className="policy__detail__text">当社は、管理するウェブサイトおよびサービス上において、以下の情報収集モジュールおよびCookieを利用しております。本サイトにおける情報収集モジュールの内容およびCookieの取扱いについて各項目のご確認をお願いいたします。</p>
							<h2 className="policy__detail__title">＜情報収集モジュールについて＞</h2>
							<p className="policy__detail__text">本サイトの機能の実現、および利便性の向上・改善を目的としたアクセス情報解析のため、本サイトではGoogle社が提供するGoogle Analyticsのアクセス解析モジュールを利用することがあります。<br/>Google Analyticsの概要<br/><a href="https://www.google.com/intl/ja/policies/privacy/partners/" target="_blank">https://www.google.com/intl/ja/policies/privacy/partners/</a></p>
							<h2 className="policy__detail__title">＜Cookieについて＞</h2>
							<h2 className="policy__detail__title">1．Cookieとは</h2>
							<p className="policy__detail__text">お客様がコンピュータまたはスマートフォンなどのデバイスを利用してウェブサイトにアクセスした際に保存するデータファイルもしくは類似した技術（Webビーコン等を含む）のことを指します。これを利用することによりご利用のデバイスによるウェブサイト訪問ページや時間などの情報を取得することができます。<br/>なお、Cookieにはお客様個人を識別できる情報は一切含まれておりません。</p>
							<h2 className="policy__detail__title">2．Cookieの利用目的</h2>
							<p className="policy__detail__text">（ア）パスワードの入力の簡略化など、ウェブサイトの利便性向上<br/>（イ）お客様の興味関心にあわせた情報の表示<br/>（ウ）スコップ・スクールウェブサイトのアクセス状況の解析<br/>（エ）ソーシャルメディア等の連携の簡易化<br/>（オ）サービス、商品、お客様接点の開発改善にむけた市場分析および、お客様への提案活動をより精緻に行うための属性情報や興味関心の類推<br/>（カ）行動ターゲティング広告の配信<br/>（キ）不正行為の監視<br/>（ク）第三者企業の（ア）～（キ）の達成を目的とした第三者企業への提供</p>
							<h2 className="policy__detail__title">3．Cookieを用いた第三者企業のツール・サービスの活用</h2>
							<p className="policy__detail__text">スコップ・スクールは、上記目的を遂行するために4.(2)に記載する企業をはじめとした第三者が提供するツール・サービスを活用します。その際は、ツール・サービスを提供する第三者が当グループと同様のCookieを収集する場合があります。</p>
							<h2 className="policy__detail__title">4．Cookieを無効にする方法</h2>
							<p className="policy__detail__text">(1)ブラウザの設定によりCookieを無効にする方法<br/>利用者がCookieのご利用を望まれない場合は、下記の設定により無効化が可能です。なお無効化された場合には、一部のサービスを受けられない場合がありますので、あらかじめご了承ください。<br/>利用者は、ブラウザの設定により、Cookieの受け取りを拒否したり、Cookieを受け取った際に警告を表示させることができます。無効にされた場合には、一部のサービスを受けられない場合がありますので、あらかじめご了承ください。</p>
							<p className="policy__detail__text"><a href="https://support.google.com/chrome/answer/95647?visit_id=637293728711030698-2305832906&amp;p=cpn_cookies&amp;hl=ja&amp;rd=1" target="_blank">Google Chrome</a><br/><a href="https://support.microsoft.com/ja-jp/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Microsoft Internet Explorer</a><br/><a href="https://support.microsoft.com/ja-jp/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank">Microsoft Edge</a><br/><a href="https://support.apple.com/ja-jp/guide/safari/sfri11471/mac" target="_blank">Apple Safari</a></p>
							<p className="policy__detail__text">(2)第三者企業のCookieを無効にする方法<br/>第三者のCookieを介した広告配信等の無効化（オプトアウト）をご希望の場合は、下記の該当企業のサイトより、無効化（オプトアウト）をお願いいたします。</p>
							<p className="policy__detail__text"><a href="https://adssettings.google.com/authenticated" target="_blank">Google広告のオプトアウト</a><br/><a href="https://btoptout.yahoo.co.jp/optout/index.html" target="_blank">Yahoo! JAPAN広告のオプトアウト</a><br/><a href="https://www.facebook.com/policies/cookies/" target="_blank">Facebook広告のオプトアウト</a><br/><a href="https://business.twitter.com/ja/help/ads-policies/product-policies/interest-based-opt-out-policy.html" target="_blank">Twitter広告のオプトアウトページ</a><br/><a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analyticsのオプトアウト</a><br/><a href="https://www.dentsu.co.jp/terms/ddai_optout.html" target="_blank">People Driven DMPのオプトアウト</a></p>
							<p className="policy__detail__text">以　上</p>
						</div>
					</div>
				</section>
			</div>
		)
	}
}