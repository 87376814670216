import { GEO_CORDING_API } from "../config"
import Eventemitter from 'events'

export default class AddressAutofill extends Eventemitter {
	static get UpdateZip () {
		return 'UpdateZip'
	}
	static get UpdateZipError () {
		return 'UpdateZipError'
	}
	constructor(post_code1, post_code2, prefecture_id, button) {
		super()
		this.$post_code1 = $(`input[name="${post_code1}"]`)
		this.$post_code2 = $(`input[name="${post_code2}"]`)
		this.$prefecture_id = $(`select[name="${prefecture_id}"]`)
		this.$button = $(button)
		
		this.$post_code1.on('blur', this.onBlur)
		this.$post_code2.on('blur', this.onBlur)
		this.$button.on('click', this.onClick)
	}

	onExit() {
		this.$post_code1.off('blur', this.onBlur)
		this.$post_code2.off('blur', this.onBlur)
		this.$button.off('click', this.onClick)
	}

	onBlur = (e) => {
		this.check(false)
	}

	onClick = (e) => {
		this.check(true)
		return false
	}

	check(isAlert=false) {
		if (this.$post_code1.val() === "" || this.$post_code2.val() === "") {
			if (isAlert) {
				this.emit(AddressAutofill.UpdateZipError, "住所取得エラー", "住所が入力されていません。")
			}
			return
		}
		this.getAddress(isAlert)
	}

	getAddress = async (isAlert) => {
		const code1 = this.$post_code1.val()
		const code2 = this.$post_code2.val()
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${code1}-${code2}&language=ja&sensor=false&key=${GEO_CORDING_API}`
		const res = await fetch(url)
		const json = await res.json()
		let isError = false


		if (!json.error_message && json.status === "OK") {

			const result = json.results[0].address_components
			let prefecture_id = ""
			let city = ""
			let address1 = ""
			if (result.length === 5) {
				prefecture_id = this.getPrefectureId(result[3].long_name)
				city = result[2].long_name
				address1 = result[1].long_name
			} else if (result.length === 6) {
				prefecture_id = this.getPrefectureId(result[4].long_name)
				city = result[3].long_name + result[2].long_name
				address1 = result[1].long_name
			} else {
				isError = true
			}
			if (!isError) {
				this.emit(AddressAutofill.UpdateZip, prefecture_id, city + address1)
			}
		} else {
			isError = true
		}

		if (isAlert && isError) {
			this.emit(AddressAutofill.UpdateZipError, "住所自動入力エラー", "住所自動入力に失敗しました。\郵便番号を確認して再度お試しいただくか、住所を手動で入力してください。")
		}
	}

	getPrefectureId(target) {
		let prefectureId = 0
		this.$prefecture_id.find('option').each((i, elm)=>{
			if ($(elm).text() === target) {
				prefectureId = $(elm).val()
				return false
			}
		})
		return prefectureId
	}
}