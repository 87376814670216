import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../../authorizedpagebase'
import Auth, { AuthError } from '../../../../../api/auth'
import Student from './student'
import Child from './child'
import { getIsStudent } from '../../../../../utils'
import queryString from 'query-string'
import Loading from '../../../../component/common/loading'
import { recreateChildPasscode } from '../../../../../api/child'
import InfoModal from '../../../../component/common/infoModal'

const STUDENT_FILE = 'txt-studentdetail'
const CHILD_FILE = 'txt-childdetail'

export default class ChildDetail extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/children/detail.html'
	}

	static get title() {
		return 'お子様詳細'
	}

	static get title_en() {
		return 'childdetail'
	}

	static get file_name() {
		return this._file_name
	}

	static set file_name(str) {
		this._file_name = str
	}

	static get header_title() {
		if (this._file_name === undefined) this._file_name = CHILD_FILE
		return (
			<div className="header__title">
				<span>
					<img src={`/assets/images/me/${this.file_name}.svg`} alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isStudent: false,
			isDataLoaded: false,
		}
		const qs = queryString.parse(this.props.location.search);
		this.child_id = parseInt(qs.child_id)
	}


	/**
	 * Auth情報を参照して子供情報を取得
	 */
	authorizeDidComplete() {
		super.authorizeDidComplete()
		this.childData = Auth.getChildById(this.child_id)
		if (this.childData !== undefined) {
			// 生徒子供判定
			const isStudent = getIsStudent(this.childData) //boolean 生徒ならtrue
			this.constructor.file_name = (isStudent) ? STUDENT_FILE : CHILD_FILE
			this.setState({
				...this.state,
				isDataLoaded: true,
				isStudent,
			})
		} else {
			this.history.push('/me/children/')
		}
	}

	/**
	 * パスコードを再生成します
	 */
	changePasscode = async () => {
		Loading.show()
		try {
			const id = this.childData.id
			const success = await recreateChildPasscode(id)
			await Auth.updateUser()
			this.childData = Auth.getChildById(id)
			this.setState({
				...this.state,
			})
		} catch(error) {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal("パスワード生成エラー", error.message)
			return
		}
		Loading.hide()
		InfoModal.instance.show('パスワードを更新しました。')
	}

	renderChild () {
		const childData = this.childData
		let isStudent = this.state.isStudent
		// isStudent = false // 強制的に子供に
		if (childData === undefined) return (null)
		if (isStudent) return <Student childData={childData} changePasscode={this.changePasscode} />
		else return <Child childData={childData} />
	}
}