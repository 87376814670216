import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FormBase from './formbase'
import { zenkaku2Hankaku } from '../../../utils'


export default class TextInput extends FormBase {

	static propTypes = {
		title: PropTypes.string,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isStudent: PropTypes.bool,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		inputs: PropTypes.arrayOf(PropTypes.shape({
			placeholder: PropTypes.string,
			name: PropTypes.string,
			name_ja: PropTypes.string,
			priority: PropTypes.number,
			value: PropTypes.string,
			prefix: PropTypes.string,
			postfix: PropTypes.string,
			error: PropTypes.object,
			targetRef: PropTypes.func,
		})),
	}


	static defaultProps = {
		title: '',
		required: false,
		isConfirming: false,
		isDisplay: true,
		onChange: () => {},
		onBlur: () => {},
		inputs: [
			{
				placeholder: '',
				name: '',
				name_ja: '',
				priority: 0,
				value: '',
				prefix: null,
				postfix: null,
				error: {},
				targetRef: () => {},
			}
		]
	}

	onBlur = (e) => {
		e.target.value = zenkaku2Hankaku(e.target.value)
		this.props.onBlur(e)
	}

	get form() {
		const colClass = 'form-edit__input-form-'+this.props.inputs.length+'col'
		return (
			<>
			{this.props.inputs.map((item, i) => {
				const error = (item.error && item.error.isError) ? ' error': ''
				return (
					<div className={colClass} key={i}>
						<input
							className={error}
							type="text"
							placeholder={item.placeholder}
							name={item.name}
							data-name_ja={item.name_ja}
							data-priority={item.priority}
							value={item.value}
							onChange={this.props.onChange}
							onBlur={this.onBlur}
							ref={item.targetRef}
						/>
						{(item.error && item.error.isError) &&
							<div className="form-edit__input-error">
								{item.error.message[0]}
							</div>
						}
					</div>
				)
			})}
			</>
		)
	}

	get confirm() {
		return (
			<>
				{this.props.inputs.map((item, i) => {
					return item.value
				})}
			</>
		)
	}

}