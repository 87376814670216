import { WP_API } from '../config'

/* 
 * 説明会一覧取得
 */
export async function getBriefingList(count=-1) {
    const res = await fetch(WP_API.BRIEFING + "?count=" + encodeURIComponent(count))
    const json = await res.json()
    if(!res.ok) throw new Error(json.message)
    return json
}
