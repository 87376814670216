import React from 'react'
import { Link } from 'react-router-dom'
import gsap from "gsap/gsap-core"
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

export default class TrimImage extends React.Component {

	static _instance = null
	static get instance() {
		return this._instance
	}

	get isSp() {
		return window.innerWidth < 780
	}

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isShown: false,
			file: null,
		}
		TrimImage._instance = this
		this.resetParams()
	}

	resetParams() {
		this.margin = {x:60, y:60}
		this.scale = 1
		this.minScale = 1
		this.maxScale = 1
		this.offset = {x:0, y:0}
		this.imageSize = {x:0, y:0}
		this.pointerDownPosition = {x:0, y:0}
		this.pointerDownOffset = {x:0, y:0}
		this.sliderValue = 0
		this.sliderDownX = 0
		this.sliderDownOffset = {x:0, y:0}
		this.touchStartPosition = {x:0, y:0}
		this.touchStartScale = 1
		this.touchStartOffset = {x:0, y:0}
	}

	componentDidUpdate() {
		if (!this.state.isShown) return
		if (this.state.file) {
			this.loadImage(this.state.file)
		}
	}

	/**
	 * 画像をロードします
	 * @param {DataURL} file
	 */
	loadImage(file) {
		this.image = new Image()
		const reader = new FileReader()
		reader.onload = (e) => {
			this.image.src = e.target.result
		}
		this.image.onload = (e) => {
			this.setup()
		}
		reader.readAsDataURL(file)
	}

	/**
	 * UIを初期化します
	 */
	setup() {

		if (this.isSp) {
			this.canvas.width = window.innerWidth
			this.canvas.height = window.innerHeight
			this.circleSize = 315
			this.margin.x = (this.canvas.width - this.circleSize) / 2
			this.margin.y = (this.canvas.height - this.circleSize) / 2
		} else {
			this.margin = {x:60, y:60}
			this.canvas.width = 800
			this.canvas.height = 800
			this.circleSize = 800 - 60 * 2
		}
		// キャンバスのサイズに合わせて画像のスケールを設定
		this.imageSize = {x:this.image.width, y:this.image.height}
		this.minScale = this.circleSize / this.image.width
		if (this.imageSize.y * this.minScale < this.circleSize) {
			this.minScale = this.circleSize / this.imageSize.y
		}


		this.maxScale = this.minScale * 4
		this.scale = this.minScale
		this.offset = { x:this.margin.x, y:this.margin.y }
		// UIのイベント追加
		this.canvas.addEventListener('mousedown', this.onPointerDown)
		this.canvas.addEventListener('touchstart', this.onTouchStart, { passive: false })
		this.sliderKnob.addEventListener('mousedown', this.onSliderDown)
		// 画像を描画
		this.drawImage()
	}

	/**
	 * スライダーのポインターが触れた時に呼び出されます
	 * @param {event} e
	 */
	onSliderDown = (e) => {
		this.sliderDownX = e.clientX
		this.sliderDownValue = this.sliderValue
		this.sliderDownOffset = {...this.offset}
		window.addEventListener('mousemove', this.onSliderMove)
		window.addEventListener('mouseup', this.onSliderUp)
	}

	/**
	 * スライダーのドラッグ中に呼び出されます
	 * @param {event} e
	 */
	onSliderMove = (e) => {
		const style = window.getComputedStyle(this.sliderBar)
		const w = parseInt(style.getPropertyValue('width'))
		const sliderOffset = (e.clientX - this.sliderDownX) / w
		this.sliderValue = this.sliderDownValue + sliderOffset
		if (this.sliderValue < 0) this.sliderValue = 0
		if (this.sliderValue > 1) this.sliderValue = 1
		this.sliderKnob.style.transform = 'translateX('+(this.sliderValue*w)+'px)'
		//
		this.scale = this.minScale + (this.sliderValue * (this.maxScale - this.minScale))
		const size = {x:this.imageSize.x * this.scale, y:this.imageSize.y * this.scale}
		const beforeScale = this.minScale + (this.sliderDownValue * (this.maxScale - this.minScale))
		const beforeSize = {x:this.imageSize.x * beforeScale, y:this.imageSize.y * beforeScale}
		this.offset = {
			x: this.sliderDownOffset.x + (beforeSize.x - size.x) / 2,
			y: this.sliderDownOffset.y + (beforeSize.y - size.y) / 2,
		}
		this.drawImage()
	}

	/**
	 * スライダーのドラッグ終了時に呼び出されます
	 * @param {event} e
	 */
	onSliderUp = (e) => {
		window.removeEventListener('mousemove', this.onSliderMove)
		window.removeEventListener('mouseup', this.onSliderUp)
	}

	/**
	 * ポインターが触れた時に呼び出されます
	 * @param {event} e
	 */
	onPointerDown = (e) => {
		e.preventDefault()
		this.pointerDownPosition = {x: e.clientX, y: e.clientY}
		this.pointerDownOffset = {...this.offset}
		window.addEventListener('mousemove', this.onPointerMove)
		window.addEventListener('mouseup', this.onPointerUp)
	}

	/**
	 * ポインターが動いた時に呼び出されます
	 * @param {event} e
	 */
	onPointerMove = (e) => {
		e.preventDefault()
		this.offset.x = this.pointerDownOffset.x + (e.clientX - this.pointerDownPosition.x)
		this.offset.y = this.pointerDownOffset.y + (e.clientY - this.pointerDownPosition.y)
		this.drawImage()
	}

	/**
	 * ポインターが離れた時に呼び出されます
	 * @param {event} e
	 */
	onPointerUp = (e) => {
		e.preventDefault()
		window.removeEventListener('mousemove', this.onPointerMove)
		window.removeEventListener('mouseup', this.onPointerUp)
	}

	//------------------------------ Touch Events ------------------------------

	/**
	 * タッチスクリーン端末でタッチされたときに呼び出されます
	 * @param {event} e
	 */
	onTouchStart = (e) => {
		e.preventDefault()
		window.addEventListener('touchmove', this.onTouchMove, { passive: false })
		window.addEventListener('touchend', this.onTouchEnd, { passive: false })
		this.touchStartPosition = {x:e.touches[0].clientX, y:e.touches[0].clientY}
		this.touchStartOffset = {...this.offset}
		this.touchStartScale = this.scale

		// 3本指になったら終わらせる
		// 2本指まではタッチ開始時の位置を記録する
		// 2本指タッチの場合はタッチ開始時の2本の指の距離を記録する
	}

	/**
	 * タッチスクリーン端末でタッチ位置が移動されたときに呼び出されます
	 * @param {event} e
	 */
	onTouchMove = (e) => {
		e.preventDefault()
		this.offset.x = this.touchStartOffset.x + (e.touches[0].clientX - this.touchStartPosition.x)
		this.offset.y = this.touchStartOffset.y + (e.touches[0].clientY - this.touchStartPosition.y)
		if (e.scale != undefined) {
			this.scale = this.touchStartScale * e.scale
			if (this.scale < this.minScale) this.scale = this.minScale
			if (this.scale > this.maxScale) this.scale = this.maxScale
		}
		const size = {x:this.imageSize.x * this.scale, y:this.imageSize.y * this.scale}
		const beforeSize = {x:this.imageSize.x * this.touchStartScale, y:this.imageSize.y * this.touchStartScale}
		this.offset = {
			x: this.offset.x + (beforeSize.x - size.x) / 2,
			y: this.offset.y + (beforeSize.y - size.y) / 2,
		}
		this.drawImage()
		// 1本指タッチの場合は単純に移動距離を取得して画像を移動させる
		// 2本指タッチの場合は2本の指それぞれの開始時からの移動距離の平均をとる
		// 2本指タッチの場合は2本の指の距離を開始時の距離と比較してスケールに反映させる
	}

	/**
	 * タッチスクリーン端末でタッチが終了したときに呼び出されます
	 * @param {event} e
	 */
	onTouchEnd = (e) => {
		e.preventDefault()
		window.removeEventListener('touchmove', this.onTouchMove)
		window.removeEventListener('touchend', this.onTouchEnd)
	}


	/**
	 * キャンバスに画像を描画します
	 */
	drawImage() {
		// 現在のスケールの画像サイズ
		const w = this.imageSize.x * this.scale
		const h = this.imageSize.y * this.scale
		if (this.scale < this.minScale) this.scale = this.minScale
		if (this.scale > this.maxScale) this.scale = this.maxScale
		// ドラッグできる範囲の限界
		if (this.offset.x > this.margin.x) this.offset.x = this.margin.x
		if (this.offset.y > this.margin.y) this.offset.y = this.margin.y
		if (this.offset.x < -(w-(this.canvas.width-this.margin.x))) this.offset.x = -(w-(this.canvas.width-this.margin.x))
		if (this.offset.y < -(h-(this.canvas.height-this.margin.y))) this.offset.y = -(h-(this.canvas.height-this.margin.y))
		// 描画
		this.ctx = this.canvas.getContext('2d')
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
		this.ctx.drawImage(this.image, this.offset.x, this.offset.y, w, h)
	}


	/**
	 * モーダルを表示します
	 * @param {DataURL} file
	 * @param {Function} onComplete
	 */
	show(file, onComplete) {
		this.onComplete = onComplete
		this.setState({
			isShown: true,
			file: file,
		})
	}

	/**
	 * モーダルを非表示にします
	 */
	hide() {
		//
		if (this.canvas) {
			this.canvas.removeEventListener('mousedown', this.onPointerDown)
			this.canvas.addEventListener('touchstart', this.onTouchStart)
		}
		window.removeEventListener('mousemove', this.onPointerMove)
		window.removeEventListener('mouseup', this.onPointerUp)
		window.removeEventListener('touchmove', this.onTouchMove)
		window.removeEventListener('touchend', this.onTouchEnd)
		//
		if (this.sliderKnob) {
			this.sliderKnob.removeEventListener('mousedown', this.onSliderDown)
		}
		window.removeEventListener('mousemove', this.onSliderMove)
		window.removeEventListener('mouseup', this.onSliderUp)
		this.resetParams()
		this.setState({ isShown: false })
	}

	/**
	 * 閉じるボタンをクリックしたときのハンドラです
	 * @param {event} e
	 */
	handleClose = (e) => {
		e.preventDefault()
		this.hide()
	}

	/**
	 * 決定ボタンをクリックした時のハンドラです
	 * @param {event} e
	 */
	handleDecide = (e) => {
		e.preventDefault()
		const canvas = document.createElement('canvas')
		canvas.width = this.canvas.width - this.margin.x * 2
		canvas.height = this.canvas.height - this.margin.y * 2
		const ctx = canvas.getContext('2d')
		ctx.drawImage(this.canvas, -this.margin.x, -this.margin.y)
		this.onComplete(canvas.toDataURL())
		this.hide()
	}

	/**
	 * HTMLをレンダリングします
	 */
	render () {
		if (!this.state.isShown) {
			return null
		}
		return (
			<section className="form-thumbnail">
				<div className="form-thumbnail-wrap"><span className="form-thumbnail__mask"></span>
					<div className="form-thumbnail__closebtn"><a href="#" onClick={this.handleClose}>
							<picture>
								<source srcSet="/assets/images/common/btn-close-wh.svg" media="(max-width: 768px)"/><img src="/assets/images/common/btn-close.svg" alt="閉じる"/>
							</picture></a></div>
					<div className="form-thumbnail__pct"><canvas ref={node => this.canvas = node} width="800" height="800" /></div>
					<div className="form-thumbnail__slider pc-view">
						<div className="form-thumbnail__slider__minus"><img src="/assets/images/form/ico-minus.svg"/></div>
						<div className="form-thumbnail__slider__plus"><img src="/assets/images/form/ico-plus.svg"/></div>
						<div className="form-thumbnail__slider__bar" ref={node => this.sliderBar = node}>
							<div ref={node => this.sliderKnob = node} className="form-thumbnail__slider__bar__btn"></div>
						</div>
					</div>
					<div className="form-thumbnail__btn"><a href="#" onClick={this.handleDecide}><span>決定</span></a></div>
				</div>
			</section>
		)
	}
}