import React from 'react'
import { Link } from 'react-router-dom'
import AuthorizedPageBase from '../../authorizedpagebase'
import Auth, { AuthError } from '../../../../api/auth'
import { getCourseClasses, getEntries } from '../../../../api/myClass'
import { formatDateToJa, getIsStudent } from '../../../../utils'

import moment from 'moment'
import { GENDER } from '../../../../config'

export default class ChildrenList extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/children/'
	}

	static get title() {
		return 'お子様管理'
	}

	static get title_en() {
		return 'management'
	}
	static get header_title() {
		return (
			<div className="header__title">
				<span className="management">
					<img src="/assets/images/me/txt-management.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}



	authorizeDidComplete() {
		super.authorizeDidComplete()
		// 受講中のデータ
		const courseList = getCourseClasses()
		courseList.then((data)=>{
			this.setState({
				...this.state,
				courseList: data.my_course_classes,
			})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})

		// 予約中データ
		const entryList = getEntries()
		entryList.then((data)=>{
			this.setState({
				...this.state,
				entryList: data.entries,
			})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})

		// 両方のAPIを待つ
		Promise.all([courseList, entryList]).then(()=>{
			this.setState({ isDataLoaded: true }) // ローディング完了
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
	}

	getChild(child) {

		const date = formatDateToJa(child.birth_date)
		const gender = GENDER[child.gender_id]
		return (
				<div className="mypage-list-block pcdetail">
					<div className="mypage-list__pcwrap-detail top">
						<div className="mypage-list__user-top">
							<div className="mypage-list__user__onlyname">{child.first_name}さん<span>{date}　{gender}</span></div>
						</div>
						<div className="mypage-list__btn">
							<Link to={`/me/children/detail.html?child_id=${child.id}`}>
								<span>詳細</span>
							</Link></div>
					</div>
				</div>
		)
	}

	getStudent(child, courses, entries) {
		return (
				<div className="mypage-list-block pcdetail">
					<div className="mypage-list__pcwrap-detail top">
						<div className="mypage-list__user-top">
							<div className="mypage-list__user__thumb">
								<img src={child.child_profile.icon_url} alt=""/>
							</div>
							<div className="mypage-list__user__name">{child.first_name}さん</div>
						</div>
						<div className="mypage-list__btn">
							<Link to={`/me/children/detail.html?child_id=${child.id}`}>
								<span>詳細</span>
							</Link>
						</div>
					</div>

					{courses.length > 0 &&
						<div className="mypage-list__detail">
							<div className="mypage-list__detail__title"><span>受講中・予約中スクール</span></div>
							{courses.map((course, key) => {
								return (
									<div className="mypage-list__detail__school" key={key}>
										<dl>
											<dt>
												<div className="mypage-list__detail__school__thumb"><img src={course.course_target_age_main_image_url} alt=""/></div>
											</dt>
											<dd>{course.course_name}<br/>対象年齢：{course.target_age_label}</dd>
										</dl>
									</div>
								)
							})}
						</div>
					}
					{entries.length > 0 &&
						<div className="mypage-list__detail">
							<div className="mypage-list__detail__title"><span>申し込み中スクール</span></div>
							{entries.map((entry, key) => {
								return (
									<div className="mypage-list__detail__school" key={key}>
										<dl>
											<dt>
												<div className="mypage-list__detail__school__thumb"><img src={entry.course_target_age_main_image_url} alt=""/></div>
											</dt>
											<dd>{entry.course_name}<br/>対象年齢：{entry.course_target_age_label}</dd>
										</dl>
									</div>
								)
							})}
						</div>
					}
				</div>
		)
	}

	renderChild () {
		const children = Auth.user.children
		return (
			<section className="mypage-list">
				<ul>
					{
						children.map((child, key) => {
							const courses = this.state.courseList.filter( item => item.child.id == child.id)
							const entries = this.state.entryList.filter( item => item.child.id == child.id)
							const isStudent = getIsStudent(child)  //boolean 生徒ならtrue
							return (
								<li  key={key}>
									{isStudent ?
										this.getStudent(child, courses, entries)
										:
										this.getChild(child)
									}
								</li>
							)
						})
					}

					<li>
						<div className="mypage-list-block">
							<div className="mypage-list__add"><Link to="/me/children/add.html">お子様を追加する</Link></div>
						</div>
					</li>
				</ul>
			</section>
		)
	}
}