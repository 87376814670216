import React from 'react'
import { Link } from 'react-router-dom'
import { getIsOnlineImage } from '../../../../utils'

export default class MyEntryCourseClass extends React.Component {
	render() {
		const data = this.props.data
		const isRegular = data.course_school == 'regular'  //boolean
		return(
			<li>
				<Link className="mypage-list-block" to={`/courses/${data.course_id}/course_target_ages/${data.course_target_age_id}`}>
					<div className="sp-view">
						<div className="mypage-list__schooltitle">
							{isRegular ? '' : `【${data.course_short_term_school_name}】` }
							{data.course_name}
						</div>
						<div className="mypage-list__user">
							<div className="mypage-list__user__thumb"><img src={data.child.child_profile.icon_url} alt=""/></div>
							<div className="mypage-list__user__name">{data.child.first_name}さん</div>
							<div className="mypage-list__user__status">
								{getIsOnlineImage(data.course_is_online)}
							</div>
						</div>
					</div>
					<div className="pc-view mypage-list-block-line1">
						<div className="mypage-list__user">
							<div className="mypage-list__user__thumb"><img src={data.child.child_profile.icon_url} alt=""/></div>
							<div className="mypage-list__user__name">{data.child.first_name}さん</div>
						</div>
						<div className="mypage-list__schooltitle">
							{isRegular ? '' : `【${data.course_short_term_school_name}】` }
							{data.course_name}
						</div>
						<div className="mypage-list__user__status">
							{getIsOnlineImage(data.course_is_online)}
							</div>
					</div>
				</Link>
			</li>
		)
	}
}