import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

export default class CreativityModule {

	constructor() {
		this.spMaxWidth = 768
		this.isSp = window.innerWidth <= this.spMaxWidth
		this.$target = $('.js_creativity')
		this.$detail = $('.js_creativity_detail')
		this.$skills = $('.js_skills')
		const top = this.$detail.offset().top - this.$target.offset().top - this.$target.height() / 2
		const tween = gsap.to('.js_creativity', {
			y: top,
			duration: 1.0,
			ease: 'cubic.inOut',
			scrollTrigger: {
				trigger: '.js_creativity',
				start: 'top 10%',
				end: 'top 11%',
				toggleActions: 'play none reverse none',
				// markers: true,
			}, onUpdate: ()=>{
				if (!tween) return
				if (tween.ratio >= 0.5) {
					this.$target.addClass('skill')
					this.$skills.addClass('active')
				} else {
					this.$target.removeClass('skill')
					this.$skills.removeClass('active')
				}
			}

		})
	}

}
