import { Application, Container, Graphics, Sprite, Texture } from 'pixi.js'
import { scrollToElement } from '../utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollTrigger)

export default class CompanyModule {

	constructor() {
		this.setupMessages()
		this.setupPhotos()
	}

	//
	setupMessages() {
		this.$lists = $('.js_message_btn').parent('li')
		this.$buttons = $('.js_message_btn')
		this.$messages = $('.js_message')
		this.$link = $('.js_message_link')
		this.$link.on('click', (e)=>{
			const $a = $(e.currentTarget)
			const href = $a.attr('href')
			this.changeMessage(this.$buttons.filter('[href="'+href+'"]'))
			scrollToElement('#pMessage')
			return false

		})
		this.$buttons.on('click', (e)=>{
			const $a = $(e.currentTarget)
			this.changeMessage($a)
			return false
		})

	}

	changeMessage($a) {
		const href = $a.attr('href')
		this.$messages.removeClass('current')
		this.$lists.removeClass('current')
		$(href).addClass('current')
		$a.parent().addClass('current')
	}

	// 写真の切り替え
	setupPhotos() {
		this.$photos = $('.js_photo')
		this.$photos.find('dt').css('overflow', 'hidden')
		for (let i=0; i<this.$photos.length; i++) {
			const photo = this.$photos[i]
			const img = $(photo).find('img').get(0)
			const container = $(photo).find('img').parent().get(0)
			const tween = gsap.to(container, {
				rotateY: 180,
				duration: 0.35,
				ease: 'sine.inOut',
				onUpdate: function() {
					if (this.ratio > 0.5) {
						gsap.set(img, {rotateY:180})
					} else {
						gsap.set(img, {rotateY:0})
					}
				},
				scrollTrigger:{
					trigger: photo,
					start: 'top 50%', // アニメーション開始位置
					end: 'top 51%',
					toggleActions: 'play none reverse none',
					// markers: true,
				}
			})
		}
	}

}
