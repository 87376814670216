import { API } from '../config'
import { parse } from 'querystring'
import { getApiKey, getSymbol } from './utils'
import Auth, { AuthError } from './auth'

/**
 * ユーザー登録
 * @param {Object} parent
 * @param {Array} children
 */

/**
 * ユーザー編集
 * @param {Object} form
 */
export async function editUser(form) {
	const postData = form
	const apiKey = await getApiKey()
	const res = await fetch(API.ME, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Accept' : 'application/json',
			'Content-Type' : 'application/json',
		}),
		method: 'PUT',
		body: JSON.stringify(postData)
	})

	const json = await res.json()
	if (!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}

/**
 * ユーザ削除
 */
export async function deleteUser() {
	let isError = false
	const apiKey = await getApiKey()
	const res = await fetch(API.ME, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
			'Accept' : 'application/json',
			'Content-Type' : 'application/json',
		}),
		method: 'DELETE'
	})
	const json = await res.json()

	if (!res.ok) {
		isError = true
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return {
		isError,
		data: json
	}
}

/*
 * ユーザ情報取得
*/
export async function getUserInformation() {
	const apiKey = await getApiKey()
	const res = await fetch(API.ME, {
		headers: new Headers({
			'x-api-key' : apiKey,
			'Authorization': 'Token token='+Auth.accessToken,
		}),
		method: 'GET'
	})
	const json = await res.json()
	if (!res.ok) {
		if (res.status == 401) {
			throw new AuthError()
		}
		throw new Error(json.message)
	}
	return json
}