import React from "react"
import { Link } from 'react-router-dom'
import Auth from "../../../api/auth"
import { getIsStudent } from '../../../utils'

/**
 * 子供選択のセレクトボックス
 */
export default class MypageSelect extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
	}

	render () {
		if(Auth.user.children.length == 0) return null
		return (
			<div className="mypage-list__select">
				<select name="test" value={this.props.child_id} ref={node => this.select = node} onChange={()=> {this.props.autoRedirect(this.select.value)}}>
					{Auth.user.children.map((value, key)=> {
						//生徒なら追加
						if(getIsStudent(value)) {
							return (
								<option key={key} value={value.id}>{value.first_name}さん</option>
							)
						}
					})}
				</select>
			</div>
		)
	}
}