import React from 'react'
import { Link } from 'react-router-dom';
import PageBase from '../pagebase';
import { PUBLISH_STATE, SCHOOL, ENTRY_STATE, REAL_SCHOOL_DATA } from '../../../config';
import { getCourseList } from '../../../api/course';
import Wave from '../../component/common/wave';
import { getIsOnlineImage } from '../../../utils';

export default class ListBase extends PageBase {

	constructor(props, school, publish_state=PUBLISH_STATE.published) {
		super(props)

		this.school = school
		this.publish_state = publish_state
		this.isRegular = (this.school === SCHOOL.regular ) ? true : false
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}

	async componentDidMount() {
		super.componentDidMount()
		const courseList = getCourseList(this.school, this.publish_state)
		if (this.sync) {
			await courseList.then(this.onDataLoaded)
		} else {
			courseList.then(this.onDataLoaded)
		}
	}

	onDataLoaded = (data) => {
		this.setState({
			isDataLoaded: true,
			courseList: data,
		})
	}

	/**
	 *
	 * @param {Object} courseData
	 * @param {string} key
	 * @param {boolean} isRealSchool
	 */
	_getCourseCard(courseData, key, isRealSchool = false) {
			const isRegular = this.isRegular
			return (
			<li key={key}>
				<div className="top-course__list__pct">
					{courseData.main_image_url &&
						<img src={courseData.main_image_url} alt={courseData.name}/>
					}
					<div className="top-course__list__pct__icon">
						{getIsOnlineImage(courseData.is_online)}
					</div>
					<Wave />
				</div>

				{/* レギュラーのみ */}
				{isRegular && courseData.name_image_url &&
					<div className="top-course__list__title socialcreativecourse"><img src={courseData.name_image_url} alt={courseData.name}/></div>
				}

				{/* 短期のみ */}
				{!isRegular && courseData.short_term_school_name &&
					<div className="top-course__list__schooltitle">{courseData.short_term_school_name}</div>
				}

				{/* 短期のみ */}
				{!isRegular && courseData.name &&
					<div className="top-course__list__title">{courseData.name}</div>
				}

				{courseData.introduction &&
					<div className="top-course__list__text">{courseData.introduction}</div>
				}
{/*  */}
				{/* <div className="top-course__list__btn-2col">
					{courseData.course_target_ages.map((target, k) => {
							let detailLink = `/courses/${courseData.id}/course_target_ages/${target.id}/`
							if(isRealSchool) detailLink = "/"
							return (
								<div key={k}>
									<div className="top-course__list__btn-2col-top">
										<p>{target.target_age_label}</p>

										
										{target.entry_state === 'in_preparation' &&
											<span className="top-course__list__btn-2col-icon standby">準備中</span>
										}
										{target.entry_state === 'accepting' &&
											<span className="top-course__list__btn-2col-icon entry-remain">受付中&ensp;<span className="small">残席あり</span></span>
										}
										{target.entry_state === 'cancel_waiting' &&
											<span className="top-course__list__btn-2col-icon entry-cancel">受付中&ensp;<span className="small">空席待ち</span></span>
										}
										{target.entry_state === 'closed' &&
											<span className="top-course__list__btn-2col-icon end">募集終了</span>
										}
									</div>
									<div className="top-course__list__btn-2col-bottom">
										<Link to={detailLink}><span>詳細</span></Link>
									</div>
								</div>
								)
						})
					}
				</div> */}
				
			</li>
		)
	}

	/**
	 * リード文表示用
	 */
	getLeadContents(isAllNonAccepting) {
		return (null)
	}

	renderChild () {

		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const courseList = this.state.courseList
			const isRegular = this.isRegular

			//[短期用] 募集中が一つでもあるか検索
			let isAllNonAccepting = true
			for(let i in courseList.courses) {
				const course = courseList.courses[i]
				for(let j in course.course_target_ages) {
					const age = course.course_target_ages[j]
					if(age.entry_state == 'accepting' || age.entry_state == 'in_preparation') isAllNonAccepting = false
				}
			}

			// レギュラーコースは古い順
			if(isRegular) {
				courseList.courses.sort((course1, course2) => {
					return course1.id - course2.id
				})
			}else {
				courseList.courses.sort((course1, course2) => {
					return course2.id - course1.id
				})
			}
			return (
				<section className="school-index">
					{/* リード文表示 */}
					{this.getLeadContents(isAllNonAccepting)}
					<div className="school-index__wrap">
						<div className="top-course__list">
							<ul>
								{/* TODO: ここを抽象化してリアルスクールも入れれるようにする */}
								{courseList.courses.map((course, key) => {
										return this._getCourseCard(course, key)
									})
								}
								{/* リアルスクール */}
								{/* {this._getCourseCard(REAL_SCHOOL_DATA, "", true)} */}
							</ul>
						</div>
					</div>
					{(!isRegular && this.publish_state === PUBLISH_STATE.published) &&
						<div className="school-index__btn"><Link to="/archive/"><span>過去のスクール一覧</span></Link></div>
					}
				</section>
			)
		}
	}
}