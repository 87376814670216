import React from 'react'
import { Link } from 'react-router-dom'
import { deleteUser } from '../../../../api/users'
import Auth, { AuthError } from '../../../../api/auth'
import AuthorizedPageBase from '../../authorizedpagebase'
import AccountDeleteComplete from './deleteComplete'
import Loading from '../../../component/common/loading'

export default class AccountDelete extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/account/delete.html'
	}

	static get title() {
		return 'アカウント削除'
	}

	static get title_en() {
		return 'accountdelete'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/me/txt-accountdelete.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)

		this.state = {
			...this.state,
			agree: false,
		}
	}

	/**
	 * 同意チェックボックス変更時
	 * @param {object} e
	 */
	onChangeAgree = (e) => {
		const field = e.target.name
		const value = e.target.value

		this.setState({
			agree: e.target.checked
		})
	}

	onBuckBtnClick = (e)=>{
		this.props.history.goBack()
		e.preventDefault()
	}

	/**
	 * 削除実行
	 */
	submit = async () => {
		Loading.show()
		try {
			const result = await deleteUser()
		} catch(e) {
			if (e instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', e.message)
			Loading.hide()
			return
		}

		Loading.hide()

		if (!result.isError) {
			// this.infoModal("アカウントを削除しました。")
			// 正常時はログアウトしてトップへ
			Auth.destroy()
			window.location.href = AccountDeleteComplete.path
		} else {
			this.errorModal("削除失敗", result.data.messages[0])
		}
	}

	renderChild () {
		// 削除実行画面
		return (
			<div>
				<section className="mypage-information">
					<div className="mypage-information-wrap">
						<div className="mypage-information__text">アカウント削除すると、スコップ・スクールに関する全ての情報が削除されます。本当に退会されますか？</div>
						<div className="mypage-information__checkbox">
							<label style={{cursor : 'pointer'}}>
								<span className="mypage-information__checkbox__label">データの削除に同意する</span>
								<input type="checkbox" onChange={this.onChangeAgree}/>
								<div className="mypage-information__checkbox__toggle"></div>
							</label>
						</div>
						<div className={`mypage-information__btn${this.state.agree ? "": " disable"}`}>
							<a href="#" onClick={this.submit}><span>アカウント削除</span></a>
						</div>
						<div className="mypage-information__btn back">
							<a href="#" onClick={this.onBuckBtnClick}>
								<span>戻る</span>
							</a>
						</div>
					</div>
				</section>
			</div>
		)
	}
}