import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getNewsList } from '../../../api/news'

export default class NewsList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}

	componentDidMount() {
		const newsList = getNewsList(3, 1,"top")//初期値は3、ここで件数変更　テスト用に第3引数にページタイプを追加
		newsList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				newsList: data,
			})
		})
	}

	render() {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const newsList = this.state.newsList
			return (
				<div className="top-news__list">
					<ul>
						{newsList.news.map((value, key)=>{
							let ts = moment.unix(value.data)
							let date = ts.format("yyyy.MM.DD")
							return (
								<li key={key}>
									<Link to={value.link}>
										<span className="top-news__list__date">{date}</span>
										<span className="top-news__list__title">{value.title}</span>
									</Link>
								</li>
							)
						})}
					</ul>
				</div>
			)
		}
	}
}