import React from 'react'
import PropTypes from 'prop-types'
import TextInput from './textinput'
import { formatYMD } from '../../../utils'
import Select from './select'


export default class DateInput extends TextInput {

	static propTypes = {
		title: PropTypes.string,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		value: PropTypes.string,
		pastYears: PropTypes.number,
		futureYears: PropTypes.number,
		inputs: PropTypes.arrayOf(PropTypes.shape({
			placeholder: PropTypes.string,
			name: PropTypes.string,
			name_ja: PropTypes.string,
			priority: PropTypes.number,
			value: PropTypes.string,
		})),
	}

	static defaultProps = {
		title: '',
		required: true,
		isConfirming: false,
		isDisplay: true,
		pastYears: 20,
		futureYears: 20,
		value: formatYMD(new Date),
		onChange: () => {},
		onBlur: () => {},
	}

	get years() {
		let years = []
		const year = new Date().getFullYear()
		const s = year - this.props.pastYears
		const e = year + this.props.futureYears
		for (let y=s; y<=e; y++) {
			years.push(y)
		}
		return years
	}

	get months() {
		return [1,2,3,4,5,6,7,8,9,10,11,12]
	}

	get days() {
		return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
	}

	get year() {
		const year = this.props.value.split('-')[0]
		if(!isNaN(year)) {
			return year
		}
		return ''
	}

	get month() {
		const month = this.props.value.split('-')[1]
		if(!isNaN(month)) {
			const monthNum = Number(month)  //一桁数の場合、行頭の0を削除 ex) 01
			return monthNum
		}
		return ''
	}

	get day() {
		const day = this.props.value.split('-')[2]
		// '', '03', '3'
		if(!isNaN(day)) {
			const dayNum = Number(day)  //一桁数の場合、行頭の0を削除 ex) 01
			return dayNum
		}
		return ''
	}

	get confirm() {
		return (
			<div>
				{this.year}年 {this.month}月 {this.day}日
			</div>
		)
	}

	handleChange = (e) => {
		let y = e.target.name == 'birth_date_year' ? e.target.value : this.year
		let m = e.target.name == 'birth_date_month' ? e.target.value : this.month
		let d = e.target.name == 'birth_date_day' ? e.target.value : this.day

		const value = `${y}-${m}-${d}`

		this.props.onChange({
			...e,
			target: {
				name: this.props.name,
				value: value
			},
		})
	}

	get form() {
		return(
			<>
				<div className="form-edit__input-form-birth-y">
					<Select
						name='birth_date_year'
						name_ja={this.props.selects.birth_date_year.name_ja}
						priority={this.props.selects.birth_date_year.priority}
						value={this.year}
						noWrap={true}
						onChange={this.handleChange}
						onBlur={this.props.onBlur}
						options={this.years}
						needEmpty={true}
						targetRef={this.props.selects.birth_date_year.targetRef}
						error={this.props.selects.birth_date_year.error}
					/>
				</div>
				<div className="form-edit__input-form-birth-text">年</div>
				<div className="form-edit__input-form-birth-m">
					<Select
						name='birth_date_month'
						name_ja={this.props.selects.birth_date_month.name_ja}
						priority={this.props.selects.birth_date_month.priority}
						value={this.month}
						noWrap={true}
						onChange={this.handleChange}
						onBlur={this.props.onBlur}
						options={this.months}
						needEmpty={true}
						targetRef={this.props.selects.birth_date_month.targetRef}
						error={this.props.selects.birth_date_month.error}
					/>
				</div>
				<div className="form-edit__input-form-birth-text">月</div>
				<div className="form-edit__input-form-birth-d">
					<Select
						name='birth_date_day'
						name_ja={this.props.selects.birth_date_day.name_ja}
						priority={this.props.selects.birth_date_day.priority}
						value={this.day}
						noWrap={true}
						onChange={this.handleChange}
						onBlur={this.props.onBlur}
						options={this.days}
						needEmpty={true}
						targetRef={this.props.selects.birth_date_day.targetRef}
						error={this.props.selects.birth_date_day.error}
					/>
				</div>
				<div className="form-edit__input-form-birth-text">日</div>
			</>
		)
	}
}