import React from 'react'
import { TROPHY_DATA } from '../../../../config'
import { isAcrossYear, getYear, getDateJa, getDayOfTheWeekJa } from '../../../../utils'

export default class ClassReport extends React.Component {

	constructor(props) {
		super(props)
	}

	/**
	 * 授業ごとの写真を取得
	 * @param {obj} subject
	 */
	countAndGetPhotos = (subject) => {
		return (
			<>
				{/* 写真複数の時 */}
				{subject.photo_urls.length > 1 &&
					<div className="report__timeline__baseblock__pctlist">
						<ul>
						{subject.photo_urls.map((photo, i) => {
							return (
								<li key={i}>
									<a href="#" onClick={this.props.onModalOpen}>
										<img src={photo} />
									</a>
								</li>
								)
							})}
						</ul>
					</div>
				}

				{/* 写真一枚だけの時 */}
				{subject.photo_urls && subject.photo_urls.length == 1 &&
					<div className="report__timeline__baseblock__pct">
					<a href="#" onClick={this.props.onModalOpen}>
					<img src={subject.photo_urls[0]} />
					</a>
					</div>
				}

				{subject.photo_urls.length == 0 &&
					<p className="report__timeline__baseblock__odai__nophoto"><span data-ruby="とうこう">投稿</span>した<span data-ruby="しゃしん">写真</span>がないよ</p>
				}
			</>
		)
	}

	/**
	 * レポートの中身（吹き出し単位）
	 * @param {obj} schedule
	 * @param {num} index
	 */
	getReportBubble = (schedule, index) => {
		const date = getDateJa(schedule.started_at)
		const dayOfTheWeek = getDayOfTheWeekJa(schedule.started_at)

		const styles_side = index % 2 == 1 ? "is_right": ""  //右寄せ左寄せどっちか
		const styles_attendance = schedule.attendance_state == "absent" ? "is_absence" : ""  //出席ステータス

		return (
			<React.Fragment key={index}>

				<div className={`report__timeline__baseblock ${styles_side} ${styles_attendance}`}>
					<div className="report__timeline__baseblock__date">{date} {dayOfTheWeek}</div>
					<div className="report__timeline__baseblock-wrap">
						<div className="report__timeline__baseblock__title">{schedule.period_content} 第{index+1}回<span className="report__timeline__baseblock__title__wave"></span></div>
						<div className="report__timeline__baseblock__text">{schedule.period_report_comment}</div>

						{/* 最後ならはなまる */}
						{index == this.props.report.report_schedules.length -  1 &&
						<span className="report__timeline__baseblock__hanamaru"></span>
						}

						{schedule.report_camera_subjects &&
							schedule.report_camera_subjects.map((subject, i) => {
								return (
									<React.Fragment key={i}>
										{/*  */}
										<div className="report__timeline__baseblock__odai">
											<div className="report__timeline__baseblock__odai__icon">お題{i+1}</div>
											<div className="report__timeline__baseblock__odai__title">{subject.content}</div>
										</div>
										{/* 写真の枚数によってスタイル切り分け */}
										{this.countAndGetPhotos(subject)}
										<hr/>
									</React.Fragment>
									)
							})
						}
					</div>
				</div>

			</React.Fragment>
		)
	}

	getReportTrophy = () => {

	}

	render() {
		const report = this.props.report
		let prevSchedule = null
		return (
			<>
				{report.report_schedules.map((schedule, i) => {
					const year = getYear(schedule.started_at)
					const date = getDateJa(schedule.started_at)
					const dayOfTheWeek = getDayOfTheWeekJa(schedule.started_at)

					// 年を跨いだことのハンドリング
					const isCross = isAcrossYear(prevSchedule, schedule.started_at)
					prevSchedule = schedule.started_at
					return (
						<React.Fragment key={i}>
							{/* 年度のラベル */}
							{( i == 0 || isCross ) &&
								<div className="report__timeline__year">{year}年</div>
							}

							{/* 吹き出し */}
							{this.getReportBubble(schedule, i)}

							{/* トロフィー */}
							{schedule.trophies.length > 0 &&
								schedule.trophies.map((trophy, key) => {
									return (
										// トロフィーないので未実装
										<div className="report__timeline__trophyget" key={key}>
											<div className="report__timeline__trophyget__date">{date}{dayOfTheWeek}</div>
											{/* NOTE: image_urlが無効 */}
											<div className="report__timeline__trophyget__pct"><img src={TROPHY_DATA[trophy.id].get_image}/></div>
											<div className="report__timeline__trophyget__desc" dangerouslySetInnerHTML={{__html: trophy.description}}></div>
										</div>
									)
								})
							}
						</React.Fragment>
					)
				})}
			</>
		)
	}
}