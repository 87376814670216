import React from 'react'
import Auth from '../../api/auth'
import PageBase from './pagebase'

export default class Login extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/login.html'
	}

	static get title() {
		return 'ログイン'
	}

	static get title_en() {
		return ''
	}
	// ---------- 基本設定ここまで ----------

	renderChild() {
		return (
			<section className="form-information">
				<div className="form-information-wrap pcnarrow">
					<div className="form-information__title">スクールへの参加申し込みを<br/>するにはログインをしてください</div>
					<div className="form-information__linebtn">
						<a href="#" onClick={() => {Auth.login(false)} }>
							<span>LINEでログイン</span>
						</a>
					</div>
					<div className="form-information__title-wline"><span>はじめての方は</span></div>
					<div className="form-information__text small">スコップ・スクールでは、LINEログイン機能を使って、サイトと連携し、コースの参加申し込みや、重要なお知らせの配信などを行います。</div>
					<div className="form-information__linebtn">
					<a href="#" onClick={() => {Auth.login(true)} }>
							<span>LINEで新規登録</span>
						</a>
					</div>
				</div>
			</section>
		)
	}
}