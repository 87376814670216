import EventEmitter from 'events';
import gsap from 'gsap'


export default class SlideFlick extends EventEmitter {
	constructor($target, autoPlay = true) {
		super()
		this.$target = $target
		this.$ul = this.$target.children( "ul" )
		this.$li = this.$ul.find( "li" )
		if (this.$ul.width() < this.$target.width()) return
		if (this.$li.length <= 1) return
		this.length
		this.timer
		this.autoPlayDuration = 8000
		this.width = this.$li.eq(1).offset().left - this.$li.eq(0).offset().left
		this.height = this.$li.height()
		this.x = 0
		this.index = 0
		this.lastMoveX
		this.startX
		this.lastX
		this.startY
		this.startOffsetX
		this.minX
		this.stoppedFlg
		this.direction // -1: undefined / 0: vertical / 1: horizontal
		this.lastTime
		this.onTimerFlg = false
		this.enabled = false
		this.autoPlay = autoPlay
		this.isPlaying = false

		this._onTouchMove = this.onTouchMove.bind(this)
		this._onTouchEnd = this.onTouchEnd.bind(this)
		this.init()
	}

	get numContents() {
		return this.$li.length
	}

	/**
	 * 初期化
	 */
	init() {

		//幅を設定
		this.length = this.$li.length

		if( this.length <= 1 ) {
			this.enabled = false
			return
		}
		this.enabled = true
		this.minX = -this.width * (this.length-1)
		//最低値を設定
		this.$ul.on( "touchstart", this.onTouchStart.bind(this) )
		//
		if (this.autoPlay) this.play()
	}

	/**
	 * ページャーを設定
	 * @param {jQuery Object} $pager
	 * @param {string} activeClass
	 */
	setPager($pager, activeClass='active') {
		this.$pager = $pager
		this.pagerActiveClass = activeClass
	}

	/**
	 * 自動切り替え開始
	 */
	play() {
		this.isPlaying = true
		clearInterval( this.timer )
		this.timer = setInterval( this.onTimer.bind(this), this.autoPlayDuration )
	}

	/**
	 * 自動切り替え停止
	 */
	stop() {
		this.isPlaying = false
		clearInterval( this.timer )
	}

	/**
	 * 自動切り替えタイマー
	 */
	onTimer() {
		this.onTimerFlg = true
		clearInterval( this.timer )
		if( !this.enabled ) return
		this.index++
		if( this.index >= this.length ) this.index = 0
		this.move()
	}

	next() {
		if( !this.enabled ) return
		var hasNext = true
		clearInterval( this.timer )
		this.index -= 1
		if( this.index >= this.length - 1 ) {
			this.index = this.length - 1
			hasNext = false
		}
		this.move()
		return hasNext
	}

	prev() {
		if( !this.enabled ) return
		var hasPrev = true
		clearInterval( this.timer )
		this.index--
		if( this.index <= 0 ) {
			this.index = 0;
			hasPrev = false
		}
		this.move()
		return hasPrev
	}


	onTouchStart(event) {
		if( !this.enabled ) return
		clearInterval( this.timer )
		this.$ul.stop(true)
		var touchEvent = event.originalEvent.touches[0]
		this.startX = this.lastX = touchEvent.pageX
		this.startY = touchEvent.pageY
		this.startOffsetX = this.startX - this.x
		this.moved = false
		this.direction = -1
		this.$ul.off( "touchmove", this._onTouchMove )
		this.$ul.off( "touchend", this._onTouchEnd )
		this.$ul.on( "touchmove", this._onTouchMove )
		this.$ul.on( "touchend", this._onTouchEnd )
		this.lastTime = 0
		this.lastMoveX = 0
	}

	onTouchMove(event) {
		if( !this.enabled ) return
		var touchEvent = event.originalEvent.touches[0]
		if ( this.direction < 0 ) {
			if ( Math.abs(touchEvent.pageX - this.startX) * 1.5 < Math.abs(touchEvent.pageY - this.startY) ) {
				this.direction = 0
				this.$ul.off( "touchmove", this._onTouchMove )
				return
			}
		}
		if (!this.moved && touchEvent.pageX == this.lastX) return
		this.moved = true
		this.direction = 1
		event.preventDefault()
		if (touchEvent.pageX == this.lastX) return
		this.lastMoveX = touchEvent.pageX - this.lastX
		this.lastX = touchEvent.pageX
		this.x = this.lastX - this.startOffsetX
		if ( this.x > 0 ) {
			var p = (this.x / this.width)
			var curved = (1 - Math.pow( 1 - p, 3 )) * 0.35
			this.x *= curved
		} if ( this.x < this.minX ) {
			var p = ( (this.minX-this.x) / this.width )
			var curved = (1 - Math.pow( 1 - p, 3 )) * 0.35
			this.x = this.minX + ( this.x - this.minX ) * curved
		}
		gsap.to(this.$ul, {'x': this.x, duration:0.1, ease:'cubic.out'})
		// var currentX = parseInt(this.$ul.css(""))
		// var toX = currentX + ( this.x - currentX ) * 0.3
		// this.$ul.stop(true).css( "marginLeft", toX + "px" ).animate( { "marginLeft":this.x, "opacity":1 }, 100, "easeOutCubic" )
		if ( Math.abs(this.lastMoveX) > 10 ) this.lastTime = +new Date()
	}

	onTouchEnd(event) {
		if( !this.enabled ) return
		if (!this.moved) return
		if (event.cancelable) event.preventDefault()
		let p = -this.x / this.width
		this.stoppedFlg = ( +new Date() - this.lastTime > 200 ) ? true : false
		if ( !this.stoppedFlg ) {
			if ( this.lastMoveX < 0 ) p += 0.5
			else p -= 0.5
		}
		this.index = Math.round(p)
		if ( this.index < 0 ) this.index = 0
		else if( this.index >= this.length ) this.index = this.length - 1
		this.move()
		this.$ul.off( "touchmove", this._onTouchMove )
		this.$ul.off( "touchend", this._onTouchEnd )

	}

	move() {
		clearInterval( this.timer )
		if( !this.enabled ) return
		this.emit('change', this.index)

		//目的地まで移動
		var outsideFlg = false
		if ( this.x > 0 || this.x < this.minX ) outsideFlg = true
		this.x = -this.index * this.width
		if ( outsideFlg ) {
			gsap.to(this.$ul, {x:this.x, duration:0.25, ease:'back.out'})
			// this.$ul.stop(true).animate( { "marginLeft" : this.x + "px" }, 250, "easeOutBack", ()=>{
			// 	this.$ul.parent().css("backgroundColor","rgba(0, 0, 0, 0)")
			// });
		} else if ( this.onTimerFlg ) {
			gsap.to(this.$ul, {x:this.x, duration:0.5, ease:'expo.inOut'})
			// this.$ul.stop(true).animate( { "marginLeft" : this.x + "px" }, 400, "easeInOutCubic" )
			this.onTimerFlg = false
		} else if ( this.stoppedFlg ) {
			gsap.to(this.$ul, {x:this.x, duration:0.5, ease:'expo.out'})
			// this.$ul.stop(true).animate( { "marginLeft" : this.x + "px" }, 500, "easeInOutExpo" )
		} else {
			gsap.to(this.$ul, {x:this.x, duration:0.325, ease:'expo.out'})
			// this.$ul.stop(true).animate( { "marginLeft" : this.x + "px" }, 325, "easeOutExpo" )
		}

		//タイマー開始
		if ( this.isPlaying ) this.timer = setInterval( this.onTimer.bind(this), this.autoPlayDuration )
	}
}

