import {API} from '../config'
import Auth from './auth'
import {getApiKey} from './utils'

/**
 * Course一覧取得
 */
export async function getNavigator(navigator_id) {
	const apiKey = await getApiKey()
	const url = API.NAVIGATOR.replace(/{navigator_id}/, navigator_id)
	
	const res = await fetch(url, {
		headers: new Headers({'x-api-key' : apiKey})
	});
	const json = await res.json();
	if (!res.ok) throw new Error(json.message);
	return json
}

