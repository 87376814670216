export default class Menu {

	static instances = []
	static closeOthers(instance) {
		this.instances.map( item => {
			if (item != instance) item.close()
		})
	}

	constructor(menu, openBtn, closeBtn) {
		Menu.instances.push(this)
		this.$menu = $(menu)
		this.$openBtn = $(openBtn)
		this.$closeBtn = $(closeBtn)
		this.onOpenBtnClicked = this.open.bind(this)
		this.onCloseBtnClicked = this.onCloseBtnClick.bind(this)
		this.onBgClicked = this.close.bind(this)
		this.$openBtn.on('click', this.onOpenBtnClicked)
		this.$closeBtn.on('click', this.onCloseBtnClicked)
		this.$menu.on('click', this.onBgClicked)
	}

	destroy() {
		this.$openBtn.off('click', this.onOpenBtnClicked)
		this.$closeBtn.off('click', this.onCloseBtnClicked)
		this.$menu.off('click', this.onBgClicked)
		this.$openBtn = null
		this.$closeBtn = null
		this.$menu = null
	}

	open(e) {
		if (this.timer) clearTimeout(this.timer)
		Menu.closeOthers(this)
		this.$menu.addClass('open')
		return false
	}

	onCloseBtnClick(e) {
		this.$menu.removeClass('open')
		return false
	}

	close(e, delay=0) {
		if (e && e.originalEvent && e.originalEvent.target != this.$menu.get(0)) return true
		if (this.timer) clearTimeout(this.timer)
		this.timer = setTimeout(this._close.bind(this), delay)
		return false
	}

	_close() {
		this.$menu.removeClass('open')
	}
}

