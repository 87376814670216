import React from 'react'
import { Link } from 'react-router-dom'
import MypageTab from '../../../component/common/mypagetab'
import Wave from '../../../component/common/wave'
import MyClassBase from './myClassBase'
import MypageSelect from '../../../component/common/mypageSelect'
import { getEntries } from '../../../../api/myClass'
import Auth from '../../../../api/auth'
import { getIsOnlineImage } from '../../../../utils'

export default class MyClassApplication extends MyClassBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/myclass/application/'
	}

	static get title() {
		return '受講済み'
	}

	static get title_en() {
		return ''
	}

	static get key() {
		return "application"
	}
	// ---------- 基本設定ここまで ----------
	componentDidMount() {
		super.componentDidMount()
	}

	loadCourseData() {
		return getEntries()
	}

	getCourseList () {
		const courseListAll = this.state.courseList
		let courseList = []
		if(courseListAll.entries && courseListAll.entries.length){
			courseList = courseListAll.entries.filter( item => item.child.id == this.state.child_id)
		}
		if(courseList && courseList.length == 0) {
			return super.nothingData()
		}
		return (
			<ul>
				{
					courseList.length > 0 && courseList.map((courseClass, key) => {
						if(courseClass.child.id == this.state.child_id){
							return (
								<li key={key}>
									<div className="mypage-list-block pcdetail">
										<div className="mypage-list__pct">
											<img src={courseClass.course_target_age_main_image_url} alt=""/>
											<Wave />
										</div>
										<div className="mypage-list__schooltitle-detail">
											{courseClass.course_name}
											<small>対象年齢：{courseClass.course_target_age_label}</small>
										</div>
										<div className="mypage-list__lead">{courseClass.course_target_age_sales_copy}</div>
										<div className="mypage-list__description">
											<div className="mypage-list__description__title">{courseClass.course_class_name}</div>
											<div className="mypage-list__description__list">
												<dl>
													<dt>日程概要</dt>
													<dd>{courseClass.course_class_description}</dd>
												</dl>
											</div>
										</div>
										<div className="mypage-list__pcwrap-detail">
											<div className="mypage-list__user-detail">
												<div className="mypage-list__user__thumb">
													<img src={courseClass.child.child_profile.icon_url} alt=""/>
												</div>
												<div className="mypage-list__user__name">{courseClass.child.first_name}さん</div>
												<div className="mypage-list__user__status">
												{getIsOnlineImage(courseClass.course_is_online)}
												</div>
											</div>
											<div className="mypage-list__btn">
											<Link to={`/courses/${courseClass.course_id}/course_target_ages/${courseClass.course_target_age_id}/`}><span>コース詳細</span></Link>
											</div>
										</div>
										{/* NOTE: 次回授業情報は予約中にはいらない */}
									</div>
								</li>
							)
						} else {
							return<></>
						}
					}
					)
				}
			</ul>
		)
	}
}