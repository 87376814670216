import { WP_API } from '../config'

/* 
 * バナー一覧取得
*/
// export async function getBannerList(count=-1) {
export async function getBannerList(count=1) {
    
    // const res = await fetch(WP_API.BANNER + "?count=" + encodeURIComponent(count))
    // const res = await fetch("https://ack-design.net/member/goto/scop_test_api/banner.php")
    const res = await fetch(WP_API.BANNER + "?count=" + encodeURIComponent(count))
    const json = await res.json()
    // const json = await fetch("http://ack-design.net/member/goto/scop_test_api/banner.json?count=" + encodeURIComponent(count))
    if(!res.ok) throw new Error(json.message)
    return json
}