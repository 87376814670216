import React from 'react'
import { Link } from 'react-router-dom'
import { LINE_URL } from '../../../config'
import AuthorizedPageBase from '../authorizedpagebase'


export default class Quit extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/quit.html'
	}

	static get title() {
		return '申し込みのキャンセル/休会/退会 アカウント削除について'
	}

	static get title_en() {
		return 'quit'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="aboutdelete">
					<picture>
						<source srcSet="/assets/images/me/txt-aboutdelete--sp.svg" media="(max-width: 768px)" />
						<img src="/assets/images/me/txt-aboutdelete.svg" alt={this.title} />
					</picture>
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	renderChild () {
		return (
			<div>
				<section className="mypage-information">
					<div className="mypage-information-wrap">
						<div className="mypage-information__subtitle">申し込みのキャンセル</div>
						<div className="mypage-information__text">・申し込みのキャンセルについては、LINEにてご連絡ください。</div>
						<div className="mypage-information__btn">
							<a href="#" onClick={() => window.open(LINE_URL.MESSAGE)}><span>LINEでお問い合わせ</span></a>
						</div>
					</div>
					<div className="mypage-information-wrap">
						<div className="mypage-information__subtitle">休会について</div>
						<div className="mypage-information__text">・休会する場合は、休会を希望する月の前月15日までに、電話又はLINEチャットでご連絡をください。システム処理上、休会希望のご連絡が前月15日を過ぎると翌月まで在籍扱いとなり、翌月の受講費や教材費を請求させていただくことになります。<br/>・タームの途中で休会される場合、教材費などは返金できませんので、あらかじめご承知おきください。<br/>・休会中は、毎月のスクールシステム費のみご請求させていただきます。スクールシステム費をお支払いいただくことで、受講プログラムの籍を保護します。<br/>・休会中も、短期スクールの優先応募や会員価格での参加などの会員特典は受けられます。<br/>・休会が可能な期間は、スコップ・スクールが承認した場合を除き、原則2か月とします。</div>
						<div className="mypage-information__btn">
							<a href="#" onClick={() => window.open(LINE_URL.MESSAGE)}><span>LINEでお問い合わせ</span></a>
						</div>
					</div>
					<div className="mypage-information-wrap">
						<div className="mypage-information__subtitle">退会について</div>
						<div className="mypage-information__text">・退会する場合は、退会を希望する月の前月15日までに、電話又はLINEチャットでご連絡をください。システム処理上、退会希望のご連絡が15日を過ぎると翌月まで在籍扱いとなり、翌月の受講費・スクールシステム費・教材費を請求させていただくことになります。<br/>・タームの途中で退会される場合、教材費などの諸経費は返金できませんので、あらかじめご承知おきください。<br/>・退会後もスコップ・スクールノートの閲覧はできますが、予告なしに変更又は閲覧を中止する場合があります。<br/>・退会後、再度入会（レギュラースクールを受講）する際は、再度入会金が発生します。</div>
						<div className="mypage-information__btn">
							<a href="#" onClick={() => window.open(LINE_URL.MESSAGE)}><span>LINEでお問い合わせ</span></a>
						</div>
					</div>
					<div className="mypage-information-wrap">
						<div className="mypage-information__subtitle">アカウント削除について</div>
						<div className="mypage-information__text">・スコップ・スクールが定める手続きを行うことで、アカウントを削除することができます。アカウントの削除を行うためには、アカウントに紐づいている全ての生徒が、レギュラースクールから退会する必要があります。<br/>・アカウントの削除後は、スコップ・スクールの提供する全てのサービスの利用ができなくなり、会員および準会員としての一切の権利を失います。<br/>・アカウントの削除後に、再度、スコップ・スクールのサービスの利用を希望する場合には、改めて新規アカウント登録を行う必要があります。<br/>・アカウント登録時に、当社が取得したお客様の個人情報について削除を希望される場合には、<a href="mailto:mailto:info@schopschool.com">info@schopschool.com</a>までメールにてご連絡ください。当社において適切であると判断した場合には合理的な範囲で対応させていただきます。</div>
						<div className="mypage-information__btn">
							<Link to="/me/account/delete.html"><span>アカウント削除にすすむ</span></Link>
						</div>
					</div>
				</section>
			</div>
		)
	}
}