import React from 'react'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../../utils';

export default class Header extends React.Component {

	constructor(props) {
		super(props);
	}

	onClick = (e) => {
		scrollToTop()
	}

	render () {
		return (
			<section className="header">
				<div className="header__wrap">
					<div className="header__logo"><Link onClick={this.onClick} to="/"><img src="/assets/images/common/pct-logo.svg" alt="SCHOP 幸せをつかむ創造力"/></Link></div>
					{this.props.header_title}
				</div>
			</section>
		)
	}
}