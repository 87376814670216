import React from "react"
import { Link , BrowserRouter,  Route , Switch ,useLocation} from 'react-router-dom'
import ScrollLink from '../../component/common/scrolllink'
import Menu from "../../../modules/menu"
import { scrollToTop,scrollToElement } from "../../../utils"

export default class SideMenu extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this.menu = new Menu('.js_menu', '.js_menu_open_btn', '.js_menu_close_btn')
	}

	onClick = (e) => {
		// console.log(e)
		
		// scrollToTop()
		// scrollToElement($(this.props.to))
		// const { hash } = useLocation();
		this.menu.close(e,200);
		// scrollToElement($(this.props.to))
	}
	// Example() {
	// 	const [count, setCount] = useState(0);

	// 	// Similar to componentDidMount and componentDidUpdate:
	// 	useEffect(() => {
	// 		// Update the document title using the browser API
	// 		document.title = `You clicked ${count} times`;
	// 	});

	// 	return (
	// 		<div>
	// 		<p>You clicked {count} times</p>
	// 		<button onClick={() => setCount(count + 1)}>
	// 			Click me
	// 		</button>
	// 		</div>
	// 	);
	// }
	render () {
		// const pathname = useLocation().pathname;
		// console.log(useLocation())
		if (window.location.href.indexOf("ie.html") >= 0) {
			return null
		}
		return (
			<section className="menu">
					<div className="menu__btn"><a className="js_menu_open_btn"><img src="/assets/images/common/btn-menu.svg" alt="メニュー"/></a></div>
					<div className="menu__list js_menu">
						<div className="menu__list__wrap">
							<div className="menu__list__head">
								<div className="menu__list__head__logo">
									<Link to="/">
										<img src="/assets/images/common/pct-logo.svg" alt="SCHOP 幸せをつかむ創造力"/>
									</Link>
								</div>
								<div className="menu__list__head__closebtn">
									<a className="js_menu_close_btn" href="#">
										<img src="/assets/images/common/btn-menu-close.svg" alt="閉じる"/>
									</a>
								</div>
							</div>
							<div className="menu__list__main">
								<ul>
									<li><Link onClick={this.onClick} to="/">トップ</Link></li>
									<li>
										<span>スコップ・スクールについて</span>
										<ul>
											{/* <li><Link onClick={this.onClick} to="/regularschool/">レギュラースクール</Link></li> */}
											{/* <li><a href="https://schopschool.jp/search/" target="_blank">全国のスコップ・スクール</a></li> */}
											{/* <li><a href="/courses/38/course_target_ages/44/">テスト</a></li> */}
											{/* <li><Link onClick={this.onClick} to="/#topConcept">スコップ・スクールコンセプト</Link></li> */}
											
											<Switch>
												<Route exact path="/">
													{/* <li><Link onClick={this.onClick} to="#topConcept">コンセプト</Link></li> */}
													{/* <li><Link onClick={this.onClick} to="#topConcept">コンセプト</Link></li> */}
													<li onClick={this.onClick}><ScrollLink to="#topConcept">コンセプト</ScrollLink></li>
													<li onClick={this.onClick}><ScrollLink to="#topClass">プログラム例</ScrollLink></li>
													<li onClick={this.onClick}><ScrollLink to="#topFeature">スクールの特徴</ScrollLink></li>
													<li onClick={this.onClick}><ScrollLink to="#topAbout">身につく力</ScrollLink></li>
												</Route>
												<Route exact path="">
													<li><Link onClick={this.onClick} to="/#topConcept">コンセプト</Link></li>
													<li><Link onClick={this.onClick}  to="/#topClass">プログラム例</Link></li>
													<li><Link onClick={this.onClick}  to="/#topFeature">スクールの特徴</Link></li>
													<li><Link onClick={this.onClick}  to="/#topProgram">身につく力</Link></li>
												</Route>
											</Switch>
											{/* <li><Link onClick={this.onClick}  to="/shortschool/">短期スクール</Link></li> */}
											{/* <li><Link onClick={this.onClick}  to="/archive/">過去のスクール</Link></li> */}
											{/* <li><Link onClick={this.onClick}  to="/archive/">具体授業例</Link></li>
											<li><Link onClick={this.onClick}  to="/archive/">1回のプログラム進行例</Link></li> */}
											{/* <li><Link onClick={this.onClick}  to="/archive/">1回のプログラム進行例</Link></li> */}
											{/* <li><ScrollLink to="/#topConcept">ビジョン</ScrollLink></li> */}
											{/* <li><Link onClick={this.onClick}  to="/about.html">身につく力</Link></li> */}
										</ul>
									</li>
									<li>
										<span>全国の教室</span>
										<ul>
											{/* <li><Link onClick={this.onClick} to="/about.html">スコップ・スクールで身につく力</Link></li> */}
											<li><a href="https://schopschool.jp/search/" target="_blank">教室一覧</a></li>
											{/* <li><Link onClick={this.onClick} to="/faq.html">よくあるご質問</Link></li> */}
										</ul>
									</li>
								</ul>
							</div>
							{/* <div className="menu__list__banner">
								<div className="menu__list__banner__lead">スコップ・スクールの申し込みフローは<br/>下記からご確認ください</div>
								<div className="menu__list__banner__link">
									<Link onClick={this.onClick} to="/briefing/">
										<picture>
											<source srcSet="/assets/images/common/pct-banner-briefing--sp.png" media="(max-width: 768px)" />
											<img src="/assets/images/common/pct-banner-briefing.png" alt="説明会の申し込みはこちら"/>
										</picture>
										</Link>
									</div>
							</div> */}
							<div className="menu__list__sub">
								<ul>
									<li><Link onClick={this.onClick} to="/company.html">会社概要</Link></li>
									<li><Link onClick={this.onClick} to="/news/">ニュース</Link></li>
									{/* <li><Link onClick={this.onClick} to="/report/">活動レポート</Link></li> */}
									<li><Link onClick={this.onClick} to="/privacy.html">個人情報保護方針</Link></li>
									{/* <li><Link onClick={this.onClick} to="/privacypolicy.html">個人情報の取り扱いについて</Link></li> */}
									<li><Link onClick={this.onClick} to="/cookie.html">Cookieポリシー</Link></li>
									{/* <li><Link onClick={this.onClick} to="/law.html">特定商取引法に基づく表記</Link></li> */}
									{/* <li><a href="#" target="_blank">コラム</a></li> */}
									<li><a href="https://forms.gle/njiVAARy4BoM1DCp7" target="_blank">お問い合わせ</a></li>
								</ul>
							</div>
						</div>
					</div>
				</section>
		)
	}
}