import React from 'react'
import PageBase from '../pagebase';
import { getBriefingList } from '../../../api/briefing';
import Wave from '../../component/common/wave';
import { Link } from 'react-router-dom';

export default class Briefing extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/briefing/'
	}

	static get title() {
		return '説明会'
	}

	static get title_en() {
		return 'briefing'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-briefing--flow.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
		}
	}

	componentDidMount() {
		super.componentDidMount()
		const briefingList = getBriefingList(-1)
		briefingList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				briefingList: data,
			})
		})
	}

	renderChild () {
		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const briefingList = this.state.briefingList
			return (
				<section className="briefing">
					<div className="briefing__wrap">
						<div className="briefing__pct">
							<picture>
								<source srcSet="/assets/images/briefing/pct-top--sp.jpg" media="(max-width: 768px)" />
								<img src="/assets/images/briefing/pct-top.jpg"/>
							</picture>
							<Wave />
						</div>
						<div className="briefing__flow">
							<ul className="briefing__flow__figure">
								<li className="briefing__flow__figure__item">
									<figure className="figure">
										<img className="figure--img" src="/assets/images/briefing/ico-briefing.svg" alt="説明会参加" />
										<div className="figure__textblock">
											<figcaption className="figure--caption">オンライン説明会</figcaption>
											<p className="figure--caption--detailed">
												スコップ・スクール/プログラムのご説明を致します。
											</p>
										</div>
									</figure>
								</li>
								<li className="briefing__flow__figure__item">
									<figure className="figure">
										<img className="figure--img" src="/assets/images/briefing/ico-experience.svg" alt="体験会参加" />
										<div className="figure__textblock">
											<figcaption className="figure--caption">オンライン体験会</figcaption>
											<p className="figure--caption--detailed">
												本申し込みの前に、お子様に体験会の受講をお願いしております。
											</p>
										</div>
									</figure>
								</li>
								<li className="briefing__flow__figure__item">
									<figure className="figure">
										<img className="figure--img" src="/assets/images/briefing/ico-join.svg" alt="入会申込" />
										<div className="figure__textblock">
											<figcaption className="figure--caption">入会申込</figcaption>
											<p className="figure--caption--detailed">
												レギュラースクールの申し込みを頂きます。
											</p>
										</div>
									</figure>
								</li>
								<li className="briefing__flow__figure__item">
									<figure className="figure">
										<img className="figure--img" src="/assets/images/briefing/ico-meeting.svg" alt="面談" />
										<div className="figure__textblock">
											<figcaption className="figure--caption">オンライン面談</figcaption>
											<p className="figure--caption--detailed">
												スコップ・スクールでの継続した学びにつながるガイダンスをさせていただきます。
											</p>
										</div>
									</figure>
								</li>
							</ul>
							<p className="briefing__flow--arrow"></p>
							<p className="briefing__flow--join">
								<a className="link">スコップ・スクール入会</a>
							</p>
						</div>
						<div className="briefing__lead">
							<p>スコップ・スクールのメソッドやコースコンセプト、不確定な時代を生きる子どもたちへの想いなどをお伝えするオンライン説明会を定期的に行っております。この機会に、たくさんのお話をさせて頂きたいと考えておりますので、是非ご参加ください。通年で通っていただくレギュラースクールの受講料や開催曜日などもお伝えさせていただきます。また、体験プログラムに参加を希望される方も、まずはこちらの説明会にご参加ください。</p>
						</div>
						<div className="briefing__list">
							<div className="briefing__list__title">日程一覧</div>
							<ul>
								{
									briefingList.briefings.map((value, key) => {

										let type = value.online ? "オンライン" : "オフライン"
										let typeCls = value.online ? "" : " off"
										let endCls = value.accept ? "" : "briefing__list__end"

										return (
											<li key={key} className={endCls}>
												<span className={`briefing__list__type${typeCls}`}>{type}</span>
												<span className="briefing__list__date"><span>{value.date}</span></span>
												<span className="briefing__list__status"></span>
											</li>
										)
									})
								}
							</ul>
							<div className="briefing__list__btn"><a href="https://pro.form-mailer.jp/lp/3bde6574215385" target="_blank"><span>説明会申し込み</span></a></div>
						</div>
						<div className="briefing__caution">
							<p>※説明会申込にあたっての注意事項</p>
							<p>●説明会受講にあたり、各ご家庭で、通信環境（Wi-Fiなど）とデバイス（PC、タブレット）を用意していただきます。事前にご準備をお願いいたします。<br/>※スマートフォンでの受講も可能ですが、画面が小さいため推奨いたしません。</p>
							<p>●説明会の配信では「Zoom」を使用いたします。事前に、使用デバイスにアプリをダウンロードしていただく必要があります。ご登録いただいたメールアドレスに、説明会前日までにZoomのミーティングIDをお送りいたしますので、そちらを入力し、入室下さい。</p>
							<p>●説明会の録画及びスクリーンショットはご遠慮ください。調査目的のリサーチャーの方の参加も固くお断りしております。</p>
							<p>●ご登録メールアドレスは確実に連絡が取れるものをご利用ください。<br/>スマホ・携帯のメールをご利用の場合は、以下のドメインのアドレスが受信できるようにあらかじめ設定してください。メールが届かない場合の応募は無効となります。ご了承下さい。<br/>【ドメイン】＠schopschool.com</p>
							<p>●保護者の方を対象として行いますが、お子様の同席も可能です。</p>
							<p>●登録後にキャンセルされる場合は、必ずご連絡をお願いします。</p>
							<p>●応募フォームより入力いただく個人情報については、「<Link to="/privacypolicy.html">個人情報の取扱いについて</Link>」をよくお読みいただき、ご理解の上、申込ください。<br/>申込を通じて、「<Link to="/privacypolicy.html">個人情報の取扱いについて</Link>」はご同意いただいたとみなさせていただきます。</p>
						</div>
					</div>
				</section>
			)
		}
	}
}