import { Application, Container, Graphics, Sprite, Texture } from 'pixi.js'
import gsap from 'gsap'
import paper from 'paper'
import MorphablePath from './morphablePath'


const svgs = [
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
	// pen
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"  y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M207.8,68.9l16.6-63.1c0.8-3.1-2-6-5.2-5.2l-36,9.5l-27.1,7.1c-2.4,0.6-4.6,1.9-6.4,3.7l-112,112 c-0.6,0.6-1.8,0.9-2.6,0.8c-2.6-0.3-5.2,0.5-7.2,2.4l-9.5,9.5c-2,2-2.8,4.6-2.4,7.2c0.1,0.8-0.2,2-0.8,2.6L8,162.6 c-10,10-10,26.1,0,36.1l18.4,18.4c10,10,26.1,10,36.1,0l7.2-7.2c2.9-3.1,4.8,1.2,9.8-3.2l9.5-9.5c2-2,2.8-4.6,2.4-7.2 c-0.2-0.9,0.1-2.1,0.7-2.7l112-112C205.9,73.5,207.1,71.3,207.8,68.9z"/></svg> ',
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
	// schop
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M217.2,26.5l-0.6-9.3c-0.3-4.7-4-8.5-8.7-8.7l-9.3-0.6c-18.1-1.1-36.1,0.6-53.4,4.9 c-14.9,3.7-29.3,9.4-42.8,16.8C88.2,37.3,75,47.2,63.3,58.9L35.1,87.1c-3.9,4-3.9,10.5,0,14.4L58.5,125c4.5,4.5,4.5,11.8,0,16.3 l-21.5,21.6l-24.4,24.5c-6.8,6.8-6.8,19,0,25c7.7,6.7,18.1,7,24.9,0l46-46c4.5-4.5,11.8-4.5,16.3,0l23.5,23.5c3.9,4,10.5,4,14.5,0 l28.2-28.2c10.1-10,18.8-21.1,26-33c11.1-18.3,18.8-38.4,22.6-59.5C217.2,55.1,218.1,40.9,217.2,26.5z"/></svg>',
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
	// balloon
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"  y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M193.2,170.1c20.1-14.3,32-34.5,31.8-57.6c-0.2-23.2-11.7-43.3-31.8-57.6S145,32.1,112.5,32.2 c-20.1-0.1-38.5,3.1-54.5,9c-9.8,3.6-26.7,12.6-39.6,25.4C6.1,78.9,0,95.1,0.1,112.5c0.1,10.1,2.1,19.6,6.3,28.4 s10.4,16.6,18.2,23.6c1.2,1.1,1.9,2.6,1.9,4.1c0,1.5-0.6,3-1.8,4.2c0,0-5.7,5.3-13.9,10.5c-4,2.4-7.5,4.1-10.1,5.2 c-0.5,0.2-0.8,0.8-0.7,1.3s0.4,1,1,1.1c10.4,2,19.9,1.8,28.3,0.5s15.7-3.8,21.7-6.5c1.4-0.6,2.9-1,4.4-1s3,0.2,4.5,0.7 c7.7,2.7,16.1,4.9,24.9,6.3s18.1,2.1,27.8,2.1c16.2,0.1,31.4-2,45-5.9C171.1,183,183.2,177.3,193.2,170.1z"/></svg>',
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
	// computer
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"  y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M214.1,163.9c8.5,0,10.9-4.9,10.9-10.9V87.7V23.3c0-6.1-4.9-11-11-11H112.5H11c-6.1,0-11,4.9-11,11v61.4v67.1 c0,6.1,4.9,11,11,11h82.4c2.8,0,3,3,3,3c0,12-4.9,30.7-18.8,30.7c-6.5,0-20.2-0.8-20.2,8c0,4.4,3.6,8,8,8h47.1h47.2c4.4,0,8-3.6,8-8 c0-8.1-10.5-8-19.4-8c-13.3,0-19.7-17-19.7-30.7c0-1.7,1.3-3,3-3L214.1,163.9z"/></svg>',
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
	// light
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"  y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M151.4,191.2v-25.3c0-13.2,5.3-25.8,14.6-35.2c13.5-13.7,21.8-32.4,21.8-53.1c0-21.6-9-41.1-23.5-54.8 c-13.5-12.9-31.7-20.8-51.8-21c-20.5,0.1-39,8.4-52.6,21.7c-14,13.7-22.7,32.9-22.7,54.1c0,20.7,8.3,39.4,21.8,53.1 c9.3,9.4,14.6,22,14.6,35.2v25.3c0,2.4,0.9,4.9,2.4,6.8l8.3,10.5c2.3,2.9,5.7,4.5,9.3,4.5c1.2,0,2.2,0.6,2.7,1.6 c2.7,5.3,8.1,8.6,14,8.6h2.2h2.2c5.9,0,11.3-3.3,14-8.6c0.5-1,1.5-1.6,2.7-1.6c3.6,0,7-1.6,9.3-4.5L149,198 C150.5,196.1,151.4,193.6,151.4,191.2z"/></svg>',
	// circle
	'<svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 225 225" style="enable-background:new 0 0 225 225;" xml:space="preserve"><path class="st0" d="M216.4,97.4c-1.9-10-4.4-19.7-8.5-28.7c-4.2-9.2-9.7-17.6-16.2-25.1c-6.6-7.5-14.2-14.1-22.6-19.5 c-8.3-5.3-17.5-9.5-27.2-12.4c-9.3-2.7-19.2-4.2-29.4-4.2c-10.3,0-20.2,1.5-29.6,4.2c-9.7,2.9-18.9,7.1-27.2,12.4 c-8.4,5.4-16,12-22.6,19.6c-6.5,7.5-12,16-16.2,25.2c-4.1,9-7,18.6-8.4,28.7c-1.4,10.1-1.4,21.6,0,29.9c1.7,10.1,4.3,19.7,8.4,28.7 c4.2,9.2,9.7,17.6,16.2,25.1c6.6,7.5,14.2,14.1,22.6,19.6c8.3,5.4,17.5,9.6,27.2,12.4c9.4,2.7,19.2,4.2,29.5,4.2 c10.6,0,20.3-1.5,29.7-4.3c9.7-2.9,18.9-7.1,27.2-12.5c8.4-5.4,16-12.1,22.6-19.6c6.5-7.5,12-16,16.1-25.2c4.1-9,7-18.6,8.4-28.7 C217.4,120.2,218.2,106.7,216.4,97.4z"/></svg>',
]

export default class Morph {
	constructor() {
		this.width = 350
		this.height = 350
		this.canvas = $('.js_morph').get(0)
		this.canvas.width = this.width
		this.canvas.height = this.height
		paper.setup(this.canvas)
		paper.project.activeLayer.transformContent = false
		// const path = new paper.Path()
		// path.strokeColor = 'red'
		// path.moveTo(new paper.Point(10,0))
		// path.lineTo(new paper.Point(0,0))
		// path.lineTo(new paper.Point(0,10))
		// svg情報配列に保存
		let paths = []
		for (const i in svgs) {
			const svg = paper.project.activeLayer.importSVG(svgs[i])
			svg.remove() // remove from stage
			const path = svg.children[1]
			path.fillColor = null
			path.strokeColor = 'red'
			path.strokeWidth = 1
			paths.push(path)
		}
		this.path = new MorphablePath(paths)
		this.path.fillColor = '#ffe600'
		this.path.position.x = paper.view.viewSize.width / 2
		this.path.position.y = paper.view.viewSize.height / 2
		this.startedOn = new Date().getTime() / 1000
		this.count = 0
		this.loop()
	}

	destroy() {
		delete paper.view.context
		delete paper.view
		this.canvas.width = 0
		this.canvas.height = 0
		this.canvas.remove
		delete this.canvas
	}

	loop() {
		const count = (this.count + 1) % this.path.paths.length
		if (count < this.count) this.path.morph = count
		gsap.to(this.path, {morph: count, duration:1.25, delay:0.5, ease:'back.out', onComplete: this.loop.bind(this)})
		this.count = count
	}
}
