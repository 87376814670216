import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import MypageTab from '../../../component/common/mypagetab'
import AuthorizedPageBase from '../../authorizedpagebase'
import Wave from '../../../component/common/wave'
import { getCourseClasses, getNextSchedule } from '../../../../api/myClass'
import MypageSelect from '../../../component/common/mypageSelect'
import Term from './term'
import { formatDate, formatDateTimeRange, formatTimeRange } from '../../../../utils'
import NoteModal from '../common/noteModal'
import ZoomModal from '../common/zoomModal'
import queryString from 'query-string'
import Loading from '../../../component/common/loading'
import { AuthError } from '../../../../api/auth'

/**
 * myClass以下のスーパークラス
 */
export default class MyClassBase extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return ''
	}

	static get title() {
		return ''
	}

	static get title_en() {
		return ''
	}

	//index or application or complete
	static get key() {
		return ""
	}

	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		const qs = queryString.parse(this.props.location.search)
		this.state = {
			...this.state,
			isDataLoaded: false,
			child_id: parseInt(qs.child_id),
		}
	}

	componentDidMount() {
		super.componentDidMount()
		const getData = this.loadCourseData()  //そのコンポーネントごとに必要なデータを取得するための非同期メソッドが返ってくる
		getData.then((data)=>{
			this.setState({
				isDataLoaded: true,
				courseList: data,
			})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return
		})
	}

	loadCourseData(){}

	/**
	 * 子供を変更したらその末尾idにリダイレクト
	 * @param {number} id
	 */
	changeChild = (id) => {
		if(this.constructor.key == 'index') this.props.history.push(`/me/myclass/?child_id=${id}`)
		else this.props.history.push(`/me/myclass/${this.constructor.key}/?child_id=${id}`)

		this.resetLoadingStatus()
		this.setState({
			child_id: id
		})
	}

	nothingData() {
		const key = this.constructor.key
		let msg = ""
		if(key == "index") msg = "現在受講中・予約中のスクールはありません。"
		else if(key == "application") msg = "現在申し込み中のスクールはありません。"
		else if(key == "complete") msg = "現在受講済みのスクールはありません。"

		return(
			<>
				<section className="mypage-list-empty">
					<div className="mypage-list-empty__lead">{msg}</div>
					<div className="mypage-list-empty__btns">
						<Link to="/regularschool/">
							<span>レギュラースクール一覧</span>
						</Link>
						<Link to="/shortschool/">
							<span>短期スクール一覧</span>
						</Link>
					</div>
				</section>
			</>
		)
	}

	renderChild () {
		return (
			<div>
				<MypageTab current={this.constructor.key} child_id={this.state.child_id}/>
				<section className="mypage-list">
					<MypageSelect child_id={this.state.child_id} autoRedirect={this.changeChild}/>
					{this.getCourseList()}
				</section>

				{/* モーダル */}
				{this.constructor.key == "index" &&
				<>
					<NoteModal ref={node => this.noteModal = node} />
					<ZoomModal ref={node => this.zoomModal = node}/>
				</>
				}
			</div>
		)
	}

	getCourseList(){}

}