import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

export default class BarPager {

	constructor($target, numContent, index=0) {
		this.$target = $target
		this.numContent = numContent
		this.$bar = this.$target.find('.js_bar')
		this.width = this.$target.width()
		this.$bar.width(this.width / this.numContent)
		this.index = index
		this.position = this.index / this.numContent
	}

	set position(value) {
		this._position = value
		if (this._position < 0) this._position = 0
		else if (this._position > 1) this._position = 1
		this.update()
	}

	setIndex(index) {
		this.index = index
		this.position = this.index / this.numContent
	}

	update() {
		if (this.tween) this.tween.kill()
		if (this.$bar.length) this.tween = gsap.to(this.$bar, {x:this._position * this.width, duration:0.2, ease:'expo.out'})
	}
}

