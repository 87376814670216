import React from 'react'
import PropTypes from 'prop-types'
import TextInput from './textinput'
import { zenkaku2Hankaku } from '../../../utils'


export default class TelInput extends TextInput {

	static propTypes = {
		title: PropTypes.string,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		inputs: PropTypes.arrayOf(PropTypes.shape({
			placeholder: PropTypes.string,
			name: PropTypes.string,
			name_ja: PropTypes.string,
			priority: PropTypes.number,
			value: PropTypes.string,
			error: PropTypes.object,
			targetRef: PropTypes.func,
		})),
	}


	static defaultProps = {
		title: '電話番号',
		required: true,
		isConfirming: false,
		isDisplay: true,
		onChange: () => {},
		inputs: [
			{
				placeholder: '000',
				name: 'tel1',
				name_ja: '電話番号_1',
				priority: 0,
				value: '',
				targetRef: ()=>{},
			},
			{
				placeholder: '0000',
				name: 'tel2',
				name_ja: '電話番号_2',
				priority: 0,
				value: '',
				targetRef: ()=>{},
			},
			{
				placeholder: '0000',
				name: 'tel3',
				name_ja: '電話番号_3',
				priority: 0,
				value: '',
				targetRef: ()=>{},
			},
		]
	}

	onBlur = (e) => {
		e.target.value = zenkaku2Hankaku(e.target.value)
		this.props.onBlur(e)
	}

	get confirm() {
		return (
			<>
				{this.props.inputs[0].value} - {this.props.inputs[1].value} - {this.props.inputs[2].value}
			</>
		)
	}

	get form () {
		const tel1 = this.props.inputs[0]
		const tel2 = this.props.inputs[1]
		const tel3 = this.props.inputs[2]
		const error1 = (tel1.error && tel1.error.isError) ? ' error': ''
		const error2 = (tel2.error && tel2.error.isError) ? ' error': ''
		const error3 = (tel3.error && tel3.error.isError) ? ' error': ''
		return (
			<>
				<div className="form-edit__input-form-tel1" style={{position: "relative"}}>
					<input
						type="text"
						className={error1}
						placeholder={tel1.placeholder}
						name={tel1.name}
						data-name_ja={tel1.name_ja}
						data-priority={tel1.priority}
						onChange={this.props.onChange}
						onBlur={this.onBlur}
						maxLength={4}
						value={tel1.value}
						ref={tel1.targetRef}
					/>
					{(tel1.error && tel1.error.isError) &&
						<div className="form-edit__input-error">
							{tel1.error.message[0]}
						</div>
					}
				</div>
				<div className="form-edit__input-form-tel-text">-</div>
				<div className="form-edit__input-form-tel2" style={{position: "relative"}}>
					<input
						type="text"
						className={error2}
						placeholder={tel2.placeholder}
						name={tel2.name}
						data-name_ja={tel2.name_ja}
						data-priority={tel2.priority}
						onChange={this.props.onChange}
						onBlur={this.onBlur}
						maxLength={4}
						value={tel2.value}
						ref={tel2.targetRef}
					/>
					{(tel2.error && tel2.error.isError) &&
						<div className="form-edit__input-error">
							{tel2.error.message[0]}
						</div>
					}
				</div>
				<div className="form-edit__input-form-tel-text">-</div>
				<div className="form-edit__input-form-tel3" style={{position: "relative"}}>
					<input
						type="text"
						className={error3}
						placeholder={tel3.placeholder}
						name={tel3.name}
						data-name_ja={tel3.name_ja}
						data-priority={tel3.priority}
						onChange={this.props.onChange}
						onBlur={this.onBlur}
						maxLength={4}
						value={tel3.value}
						ref={tel3.targetRef}
					/>
					{(tel3.error && tel3.error.isError) &&
						<div className="form-edit__input-error">
							{tel3.error.message[0]}
						</div>
					}
				</div>
			</>
		)
	}
}