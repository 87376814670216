import { Application, Container, Graphics, Sprite, Texture, utils } from 'pixi.js'
import gsap from 'gsap'
import EventEmitter from 'events'
gsap.registerPlugin(gsap.plugins.css)

export default class TopVideo extends EventEmitter {

	constructor() {
		super()
		this.muted = true
		this.video = $('.js-top-video').get(0)
		this.$muteBtn = $('.js-top-video-mute')
		this.$muteBtn.on('click', this.toggleMute.bind(this))
	}

	toggleMute() {
		if (this.video.muted) {
			this.unmute()
		} else {
			this.mute()
		}
		return false
	}

	mute() {
		this.video.muted = true
		this.$muteBtn.addClass('active')
	}

	unmute()  {
		this.video.muted = false
		this.$muteBtn.removeClass('active')
	}
}
