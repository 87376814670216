import { Application, Container, Graphics, Sprite, Texture, utils } from 'pixi.js'
import gsap from 'gsap'
import EventEmitter from 'events'
gsap.registerPlugin(gsap.plugins.css)

export default class Intro extends EventEmitter {

	constructor() {
		super()

		gsap.set($('.mv__title>*'), {translateY:50, opacity:0, rotationZ:10})
		gsap.set($('.js_banner'), {y: 50, opacity:0})
		gsap.set($('.mv__banner__pager'), {y: 20, opacity:0})
		gsap.set($('.top-lead'), {y: 50, opacity:0})

		// events
		this._onVideoLoaded = this.onVideoLoaded.bind(this)
		this.$mv = $('.mv')
		$(window).on('resize',this.onResize.bind(this))

		//
		this.view = $('.js_intro').get(0)

		// 非対応は終了
		if (this.isSkip) {
			return
		}

		this.app = new Application({
			view: this.view,
			width: window.innerWidth,
			height: window.innerHeight,
			resolution: window.devicePixelRatio,
			autoResize: true,
			transparent: true,
		})
		// bg
		this.bg = new Graphics()
		this.app.stage.addChild(this.bg)
		this.holeSize = 0
		// setup display objects
		this.container = new Container()
		this.app.stage.addChild(this.container)
		//
	}

	get isSkip() {
		return (!utils.isWebGLSupported() || document.location.hash.match(/skipintro/) || $.cookie('skipIntro') )
	}

	destroy() {
		// 非対応またはスキップ時は終了
		if (this.isSkip) {
			return {}
		}

		if (this.lead01) this.lead01.texture.destroy(true)
		if (this.lead02) this.lead02.texture.destroy(true)
		if (this.lead03) this.lead03.texture.destroy(true)
		this.app.destroy(true, {children: true, texture:true, baseTexture:true})
		delete this.app
	}

	setup() {
		// 非対応またはスキップ時は終了
		if (this.isSkip) {
			this.$mv.css('visibility', 'visible')
			this.setupVideo()
			this.playVideo()
			this.onCompleteIntro()
			return
		}

		this.onResize()
		this.update()
		this.load()
	}

	load() {
		this.app.loader.add('lead01', '/assets/images/intro/txt-lead01.svg')
		this.app.loader.add('lead02', '/assets/images/intro/txt-lead02.svg')
		this.app.loader.add('lead03', '/assets/images/intro/txt-lead03.svg')
		this.app.loader.load(this.onLoad.bind(this))
	}


	onLoad() {
		this.lead01 = new Sprite(Texture.from($('.js_intro_lead01').get(0)))
		this.lead02 = new Sprite(Texture.from($('.js_intro_lead02').get(0)))
		this.lead03 = new Sprite(Texture.from($('.js_intro_lead03').get(0)))
		this.lead01.width = this.lead02.width = this.lead03.width = 20
		this.lead01.scale.y = this.lead01.scale.x
		this.lead02.scale.y = this.lead02.scale.x
		this.lead03.scale.y = this.lead03.scale.x
		this.lead01.anchor.x = 0.5
		this.lead02.anchor.x = 0.5
		this.lead03.anchor.x = 0.5
		this.lead01.position.x = 45
		this.lead03.position.x = -45
		this.lead01.alpha = 0
		this.lead02.alpha = 0
		this.lead03.alpha = 0
		this.container.addChild(this.lead01)
		this.container.addChild(this.lead02)
		this.container.addChild(this.lead03)
		this.onResize()
		this.start()
	}

	onResize() {
		if (this.app) {
			this.app.renderer.resize(window.innerWidth, window.innerHeight)
			this.stageWidth = this.app.renderer.width / this.app.renderer.resolution
			this.stageHeight = this.app.renderer.height / this.app.renderer.resolution
			this.container.position.x = this.stageWidth / 2
			this.container.position.y = this.stageHeight / 2 - 75
		}
	}


	update() {
		this.bg.clear()
		this.bg.beginFill(0xffe600)
		const s = (this.stageWidth + this.stageHeight) * 2
		this.bg.drawRect((this.stageWidth-s)/2, (this.stageHeight-s)/2, s, s)
		if (this.holeSize > 0) {
			this.bg.beginHole()
			this.bg.drawCircle(this.stageWidth/2, this.stageHeight/2, this.holeSize)
			this.bg.endHole()
		}
	}

	start() {
		this.$mv.css('visibility', 'visible')
		if (document.location.hash.match(/skipintro/) || $.cookie('skipIntro') ) {
			this.setupVideo()
			this.playVideo()
			this.onCompleteIntro()
			this.startTitleMotion()
			return
		}
		const tl = gsap.timeline()
		tl.to(this.lead01, {alpha:1, duration:0.5}, 0.25)
		tl.fromTo(this.lead01.position, {y:-20}, {y:0, duration:0.75, ease:'expo.out'}, 0.25)
		tl.to(this.lead02, {alpha:1, duration:0.5}, 1.25)
		tl.fromTo(this.lead02.position, {y:-20}, {y:0, duration:0.75, ease:'expo.out'}, 1.25)
		tl.to(this.lead03, {alpha:1, duration:0.5}, 1.75)
		tl.fromTo(this.lead03.position, {y:-20}, {y:0, duration:0.75, ease:'expo.out', onComplete: this.onCompleteCopy.bind(this)}, 1.75)
	}

	setupVideo() {
		this.spMaxWidth = 768
		this.isSp = window.innerWidth <= this.spMaxWidth
		let src = this.isSp ? '/assets/images/schop--sp.mp4' : '/assets/images/schop.mp4'
		this.$mv.find('video').attr('src',src)
		this.video = $('.js_movie').get(0)
		this.video.playbackRate = 0.8
	}

	onCompleteCopy() {
		this.setupVideo()
		this.video.addEventListener('canplay', this._onVideoLoaded)
		this.videoLoadTimer = setTimeout(this.onVideoTimeout.bind(this), 5000)
	}

	onVideoTimeout() {
		$('.mv__movie').addClass('bg')
		this.onVideoLoaded()

	}

	onVideoLoaded() {
		clearTimeout(this.videoLoadTimer)
		this.video.removeEventListener('canplay', this._onVideoLoaded)
		this.video.pause()
		try{
			this.video.currentTime = 0
		} catch(e) {

		}
		const tl = gsap.timeline()
		tl.to(this.lead01, {alpha:0, duration:0.5}, 0)
		tl.to(this.lead02, {alpha:0, duration:0.5}, 0)
		tl.to(this.lead03, {alpha:0, duration:0.5}, 0)
		tl.to(this.lead01.position, {y:20, duration:0.75}, 0)
		tl.to(this.lead02.position, {y:20, duration:0.75}, 0)
		tl.to(this.lead03.position, {y:20, duration:0.75}, 0)
		tl.to(this, {holeSize: this.stageWidth+this.stageHeight, duration: 0.35, ease:'cubic.in', onUpdate: this.update.bind(this), onComplete: this.onCompleteIntro.bind(this)}, 0.25)
	}


	onCompleteIntro() {
		$.cookie('skipIntro', 1, {expires: 7, path: '/'})
		$('.intro').remove()
		this.startTitleMotion()
		this.emit('complete')
	}

	playVideo() {
		try {
			this.video.currentTime = 0.25
			this.video.play()
		} catch(e) {}
		this.video.addEventListener('ended', this.onVideoEnded.bind(this))
		this.updateVideo()
	}


	startTitleMotion(delay=0.5) {
		this.playVideo()
		this.$titles = $('.mv__title>*')
		gsap.set(this.$titles, {css:{translateY:50, opacity:0, rotationZ:10}})
		for (let i=0; i<this.$titles.length; i++) {
			let d = delay + i*0.075+0.2
			if (i > 0) d += 1.0
			gsap.to(this.$titles.eq(i), {css:{translateY:0, opacity:1, rotationZ:0}, duration:0.3, ease:'back.out', delay:d})
		}
		gsap.fromTo($('.js_banner'), {y: 50, opacity:0}, {y:0, opacity:1, duration:0.5, ease:'back.out', delay:delay+1.8})
		gsap.fromTo($('.mv__banner__pager'), {y: 20, opacity:0}, {y:0, opacity:1, duration:0.5, ease:'back.out', delay:delay+1.9})
		gsap.fromTo($('.top-lead'), {y: 50, opacity:0}, {y:0, opacity:1, duration:0.5, ease:'back.out', delay:delay+2.0})
	}

	updateVideo() {
		try {
			if (this.video.currentTime > this.video.duration - 1.0) this.onVideoEnded()
		} catch(e) {

		}
		window.requestAnimationFrame(this.updateVideo.bind(this))
	}

	onVideoEnded(e) {
		if (this.videoEnded) return
		$('.mv__movie').removeClass('loading')
		gsap.to(this.video, {opacity:0, duration:0.75, delay: 0.5, ease:'cubic.inOut', onComplete:this.onFadeOut.bind(this)})
		this.videoEnded = true
	}

	onFadeOut() {
		this.video.pause()
	}
}
