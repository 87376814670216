import React from 'react'
import { getNextSchedule } from '../../../../api/myClass'
import { formatDate, formatTimeRange, getIsOnlineImage } from '../../../../utils'
import Wave from '../../../component/common/wave'

export default class MyCourseClass extends React.Component {

	next(terms) {
		const nextSchedule = getNextSchedule(terms)
		if (!nextSchedule) return '未定'
		const start = nextSchedule.schedule.started_at
		const end = nextSchedule.schedule.finished_at
		const date = formatDate(start)
		const time = formatTimeRange(start, end)
		return (

			<>
				{date}<br className="pc-view" />
				{time}
			</>
		)
	}

	handleOpenZoom = (e) => {
		e.preventDefault()
		this.props.onOpenZoom(this.props.data)
	}

	handleOpenNote = (e) => {
		e.preventDefault()
		this.props.onOpenNote(this.props.data)
	}

	render() {
		const courseData = this.props.data
		let courseImgUrl = courseData.course_main_image_url

		// 受講済みは表示しない
		if(courseData.member_state == "finished") return <></>

		if(courseImgUrl == null) return <></>

		return(
			<>
				{courseData &&
					<li>
						<div className="mypage-list-block">
							<div className="mypage-list__pct"><img src={courseImgUrl} alt={courseData.course_name}/>
								<Wave />
							</div>
							<div className="mypage-list__pcwrap-index">
								<div className="mypage-list__schooltitle">{courseData.course_name}</div>
								<div className="mypage-list__user">
									<div className="mypage-list__user__thumb"><img src={`${courseData.child.child_profile.icon_url}?${new Date().getTime}`} alt={courseData.child.first_name+'さん'}/></div>
									<div className="mypage-list__user__name">{courseData.child.first_name}さん</div>
									<div className="mypage-list__user__status">
										{getIsOnlineImage(courseData.course_is_online)}
									</div>
								</div>
								<div className="mypage-list__pcwrap-index2">
									<div className="mypage-list__date"><span>次回授業</span>
										<p>{this.next(courseData.my_terms)}</p>
									</div>
									<div className="mypage-list__btns">
										<a href="#" onClick={this.handleOpenNote}>
											<span className="mypage-list__btns__icon">
												<img src="/assets/images/me/btn-note.svg" alt="スコップ・スクールノート"/>
											</span>
											<span className="mypage-list__btns__text">スコップ・スクールノート</span>
										</a>
										<a href="#" onClick={this.handleOpenZoom} target="_blank">
											<span className="mypage-list__btns__icon">
												<img src="/assets/images/me/btn-zoom.svg" alt="Zoom URL"/>
											</span>
											<span className="mypage-list__btns__text">Zoom URL</span>
										</a>
										</div>
								</div>
							</div>
						</div>
					</li>
				}
			</>
		)
	}
}
