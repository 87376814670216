import React from 'react'
import moment from 'moment'
import PageBase from '../pagebase'
import { Link } from 'react-router-dom';
import queryString from 'query-string'
import { getNewsList } from '../../../api/news';

export default class News extends PageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/news/'
	}

	static get title() {
		return 'ニュース'
	}

	static get title_en() {
		return 'news'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/common/txt-news.svg" alt={this.title} />
				</span>
			</div>
		)
	}

	static get is_need_banner() {
		return true
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		const qs = queryString.parse(this.props.location.search);
		console.log(qs)
		let count = 10//1ページの表示件数 後の計算で使用
		let page = (qs.page) ? parseInt(qs.page) : 1
		if (page <= 0) page = 1

		this.state = {
			...this.state,
			isDataLoaded: false,
			count,
			page,
		}
	}

	componentDidMount() {
		super.componentDidMount()
		this.getDataAndSetState(this.state.page)
	}

	/**
	 * 次へクリック時
	 * @param {*} e
	 */
	onNextBtnClick = (e) => {
		this.getDataAndSetState(this.state.page + 1)
	}

	/**
	 * 前へクリック時
	 * @param {*} e
	 */
	onPrevBtnClick = (e) => {
		this.getDataAndSetState(this.state.page - 1)
	}

	/**
	 * APIから情報を取得してstateに反映
	 * @param {*} e
	 */
	getDataAndSetState(page) {
		let offset =  ((page-1) * this.state.count + 1)
		// console.log("count:" +this.state.count+ ",off:" +offset)
		const newsList = getNewsList(this.state.count, offset,"news")//テスト用に第3引数にページタイプを追加
		newsList.then((data)=>{
			this.setState({
				isDataLoaded: true,
				newsList: data,
				page,
			})
		})
		this.resetLoadingStatus()
	}

	renderChild () {

		if (!this.state.isDataLoaded) { // データ未取得時
			return (null)
		} else {
			const page = this.state.page
			const newsList = this.state.newsList
			const total_page = Math.ceil(newsList.total / this.state.count)

			console.log(newsList.news)
			return (
				<>
					<section className="newsindex">
						<div className="newsindex__wrap">
						<div className="newsindex__list">
								<ul>
									{
										newsList.news.map((value, key)=>{
											let ts = moment.unix(value.data)
											let date = ts.format("yyyy.MM.DD")
											return (
												<li key={key}>
													<Link to={value.link}>
														<span className="newsindex__list__date">{date}</span>
														<span className="newsindex__list__title">{value.title}</span>
													</Link>
												</li>
											)
										})
									}
								</ul>
							</div>
						</div>
					</section>
					<section className="pagenation">
						<div className="pagenation__wrap">
							<div className="pagenation__prev">
								{page!==1 &&
									<Link to={`/news/?page=${page-1}`} onClick={this.onPrevBtnClick}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="前へ" />
										</picture>
									</Link>
								}

							</div>
							<div className="pagenation__num">
								<span>{page} / {total_page}</span>
							</div>
							<div className="pagenation__next">
								{total_page !== page &&
									<Link to={`/news/?page=${page+1}`} onClick={this.onNextBtnClick}>
										<picture>
											<source srcSet="/assets/images/common/btn-pagination--sp.svg" media="(max-width: 768px)" />
											<img src="/assets/images/common/btn-pagination.svg" alt="前へ" />
										</picture>
									</Link>
								}
							</div>
						</div>
					</section>
				</>
			)
		}
	}
}