import React from 'react'
import { Link } from 'react-router-dom'
import { deleteChild } from '../../../../api/child'
import AuthorizedPageBase from '../../authorizedpagebase'
import Auth, { AuthError } from '../../../../api/auth'
import Loading from '../../../component/common/loading'
import { getIsStudent } from '../../../../utils'
import queryString from 'query-string'

const STUDENT_DELETE = 'txt-studentdelete'
const CHILD_DELETE = 'txt-childdelete'
const CHILD_DETAIL = 'txt-childdetail'

export default class ChildDelete extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/children/delete.html'
	}

	static get title() {
		return 'お子様情報の削除'
	}

	static get title_en() {
		return 'childdelete'
	}

	//get用
	static get file_name() {
		return this._file_name
	}

	//一回staticにset
	static set file_name(str) {
		this._file_name = str
	}

	static get header_title() {
		if (this._file_name === undefined) this._file_name = CHILD_DELETE
		return (
			<div className="header__title">
				<span>
					<img src={`/assets/images/me/${this.file_name}.svg`} alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false,
			button_enable: false,
			isComplete: false,  //完了画面かどうかフラグ
			isStudent: false
		}
		const qs = queryString.parse(this.props.location.search);
		this.child_id = parseInt(qs.child_id)
	}

	/**
	 * Auth情報を参照して子供情報を取得
	 */
	authorizeDidComplete() {
		super.authorizeDidComplete()
		this.childData = (()=>{
			for (let i in Auth.user.children) {
				const data = Auth.user.children[i]
				if (data.id === this.child_id) {
					return data
				}
			}
			return undefined
		})()

		if (this.childData !== undefined) {
			// 生徒子供判定
			const isStudent = getIsStudent(this.childData)
			this.constructor.file_name = (isStudent) ? STUDENT_DELETE : CHILD_DELETE  //一回staticに登録（set）する必要がある
			this.setState({
				...this.state,
				isDataLoaded: true,
				isStudent,
			})
		}
	}

	/**
	 * チェックボックスの状態をステートに反映する
	 * @param {Object} e
	 */
	onCheck = (e) => {
		if(e.target.checked) {
			this.setState({
				button_enable: true
			})
		}else {
			this.setState({
				button_enable: false
			})
		}
	}

	/**
	 * ユーザー削除
	 * @param {*} e
	 */
	onDelete = async(id, e) => {
		e.preventDefault()
		this.confirmShow(
			"確認",
			'このお子様の情報を削除します',
			(async()=> { //OK時
				Loading.show()
				try {
					const data = await deleteChild(id)
				} catch(error) {
					if (error instanceof AuthError) {
						this.onUnauthorizedError()
					} else {
						this.errorModal('削除失敗', error.message)
						Loading.hide()
					}
					return
				}
				this.infoModal("削除完了", "削除しました。")
				this.setState({
					isComplete: true
				})
				Auth.updateUser().then(() => {
					this.props.history.push('/me/children/')
				}).catch( error => {
					if (error instanceof AuthError) this.onUnauthorizedError()
					else this.errorModal('エラー', error.message)
					return
				})
			}),
			null
		)
	}

	renderChild () {
		const params = this.props.match
		const child_id = this.child_id
		const childData = Auth.getChildById(child_id)

		return (
			<>
			{this.state.isComplete ?
				<div>
					<section className="mypage-information">
						<div className="mypage-information-wrap">
							<div className="mypage-information__text">お子様情報の削除が完了しました。</div>
							<div className="mypage-information__btn back"><Link to="/me/"><span>マイページトップ</span></Link></div>
						</div>
					</section>
				</div>
				:
				<section className="mypage-information">
					<div className="mypage-information-wrap">
						<div className="mypage-information__name">{childData.last_name}{childData.first_name}</div>
						<div className="mypage-information__text">{childData.last_name}{childData.first_name}さんのデータを削除します。<br/>お子様に関する全ての情報が削除されます。本当に削除されますか？</div>
						<div className="mypage-information__checkbox">
							<label>
								<span className="mypage-information__checkbox__label">データの削除に同意する</span>
								<input type="checkbox" onChange={this.onCheck.bind(this)}/>
								<div className="mypage-information__checkbox__toggle"></div>
							</label>
						</div>
						{this.state.button_enable ?
							<div className="mypage-information__btn"><Link to="#" onClick={this.onDelete.bind(this, child_id)}><span>削除する</span></Link></div>
							:
							<div className="mypage-information__btn disable"><Link to="#"><span>削除する</span></Link></div>
						}
						<div className="mypage-information__btn back"><Link to={`/me/children/detail.html?child_id=${this.child_id}`}><span>キャンセル</span></Link></div>
					</div>
				</section>
			}
			</>
		)
	}
}