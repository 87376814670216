import React from 'react'
import PropTypes from 'prop-types'
import Select from './select'
import { PREFECTURES } from '../../../config'


export default class PrefectureSelect extends Select {

	static propTypes = {
		title: PropTypes.string,
		name_ja: PropTypes.string,
		priority: PropTypes.number,
		value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		name: PropTypes.string,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
	}

	static defaultProps = {
		title: '都道府県',
		// 決め打ち
		name_ja: '都道府県',
		priority: 0,
		value: '',
		required: false,
		isConfirming: false,
		isDisplay: true,
		onChange: () => {},
		onBlur: () => {},
		name: '',
		options: PREFECTURES,
	}



	// get form() {
	// 	return (
	// 		<div className="form-edit__input-form-1col">
	// 			<select name={this.props.name} value={this.props.value} onChange={this.props.onChange} onBlur={this.props.onBlur} >
	// 				{this.constructor.prefectures.map( (item, i) => {
	// 					return (
	// 						<option value={(i !== 0) ? i : ''} key={i}>{item}</option>
	// 					)
	// 				})}
	// 			</select>
	// 			{(this.props.error && this.props.error.isError) &&
	// 				<div className="form-edit__input-error">
	// 					{this.props.error.message[0]}
	// 				</div>
	// 			}
	// 		</div>
	// 	)
	// }
}