import React from 'react'
import { Link } from 'react-router-dom'
import Auth from '../../../../api/auth'
import MyEntryCourseClass from './myEntryCourseClass'

export default class MyEntryCourseClassList extends React.Component {	
	
	render() {
		const entryList = this.props.entryList
		const firstChildId = Auth.getFirstStudentId()
		return (
			<>
				{entryList?.entries.length > 0 &&
					<>
						<div className="mypage-list__title"><span>申し込み中スクール</span>
							<div className="mypage-list__title__btn">
								<Link to={`/me/myclass/application/?child_id=${firstChildId}`}><span>一覧</span></Link>
								</div>
						</div>
						<ul>
							{entryList.entries.map((value, key)=>{
								return <MyEntryCourseClass data={value} key={key} />
							})}
						</ul>
					</>
				}
			</>
		)
	}
}