import React from 'react'
import AuthorizedPageBase from '../../authorizedpagebase'
import Validate, {MaxLength, MinLength, EqualLength} from '../../_utils/validate'
import Auth, { AuthError } from '../../../../api/auth'
import { editUser } from '../../../../api/users'
import DateInput from '../../../component/form/dateinput'
import PrefectureSelect from '../../../component/form/prefectureselect'
import Select from '../../../component/form/select'
import TelInput from '../../../component/form/telinput'
import TextInput from '../../../component/form/textinput'
import ZipInput from '../../../component/form/zipinput'
import AddressAutofill from '../../../../modules/addressAutofill'
import Loading from '../../../component/common/loading'
import { scrollToTop } from '../../../../utils'
import { formatDateTime2 } from '../../../../utils'
import { FORM_INFO } from '../../../../config'

export default class AccountEdit extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/account/edit.html'
	}

	static get title() {
		return '保護者情報編集'
	}

	static get title_en() {
		return 'baseinfoedit'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span>
					<img src="/assets/images/me/txt-baseinfoedit.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------

	constructor(props) {
		super(props)

		this.state = {
			...this.state,
			isConfirming: false,
			form: {
				last_name: '',
				first_name: '',
				last_name_kana: '',
				first_name_kana: '',
				post_code1: '',
				post_code2: '',
				prefecture_id: '',
				city: '',
				address1: '',
				address2: '',
				tel1: '',
				tel2: '',
				tel3: '',
				email: '',
				emergency_name1: '',
				emergency_tel1_1: '',
				emergency_tel1_2: '',
				emergency_tel1_3: '',
				emergency_name2: '',
				emergency_tel2_1: '',
				emergency_tel2_2: '',
				emergency_tel2_3: '',
				work_place: '',
				// reason_id: '',
				errors: {},
			}
		}

		// 親フォーム用バリデーションスキーム
		this.validateScheme = {
			last_name: [Validate.Required],
			first_name: [Validate.Required],
			last_name_kana: [Validate.Required, Validate.Furigana],
			first_name_kana: [Validate.Required, Validate.Furigana],
			post_code1: [Validate.Required, Validate.Number, new EqualLength(3)],
			post_code2: [Validate.Required, Validate.Number, new EqualLength(4)],
			prefecture_id: [Validate.Required],
			city: [Validate.Required],
			address1: [Validate.Required],
			tel1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			tel2: [Validate.Required, Validate.Number, new MaxLength(4)],
			tel3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			email: [Validate.Required, Validate.Mail],
			emergency_name1: [Validate.Required],
			emergency_tel1_1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			emergency_tel1_2: [Validate.Required, Validate.Number, new MaxLength(4)],
			emergency_tel1_3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			emergency_name2: [Validate.Required],
			emergency_tel2_1: [Validate.Required, Validate.Number, new MaxLength(4), Validate.Tel],
			emergency_tel2_2: [Validate.Required, Validate.Number, new MaxLength(4)],
			emergency_tel2_3: [Validate.Required, Validate.Number, new MinLength(3), new MaxLength(4)],
			work_place: [Validate.Required],
		}

	}

	componentDidMount() {
		super.componentDidMount()
		this.isSetup = false
		this.formInit()
	}

	componentDidUpdate(){
		super.componentDidUpdate()
		if (!this.isSetup) {
			this.isSetup = true
			this.addressAutofill = new AddressAutofill(
				"post_code1",
				"post_code2",
				"prefecture_id",
				".js-address-autofill"
			)
			this.addressAutofill.on(AddressAutofill.UpdateZip, this.updateZip)
			this.addressAutofill.on(AddressAutofill.UpdateZipError, this.updateZipError)
		}
	}

	formInit = async () => {
		//ユーザ情報APIから取得
		let user = null
		try {
			user = await Auth.getUser(Auth.accessToken)
		} catch(e) {
			if (e instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', e.message)
			return
		}

		//フォームの分割していたものをDBに登録するときに連結していたものを再び分割
		user.post_code1 = user.post_code.split('-')[0]
		user.post_code2 = user.post_code.split('-')[1]
		user.emergency_tel1_1 = user.emergency_tel1.split('-')[0]
		user.emergency_tel1_2 = user.emergency_tel1.split('-')[1]
		user.emergency_tel1_3 = user.emergency_tel1.split('-')[2]
		user.emergency_tel2_1 = user.emergency_tel2.split('-')[0]
		user.emergency_tel2_2 = user.emergency_tel2.split('-')[1]
		user.emergency_tel2_3 = user.emergency_tel2.split('-')[2]
		user.tel1 = user.tel.split('-')[0]
		user.tel2 = user.tel.split('-')[1]
		user.tel3 = user.tel.split('-')[2]
		user.address2 = user.address2 ? user.address2 : ''

		const user_state = {
			last_name: user.last_name,
			first_name: user.first_name,
			last_name_kana: user.last_name_kana,
			first_name_kana: user.first_name_kana,
			post_code1: user.post_code1,
			post_code2: user.post_code2,
			prefecture_id: user.prefecture_id,
			city: user.city,
			address1: user.address1,
			address2: user.address2,
			tel1: user.tel1,
			tel2: user.tel2,
			tel3: user.tel3,
			email: user.email,
			emergency_name1: user.emergency_name1,
			emergency_tel1_1: user.emergency_tel1_1,
			emergency_tel1_2: user.emergency_tel1_2,
			emergency_tel1_3: user.emergency_tel1_3,
			emergency_name2: user.emergency_name2,
			emergency_tel2_1: user.emergency_tel2_1,
			emergency_tel2_2: user.emergency_tel2_2,
			emergency_tel2_3: user.emergency_tel2_3,
			work_place: user.work_place,
			created_at: formatDateTime2(user.created_at),
			updated_at: formatDateTime2(user.updated_at),
		}

		this.setState({
			form: {
				...this.state.form,
				...user_state
			}
		})

	}

	// コンポーネントが非表示になるとき
	componentWillUnmount() {
		super.componentWillUnmount()
		this.addressAutofill.onExit()
	}

	/**
	 * 値変更時
	 * @param {*} e
	 */
	handleChange = (e) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		})
		e.preventDefault()
	}

	/**
	 * フォーカス離れた時
	 * @param {*} e
	 */
	handleBlur = (e) =>{
		const name = e.target.name
		const value = e.target.value
		const name_ja = e.target.dataset.name_ja
		const priority = e.target.dataset.priority

		this.setState({
			form: {
				...this.state.form,
				[name]: value,
				errors: {
					...this.state.form.errors,
					[name]: Validate.isValid(name_ja, value, priority, this.validateScheme[name])
				},
			}
		})
	}

	/**
	 * 登録処理
	 * @param {*} e
	 */
	submit = async (e) => {
		e.preventDefault()
		// 一個でもエラーあるか
		let isErrorAtLeastOnce = false

		// 親のバリデーション確認
		isErrorAtLeastOnce = Validate.isAllValidationOk(this.state.form.errors, this.validateScheme)

		for(const key in this.validateScheme) {
			console.log(this.validateScheme[key]);
		}

		if (isErrorAtLeastOnce) {
			// 一度もフォーカスあたってないものにをエラーにしてステートを更新
			let _state = {...this.state}
			_state.form.errors = Validate.setErrorData(this.state.form.errors, this.validateScheme)
			this.setState(_state)
			let errors = []
			for(const key in this.state.form.errors) {
				const error = this.state.form.errors[key]
				if(error.isError) errors.push(error)
			}
			errors = errors.sort((el1, el2) => el1.priority - el2.priority)
			const errorMsgs = errors.map((el, _) => Validate.formatErrorMessage(el))
			// TODO: エラーモーダルにエラーメッセージの配列渡せるように改修
			this.errorListModal("エラー", "入力内容に誤りがあります。ご確認ください。", errorMsgs)  // バリデーションのエラー
			return false
		}

		Loading.show()
		scrollToTop()
		try {
			await editUser(this.state.form)
			Auth.updateUser().then(()=> {
				Loading.hide()
				this.infoModal("編集完了", "情報を更新しました。")
				this.updateTime()  //componentDidUpdateのなかで行うと無限ループが発生してしまうためここで行う
			})
		} catch(e) {
			if (e instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', e.message)  // APIのエラー
			return false
		}

		return false
	}

	/**
	 * 最終更新日を更新します
	 */
	updateTime = async () => {
		let user = null
		try {
			user = await Auth.getUser(Auth.accessToken)
		} catch(e) {
			if (e instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', e.message)  // APIのエラー
			return
		}
		this.setState({
			form: {
				...this.state.form,
				updated_at: formatDateTime2(user.updated_at)
			}
		})
	}

	/**
	 * 住所更新時
	 */
	updateZip = (prefecture_id, city)=> {
		this.setState({
			form: {
				...this.state.form,
				prefecture_id,
				city,
			}
		})
	}

	/**
	 * 住所更新エラー
	 * @param {*} titls -
	 * @param {*} msg -
	 */
	updateZipError = (titls, msg)=>{
		this.errorModal(titls, msg)
	}

	/**
	 * 戻るボタンクリック時
	 * @param {*} e
	 */
	onBuckBtnClick = (e)=>{
		this.props.history.goBack()
		e.preventDefault()
	}


	renderChild () {

		return (
			<div>
				<section className="mypage-edit">
					<div className="mypage-edit-wrap">
						<div className="mypage-edit__entrydate">
							<dl>
								<dt>入会日</dt>
								<dd>{this.state.form.created_at}</dd>
							</dl>
							<dl>
								<dt>最終更新日</dt>
								<dd>{this.state.form.updated_at}</dd>
							</dl>
						</div>

						<div className="mypage-edit__input">
							<div className="mypage-edit__pcwrap-input2col">
								<TextInput
									title='お名前'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'last_name',
											name_ja: FORM_INFO.last_name.text,
											priority:FORM_INFO.last_name.priority,
											placeholder: "名",
											value:this.state.form.last_name,
											error: this.state.form.errors.last_name
										},
										{
											name:'first_name',
											name_ja: FORM_INFO.first_name.text,
											priority:FORM_INFO.first_name.priority,
											placeholder:'名',
											value:this.state.form.first_name,
											error: this.state.form.errors.first_name
										},
									]}
								/>
								<TextInput
									title='ふりがな'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'last_name_kana',
											name_ja: FORM_INFO.last_name_kana.text,
											priority:FORM_INFO.last_name_kana.priority,
											placeholder:'せい',
											value:this.state.form.last_name_kana,
											error: this.state.form.errors.last_name_kana
										},
										{
											name:'first_name_kana',
											name_ja: FORM_INFO.first_name_kana.text,
											priority:FORM_INFO.first_name_kana.priority,
											placeholder:'めい',
											value:this.state.form.first_name_kana,
											error: this.state.form.errors.first_name_kana
										},
									]}
								/>
							</div>
							<div className="mypage-edit__input-postnumber">
								<ZipInput
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'post_code1',
											name_ja: FORM_INFO.post_code1.text,
											priority:FORM_INFO.post_code1.priority,
											placeholder: "000",
											value: this.state.form.post_code1,
											error: this.state.form.errors.post_code1
										},
										{
											name:'post_code2',
											name_ja: FORM_INFO.post_code2.text,
											priority:FORM_INFO.post_code2.priority,
											placeholder: "0000",
											value: this.state.form.post_code2,
											error: this.state.form.errors.post_code2
										},
									]}
								/>
							</div>
							<div className="mypage-edit__pcwrap-input2col">
								{/* これはname_ja一個しかないのでクラス内で決め打ちする */}
								<PrefectureSelect name='prefecture_id'
									required
									name_ja={FORM_INFO.prefecture_id.text}
									priority={FORM_INFO.prefecture_id.priority}
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									value={this.state.form.prefecture_id}
									error={this.state.form.errors.prefecture_id}
								/>
								<TextInput
									title='市町村'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'city',
											name_ja: FORM_INFO.city.text,
											priority:FORM_INFO.city.priority,
											placeholder:'〇〇区〇〇町',
											value:this.state.form.city,
											error: this.state.form.errors.city
										},
									]}
								/>
								<TextInput
									title='番地'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'address1',
											name_ja: FORM_INFO.address1.text,
											priority:FORM_INFO.address1.priority,
											placeholder:'〇丁目〇番地',
											value:this.state.form.address1,
											error: this.state.form.errors.address1
										},
									]}
								/>
								<TextInput
									title='建物名・号室'
									onChange={this.handleChange}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'address2',
											name_ja: FORM_INFO.address2.text,
											priority:FORM_INFO.address2.priority,
											placeholder:'〇〇マンション 〇〇号室',
											value:this.state.form.address2
										},
									]}
								/>
								<TelInput
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name: 'tel1',
											name_ja: FORM_INFO.tel1.text,
											priority:FORM_INFO.tel1.priority,
											placeholder:'00',
											value:this.state.form.tel1,
											error: this.state.form.errors.tel1
										},
										{
											name: 'tel2',
											name_ja: FORM_INFO.tel2.text,
											priority:FORM_INFO.tel2.priority,
											placeholder:'0000',
											value:this.state.form.tel2,
											error: this.state.form.errors.tel2
										},
										{
											name: 'tel3',
											name_ja: FORM_INFO.tel3.text,
											priority:FORM_INFO.tel3.priority,
											placeholder:'0000',
											value:this.state.form.tel3,
											error: this.state.form.errors.tel3
										},
									]}
								/>
								<TextInput
									title='メールアドレス'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'email',
											name_ja: FORM_INFO.email.text,
											priority:FORM_INFO.email.priority,
											placeholder:'schop@schopshchool.com',
											value:this.state.form.email, error: this.state.form.errors.email
									},
									]}
								/>
							</div>
							<div className="form-edit__subtitle">緊急連絡先1</div>
							<div className="mypage-edit__pcwrap-input2col">
								<TextInput
									title='生徒との続柄'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'emergency_name1',
											name_ja: FORM_INFO.emergency_name1.text,
											priority:FORM_INFO.emergency_name1.priority,
											placeholder:'父',
											value:this.state.form.emergency_name1,
											error: this.state.form.errors.emergency_name1
										},
									]}
								/>
								<TelInput
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name: 'emergency_tel1_1',
											name_ja: FORM_INFO.emergency_tel1_1.text,
											priority:FORM_INFO.emergency_tel1_1.priority,
											placeholder:'00',
											value: this.state.form.emergency_tel1_1,
											error: this.state.form.errors.emergency_tel1_1
										},
										{
											name: 'emergency_tel1_2',
											name_ja: FORM_INFO.emergency_tel1_2.text,
											priority:FORM_INFO.emergency_tel1_2.priority,
											placeholder:'0000',
											value: this.state.form.emergency_tel1_2,
											error: this.state.form.errors.emergency_tel1_2
										},
										{
											name: 'emergency_tel1_3',
											name_ja: FORM_INFO.emergency_tel1_3.text,
											priority:FORM_INFO.emergency_tel1_3.priority,
											placeholder:'0000',
											value: this.state.form.emergency_tel1_3,
											error: this.state.form.errors.emergency_tel1_3
										},
									]}
								/>
							</div>
							<div className="form-edit__subtitle">緊急連絡先2</div>
							<div className="mypage-edit__pcwrap-input2col">
								<TextInput
									title='生徒との続柄'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'emergency_name2',
											name_ja: FORM_INFO.emergency_name2.text,
											priority:FORM_INFO.emergency_name2.priority,
											placeholder:'祖母',
											value:this.state.form.emergency_name2,
											error: this.state.form.errors.emergency_name2
										},
									]}
								/>
								<TelInput
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name: 'emergency_tel2_1',
											name_ja: FORM_INFO.emergency_tel2_1.text,
											priority:FORM_INFO.emergency_tel2_1.priority,
											placeholder:'00',
											value: this.state.form.emergency_tel2_1,
											error: this.state.form.errors.emergency_tel2_1
										},
										{
											name: 'emergency_tel2_2',
											name_ja: FORM_INFO.emergency_tel2_2.text,
											priority:FORM_INFO.emergency_tel2_2.priority,
											placeholder:'0000',
											value: this.state.form.emergency_tel2_2,
											error: this.state.form.errors.emergency_tel2_2
										},
										{
											name: 'emergency_tel2_3',
											name_ja: FORM_INFO.emergency_tel2_3.text,
											priority:FORM_INFO.emergency_tel2_3.priority,
											placeholder:'0000',
											value: this.state.form.emergency_tel2_3,
											error: this.state.form.errors.emergency_tel2_3
										},
									]}
								/>
								<TextInput
									title='勤務先'
									required
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									isConfirming={this.state.isConfirming}
									inputs={[
										{
											name:'work_place',
											name_ja: FORM_INFO.work_place.text,
											priority:FORM_INFO.work_place.priority,
											placeholder:'株式会社〇〇',
											value: this.state.form.work_place,
											error: this.state.form.errors.work_place
										},
									]}
								/>
								<div className="mypage-edit__input-btn">
									<input type="submit" value="変更する" onClick={this.submit}/>
								</div>
							</div>
						</div>
					</div>
				</section>
			<section className="mypage-backbtn"><a href="#" onClick={this.onBuckBtnClick}><span>戻る</span></a></section>
		</div>
		)
	}
}