import React from 'react'
import { Link } from 'react-router-dom'
import { GENDER } from '../../../../../config'


export default class Student extends React.Component {
	/**
	 * パスコードを再生成します
	 * @param {event} e
	 */
	onChangePasscodeClick = (e) => {
		e.preventDefault()
		this.props.changePasscode()
	}

	getLinks (childData) {
		return (
			<>
				<div className="mypage-information__btn">
					<Link to={`/me/children/edit.html?child_id=${childData.id}`}><span>編集する</span></Link>
				</div>
				<div className="mypage-information__btn bggray">
					<Link to={`/me/children/delete.html?child_id=${childData.id}`}><span>削除する</span></Link>
				</div>
				<hr />
				<div className="mypage-information__btn">
					<Link to={`/me/myclass/?child_id=${childData.id}`}><span>受講中一覧</span></Link>
				</div>
				<div className="mypage-information__btn">
					<Link to={`/me/myclass/complete/?child_id=${childData.id}`}><span>受講済一覧</span></Link>
				</div>
				<div className="mypage-information__btn">
					<Link to={`/me/report/?child_id=${childData.id}`}><span>スコップ・スクールレポート</span></Link>
				</div>
			</>
		)
	}

	render() {
		const childData = this.props.childData
		const child_profile = childData.child_profile
		let dates = childData.birth_date.split('-')
		let y = dates[0]
		let m = dates[1]
		let d = dates[2]

		return (
			<>
				<section className="mypage-information">
					<div className="mypage-information-wrap">
						<div className="mypage-information__pcwrap-studentdetail">
							<div className="mypage-information__pcwrap-studentdetail-sub">
								<div className="mypage-information__thumb">
									<div className="mypage-information__thumb__pct">
										<img src={`${child_profile.icon_url}?${new Date().getTime()}`} alt=""/>
									</div>
									<div className="mypage-information__thumb__name">
									{childData.last_name}{childData.first_name}
									<small>{child_profile.last_name_kana}{child_profile.first_name_kana}</small>
									</div>
								</div>
								<div className="pc-view">
									{this.getLinks(childData)}
								</div>
							</div>
							<div className="mypage-information__pcwrap-studentdetail-main">
								<div className="mypage-information__list">
									<dl>
										<dt>生年月日</dt>
										<dd>{y}年{m}月{d}日</dd>
									</dl>
									<dl>
										<dt>性別</dt>
										<dd>{GENDER[childData.gender_id]}</dd>
									</dl>
									<dl>
										<dt>通っている小学校</dt>
										<dd>{child_profile.elementary_school}</dd>
									</dl>
									<dl>
										<dt>学年</dt>
										<dd>小学{child_profile.grade_id}年生</dd>
									</dl>
									<dl>
										<dt>興味があること</dt>
										<dd>{child_profile.interest}</dd>
									</dl>
									<dl>
										<dt>習い事</dt>
										<dd>{child_profile.after_school_activity}</dd>
									</dl>
									<div className="mypage-information__list__passcode">
										<dl>
											<dt>パスコード</dt>
											<dd>{child_profile.passcode}<a href="#" onClick={this.onChangePasscodeClick}><img src="/assets/images/me/btn-reflesh.svg"/></a></dd>
										</dl>
										<div className="mypage-information__list__passcode__attention">※スコップ・スクールノートへのアクセス及びスコップ・スクールレポートの共有の際に必要です</div>
									</div>
								</div>
							</div>
							<div className="sp-view">
								{this.getLinks(childData)}
							</div>
						</div>
					</div>
				</section>
				<section className="mypage-backbtn"><Link to="/me/children/"><span>お子様一覧へ</span></Link></section>
			</>
		)
	}
}