
import React from 'react'
import Footer from '../component/common/footer'
import PageBase from './pagebase';

export default class NotFound extends PageBase {

	static get path(){
		return '/summer2021.html'
	}

	static get title() {
		return '2021年夏スクール申し込みページ'
	}

	static get title_en() {
		return 'summer2021'
	}

	renderChild () {
		return (
			<>
			<div className="lowerPage" id="wrapper">

				<section className="summer2021">
					<div className="summer2021__wrap">
						<div className="summer2021__btn">
							<a href="https://pro.form-mailer.jp/lp/7151e3b6230377">
							<span>申し込みはコチラ</span>
							</a>
						</div>
						<div className="summer2021__img">
							<img src="/assets/images/lp/summer_2021.png" alt="2021年夏スクール申し込み"></img>
						</div>

						<div className="summer2021__btn">
							<a href="https://pro.form-mailer.jp/lp/7151e3b6230377">
							<span>申し込みはコチラ</span>
							</a>
						</div>
					</div>
				</section>
			</div>
		</>
		)
	}
}