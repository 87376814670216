import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Wave from '../../component/common/wave'
import AuthorizedPageBase from '../authorizedpagebase'
import Auth, { AuthError } from '../../../api/auth'
import { getEntries } from '../../../api/myClass'
import { getCourseClasses } from '../../../api/myClass'
import { GENDER } from '../../../config'
import MyCourseClassList from './top/myCourseClassList'
import MyEntryCourseClassList from './top/myEntryCourseClassList'
import ZoomModal from './common/zoomModal'
import NoteModal from './common/noteModal'
import { getIsStudent } from '../../../utils'
import { getCourseList } from '../../../api/course'
import { getReportList } from '../../../api/schopReport'

export default class Me extends AuthorizedPageBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/'
	}

	static get title() {
		return 'マイページトップ'
	}

	static get title_en() {
		return 'mypagetop'
	}

	static get header_title() {
		return (
			<div className="header__title">
				<span className="mypagetop">
					<img src="/assets/images/me/txt-mypagetop.svg" alt={this.title} />
				</span>
			</div>
		)
	}
	// ---------- 基本設定ここまで ----------
	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isDataLoaded: false, // ローディングが終わるまでレンダーせず、ローディング画面を表示するための設定
		}
	}

	authorizeDidComplete() {
		super.authorizeDidComplete()
		// 受講中のデータ
		const courseList = getCourseClasses()
		courseList.then((data)=>{
			this.setState({
				...this.state,
				courseList: data,
			})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return false
		})

		// 予約中データ
		const entryList = getEntries()
		entryList.then((data)=>{
			this.setState({
				...this.state,
				entryList: data,
			})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return false
		})

		const reportList = getReportList()
		reportList.then((data) => {
			this.setState({reportList: data})
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return false
		})

		// 両方のAPIを待つ
		Promise.all([courseList, entryList, reportList]).then(()=>{
			this.setState({ isDataLoaded: true }) // ローディング完了
		}).catch( error => {
			if (error instanceof AuthError) this.onUnauthorizedError()
			else this.errorModal('エラー', error.message)
			return false
		})
	}

	// 子供ごとに最新のレポートを取得
	getEachNewerReports = () => {
		const reportList = this.state.reportList.reports
		if(!reportList) return null

		let newThings = []
		for(const i in reportList) {
			const data = reportList[i]
			let isId = false  // そもそも配列にその子供が未登録
			// タームIDが大きいものほど新しい
			for(const j in newThings) {
				if(data.child.id == newThings[j].child.id) {
					if(data.child.id > newThings[j].term_id) {
						newThings[j] = data
					}
					isId = true
				}
			}
			if(!isId) newThings.push(data)
		}
		return newThings
	}

	handleOpenNote = (data) => {
		if (!this.noteModal) return
		this.noteModal.show(data)
	}

	handleOpenZoom = (data) => {
		if (!this.zoomModal) return
		this.zoomModal.show(data)
	}

	childEmpty() {
		return(
			<>
				<div className="form-information__text">お子様の登録がありません。お子様を追加してください。</div>
				<div className="mypage-information__btn">
					<Link to="/me/children/add.html"><span>お子様を追加</span></Link>
				</div>
			</>
		)
	}

	childList() {
		return(
			<ul>
				{Auth.user.children.map((data,i)=>{
					const birth = moment(data.birth_date, "YYYY-MM-DD").format("YYYY年MM月DD日")
					const isStudent = getIsStudent(data)
					return (
						<li key={i}>
							<Link className="mypage-list-block" to={`/me/children/detail.html?child_id=${data.id}`}>
								<div className="mypage-list-block-line1-child">
									<div className="mypage-list__report">
										<div className="mypage-list__report__thumb">
											{isStudent ?
											<img src={data.child_profile.icon_url} alt={`${data.last_name} ${data.first_name}`} />
											:
											<img src="/assets/images/me/txt-student-noimg.png" alt="仮" />
											}
										</div>
										<div className="mypage-list__report__name">{data.first_name}さん</div>
									</div>
									<div className="mypage-list__text pc-view">{birth}　{GENDER[data.gender_id]}</div>
								</div>
							</Link>
						</li>
					)
				})}
			</ul>
		)
	}

	renderChild () {
		const courseList = this.state.courseList
		const entryList = this.state.entryList

		// それぞれの子供の最新のレポートのみを格納した配列を返す
		const newerReportList = this.getEachNewerReports(this.state.reportList)

		return (
			<section className="mypage-list">

				{/* 受講一覧 */}
				<MyCourseClassList
					courseList={courseList}
					onOpenNote={this.handleOpenNote}
					onOpenZoom={this.handleOpenZoom}
				/>

				{/* 申し込み一覧 */}
				<MyEntryCourseClassList
					entryList={entryList}
				/>

				<div className="mypage-list__title"><span className="mypage-list__title-report">スコップ・スクールレポート</span></div>
				<ul>
					{newerReportList && newerReportList.map((report, key) => {
							return (
								<li key={key}>
									{/* 子供のみの指定でレポートページへとばす */}
									<Link to={`/me/report/?child_id=${report.child.id}`} className="mypage-list-block">
										<div className="mypage-list-block-line1">
											<div className="mypage-list__report">
												<div className="mypage-list__report__thumb">
													<img src={report.child.child_profile.icon_url} alt=""/>
												</div>
												<div className="mypage-list__report__name">{report.child.first_name}さんのレポート</div>
											</div>
											<div className="mypage-list__text">{report.course_name}</div>
										</div>
									</Link>
								</li>
							)
					})}
				</ul>

				{/* お子様 */}
				<>
					<div className="mypage-list__title"><span className="mypage-list__title-child">お子様</span></div>
					{Auth.user.children.length > 0 ?
						this.childList()
						:
						this.childEmpty()
					}
				</>

				{/* モーダル */}
				<NoteModal ref={node => this.noteModal = node} />
				<ZoomModal ref={node => this.zoomModal = node}/>
			</section>
		)
	}
}