import CompleteBase from '../completeBase'

export default class RegularSchoolApplicationComplete extends CompleteBase {

	// ---------- 基本設定 ----------
	static get path() {
		return '/me/regularschool/complete.html'
	}

	static get title() {
		return 'クラス選択画面　(レギュラースクール)'
	}

	static get title_en() {
		return 'regularschool_application_complete'
	}
}