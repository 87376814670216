import { WP_API } from '../config'

export async function getReportList(count=3, offset=0) {
	const res = await fetch(`${WP_API.REPORT}?count=${encodeURIComponent(count)}&offset=${encodeURIComponent(offset)}`)
	const json = await res.json()
	if(!res.ok) throw new Error(json.message)
	return json
}

export async function getReportDetail(id) {
	const res = await fetch(WP_API.REPORT + '/' + encodeURIComponent(id))
	const json = await res.json();
	if (!res.ok) throw new Error(json.message)
	return json
}
