import { Application, Container, Graphics, Sprite, Texture } from 'pixi.js'
import gsap from 'gsap'

export default class FaqModule {
	constructor() {
		const $targets = $('.js-collapse')
		$targets.each((i) => {
			const $target = $targets.eq(i)
			const $handle = $target.find('.js-collapse-handle')
			const $panel = $target.find('.js-collapse-panel')
			$handle.on('click', (e) => {
				if ($target.hasClass('selected')) {
					$target.removeClass('selected')
				} else {
					$target.addClass('selected')
				}
				return false
			})
		})
	}
}

