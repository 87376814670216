import React from 'react'
import Footer from '../component/common/footer'
import Header from '../component/common/header'
import {BASE_TITLE} from '../../config'
import Loading from '../component/common/loading'
import { scrollToTop } from '../../utils'
import InfoModal from '../component/common/infoModal'
import Auth from '../../api/auth'
import TagManager from 'react-gtm-module'
import Banner from '../component/common/banner'

export default class PageBase extends React.Component {

	// ここ以下を必ずオーバーライドしてください
	// ---------- 基本設定 ----------
	static get path() {
		return '/hoge'
	}

	static get title() {
		return 'スコップ・スクール'
	}

	static get title_en() {
		return 'hoge'
	}

	static get header_title() {
		return (null)
	}

	// これは必要なときのみのオーバーライドで良い
	static get is_need_banner() {
		return false
	}
	// ---------- 基本設定ここまで ----------

	static isFistPageToShow = true // GoogleのJavaScriptにページの内容を認識させるため、初回ページ表示は同期的にデータを読み込む必要があるのでそのためのフラグ

	constructor(props) {
		super(props)
		this.isInitSetup = false
		this.sync = PageBase.isFistPageToShow // 同期的にデータを読み込むべきかどうかのフラグ
		this.hasObservedLoading = false
		this.state = {
			...this.state,
			isDataLoaded: true, // 個別のローディングプロセスがある場合はサブクラスのコンストラクタでこれをfalseにしてください
			waitImageLoading: false, // 画像のローディングを待つ必要がない場合はサブクラスのコンストラクタでこれをfalseにしてください
		}

		// NOTE : アカウント未登録で「Lineでログイン」しようとした場合は確認してからリダイレクト
		Auth.removeAllListeners(Auth.confirmAndRedirect)
		Auth.on(Auth.confirmAndRedirect, ()=>{
			this.infoModal(
				"アカウント未登録",
				"ご利用のLINE IDでの登録がありませんでした。\nアカウント登録画面に移動します。",
				()=>{
					Auth.redirectRegistorForm()
				})
		})
		PageBase.isFistPageToShow = false
	}

	resetLoadingStatus = () => {
		this.hasObservedLoading = false
		scrollToTop()
		Loading.show()
	}

	/**
	 * コンポーネントがはじめてレンダーされた時に実行されます
	 */
	componentDidMount(useSendGa=true) {
		const ua = window.navigator.userAgent.toLowerCase()
		const iePage = "/ie.html"

		if ((ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) && location.pathname != iePage) {
			window.location.href = iePage
		}
		document.title = `${this.constructor.title} | ${BASE_TITLE}`
		if(useSendGa) this.sendGa()

		if (!this.hasObservedLoading && this.state.isDataLoaded && this.node) this.observeLoading()
	}

	/**
	 * コンポーネントに変更が発生した際に実行されます
	 */
	componentDidUpdate() {
		if (!this.hasObservedLoading && this.state.isDataLoaded && this.node) this.observeLoading()
	}

	/**
	 * gaにデータ送信
	 */
	sendGa() {
		// TagManager.dataLayer({
		// 	dataLayer: {
		// 		page: location.pathname,
		// 	},
		// 	dataLayerName: "PageDataLayer",
		// 	event: 'delayedHistoryChange',
		// })

		this.setupOuterDomain()
	}

	/**
	 * 申し込みフォームドメインの許可設定
	 */
	setupOuterDomain = ()=>{
		if (this.isInitSetup) return
		// NOTE: GAがページ直後ないのでset timeout を設定
		setTimeout(()=>{
			if (window.ga === undefined) {
				this.setupOuterDomain()
				return
			}

			ga('create', 'UA-141258029-1', 'schopschool.com', {'allowLinker': true});
			ga('require', 'linker');
			ga('linker:autoLink', ['schopschool.com', 'pro.form-mailer.jp']);
			this.isInitSetup = true
			// console.log("gtm...4...ok");
		}, 1000)
	}

	/**
	 * this.node がセットされてから一度だけ実行されます
	 */
	observeLoading() {
		if (!this.state.waitImageLoading) {
			this._componentDidLoad()
			Loading.hide()
			return
		}

		this.hasObservedLoading = true
		const images = this.node.getElementsByTagName('img')
		if (images && images.length) {
			this.waitLoading(images).then( () => {
			}).catch((e) => {
				// console.log(e)
			}).finally( () => {
				this._componentDidLoad()
			})
		} else {
			this._componentDidLoad()
		}
	}

	/**
	 * 全ての画像のロードを待ちます
	 * @param {Array<img>} images
	 */
	async waitLoading(images) {
		let tasks = []
		for (let i=0; i<images.length; i++) {
			const element = images[i]
			tasks.push(new Promise((resolve, reject) => {
				if (element.naturalWidth > 0 && element.complete) {
					resolve()
				} else {
					element.onload = resolve
					element.onerror = reject
				}
			}))
		}
		return Promise.all(tasks)
	}

	/**
	 * 全ての画像がローディングされたときに実行されます
	 */
	_componentDidLoad() {
		if (this.unmounted) return
		this.componentDidLoad()
	}

	/**
	 *
	 */
	componentDidLoad() {
		Loading.hide()
	}

	/**
	 * このページから離れるときに実行されます
	 */
	componentWillUnmount() {
		this.unmounted = true
		Loading.show()
	}

	/**
	 * サブクラスでこのページのコンテンツを返してください
	 */
	renderChild() {
		return (
			<section></section>
		)
	}

	_renderChild() {
		return this.renderChild()
	}

	/**
	 * インフォメーションモーダル
	 *
	 * @param {string} title - タイトル
	 * @param {string} message - メッセージ
	 * @param {function} callback - コールバック
	 */
	infoModal(title, message, callback = null) {
		InfoModal.instance.show(title, message, callback)
	}

	/**
	 * エラーモーダル
	 *
	 * @param {string} title - タイトル
	 * @param {string} message - メッセージ
	 * @param {function} callback - コールバック
	 */
	errorModal(title, message, callback = null) {
		InfoModal.instance.show(title, message, callback)
	}

	/**
	 * エラーを箇条書きで表示するモーダル
	 * @param {*} title
	 * @param {*} message
	 * @param {*} errors
	 */
	errorListModal(title, message, errors, callback=null) {
		let errorMessage = errors.join('\n')
		InfoModal.instance.show(title, message, callback, errorMessage)
	}

	/**
	 * 確認モーダル
	 * @param {string} title - タイトル
	 * @param {string} message - メッセージ
	 * @param {function} okCallback - OK時コールバック
	 * @param {function} cancelCallback - cancel時コールバック
	 */
	confirmShow(title, message, okCallback = null, cancelCallback = null) {
		InfoModal.instance.confirmShow(title, message, okCallback, cancelCallback)
	}
	/**
	 * ここはサブクラス側では触らないでください
	 */

	render () {
		return (
			<div className={this.constructor.title_en == 'top' ? '' : 'lowerPage'} ref={node => this.node = node}>
				{(this.constructor.title_en != 'top' && this.constructor.title_en != 'ie') && (<Header header_title={this.constructor.header_title} />)}
				{this._renderChild()}
				{this.constructor.is_need_banner && <Banner />}
				{this.constructor.title_en != 'top' && (<Footer title={this.constructor.title_en} />)}
			</div>
		)
	}
}