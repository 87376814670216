import React from 'react'
import Footer from '../component/common/footer'
import Header from '../component/common/header'
import {BASE_TITLE} from '../../config'
import PageBase from './pagebase'
import Auth from '../../api/auth'
import Loading from '../component/common/loading'


/**
 * 認証済みのユーザしかアクセスできないページのベースクラスです
 */
export default class AuthorizedPageBase extends PageBase {

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			authorized: false,
		}
	}

	/**
	 * コンポーネントがはじめてレンダーされる時に一度だけ実行されます
	 */
	componentDidMount() {
		super.componentDidMount()
		// 認証状態を確認して、認証されたら state.authorized を true にすることで再描画を走らせる
		Auth.check((isAuthorized)=>{
			if (isAuthorized) {
				this.authorizeDidComplete()
				this.setState({authorized: true})
			} else {
				// 認証されてない場合はログイン画面にリダイレクト
				document.location.href = '/login.html'
			}
		})
	}

	onUnauthorizedError() {
		this.infoModal(
			"セッションが無効です",
			"再度ログインしてください",
			()=>{
				Auth.logout()
				// this.props.history.push('/login.html')
			}
		)
	}

	/**
	 * Auth完了時イベント
	 */
	authorizeDidComplete() {
	}

	/**
	 * ここはサブクラス側では触らないでください
	 */
	_renderChild() {
		if (!this.state.authorized || !this.state.isDataLoaded) return null
		return this.renderChild()
	}
}