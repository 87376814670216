import React from 'react'
import gsap from "gsap/gsap-core"
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(CSSPlugin)
gsap.registerPlugin(ScrollToPlugin)

export default class InfoModal extends React.Component {
	static instance = null

	/**
	 * constructor
	 * @param {Object} props
	 */
	constructor(props) {
		super(props)
		this.tween = null
		this.state = {
			...this.state,
			title: null,
			message: null,
			errorMessage: null,
			isConfirm: false,
		}
		InfoModal.instance = this
	}

	/**
	 * モーダルを表示
	 * @param {string} title - タイトル
	 * @param {string} message - メッセージ
	 * @param {function} callback - コールバック
	 */
	show(title, message, callback = null, errorMessage = null) {
		this.tween?.kill()
		this.callback = callback

		this.setState({
			...this.state,
			title,
			message,
			errorMessage: errorMessage,
			isConfirm: false,
		})

		this.tween = gsap.fromTo(this.node, {
			display: 'block',
			opacity: 0,
			scale: 1.1,
		},{
			opacity: 1,
			scale: 1,
			duration: 0.25,
			ease: 'Expo.easeOut',
			onComplete: ()=>{
				this.close?.focus()
			}
		})
		window.addEventListener('keydown', this.onKeypress)
		// 先頭までスクロール
		this.scrollContent.scrollTo({top: 0})
	}

	/**
	 * 確認モーダルを表示
	 * @param {string} title - タイトル
	 * @param {string} message - メッセージ
	 * @param {function} okCallback - OK時コールバック
	 * @param {function} cancelCallback - cancel時コールバック
	 */
	confirmShow(title, message, okCallback = null, cancelCallback = null) {
		this.tween?.kill()
		this.okCallback = okCallback
		this.cancelCallback = cancelCallback
		this.setState({
			...this.state,
			title,
			message,
			errorMessage: "",
			isConfirm: true,
		})
		this.tween = gsap.fromTo(this.node, {
			display: 'block',
			opacity: 0,
			scale: 1.1,
		},{
			opacity: 1,
			scale: 1,
			duration: 0.25,
			ease: 'Expo.easeOut'
		})
		// 先頭までスクロール
		this.scrollContent.scrollTo({top: 0})
	}

	/**
	 * 確認モーダル: キャンセルクリック時
	 */
	onCancelClick = (e) => {
		e.preventDefault()
		this.callback = this.cancelCallback
		this.hide()
	}

	/**
	 * 確認モーダル: OKクリック時
	 */
	onOkClick = (e) => {
		e.preventDefault()
		this.callback = this.okCallback
		this.hide()
	}

	/**
	 * モーダルを非表示
	 */
	hide() {
		this.tween?.kill()
		this.tween = gsap.to(this.node, {
			opacity: 0,
			display: 'none',
			duration: 0.3,
			ease: 'Sine.easeOut',
			onComplete: ()=>{
				if (this.callback) {
					this.callback()
				}

				this.callback = null
				this.onKeypress = null
				this.cancelCallback = null
			}
		})
		window.removeEventListener('keydown', this.onKeypress)
	}

	onKeypress=(e)=>{
		// escape / enter なら終了
		if (e.keyCode === 27 || e.keyCode === 13) {
			this.hide()
		}
	}

	onClosClick = (e)=>{
		e.preventDefault()
		if (this.state.isConfirm) return
		this.hide()
	}

	/**
	 * render
	 */
	render () {
		return (
			<section className="modal" ref={node => this.node = node} >
				<span className="modal-bg" onClick={this.onClosClick}></span>
				<div className="modal-wrap">
					{!this.state.isConfirm &&
						<div className="modal__closebtn">
							<a href="#" onClick={this.onClosClick} ref={node=>this.close = node}>
								<img src="/assets/images/common/btn-close.svg" alt="閉じる"/>
							</a>
						</div>
					}
					<div className="modal-wrap__inner" ref={node => this.scrollContent = node}>
						{this.state.title &&
							<div className="modal__title">{this.state.title}</div>
						}
						{this.state.message &&
							<>
							<div className="modal__text">
								{this.state.message.split('\n').map((str, index) => (
									<React.Fragment key={index}>{str}<br /></React.Fragment>
									))}
								{/* エラー文言の配列回す */}
							</div>
							<div className="modal__text error">
								{this.state.errorMessage && this.state.errorMessage.length>0 && this.state.errorMessage.split('\n').map((str, index) => (
									<React.Fragment key={index}>{str}<br /></React.Fragment>
									))}
							</div>
							</>
						}

						{this.state.isConfirm &&
							<div className="modal__btns">
								<div className="modal__btn cancel">
									<a
										href="#"
										onClick={this.onCancelClick}
									>
										キャンセル
									</a>
								</div>
								<div className="modal__btn ok">
									<a
										href="#"
										onClick={this.onOkClick}
									>
										OK
									</a>
								</div>
							</div>
						}
					</div>
				</div>
			</section>
		)
	}
}