import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TextInput from './textinput'
import { zenkaku2Hankaku } from '../../../utils'


export default class ZipInput extends TextInput {

	static propTypes = {
		title: PropTypes.string,
		required: PropTypes.bool,
		isConfirming: PropTypes.bool,
		isDisplay: PropTypes.bool,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		inputs: PropTypes.arrayOf(PropTypes.shape({
			placeholder: PropTypes.string,
			name: PropTypes.string,
			name_ja: PropTypes.string,
			priority: PropTypes.number,
			value: PropTypes.string,
			error: PropTypes.object,
			targetRef: PropTypes.func,
		})),
	}

	static defaultProps = {
		title: '郵便番号',
		required: true,
		isConfirming: false,
		isDisplay: true,
		onChange: () => {},
		inputs: [
			{
				placeholder: '000',
				name: 'zip1',
				name_ja: '郵便番号_1',
				priority: 0,
				value: '',
				targetRef: ()=>{},
			},
			{
				placeholder: '0000',
				name: 'zip2',
				name_ja: '郵便番号_2',
				priority: 0,
				value: '',
				targetRef: ()=>{},
			},
		]
	}

	onBlur = (e) => {
		e.target.value = zenkaku2Hankaku(e.target.value)
		this.props.onBlur(e)
	}

	get confirm() {
		return (
			<>
				{this.props.inputs[0].value} - {this.props.inputs[1].value}
			</>
		)
	}

	get form() {
		const code1 = this.props.inputs[0]
		const code2 = this.props.inputs[1]
		const error1 = (code1.error && code1.error.isError) ? ' error': ''
		const error2 = (code2.error && code2.error.isError) ? ' error': ''
		return (
			<>
				<div className="form-edit__input-form-postnumber1" style={{position: "relative"}}>
					<input
						type="text"
						className={error1}
						placeholder={code1.placeholder}
						name={code1.name}
						data-name_ja={code1.name_ja}
						data-priority={code1.priority}
						onChange={this.props.onChange}
						onBlur={this.onBlur}
						maxLength={3}
						value={code1.value}
						ref={code1.targetRef}
					/>
					{(code1.error && code1.error.isError) &&
						<div className="form-edit__input-error">
							{code1.error.message[0]}
						</div>
					}
				</div>
				<div className="form-edit__input-form-postnumber-text">-</div>
				<div className="form-edit__input-form-postnumber2" style={{position: "relative"}}>
					<input
						type="text"
						className={error2}
						placeholder={code2.placeholder}
						name={code2.name}
						data-name_ja={code2.name_ja}
						data-priority={code2.priority}
						onChange={this.props.onChange}
						onBlur={this.onBlur}
						maxLength={4}
						value={code2.value}
						ref={code2.targetRef}
					/>
					{(code2.error && code2.error.isError) &&
						<div className="form-edit__input-error">
							{code2.error.message[0]}
						</div>
					}
				</div>
			</>
		)
	}

	render() {
		return (
			<div className="form-edit__input-postnumber">
				{super.render()}
				{!this.props.isConfirming &&
				<div className="form-edit__input-postnumber-btn"><a href="#" className="js-address-autofill"><span>住所自動入力</span></a></div>
				}
			</div>
		)
	}
}