import React from 'react'
import { NOTE_URL, LINE_URL } from '../../../../config'
import { copyUrl, getDeviceInfo, hideElement, showElement } from '../../../../utils'
import '../../../../assets/css/mine/transition.css'
import InfoModal from '../../../component/common/infoModal'
const QRCode = require('qrcode')

export default class NoteModal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			qr: ''
		}
	}



	show(data) {
		const url = this.getNoteUrl(data)
		QRCode.toDataURL(url).then((dataUrl) => {
			this.setState({
				data: data,
				qr: dataUrl,
			})
		}).catch((err) => {
			throw new Error(err)
		})
		showElement(this.modal, 0.5)
		showElement(this.modalContent, 0.5, 0.2, true)

		//NOTE: クリップボードコピー対応のブラウザか確認
		window.addEventListener("copy", this.testCopy)
		this.useCopy = document.execCommand("copy")

		// ボタンの半透明処理
		// if (!this.useCopy) {
		// 	this.copyBtn.classList.add("disable")
		// }
	}

	testCopy = (e)=>{
		// console.log("testCopy");
		e.clipboardData.setData('text/plain', "")
		e.preventDefault()
		window.removeEventListener("copy", this.testCopy)
	}


	getNoteUrl(data) {
		if(data) {
			return NOTE_URL + '?token=' + data.child.child_profile.page_token
		}
		return null
	}

	hide() {
		hideElement(this.modalContent, 0.5, 0, true)
		hideElement(this.modal, 0.5, 0.2)
	}

	handleClose = (e) => {
		this.hide()
		e.preventDefault()
	}

	handleCopyUrl = (e) => {
		e.preventDefault()
		const url = this.getNoteUrl(this.state.data)

		if (this.useCopy) {
			copyUrl(url)
			InfoModal.instance.show("クリップボードにコピーしました。")
		} else {
			if ('clipboard' in navigator) {
				navigator.clipboard.writeText(url)
				InfoModal.instance.show("クリップボードにコピーしました。")
			} else {
				InfoModal.instance.show("", "お使いのブラウザーでは自動コピーできません。\nお手数ですが手動でコピーお願いします。\n\n" + url)
			}
		}
	}

	handleOpenUrl = (e) => {
		e.preventDefault()
		const url = this.getNoteUrl(this.state.data)
		window.open(url)
	}

	handleShareLine = (e) => {
		e.preventDefault()
		if(this.state.data) {
			const data = this.state.data
			const shareText = `${data.course_name} ${data.name}\n${data.child.first_name}さんのスコップ・スクールノートURLはこちらです`
			const shareUrl = this.getNoteUrl(data)
			const param = encodeURIComponent(`${shareText}\n\n${shareUrl}`)

			window.open(`${LINE_URL.SHARE}${param}`)
		}
	}

	pc() {
		return (
			<section className="mypage-modal" style={{display: 'none', opacity: 0}} ref={node => this.modal = node}><span className="mypage-modal-bg" onClick={this.handleClose}></span>
				<div className="mypage-modal-wrap" ref={node => this.modalContent = node}>
					<div className="mypage-modal-top">
						<div className="mypage-modal__closebtn"><a href="#" onClick={this.handleClose}><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
						<div className="mypage-modal__title">スコップ・スクールノート</div>
						<div className="mypage-modal__passcode">パスコード：{this.state.data && this.state.data.child.child_profile.passcode}</div>
					</div>
					{this.bottom("スマートフォンで開いてください")}
				</div>
			</section>
		)
	}

	sp() {
		return (
			<section className="mypage-modal" style={{display: 'none', opacity: 0}} ref={node => this.modal = node}>
				<span className="mypage-modal-bg" onClick={this.handleClose}></span>
				<div className="mypage-modal-wrap" ref={node => this.modalContent = node}>
					<div className="mypage-modal-top">
						<div className="mypage-modal__closebtn"><a href="#" onClick={this.handleClose}><img src="/assets/images/common/btn-close.svg" alt="閉じる"/></a></div>
						<div className="mypage-modal__title">スコップ・スクールノート</div>
						<div className="mypage-modal__btn"><a href="/" onClick={this.handleOpenUrl}><span>スコップ・スクール ノートを開く</span></a></div>
						<div className="mypage-modal__passcode">パスコード：{this.state.data && this.state.data.child.child_profile.passcode}</div>
					</div>
					{this.bottom("スコップ・スクールノート")}
				</div>
			</section>
		)
	}

	bottom(message) {
		return (
			<div className="mypage-modal-bottom">
				<div className="mypage-modal__qrcode">
					<div className="mypage-modal__qrcode__title">{message}</div>
					<div className="mypage-modal__qrcode__pct"><img src={this.state.qr}/></div>
					<div className="mypage-modal__qrcode__btns">
						<a href="#" onClick={this.handleCopyUrl} ref={node=>this.copyBtn=node}><img src="/assets/images/me/ico-copy.svg" alt="URLをコピー"/><span>URLをコピー</span></a>
						<a href="#" onClick={this.handleShareLine}><img src="/assets/images/me/ico-line.svg" alt="LINEで共有"/><span>LINEで共有</span></a>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const device = getDeviceInfo()
		return (device.isPC) ? this.pc(): this.sp()
	}
}