import React from 'react'
import { scrollToElement } from '../../../utils'
import Menu from "../../../modules/menu"
import SideMenu from '../../component/common/sidemenu'

export default class ScrollLink extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			...this.state,
			isOpen: false
		}
	}
	initMenu() {
		if (this.state.isAuthorized && this.menu == null) {
			this.menu = new Menu('.js_menu-me', '.js_menu-me_open_btn', '.js_menu-me_close_btn')
		}
			console.log("menuinit")
	}
	// componentDidMount() {
	// 	// this.menu = new Menu('.js_menu', '.js_menu_open_btn', '.js_menu_close_btn')
	// }
	onClick = (e) => {
		// console.log(SideMenu)
		// this.menu.close(e,200)
		// console.log(SideMenu)
		// SideMenu.menu.close(e,200)
		if (this.menu) this.menu.close(e,200)
		e.preventDefault()
		scrollToElement($(this.props.to))		
	}

	render () {
		return (
			<a onClick={this.onClick} className={this.props.className} href="#">{this.props.children}</a>
		)
	}
}